.listing-action-items {

  a {
    text-decoration: none;
    color: $white;
  }

  .sf-form {
    .sf-form-row {
      padding: $base-unit 0;
    }
  }

  .listing-see-details {
    color: $sf-gray-dark;
    font-size: 12px;
    position: absolute;
    bottom: 0px;
    right: 10px;

    &:hover {
      text-decoration: underline;
      color: $sf-green;
    }
  }

  &.admin {
    .sf-btn-primary {
      width: calc(50% - 5px);
      margin: 0;

      &:first-of-type {
        margin-right: 5px;
      }

      &:last-of-type {
        margin-left: 5px;
      }
    }
  }

  .sf-btn-primary {
    width: 100%;
    line-height: $font-size-lg;
    border: none;
  }

  .checkbox-inquiry-submitted {
    background: $sf-gray-light;
    height:24px;
    width:24px;
    border-radius: 3px;
    color: $sf-gray-base;
    text-align: center;
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-weight: 500;

    @include screen-max('sm') {
      left: 0px;
      bottom: 0px;
    }

    .fa {
      line-height: 24px;
      font-size: 18px;
    }
  }

  .listing-controls-checkbox {
    width: 24px;
    height: 24px;
    border: 1px solid $sf-gray-base;
    border-radius: 3px;
    position: absolute;
    left: 10px;
    bottom: 0px;
    cursor: pointer;

    @include screen-max('sm') {
      width: 20px;
      height:20px;
      left: 0px;
      bottom:-5px;
    }

    &:hover {
      background: #FAFAFA;
    }

    &.checked {
      &:after {
        font: normal normal normal 20px FontAwesome;
        position: absolute;
        left: 1px;
        top: 1px;
        content: '\f00c';
        color: $sf-green;
        display: block;

        @include screen-max('sm') {
          left: 0px;
          top: 0px;
          font-size: 18px;
        }
      }
    }
  }
}
