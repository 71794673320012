.sf-inbox-list {
  margin: 0 $base-unit;
  @include screen('sm') {
    margin: 0;
  }
  .inbox-no-results {
    text-align: center;
    margin: $base-unit auto;
  }

  .inbox-item {
    @include screen('sm') {
      &:first-child {
        border-top: 0;
      }
    }
  }
}
