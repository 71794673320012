.stf-loading {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;

  &.fullscreen {
    position: fixed;
  }

  &.hidden-default {
    display: none;
  }

  &::after {
    width: 100px;
    height: 100px;
    display: block;
    content: '';
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 50px);
  }

  &.dark {
    background: rgba(255, 255, 255, 0.4);

    &::after {
      background: url('/assets/images/loading/stf-loader-dark.gif');
    }
  }

  &.light {
    background: rgba(0, 0, 0, 0.4);

    &::after {
      background: url('/assets/images/loading/stf-loader-light.gif');
    }
  }
}

.loading {
  position:absolute;
  height:30px;
  width:30px;
  background-position:center center;
  -webkit-animation: spin 0.4s infinite linear;
  animation: spin  0.4s infinite linear;

  &.center {
    left: calc(50% - 15px);
  }

  &.light {
    background: url('/assets/images/loading/loading_spinner_ring_light.png');
    background-size: cover;
    opacity: 0.75;
  }
  &.dark {
    background: url('/assets/images/loading/loading_spinner_ring_dark.png');
    background-size: cover;
    opacity: 0.7;
  }
}

.fullscreen-loading {
  @include z-layer-top;

  width: 100%;
  min-height: calc(100vh - #{$navbar-height});
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.2);
}

.loading-dots {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: $base-unit * 10;

  .dot {
    height:8px;
    width:8px;
    border-radius: 50%;
    display: inline-block;
    margin: auto 4px;
    background: #fff;

    animation: ellipsis 700ms infinite;
    -webkit-animation: ellipsis 700ms infinite;
    -moz-animation: ellipsis 700ms infinite;
    -o-animation: ellipsis 700ms infinite;

    &:nth-child(2) {
      -webkit-animation-delay: 50ms;
      animation-delay: 50ms;
    }

    &:nth-child(3) {
      -webkit-animation-delay: 100ms;
      animation-delay: 100ms;
    }

    &:nth-child(4) {
      -webkit-animation-delay: 150ms;
      animation-delay: 150ms;
    }
  }

  &.dark {
    .dot {
      background: $sf-gray-dark;
    }
  }

  &.append {
    position: static;
    top: auto;
    padding: 20px 0;
  }
}

.loading-text {
  display:block;
  line-height: 60px;
  text-align:center;
  color: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: scale(0.0);
  }
  50% {
    -webkit-transform: scale(1.0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

@keyframes ellipsis {
  0% { opacity: 0; }
  24% { opacity: 0; }
  25% { transform: scale(1); opacity: 1; }
  30% { transform: scale(1.5); }
  50% { transform: scale(0.9); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}

@-webkit-keyframes ellipsis {
  0% { opacity: 0; }
  24% { opacity: 0; }
  25% { -webkit-transform: scale(1); opacity: 1; }
  30% { -webkit-transform: scale(1.5); }
  50% { -webkit-transform: scale(0.9); }
  80% { -webkit-transform: scale(1); }
  100% { -webkit-transform: scale(1); }
}
@-moz-keyframes ellipsis {
  0% { opacity: 0; }
  24% { opacity: 0; }
  25% { transform: scale(1); opacity: 1; }
  30% { transform: scale(1.5); }
  50% { transform: scale(0.9); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}
@-o-keyframes ellipsis {
  0% { opacity: 0; }
  24% { opacity: 0; }
  25% { transform: scale(1); opacity: 1; }
  30% { transform: scale(1.5); }
  50% { transform: scale(0.9); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}
@keyframes ellipsis {
  0% { opacity: 0; }
  24% { opacity: 0; }
  25% { transform: scale(1); opacity: 1; }
  30% { transform: scale(1.5); }
  50% { transform: scale(0.9); }
  80% { transform: scale(1); }
  100% { transform: scale(1); }
}
