html > body.embedded-listing {
  padding-top: 0;
}

.listing.widget {
  width: 400px;
  height: 300px;
  background: #FFF;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 1px solid $secondary-5-grey;

  .widget-logo {
    height: 24px;
    position: absolute;
    z-index: 2;
    top: 8px;
    right: 8px;
  }

  .listing-photo-container {
    height: 170px;
    overflow: hidden;

    .listing-photo {
      background-size: cover;
      background-position: center center;
      height: 100%;
      width: 100%;
      transition: all ease-in .25s;
      transform: scale(1);
    }

    &.not-found {
      height: 185px;
    }
  }

  &:hover .listing-photo-container .listing-photo {
    transform: scale(1.1);
  }

  .listing-widget-404-title {
    color: #FFF;
    position: absolute;
    width: 100%;
    top: 60px;
    text-align: center;
    font-size: 36px;
    font-weight: $font-weight-heavy;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.2);
  }

  .listing-header {
    .listing-header-contents {
      padding: 0px 40px;
      font-weight: $font-weight-regular;
      line-height: 40px;

      @include screen-max('sm') {
        padding: 0 20px;
        line-height: 30px;
      }

      .listing-price {
        font-size: 16px;
        cursor: default;
        float: none;
      }
    }
  }

  .listing-main-info {
    position: relative;
    padding: 20px;

    @include screen-max('sm') {
      padding: 20px 10px 10px 10px;
    }
  }

  .listing-title {
    font-size: 14px;
    color: $secondary-2-grey;
    font-weight: $font-weight-regular;
    margin-bottom: 4px;

    @include text-overflow;
  }

  .listing-owner-avatar {
    right: 20px;
    top: -40px;
    height: 50px;
    width: 50px;
    border-radius: 4px;
    border: 2px solid #FFF;
    position: absolute;
    background-size: cover;
    background-position: center center;
    background-color: #FFF;
  }

  .listing-details {
    font-size: 12px;
    color: $secondary-2-grey;

    .listing-address {
      font-size: 12px;
      margin-bottom: 2px;

      @include text-overflow;
    }

  }
  .btn-view-listing {
    color: $secondary-2-grey;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    float: none;
    display: block;
    border-top: 1px solid $secondary-5-grey;
    background: #FFF;
    font-weight: $font-weight-regular;
    transition: background 0.2s ease;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0px;
    text-decoration: underline;

    &:hover {
      background: #F5F5F5;
      border-color: #F5F5F5;
    }
  }
}
