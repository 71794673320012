.sf-form-simplified-project-type-selector {
  .sf-form-simplified-project-type-selector-container {
    .sf-form-row {
      padding: 0;

      .sf-form-tag {
        width: 100%;

        &:first-of-type {
          margin-top: 0;
        }

        margin-top: $base-unit;
        display: block;

        .tag-label {
          color: $secondary-2-grey;
          font-weight: $font-weight-light;
          font-family: $brown-pro;
        }
      }
    }
  }
}