.sf-ics-link {
  .add-to-calendar-button {
    margin: 20px $quarter-unit 2px;
    border-radius: $border-radius-sm;
    border: 1px solid #57d497;
    color: $sf-green !important;
    box-shadow: none !important;
    padding: 7px 35px 9px;
    font-size: $font-size-xs !important;
    visibility: hidden;

    .addeventatc_icon {
      display:none;
    }
  }
}