// Prismic container
#psm-content.homepage {
  margin-top: -$base-unit * 4;

  @include landscape-mode {
    margin-top: 0;
  }
}

.main-splash-container {
  width: 100%;

  .main-splash-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100vh;
    text-align: center;
    position: relative;
    padding-bottom: $base-unit * 4;
    background-position: center center;
    background-size: cover;

    &:after {
      width: 100%;
      height: 100%;
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.15);
    }

    @include landscape-mode {
      height: 80vh;
      text-align: left;
      padding-bottom: 0;
    }

    .main-image-carousel {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-position: center center;
      background-size: cover;
      opacity: 1;
    }

    // Title
    .main-splash-header {
      @include z-layer-1;

      width: 100%;
      color: $sf-white;
      font-size: $font-size-xxxl;
      font-weight: $font-weight-heavy;
      padding: 0 ($quarter-unit * 3);

      @include screen('xs') {
        font-size: 40px;
      }

      @include screen('ss') {
        font-size: 46px;
      }

      @include screen('sm') {
        font-size: 50px;
      }

      @include landscape-mode {
        font-size: $font-size-xl;
      }
    }

    // Subtitle
    .main-splash-subheader {
      @include z-layer-1;

      width: 100%;
      color: $sf-white;
      font-size: $base-unit;
      font-weight: $font-weight-regular;
      margin: $base-unit 0;
      padding: 0 ($quarter-unit * 3);

      @include screen('xs') {
        font-size: $font-size-lg;
      }

      @include screen('ss') {
        margin: ($base-unit * 2) 0 $base-unit;
      }

      @include screen('sm') {
        margin: ($base-unit * 2) 0;
        font-size: 21px;
      }

      @include landscape-mode {
        margin: 0 0 $half-unit;
        font-size: $font-size-md;
        font-weight: $font-weight-light;
      }
    }

    .main-splash-header, .main-splash-subheader {
      @include landscape-mode {
        text-align: center;
      }
    }

    // Search form
    .main-search {
      @include z-layer-2;

      width: 100%;

      .search {
        max-width: none;
        margin: $quarter-unit 0 0;
        padding: 0 ($quarter-unit * 3);

        @include screen('sm') {
          margin-top: $half-unit;
        }
      }
    }
  }

  // Core markets
  .secondary-search {
    @include z-layer-1;

    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(0,0,0,.4));

    @include landscape-mode {
      background: $sf-white;
      position: relative;
    }

    .core-markets {
      list-style: none;
      padding: 10px 0;
      color: $sf-white;

      @include landscape-mode {
        color: $sf-black;
      }

      a {
        color: $sf-white;

        @include landscape-mode {
          color: $sf-black;
        }
      }

      li {
        display: inline-block;
        padding: $quarter-unit $half-unit;
        font-weight: $font-weight-regular;

        @include screen('md') {
          padding: $quarter-unit $base-unit;
        }

        &.title {
          text-decoration: underline;
        }
      }
    }

    @include screen-max('sm') {
      font-size: 12px;
    }
  }
}
