#show-map-switch {
  margin-left: $base-unit;
  display: none;
  border-left: 1px solid $sf-gray-base;
  padding-left: $padding-base;

  @include screen('sm') {
    display: inline-block;
  }

  .label {
    display: inline-block;
    margin-right: $half-unit;
    font-weight: $font-weight-regular;
  }
}
