.how-it-works-section {
  &.blog-links {
    max-width: $max-content-width-md;
    margin: auto;

    p {
      text-align: center;
      color: $sf-black;
      font-size: 16px;

      @include screen-max('sm') {
        padding: 0 16px;
      }
    }
  }
  .blog-link-item {
    width: calc(100% / 3);
    display: inline-block;
    text-align: center;
    padding: 24px 16px;
    vertical-align: top;

    @include screen-max('sm') {
      width: 100%;
      padding: 16px;
    }

    .blog-link-item-header {
      color: $sf-black;
      padding-bottom: 24px;
    }

    img {
      width: 100%;
      max-width: 267px;
      margin: auto;
    }

    .blog-link-item-image {
      width: 100%;
      max-width: 267px;
      height:400px;
      margin: auto;
      background-size: cover;
      background-position: center center;
      position: relative;
      cursor: pointer;

      @include screen-max('sm') {
        max-width: 80%;
      }
      @include screen-max('xs') {
        max-width: none;
        height: 300px;
      }

      &:hover {
        .blog-link-item-title {
          text-decoration: underline;
        }
      }
    }

    .blog-link-item-title {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px;
      background: rgba(0, 0, 0, 0.5);
      color: #FFF;
    }
  }

}
