.btn {
  padding: 20px;
  border-radius: 4px;
  background: $primary-green;
  color: #FFF;
  display: inline-block;
  transition: background 0.1s ease;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;

  &:hover {
    color: #FFF;
    background: darken($primary-green, 10%);
  }

  &:active {
    box-shadow: inset 0px 2px 2px rgba(0,0,0,0.2);
    background: darken($primary-green, 15%);
  }

  &.large {
    padding: 20px 30px;
  }

  &.small {
    padding: 10px;
    font-size: 14px;
  }

  &.outline-only {
    border: 1px solid $primary-green;
    background: rgba(255,255,255,0);
    color: $primary-green;

    &:hover {
      background: rgba(87, 212, 151,0.1);
    }

    &:active {
      background: $primary-green;
      color:#FFF;
      box-shadow: none;
    }
  }
}
