.payout-account {
  position: relative;
}

.payout-account-default {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0 5px;
  background: #fff;
  border: 1px solid #74B0FA;
  color: #74B0FA;
  border-radius: 4px;
  font-size: 12px;
}
