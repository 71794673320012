.sf-slider {
  display:inline-block;
  position: relative;
  border-right: 1px solid $sf-gray-light;

  @include screen-max('sm') {
    width: 100%;
    display:block;
  }

  .slider-text {
    color: $sf-gray-dark;
    text-align: center;
    vertical-align:top;
    height: 20px;
    line-height: 20px;
    font-weight: bold;

    @include screen-max('sm') {
      font-size: 12px;
    }
  }

  .transclude-content {
    height: 30px;
  }

  .ui-widget-content {
    border: none;
    height:5px;
    position:relative;

    @include screen-max('sm') {
      margin-left: 0px;
      padding:0 10px;
    }

    .ui-widget-header {
      background: $sf-green;
      box-shadow: inset 0 2px 2px rgba(0,0,16,0.1);
    }

    .ui-slider-handle {
      @include circle($base-unit * 1.25);

      cursor: -webkit-grab;
      background: #FFF;
      border: 1px solid $sf-black;
      box-shadow: $box-shadow-base;
      top: ($half-unit * -1);
      outline: none;
      margin:0;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);

      &:active {
        cursor: -webkit-grabbing;
      }
    }
  }
}
