.sf-form-event-type-container {
  padding-bottom: $base-unit;

  @include screen-max('sm') {
    padding-bottom: $half-unit;
  }
}

.sf-form-event-type {
 @include button-secondary($sf-gray-dark);

 width: calc(25% - 8px);
 padding: $base-unit;
 text-align: left;
 overflow: auto;

 @include screen-max('sm') {
   margin-bottom: 8px;
   width: calc(50% - 8px);
 }

 &.secondary {
   .fa {
     @include align-middle;
   }

   .info-section .event-type-name {
     margin: 0;
   }
 }

 &.selected {
   @include button-primary;

   padding: $base-unit;
   text-align: left;
   overflow: auto;

   &:hover {
     @include status-base($sf-success);
   }
 }

 &.disabled {
   opacity: 0.6;
 }

 .fa {
   @include absolute-top-left($base-unit);
   font-size: $font-size-lg;
 }

 .info-section {
   width: calc(100% - #{$base-unit * 1.5});
   float:right;

    .event-type-name {
      margin-bottom: $half-unit;
      font-size: $font-size-sm;
      font-weight: 200;
    }
    .event-type-example {
      font-size: $font-size-xs;
      font-weight: 200;
    }
 }

}
