.sf-listing-results {
  position: relative;
  clear: both;
  overflow: auto;
  padding: 0 ($half-unit * 1.5) $half-unit;

  @include screen('sm') {
    padding-top: $half-unit;
  }

  @include screen('md') {
    top: 40px;
    padding-top: $half-unit * 5;
  }

  .listings-empty {
    padding: $padding-base;

    .sf-icon {
      font-size: ($base-unit * 4);
      padding: $half-unit;
      text-align: center;
      padding-top: 5vh;
    }

    p {
      text-align: center;
    }

    .hbspt-form {
      width: 100%;

      iframe {
        padding: 0 ($base-unit * 3);
        width: 100% !important; //- NOTE: must do this because Hubspot will try to auto-size it and fail miserably
        border: 0;
      }
    }
  }
}
