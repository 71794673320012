.sf-message-activity {
  text-align: center;

  .sf-message-container {
    font-weight: $font-weight-regular;
    font-size: $font-size-xs;
    color: $sf-black;
    padding: 0;
  }

  .sf-modal .sf-modal-contents .sf-modal-contents-body {
    padding: $padding-base;
    text-align: left;

    .message-user-name {
      margin-top: 0 !important;
      margin-bottom: $base-unit;
    }

    .dates {
      padding-bottom: $padding-base;
      margin-bottom: $base-unit;
      border-bottom: 1px solid $sf-gray-light;

      .move-in, .move-out {
        color: $sf-gray-dark;
        font-weight: $font-weight-light;

        .title {
          margin-bottom: $half-unit;
        }

        &.move-in {
          margin-right: $base-unit * 3;
        }
      }

      &:after {
        display: block;
        content: "";
        clear: both;
      }

      .date {
        color: $black;
        font-weight: $font-weight-regular;
        font-size: $font-size-lg;
      }
    }

    .invoice-details {
      padding: $padding-base;
      border: 1px solid $sf-gray-light;
      text-align: left;

      .invoice-details-row {
        font-size: $font-size-sm;
        margin-bottom: $half-unit;
        color: $sf-gray-dark;

        .invoice-details-label {
          display: inline-block;
        }
      }

      .invoice-details-discount-warning {
        color: $sf-orange;
        font-size: $font-size-xs;
        margin-top: $base-unit;
        text-align: center;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .invoice-details-fees-warning {
        color: $sf-gray-dark;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        margin-top: $base-unit;
      }

      .invoice-details-total {
        font-weight: $font-weight-regular;

        .invoice-details-row {
          font-size: $font-size-md;
          color: $black;
          margin-bottom: 0;
        }
      }
    }
  }
}
