.sf-modal {
  position: fixed;
  z-index: 2147483020;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: scroll;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  @include screen-max('xs') {
    background: #FFF;
  }

  .sf-modal-backdrop {
    position : absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .sf-modal-title {
    font-weight: $font-weight-heavy;
    font-size: $font-size-xl !important;

    padding:$half-unit 0;
    color: $sf-gray-dark;
    text-align: center;

    @include screen-max('sm') {
      padding-top: $base-unit;
    }
  }

  .sf-modal-contents {
    z-index: 1;
    padding: $base-unit;
    background-color: $sf-white;
    border-radius: $border-radius-base;
    width: 500px;
    margin-bottom:80px;
    position: relative;

    @include screen-max('sm') {
      width: 90%;
    }

    @include screen-max('xs') {
      position: absolute;
      border-radius: 0;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 40px;
    }

    p {
      text-align: center;
    }

    &.narrow {
      max-width: 415px;

      .sf-form {
        padding: 0 20px;

        @include screen-max('xs') {
          padding: 0;
        }
      }
    }

    &.large {
      width: 650px;
      padding: 20px;

      @include screen-max('sm') {
        width: auto;
        padding: 10px;
      }
    }

    &.invoice-modal {
      padding:0px;
      width: 540px;

      @include screen-max('sm') {
        width: 100%;
      }
    }

    .sf-modal-close {
      @include button-close-gutter;
    }

    .sf-modal-back {
      position: absolute;
      top: 4px;
      left: 15px;
      font-size: 23px;
      cursor: pointer;
      color: $sf-gray-dark;
    }

    .sf-modal-contents-body {
      padding: $base-unit;
      text-align: center;

      p {
        text-align: left;
      }
    }

    .sf-modal-subheader {
      @include header-md;
      text-align: left;

      .fa {
        font-size: $font-size-xl;
        margin-right: $base-unit;
        color: $sf-gray-dark;
        width: $base-unit * 2;
      }
    }

    .sf-modal-text {
      @include body-text;

      &.extra-margin {
        margin-left: ($base-unit * 3);
      }
    }

    .sf-modal-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .btn {
        flex-grow: 1;
        margin: 0 $half-unit 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.btn-light {
          padding: $half-unit;
        }
      }
    }
  }
}
