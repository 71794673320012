.sf-scroll-thumbnails {
  overflow: hidden;
  position: relative;

  ul {
    height: 100%;
    display: block;
    white-space: nowrap;
    position: absolute;
    -webkit-transition: left 0.3s ease-in-out;
    -moz-transition: left 0.3s ease-in-out;
    -o-transition: left 0.3s ease-in-out;
    transition: left 0.3s ease-in-out;
    padding: 0;
    margin: 0;

    li {
      height: 100%;
      display: inline-block;
      opacity: 0.5;
      cursor: pointer;
      transition: all ease .25s;

      &:hover {
        opacity: 1;
      }

      img {
        display: block;
        height: 100%;
        border-radius: $border-radius-base;
      }

      + li {
        margin-left: $padding-base;
      }
    }

    li.active {
      opacity: 1;
    }
  }
}
