.sf-pagination.pagination-container {
  padding: ($padding-base * 2) $padding-base ($padding-base * 4) $padding-base;
  color: $sf-gray-dark;

  @include screen-max('sm') {
    padding: $base-unit $half-unit;

    text-align: center;
  }

  .listing-pagebar {
    margin: 0;
    padding: 0;

    @include screen-max('sm') {
      margin-top: 10px;
    }
  }

  .pagination > li {
    @include square($base-unit * 2);
    border-radius: $border-radius-sm;
    display: inline-block;
    vertical-align: top;
    margin: 0 ($half-unit / 2);
    background: $white;
    line-height: ($base-unit * 2);
    text-align: center;
    border: 1px solid $sf-gray-base;
    cursor: pointer;

    @include screen-max('sm') {
      width: $base-unit * 1.5;
      margin: ($half-unit / 4);
    }

    &.ellipsis {
      background: none;
      border: none;
      cursor: default;
    }

    &.arrow {
      font-size: $font-size-lg;
    }

    &.active {
      @include status-base($sf-green);

      border-color: $sf-green;
    }
  }

}
