.sf-likert-scale {
  position: relative;
  margin-bottom: 10px;

  .likert-scale-label {
    font-weight: 500;
    color: $sf-black;
    padding-bottom: 10px;
  }

  .likert-scale-content {
    position: relative;
  }

  .likert-scale-star {
    position: absolute;
    width: 100%;
    top: 0px;
  }

  .likert-scale-rating {
    height: 12px;
    text-align: center;
    display: inline-block;
    float:left;
    cursor: pointer;
    transition: color 0.2s ease;

    &:hover {
      .likert-star {
        font-size: 35px;
        bottom: 10px;
      }
    }

    &.selected {
      .likert-star {
        animation: pulse_animation 700ms 1;
        -webkit-animation: pulse_animation 700ms 1;
        -moz-animation: pulse_animation 700ms 1;
        -o-animation: pulse_animation 700ms 1;
      }
    }

    .likert-star {
      font-size: 30px;
      margin: auto;
      position: relative;
      bottom: 8px;
      color: $sf-yellow;
      z-index: 10;
    }
  }

  .review-rank-labels {
    position: relative;
    top: 10px;
    .review-labels {
      display: block;
      height: 40px;
    }
    .review-rank-label {
      display: inline-block;
      margin-top: 10px;
      font-size: 10px;
      color: $sf-gray-dark;
      text-align: center;
      &.high {
        float: right;
      }
    }
  }
}
