.payment-methods-content-container {
  padding-bottom: 1px; // Ensure no white area at bottom due to form
}

.delete-confirm-actions {
  text-align:center;
  .btn {
    width: 40%;
    margin:0 5%
  }
}

.payment-method-container {
  min-height: calc(100vh - #{$navbar-height});
  background-color: $sf-white;
  left: 0px;
  right: 0px;
  max-width: $max-content-width-sm;
  padding: 0 $padding-base;
  margin: 0 auto;
  overflow: auto;

  @include screen-max('xs') {
    margin-top: 20px;
  }

  .payment-method-no-payment {
    text-align: center;
    margin-top: 24px;
  }

  .user-email-verified {
    border-radius: $border-radius-base;
    background-color: $secondary-6-grey;
    padding: $padding-base;
    margin-top: $base-unit;

    h3 {
      margin: 0 0 $base-unit;
    }

    .error-list {
      background: tint($sf-red, 90%);
      padding: $padding-base;
      border-radius: $border-radius-base;
      margin-bottom: $base-unit;
      font-weight: $font-weight-regular;
    }

    .success {
      background: tint($sf-green, 80%);
      padding: $padding-base;
      border-radius: $border-radius-base;
      margin-bottom: $base-unit;
      color: $sf-black;
      font-weight: $font-weight-regular;
    }

    button.btn.btn-primary {
      padding: $padding-quarter * 3;
      font-size: $font-size-md;

      &.disabled {
        background-color: $sf-gray-dark;
      }
    }
  }

  .add-payment {
    margin: 5px 0;
  }

  .payment-method-loading {
    position:relative;
    height: 60px;
    width:100%;

    .loading-payments-copy {
      display:block;
      margin:0 auto;
      text-align:center;
      position:relative;
      line-height: 100px;
    }
  }

}

/* SHOW PAGE */

.amount-validation-fields {
  .amount-field {
    display: inline-block;
    width: 50%;
    vertical-align: block;

    .sf-form-row {
      .alert-danger {
        .sf-form-row-header {
          display: none;
        }
      }

      .sf-form-row-input {
        input.form-control {
          width: calc(100% - 20px);
        }
      }
    }
  }

  .amount-field.amount-1 {
    .sf-form-row {
      .sf-form-row-header {
        .header-text {
          padding: 0 10px 0 20px;
        }
      }
      .sf-form-row-input-group {
        margin: 5px 10px 5px 20px;
      }
    }
  }

  .amount-field.amount-2 {
    .sf-form-row {
      .sf-form-row-header {
        .header-text {
          padding: 0 20px 0 10px;
        }
      }
      .sf-form-row-input-group {
        margin: 5px 20px 5px 10px;
      }
    }
  }
}

.verification-amounts {
  .verification-subheader {
    padding: 0 20px 20px;
  }

  .sf-form-footer {
    margin: 20px 20px 30px;
    padding: 0;
  }
}

.payment-account-info {

  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid $sf-gray-light;

}

.delete-button-container {
  margin-top: 25px;
  padding: 20px;

  a {
    color: #e52b21;
    font-weight: 900;
    cursor: pointer;
  }
}

/* CREATE PAGE */

.bank-hero {
  padding: 40px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  span.header {
    display: block;

    margin: 10px 0;

    font-size: $font-size-lg;
    font-weight: bold;
  }
}

.cc-validation-fields {
  .field-month,
  .field-year {
    display: inline-block;
    width: 50%;
    vertical-align: bottom;

    .sf-form-row {
      .alert-danger {
        .sf-form-row-header {
          display: none;
        }
      }
    }
  }

  .field-month {
    .sf-form-row {
      .sf-form-row-header {
        .header-text {
          padding-left: 0;
        }
      }
    }
  }

  .field-year {
    .sf-form-row {
      .sf-form-row-header {
        .header-text {
          padding-left: 0;
        }
      }
      .sf-form-row-input {
        select.form-control {
          margin: 5px auto 5px 0;
        }
      }
    }
  }
}

/* HOME PAGE */

.transaction-header {
  cursor: initial;

  & > strong {
    width: calc(100% - 120px);
  }

  & > span.transfer-fee {
    display: inline-block;
    width: 120px;
    text-align: right;
  }
}

.add-payment-page-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  p {
    font-size: 20px;
    text-align: center;
  }
}
.payment-methods {

  margin-top: 75px;

  @include screen-max('xs') {
    margin-top:20px;
  }

}

.payment-method {

  margin: 0;
  background-color: #FFF;
  overflow: hidden;
  position: relative;
  width: 100%;
  padding: 0;

  .payment-method-primary-info {
    padding: 20px;

    &.verification-header {
      cursor: pointer;
    }
  }

  .payment-method-main-info {
    display: inline-block;
    vertical-align: middle;
    padding: 0;

    width: calc(100% - 24px);
    @include text-overflow;

    .fa {
      width: 54px;
      font-size: 30px;
      color: $sf-gray-dark;
      margin-right:10px;
      vertical-align: middle;
    }
  }

  &.card,
  &.bank_account {
    cursor: pointer;
  }

  &.bank_account {

    .unverified {
      position: absolute;
      top: 5px;
      right: 5px;
      color: $sf-orange;
      border-radius: 4px;
      font-size: 12px;
      padding-bottom: 2px;
      width: 65px;
      text-align: center;
      border: solid 1px $sf-orange;

      @include screen-max('sm') {
        font-size: 8px;
        padding-bottom: 0px;
        position: absolute;
        top: 0px;
        right: -10px;
        border: 0px;
      }
    }
  }

  &:not(.card) {
    .payment-method-main-info {
      width: calc(100% - 24px); // no CC image
      padding-left: 0;
    }
  }

  .payment-method-show-arrow,
  .payment-method-more-arrow {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    font-size: 2em;
    color: lighten($sf-black, 50%);
    text-align: center;
    cursor: pointer;
  }
  .payment-method-show-arrow {
    margin-top: -3px; // Better center the arrow
  }

  .cc-image {

    display: inline-block;
    vertical-align: middle;
    width: 71px;
    height: 43px;
    background-image: url('/assets/images/credit_cards.png');

    &.amex {

      background-position: 0px -44px;

    }

    &.diners {

      background-position: 0px -88px;

    }

    &.jcb {

      background-position: 0px -132px;

    }

    &.discover {

      background-position: 0px -176px;

    }

    &.mastercard {

      background-position: 0px -220px;

    }

    &.maestro {

      background-position: 0px -264px;

    }

    &.visa {

      background-position: 0px -308px;

    }

  }

}

.payment-method-header {
  border-bottom: 1px solid $sf-gray-light;
  padding: 20px;

  .lock-icon {
    height: 1.5em !important;
    width: 1.5em !important;
    margin: $base-unit 0;
  }

  &.button {
    border: 2px solid $sf-gray-light;
    width: 80%;
    margin: ($base-unit * 1.5) auto $half-unit;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;

    &:hover {
      box-shadow:  0 0 8px rgba(0,0,0,0.1)
    }
  }

  &.white {
    background: #fff;
  }

  & > strong,
  & > span.title {
    display: inline-block;
    width: calc(100% - 75px);
    .fa {
      font-size: 16px;
      color:$sf-green;
      margin-right: 10px;
    }
  }

  & > span.fee,
  & > span.card-valid,
  & > span.bank-valid {
    display: inline-block;
    width: 75px;
    text-align: right;
    color: $sf-gray-dark;
  }

  & > span.fee {
    font-size: $font-size-sm;
    color:$sf-green;
  }

}

.border-top {
  .payment-method {
    border-top: 1px solid $sf-gray-light;
  }
}
.disable-pointer {
  .payment-method {
    cursor: default;
  }
}

.verification-pending {
  background-color: #ffdd99;
  // padding: 20px;
  margin: 20px 0 0;
  border-top: 1px solid $sf-gray-dark;
  border-bottom: 1px solid $sf-gray-dark;
}

.payment-method-update-button {
  cursor: pointer;

  .payment-method-main-info {
    color: $sf-green;
    font-weight: 400;
  }
}

.account-card-element {
  margin: 0 ($base-unit * 1.5);
  border: 1px solid $sf-gray-base;
  padding: $padding-half + 4;
  border-radius: 2px;
}

div.g-recaptcha#captcha {
  margin: ($base-unit * 1.5) 0 ($base-unit * 1.5) ($base-unit * 1.5);
}
