.sf-navbar {
  @include z-layer-top;
  @include card-anchored-top;
  @include body-text;

  position: fixed;
  overflow: visible;
  box-shadow: $box-shadow-line;
  display: flex;
  width: 100%;
  transition: all ease .2s;
  height: $navbar-height;

  &:not(.navbar-logged) {
    padding-right: $padding-base;
  }

  .nav-item {
    @include nav-item;
    transition: color ease .2s;

    &.green {
      color: $sf-green;
    }
  }

  @include screen-max('sm') {
    transition: left 0.1s ease, right 0.1s ease;
  }

  a {
    color: inherit;
  }

  .logo {
    @include nav-item;

    cursor: pointer;
    padding-left: 0px;

    @include screen-max('sm') {
      text-align: center;
    }

    img.img-light {
      height: $base-unit + $quarter-unit;

      @include screen-max('sm') {
        height: $base-unit;
        margin-bottom: $quarter-unit;
      }
    }
  }

  .flex-item {
    display: flex;
    align-items: center;

    .nav-btn {
      padding: $padding-half;
      border-radius: $border-radius-base;

      i.nav-icon {
        @include screen('md') {
          margin-right: $half-unit;
        }
      }

      &:hover {
        background-color: #f5f5f4;
        color: $sf-black;
      }
    }
  }

  .nav-separator {
    display: none;
    height: $base-unit;
    width: 1px;
    background: #ddd;
    margin: 0 $quarter-unit;

    @include screen('md') {
      margin: 0 $half-unit;
    }

    @include screen('sm') {
      display: block;
    }
  }

  .account-button {
    color: $white;
    background-color: $sf-gray-base;
    border-radius: 50%;
    padding: $quarter-unit 0;
    width: $base-unit * 2;
    height: $base-unit * 2;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      font-weight: $font-weight-heavy;
      font-size: $font-size-lg;
    }
  }

  .btn-list-space {
    margin-left: $half-unit;
  }

  .navbar-button {
    .fa {
      font-size: $font-size-lg;
    }

    @include screen('sm') {
      position: relative;
      bottom: ($quarter-unit * -1);
      margin-left: 5px;
    }

    @include screen-max('sm') {
      padding-left: 0px;
    }
  }

  .nav-avatar {
    @include avatar-md;
    position: relative;
    cursor: pointer;
  }

  .logo-dropdown {
    position: relative;
    display: flex;
    align-items: center;

    .nav-icon {
      width: $base-unit * 2;
      height: $base-unit * 2;
    }

    i.offset {
      margin-top: 12px;
    }
  }

  .nav-icon {
    position: relative;
    font-size: $font-size-md;
    color: #44403c;

    @include screen('md') {
      &:not(.always-visible) {
        display: none;
      }
    }

    svg {
      @include absolute-middle;
    }
  }

  .notification-count {
    @include status-base(#10B981);
    position: absolute;
    top: -2px;
    left: 16px;
    padding: 3px 6px;
    font-size: $font-size-sub;
    border: 1px solid $white;
    border-radius: $border-radius-base;
    font-weight: $font-weight-regular;
    pointer-events: none;

    @include screen('md') {
      padding: $padding-quarter;
      font-size: $font-size-xs;
      margin-left: $quarter-unit;
      position: relative;
      left: 0;
      top: 0;
    }
  }

  .navbar-title {
    position: relative;
    width: 100%;
    padding-left: $base-unit * 3;
  }

  .listing-search {
    position: relative;
    flex-grow: 1;
    cursor: default;

    .location-search {
      width: 100%;
      max-width: 480px;
    }

    @include screen-max('sm') {
      .address-results {
        @include border-bottom-radius(0);

        position: fixed;
        top: 60px;
        border: 0;
      }
    }

    input {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      outline: none;
      border: 1px solid $sf-gray-dark;
      border-radius: 5px;
      padding: 0;
      margin: 13px 0;

      line-height: $quarter-unit * 9;
      padding-left: $base-unit * 3;

      @include screen-max('sm') {
        padding-left: $quarter-unit * 9;
      }
    }

    .input-prefix {
      @include align-middle;
      @include z-layer-1;

      left: $base-unit;
      color: $sf-gray-dark;
      margin-top: $quarter-unit;

      @include screen-max('xs') {
        left: $half-unit;
      }
    }
  }
}

#homepage-navbar {
  &.navbar-transparent {
    .logo-dropdown .nav-icon {
      content: url('/assets/images/brand/sf_favicon_bow_white.png');

      @include landscape-mode {
        content: '';
      }
    }

    .logo .img-light {
      content: url('/assets/images/brand/storefront_logo_white.png');

      @include landscape-mode {
        content: '';
      }
    }

    .sf-navbar {
      background-color: transparent;
      color: $sf-white;
      box-shadow: none;

      @include landscape-mode {
        background-color: $sf-white;
        color: $sf-black;
      }

      &.navbar-logged {
        .listing-search + .nav-item.hidden-xs, .flex-item {
          display: none;

          @include landscape-mode {
            background-color: $sf-white;
            color: $sf-black;

            &.flex-item {
              display: flex;
            }
          }
        }
      }
    }
  }
}
