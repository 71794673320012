* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; // better scrolling on iOS webkit devices
  -ms-touch-action: manipulation; /* IE10  */
}

a {
  text-decoration: none;
  transition: color 0.2s ease;
  color: $primary-green;
}

img {
  border: 0px;
}
