.sf-form-row-images {
  .image-upload {
    padding: 20px;
    border-radius: $border-radius-base;
    border: 1px solid $sf-gray-base;
    text-align: center;

    @include screen-max('sm') {
      margin-bottom: $base-unit;
      padding: $half-unit;
    }

    &.highlighted {
      border: 2px solid $sf-green;
    }

    .sf-btn-primary {
      margin: 0;
    }

    .image-upload-text {
      font-weight: 400;
      color: darken($sf-gray-dark, 30%);
    }

    .image-upload-subtext {
      @include header-xs;

      margin-top: $half-unit;
      color: $sf-gray-dark;
    }

    .image-upload-tip {
      font-size: 10px;
      margin-top: 10px;
    }
  }
}

.sf-form-row-uploaded-image,
.sf-form-row-sort-placeholder,
.pending-uploads,
.successful-uploads {
  position: relative;
  display: inline-block;
  margin: $half-unit;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.0);
  transition: box-shadow 0.2s ease;
  background: $sf-gray-light;

  &.reorder {
    cursor: move;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    &:hover {
      box-shadow: $box-shadow-hover;
    }
  }

  &.landscape {
    width: calc((100% / 3) - #{$base-unit});
    height: 120px;
  }

  .fa-check {
    color: $sf-green;
  }

  .loading.dark {
    top: calc(50% - 16px);
    opacity: 0.4;
  }

  .sf-form-row-image-contents {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }

  .sf-form-row-remove-image {
    @include absolute-top-right($quarter-unit);
    @include circle($base-unit * 1.5);

    line-height: 22px;
    font-size: $font-size-lg;
    background: rgba($black, 0.8);
    color: $white;

    text-align: center;

    cursor: pointer;
  }
}

.pending-uploads {
  .loading-dots {
    @include align-middle;
    .dot {
      margin: auto;
      background: $sf-gray-dark;
    }
  }
}

.successful-uploads {
  background: $sf-green;

  &:after {
    @include absolute-middle;

    color: $white;
    font: normal normal normal $font-size-xl FontAwesome;
    content: '\f058';
  }
}

.sf-form-row-sort-placeholder {
  border: 2px dashed $sf-gray-dark;

  &:after {
    @include absolute-middle;
    @include subheader;

    font: normal normal normal $font-size-xl FontAwesome;
    content: '\f03e';
  }
}
