.sf-inquiry-user-panel {
  .user-info {
    @include text-overflow;

    width: calc(100% - 40px);
    padding-left: $base-unit;
    display: inline-block;

    @include screen-max('sm') {
      padding-left: 10px;
    }

    .user-name {
      @include header-md;
    }

    .user-email,
    .user-phone,
    .user-company-name {
      @include header-sm;
      @include text-overflow;
    }
  }
}
