.about-subnav {
  padding:20px 0;
  color: #FFF;
  width:100%;
  margin:0 auto;
  text-align:center;
  position: absolute;
  bottom: 10px;
  left:0;

  @include screen-max('sm') {
    padding: 10px 0;
  }

  &.dark {
    padding-top: 95px;
    border-bottom:1px solid $secondary-4-grey;

    @include screen-max('sm') {
      padding-top: 60px;
    }
    .subnav-item {
      color: darken($secondary-3-grey, 20%);

      &:hover {
        color: $secondary-2-grey;
      }

      &.active {
        color: darken($secondary-3-grey, 20%);
      }
    }
  }

  .subnav-item {
    cursor:pointer;
    width: width(100% / 8);
    min-width: 90px;
    text-align:center;
    display:inline-block;
    font-size:20px;
    color: rgba(255,255,255, 0.7);
    transition: color 0.3s ease, border-color 0.2s ease;
    margin: auto 10px;
    border-bottom: 3px solid transparent;

    @include screen-max('sm') {
      min-width: 50px;
      font-size:12px;
      margin: 0px;
    }

    &:hover {
      color: rgba(255,255,255, 1);
      border-color: $primary-green;
    }

    &.active {
      color: rgba(255,255,255, 1);
      border-bottom: 3px solid $primary-green;
    }
  }
}

.about-us {

  .about-section {
    position:relative;

    &.main {
      height: calc(100vh - #{$navbar-height});
      min-height: 400px;
      padding: 10px 40px;
      width: 100%;
      background-size: cover;
      background-position: center center;
      display: table;

      @include screen-max('sm') {
        background-position: 62% center;
        padding: 10px;
      }
    }

    &.index {
      background-image:
        linear-gradient(to top, rgba(0,0,0,0.5), transparent),
        url("/assets/images/about/about_header_image_06.jpg");
    }

    &.press {
      background-image:
        linear-gradient(to top, rgba(0,0,0,0.5), transparent),
        url("/assets/images/about/about_press_02.jpg");
      background-position: 28% center ;
    }

    &.info {
      padding: 60px 40px;
      background: #FFF;
      max-width: 800px;
      margin: auto;

      @include screen-max('sm') {
        padding:20px;
      }
    }

    &.members-container {
      padding-top: 60px;
      max-width:800px;
      margin:0 auto;
      text-align:center;
      background: #FFF;

      @include screen-max('sm') {
        padding-top: 40px;
      }

      .sf-image-icon {
        color: $secondary-2-grey;
      }
    }

    &.press {
      padding: 40px;
      margin: 0 auto;
    }

    &.bottom-content {
      max-width: $max-content-width-md;
      margin: 0 auto;
      padding: 40px 0 80px 0;

      @include screen-max('sm') {
        padding-bottom: 40px;
      }
    }

    .about-section-header {
      font-size: 36px;
      text-align: center;
      color: $secondary-2-grey;

      @include screen-max('sm') {
        font-size: 24px;
      }

      .fa {
        font-size: 50px;
        display: block;
        margin-bottom: 10px;

        @include screen-max('sm') {
          font-size: 30px;
        }
      }
    }

    p {
      line-height: 30px;
      color: darken($secondary-3-grey, 10%);

      @include screen-max('sm') {
        line-height:20px;
      }

      &.vision-copy {
        font-size: 20px;
        text-align: center;

        @include screen-max('sm') {
          font-size: 14px;
        }
      }
    }

    h3 {
      font-size: 30px;
      font-weight: 500;
      color: darken($secondary-3-grey, 20%);
      padding-top: 40px;
      padding-bottom: 10px;
      max-width: $max-content-width-md;
      margin: 0 auto;
    }

    .about-section-subheader {
      font-size: 20px;
      font-weight: 500;
      color: darken($secondary-3-grey, 20%);
      padding: 20px 0;
      max-width: $max-content-width-md;
      margin: 0 auto;
    }

    .press-link {
      max-width: $max-content-width-md;
      margin: 0 auto;
      font-size: 20px;
      padding: 15px 0;

      @include screen-max('sm') {
        font-size:14px;
        margin: 10px 0;
        padding: 10px 0;
      }

      &.first {
        margin-top: 20px;
      }

      a {
        margin-right: 10px;
      }

      span {
        font-size: 14px;
        color: darken($secondary-3-grey, 20%);
      }
    }

    .about-main-content {
      color: #FFF;
      display:table-row;
      vertical-align:middle;

      .about-main-container {
        max-width: 800px;
        margin:0 auto;
        text-align: center;

        .about-main-header {
          font-size: 52px;
          margin-bottom: 20px;
          text-shadow: 0px 2px 4px rgba(0,0,0,0.1);
          font-weight: $font-weight-heavy;

          @include screen-max('sm') {
            margin-top: 10px;
            font-size: 40px;
          }
        }

        p {
          margin: 10px auto;
          max-width: 800px;
          font-size: 18px;
          color: #FFF;
          text-shadow: 0px 2px 4px rgba(0,0,0,0.1);

          @include screen-max('sm') {
            font-size: 14px;
            margin-top: 20px;
          }
        }

        .about-main-subheader {
          margin-top: 10px;
          text-shadow: 0px 2px 6px rgba(0,0,0,0.1);

          @include screen-max('sm') {
            font-size: 10px;
          }
        }
      }
    }

    .about-info-panel-header {
      font-size: 24px;
      padding: 30px 0px 10px 0px;
      color: $secondary-2-grey;
      text-align: center;
      font-weight: $font-weight-heavy;
      max-width: 200px;
      margin-bottom: 20px;
      border-bottom: 3px solid $primary-green;

      @include screen-max('sm') {
        font-size: 16px;
        max-width: 50%;
        margin-bottom: 10px;
        padding-top: 10px;
        margin: auto;
      }
    }

    .about-info-panel {
      width:100%;
      max-width: 800px;
      margin:0px auto;
      overflow: auto;
      display: table;

      @include screen-max('sm') {
        margin-top: 10px;
      };

      .logo-circle {
        position: absolute;
        background: $secondary-2-grey;
        height:50px;
        width: 50px;
        border-radius: 50%;
        bottom:-26px;
        right: -26px;
        z-index: 1;
        border: 4px solid #FFF;
      }

      .about-info-panel-content {
        display: table-cell;
        vertical-align: top;
        padding:60px 40px;
        font-size: 30px;
        width: 50%;
        height:350px;
        text-align:center;
        color: $secondary-2-grey;
        position: relative;

        @include screen-max('sm') {
          padding: 10px;
          vertical-align: top;
          height: 280px;
          padding-top: 70px;
        }

        &.principle {
          height: 280px;
        }

        &.versus {
          &:first-of-type {
            border-right: 10px solid #FFF;

            @include screen-max('sm') {
              border-width: 0px;
            }

            &:after {
              content: 'VS';
              color: #FFF;
              position: absolute;
              height: 50px;
              width: 50px;
              line-height: 52px;
              font-size: 14px;
              border-radius: 50%;
              right:-39px;
              top: calc(50% - 25px);
              background: $secondary-2-grey;
              z-index: 1;
              border: 3px solid #FFF;

              @include screen-max('sm') {
                right: -18px;
                height:30px;
                width: 30px;
                line-height:30px;
                font-size: 10px;
                border-width: 2px;
              }
            }

          }

          &:last-of-type {
            border-left: 10px solid #FFF;

            @include screen-max('sm') {
              border-width: 0px;
            }

            &:after {
              content: ' ';
              position: absolute;
              height: 100%;
              left:-10px;
              top: 0;
              border-left: 3px solid $secondary-2-grey;
              z-index: 0;

              @include screen-max('sm') {
                left: 0px;
              }
            }
          }
        }

        .fa {
          font-size: 50px;
          margin-bottom: 10px;

          @include screen-max('sm') {
            font-size: 30px;
          }
        }

        .about-info-panel-number {
          font-size:50px;
          margin-bottom: 10px;
          font-weight: $font-weight-heavy;

          @include screen-max('sm') {
            font-size: 20px;
          }

          &.green {
            color: $primary-green;
          }
        }

        .about-info-panel-principles {
          font-size: 20px;
          margin-bottom:10px;
          color: $secondary-2-grey;

          @include screen-max('sm') {
            font-size: 14px;
          }

          b {
            border-bottom: 2px solid $primary-green;
          }
        }

        .about-info-panel-copy {
          font-size: 16px;
          line-height: initial;
          color: $secondary-2-grey;

          a {
            color: $primary-green;
            margin-left: 5px;
          }

          @include screen-max('sm') {
            font-size: 12px;
          }
        }
      }
    }

    .bottom-content {
      padding: 20px 0;
      font-size: 30px;
      text-align:center;

      @include screen-max('sm') {
        font-size: 20px;
        padding-bottom: 40px;
      }

      .bottom-text {
        max-width:500px;
        padding: 40px 0;
        margin:0 auto;
        font-weight: 400;
        color: $secondary-3-grey;

        @include screen-max('sm') {
          padding: 20px 0;
        }
      }

      .btn-bottom {
        padding:20px 30px;
        font-size: 18px;

        @include screen-max('sm') {
          font-size: 12px;
        }
      }
    }

    .brand-logo-wrapper {
      margin-top:0;
      padding-top:10px;
    }
  }
}
