@mixin status-base($bgColor: $sf-gray-dark) {
 color: $white;
 font-weight: bold;
 background: $bgColor;
}

@mixin status-outline($color: $sf-gray-dark) {
  color: $color;
  border-color: $color;
  background: transparent;
}

.pending {
  @include status-base($sf-pending);
}

.success {
  @include status-base($sf-success);
}

.warning {
  @include status-base($sf-warning);
  color: $sf-black;
}

.error {
  @include status-base($sf-error);
}

.archived {
  background: $sf-gray-base;
  color: $sf-gray-dark
}

.outline-pending {
 @include status-outline($sf-pending);
}

.outline-success {
 @include status-outline($sf-success);
}


.outline-warning {
 @include status-outline($sf-warning);
}

.outline-error {
 @include status-outline($sf-error);
}

.hidden {
  display: none;
}

.rejected {
  color: $sf-red;
}

@mixin unread-icon {
  @include align-middle;
  @include circle($half-unit);
  @include z-layer-1;

  background: $sf-green;
  left: ($half-unit / 2);
}

@mixin payment-statuses {
  &.c-failed,
  &.c-refunded,
  &.p-failed,
  &.canceled-inquiry,
  &.expired-inquiry {
    background: $sf-error;
    border-color: $sf-error;
  }

  &.c-missing-stripe-account {
    background: $sf-warning;
    border-color: $sf-warning;
  }

  &.c-pending,
  &.p-pending,
  &.c-authorized-deferred-terms {
    background: $sf-pending;
    border-color: $sf-pending;
  }
}
