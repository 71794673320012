.carousel {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  max-width: $max-content-width-md;
  min-height: 375px;
  margin: auto;
  transition: height ease .5s;

  @include screen-max('sm') {
    min-height: 55vh;
  }

  .panel-arrow {
    position:absolute;
    height: 100%;
    width: 100px;
    font-size: 60px;
    top:0;
    color: $secondary-3-grey;
    text-align:center;
    z-index: 5;
    transition: color 0.2s ease;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    @include screen-max('sm') {
      width: 50px;
      font-size: 40px;
    }

    &:hover {
      color: $secondary-2-grey;
    }

    &.previous {
      left: 40px;

      @include screen-max('sm') {
        left: 0px;
      }
    }

    &.next {
      right: 40px;

      @include screen-max('sm') {
        right: 0px;
      }
    }

    .fa {
      position:relative;
      top: calc(50% - 20px);
    }
  }

  .carousel-panel {
    width:100%;
    -webkit-transition: right 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition:         right 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
    position:absolute;
    display:none;

    &.active {
      display: block;
      right: 0px;
    }

    &.to-out {
      display: block;
      right: 100%
    }

    &.from-in {
      display: block;
      right: -100%;
    }
  }

  .success-story-panel {
    position: absolute;
    width: 60%;
    min-width: 400px;
    max-width: 600px;
    padding: 40px;
    background: #FFF;
    margin:0 auto;
    border-radius: 2px;
    position: relative;
    text-align: center;

    @include screen-max('sm') {
      width:250px;
      min-width:200px;
      padding:20px 20px 35px 20px;
    }

    .success-story-text {
      color: $secondary-2-grey;
      font-size: 16px;

      @include screen-max('sm') {
        font-size:13px;
      }
    }

    .success-story-name {
      margin: 20px auto;
      font-weight:400;
      font-size:15px;

      @include screen-max('sm') {
        font-size:13px;
      }
    }

    .success-story-image {
      width: 120px;
      height:120px;
      padding:10px;
      border-radius: 50%;
      background: #FFF;
      margin: 10px auto;
      overflow: hidden;

      @include screen-max('sm') {
        padding:5px;
        width: 80px;
        height: 80px;
      }

      img {
        width:100%;
      }
    }
  }
}
