.cluster {
  background-image: url('/assets/images/icons/map-cluster-unselected.png');
  width: 53px;
  height: 52px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  display: flex;
  align-items: center;

  &.highlighted {
    background-image: url('/assets/images/icons/map-cluster-selected.png');
  }

  > div {
    font-family: Arial, sans-serif;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: $font-weight-regular;
  }
}

.marker {
  background-image: url('/assets/images/brand/pin_unselected.png');
  width: 26px;
  height: 35px;
  cursor: pointer;
}

#search-container {
  position: relative;

  &::after {
    display: block;
    clear: both;
    content: '';
  }

  & + .footer {
    background-color: #f9f9f9;

    #psm-content {
      margin: 0;
    }
  }
}

#search-map {
  position: relative;
}

#search-map #map-container, #search-map #static-map {
  background-color: #EDE7DF;
  position: fixed;
  width: 35%;
  float: right;
  height: calc(100vh - #{$navbar-height} - 66px);
  top: $navbar-height + 66px;
  right: 0;
  display: none;

  @include screen('md') {
    width: 45%;
  }

  @include screen('sm') {
    display: block;
  }

  &.map-hidden {
    display: none;
  }

  &.scroll-anchor-to-footer {
    position: absolute;
    top: auto;
    bottom: 0;
  }

  #map {
    width: 100%;
    height: 100%;
  }

  #debug-info {
    position: absolute;
    top: 8px;
    left: 8px;
    background: $sf-black;
    color: $sf-white;
    font-size: $font-size-sm;
    padding: $padding-quarter;
  }
  .mapboxgl-ctrl-attrib-button {
    display: none;
  }
}
