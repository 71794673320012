.view-as {

  .view-as-container {
    .view-as-search {
      padding: $base-unit 0;

      .view-as-search-prefix {
        display: inline-block;
        height: 38px;
        width: 35px;
        border: 1px solid $sf-gray-base;
        border-right: none;
        border-radius: 6px 0px 0px 6px;
        float: left;
        text-align: center;
        line-height: 32px;
        font-size: 18px;
        color: $sf-gray-dark;
      }

      input {
        display: inline-block;
        width: calc(100% - 150px);
        border-left: none;
        border-radius: 0px 6px 6px 0px;
      }
    }

    .user-search-by {
      display: inline-block;
      width: 100px;
      float: right;
      box-shadow: none;
      border-color: $sf-gray-base;
      height: 40px;
    }
  }

  .view-as-results {
    max-height: 50vh;
    overflow:scroll;
    margin-bottom: 20px;

    .view-as-loading-container {
      height:60px;
      line-height:100px;
      text-align:center;
      color: $sf-gray-dark;
      font-weight: 400;
      display:block;

      .loading {
        opacity: 0.6;
      }
    }

    .view-as-empty {
      text-align: center;
    }

    .view-as-row {
      padding:10px;
      border-bottom:1px solid $sf-gray-light;
      display: table;
      width:100%;
      cursor: pointer;
      background:#FFF;
      transition: background 0.1s ease, border-color 0.1s ease;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background: lighten($sf-green, 5%);
        border-color: $sf-green;
      }

      .view-as-info {
        display: table-cell;
        padding-left: 10px;
        width: 100%;
        vertical-align: middle;

        .view-as-name {
          width: 50%;
          display:inline-block;
          font-weight: 400;
        }

        .view-as-email {
          width: 50%;
          display:inline-block;
          font-size: 11px;
          color: $sf-black;
        }
      }

      .view-as-arrow {
        display:table-cell;
        width:30px;
        vertical-align:middle;
        font-size: 10px;
        color:#FFF;

        .fa {
          font-size: 14px;
          position:relative;
          top:1px;
        }
      }
    }
  }

}
