.how-it-works {
  background: $white;

  .subnav-item {
    margin: auto 40px;

    @include screen-max('sm') {
      margin: auto 10px;
    }
  }

  .btn-call,
  .btn-email {
    margin: 10px;
    width: calc(50% - 40px);

    @include screen-max('sm') {
      width: 100%;
      margin: 10px auto;
    }
  }

  .how-it-works-section {
    position: relative;
    display: table;
    width: 100%;
    top: 0;
    min-height: 550px;
    padding:60px 0;

    @include screen-max('sm') {
      min-height: 450px;
    }

    &.hiw-buttons,
    &.listing-space,
    &.finding-space {
      max-width: 800px;
      margin: auto;
    }

    &.case-studies {
      margin-bottom: 40px;
    }

    &.main {
      height: calc(100vh - #{$navbar-height});

      @include screen-max('sm') {
        padding: 20px;
      }
    }

    &.video {
      text-align:center;
    }

    &.background-1 {
      background-image:
        linear-gradient(to top, rgba(0,0,0,0.4), transparent),
        url("/assets/images/how_it_works/how-it-works.jpg");
      background-size:cover;
      background-position: 50% 0%;
    }

    &.background-2 {
      background-image:
        linear-gradient(to top, rgba(0,0,0,0.4), transparent),
        url("/assets/images/how_it_works/finding_space.jpg");
      background-size:cover;
      background-position: 50% 0%;
    }

    &.background-3 {
      background-image:
        linear-gradient(to top, rgba(0,0,0,0.4), transparent),
        url("/assets/images/how_it_works/listing_space.jpg");
      background-size:cover;
      background-position: 50% 0%;
    }

    &.background-5 {
      background-image: url("/assets/images/how_it_works/header_image_storefront_guarantee.jpg");
      background-size:cover;
      background-position: center center;

      @include screen-max('sm') {
        background-position: 72% center;
      }
    }

    .how-it-works-section-header {
      font-size: 36px;
      text-align: center;
      color: $secondary-2-grey;

      @include screen-max('sm') {
        font-size: 20px;
        padding: 0px 10px;
      }

      .sf-icon {
        @include screen('sm') {
          height:80px;
          width: 80px;
        }
      }
    }

    .how-it-works-copy {
      margin: 10px auto;
      text-align: center;
      line-height: 30px;
      color: darken($secondary-3-grey, 10%);

      @include screen-max('sm') {
        line-height:20px;
        padding: 10px;
      }
    }
  }

  .section-container {
    display: table-cell;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: center;
  }

  .main-header {
    font-weight: $font-weight-heavy;
    font-size: 52px;
    margin-top: 40px;
    color: #FFF;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.1);

    @include screen-max('sm') {
      font-size: 24px;
      margin-top: 20px;
    }

    &.listing-space {
      margin-top: 15%;
      font-size:35px;

      @include screen-max('sm') {
        font-size: 20px;
      }
    }
  }

  .main-subheader {
    font-size: 20px;
    color: #FFF;
    margin-top: 10px;
    text-shadow: 0px 2px 4px rgba(0,0,0,0.1);

    @include screen-max('sm') {
      font-size: 14px;
    }
  }


  .image-copy-combo-panel {
    width: calc((100% / 3) - 25px);
    height: $base-unit * 16;
    display: inline-block;
    vertical-align: top;
    position: relative;
    overflow: hidden;
    padding: 40px 0px;
    margin: 10px;
    margin-top: 60px;

    @include screen-max('sm') {
      width: calc(100% - 40px);
      display: block;
      margin: 20px auto;
    }

    .combo-copy-header  {
      width: 75%;
      margin: auto;
      text-align: center;
      font-size: 30px;
      font-weight: $font-weight-heavy;
      padding-bottom: 10px;
    }
  }

  .btn-listing-space {
    margin-top: 40px;
  }
}

.how-it-works-steps {
  display:table;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  max-width: 1200px;
  margin: auto;

  &.main {
    max-width: $max-content-width-md;
    margin: 0 auto;
    padding-bottom: 40px;

    img {
      width: inherit;
    }

    .how-it-works-step-copy {
      text-shadow: none;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      .how-it-works-step-header {
        color: $secondary-2-grey;
        padding: 10px;

        @include screen-max('sm') {
          padding: 5px 10px;
        }
      }

      .how-it-works-step-subheader {
        color: $secondary-2-grey;
        padding: 10px;
      }
    }
  }

  .how-it-works-step-img {
    height: 200px;
    width: 200px;
    line-height: 120px;
    text-align:center;
    font-size: 60px;
    font-weight: 700;
    margin: 0 auto;
    text-shadow: none;
    color: $primary-green;
    margin-bottom: 10px;

    img {
      width: inherit;
    }

    @include screen-max('sm') {
      height: 120px;
      width: 120px;
    }
  }

  .how-it-works-step-copy {
    display:table-cell;
    min-height: 200px;
    vertical-align: top;
    text-align:center;
    padding: 40px;
    padding-top: 60px;
    color: $secondary-2-grey;

    @include screen-max('sm') {
      display:block;
      padding: 10px;
    }

    .how-it-works-step-header {
      font-size: 25px;
      font-weight: $font-weight-heavy;

      @include screen-max('sm') {
        font-size: 20px;
      }
    }

    .how-it-works-step-subheader {
      margin-top: 20px;
      font-size: 16px;
      max-width: $max-content-width-md;
      margin: 20px auto 0 auto;
      color: $secondary-3-grey;

      @include screen-max('sm') {
        margin-top: 0px;
        font-size: 12px;
        padding: 10px;
      }
    }
  }
}

.how-it-works-section-step {
  min-height: 200px;
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: table;
  margin-top: 60px;

  .how-it-works-step-panel {
    padding: 20px 40px;
    position: relative;
    display: table-cell;
    vertical-align: middle;
    color: $secondary-2-grey;

    @include screen-max('sm') {
      padding: 20px;
      font-size: 12px;
    }

    &:first-child {
      border-right: 3px solid $secondary-4-grey;
      text-align: center;
    }

    &.left {
      font-size: 24px;
      font-weight: $font-weight-heavy;
      color: $secondary-2-grey;
      width: 40%;

      @include screen-max('sm') {
        font-size: 16px;
      }
    }

    .sf-icon {
      margin-bottom: $half-unit * 1.5;
    }

    .fa-desktop {
      font-size: 70px;
      display: block;
      margin: auto;

      @include screen-max('sm') {
        font-size: 40px;
      }
    }
  }

  .step-number {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: $secondary-2-grey;
    text-align: center;
    line-height: 48px;
    color: #FFF;
    font-size: 16px;
    position: absolute;
    right: -26px;
    top: calc(50% - 26px);
    border: 2px solid #FFF;

    @include screen-max('sm') {
      height: 30px;
      width: 30px;
      line-height: 28px;
      font-size: 14px;
      right:-16px;
    }
  }

  .more-info {
    font-weight: $font-weight-heavy;
    color: $secondary-2-grey;
    border-bottom: 3px solid $primary-green;
    position: absolute;
    bottom: 0px;
    right: 40px;
    text-align: center;
    cursor: pointer;
  }
}

.more-info-panel {
  padding: 0px 20px;
  background: $secondary-6-grey;
  margin-top: 20px;
  color: $secondary-2-grey;
  overflow: hidden;
  height: 100%;
  max-height:0px;
  transition: max-height 0.4s ease;

  &.open {
    max-height: 100%;
  }

  .more-info-header {
    font-size: 18px;
    margin-bottom: 5px;
    padding-top: 20px;

    @include screen-max('sm') {
      font-size: 16px;
    }
  }

  .more-info-text {
    font-size: 14px;
    padding-bottom: 20px;

    a {
      color: $sf-green;
      text-decoration: underline;
    }

    @include screen-max('sm') {
      font-size: 12px;
    }
  }
}
