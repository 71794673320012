.manage-subscriptions-container {
  .manage-subscriptions {
    background: $white;
    margin: 0 auto;
    padding-bottom: $double-unit;

    @include screen('md') {
      width: 60%;
    }

    @include screen('sm') {
      padding: $base-unit;
    }
    @include screen('lg') {
      padding: $half-unit * 3;
    }
  }
}
