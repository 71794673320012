.sf-tooltip {
  @include card-popover(rgba(0, 0, 0, 0.75), transparent);

  color: $white;
  font-weight: normal;
  font-size: $font-size-xs;
  left: $half-unit * -1;
  top: calc(100% + 12px);
  padding: $half-unit $base-unit;

  a {
    color: $sf-green;
  }
}
