.owned-listings-container {
  max-width: $max-content-width-md;
  margin: auto;
  padding: 20px;
}

.listing-space {
  &.publish-landing-page {
    height: calc(100vh - #{$base-unit * 8});
    background: $white;
    padding: ($padding-base * 3) $base-unit $base-unit;

    .content {
      max-width: $max-content-width-md;
      margin: 0 auto;

      #btn-publish {
        margin-top: $base-unit * 2;
      }
    }
  }
}

[data-sf-share-listing] {
  pre {
    white-space: pre-wrap;
  }
}

.listing-container {
  overflow: visible;
  background-color: $white;

  .text-link a {
    color: inherit;
    text-decoration: underline;
    font-weight: $font-weight-medium;
  }

  @include screen-max('sm') {
    padding-bottom: 0px;
  }

  .listings-loading {
    padding-top: 80px;
    text-align: center;
    line-height: 100px;
  }

  .listing {
    overflow: visible;
    position: relative;

    @include screen-max('sm') {
      padding: 0px;
    }

    .listing-main-header {
      .listing-info-header {
        padding: $padding-base;
        line-height: 1;
        text-align: center;

        dl:first-of-type, dl:last-of-type {
          margin-top: 0;
          margin-bottom: 0;
        }

        &.pending {
          color: $sf-black;
        }

        &.locked {
          a:not(.cta) {
            color: inherit;
            text-decoration: underline;
            font-weight: $font-weight-regular;
          }
        }
      }

      .btn {
        margin: 0 3px;
        vertical-align: top;
      }
    }

    #downloadFloorPlansBanner {
      background-color: $sf-yellow;
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      width: 100%;
      z-index: 7;
      top: $double-unit * 2;
      padding: $padding-quarter $padding-double;
      p {
        width: 100%;
        text-align: center;
      }
      img {
        width: $double-unit;
      }
      button {
        background-color: transparent;
        border: none;
        width: $double-unit;
        align-items: center;
        cursor: pointer;
      }
    }

    .listing-photo-header {
      position: relative;

      &:hover {
        .download-images {
          opacity: 1;
        }
      }

      &.anchored {
        position: fixed;
        bottom: calc(100% - #{$navbar-height + 54px});
        z-index: 5;
        left: 0;
        right: 0;

        @include screen-max('sm') {
          left: 0;
          right: 0;
          bottom: calc(100% - 108px);
        }

        .photo-indicator {
          display: none;
        }
      }

      .photo-header {
        height: inherit;
        min-height: inherit;
        position: relative;
        overflow: hidden;
        justify-content: center;

        .listing-image {
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
        }

        .listing-mosaic-image {
          background-size: cover;
          background-position: center;
          cursor: pointer;
          position: relative;
          background-color: #eeeeee;

          &:not(.is-video)::before {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $sf-white;
            opacity: 0;
            transition: ease .2s;
          }

          &:not(.is-video):hover {
            &::before {
              opacity: 0.2;
            }
          }
        }

        .arrow {
          color: $white;
          text-shadow: 0 1px 2px rgba(0,0,0,0.6);

          &.left {
            position: absolute;
            left: 10px;
          }
          &.right {
            position: absolute;
            right: 10px;
          }
          .fa {
            font-size: 46px;
          }
        }
      }

      .download-images {
        @include circle($base-unit * 3);
        position: absolute;
        top: $base-unit;
        right: $base-unit;
        line-height: ($base-unit * 3);
        font-size: $font-size-xl;
        text-align: center;
        color: $white;
        background: rgba(0, 0, 0, 0.2);
        opacity: 0.2;
        cursor: pointer;
        transition: opacity 0.4s ease, background-color 0.4s ease;
        -webkit-transition: opacity 0.4s ease, background-color 0.4s ease;
        z-index: 10;

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }

        &.new {
          top: $base-unit * 4.5;

          @include screen-max('sm') {
            top: $base-unit * 4;
          }
        }
      }

      .download-images {
        top: $base-unit * 4.5;

        @include screen-max('sm') {
          display: none;
        }

        &.new {
          top: $base-unit * 9;
        }
      }

      .favorite-tag {
        @include favorite-tag($base-unit * 3, $font-size-xl, $base-unit * 3);
      }
    }

    #test-floor-plans-button {
      margin-left: 8px;
      margin-right: 8px;
      button {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin: auto;
        height: $double-unit + 3;
        border-radius: $border-radius-sm;
        border: 1px solid $sf-black;
        background-color: transparent;
        padding: $padding-quarter $padding-half;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        p {
          margin: 0;
          font-size: $font-size-sm;
        }
        img {
          width: $base-unit;
          margin-right: $quarter-unit;
        }
        &:hover {
          background-color: $sf-gray-light;
        }
        &:focus {
          outline: none;
        }
      }
    }

    .listing-details {
      position: relative;
      display: flex;
      justify-content: flex-end;
      flex-direction: row-reverse;

      p {
        font-size: $font-size-md;
        font-weight: $font-weight-medium;
      }

      button {
        border: 0;
        cursor: pointer;
        background: none;
      }

      .listing-badge:empty {
        display: none;

        & + div {
          display: none;
        }
      }

      .badge-container {
        &:empty + div {
          display: none;
        }

        .sf-listing-badge {
          display: flex;

          .ribbon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $padding-quarter $padding-half;
            font-size: $font-size-xs;
            font-weight: $font-weight-semi;
            border-radius: $border-radius-base;
            text-transform: uppercase;

            i {
              margin-right: 6px;
            }

            span {
              line-height: 1;
            }

            &.yellow {
              color: #374151;
            }
          }
        }
      }

      #quick_descriptions > div:last-of-type {
        border: 0;
      }

      .badge-tooltip {
        font-weight: 300;
      }

      .description {
        > .listing-description {
          &.draft, &.draft-original {
            margin-left: $base-unit;
            width: calc(100% - #{$base-unit});
            padding-left: $base-unit;
          }

          &.draft {
            border-left: 3px solid $sf-green;
          }

          &.draft-original {
            border-left: 3px solid $sf-red;
            text-decoration: line-through;
          }
        }
      }

      .listing-details-left {
        width: 100%;
        flex-shrink: 0;

        @include screen(1024px) {
          width: calc(100% - 350px);
        }
      }

      .listing-navbar-container {
        display: flex;
        justify-self: center;

        &::before {
          backdrop-filter: blur(4px);
          -webkit-backdrop-filter: blur(4px);

          position: absolute;
          left: 24px;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.8);
          content: "";

          @include screen(1024px) {
            display: block;
            width: calc(100% - 350px - 48px);
          }
        }

        .left {
          position: relative;
          width: 100%;

          @include screen(1024px) {
            width: calc(100% - 350px);
          }
        }

        .right {
          display: none;
          width: 350px;

          @include screen(1024px) {
            display: block;
          }
        }
      }

      .listing-details-right {
        display: none;
        transition: all ease .5s .1s;
        position: sticky;
        vertical-align: top;
        top: 80px;
        margin-left: $base-unit;
        flex-grow: 1;
        align-self: flex-start;

        @include screen(1024px) {
          display: block;
        }

        .managed-by-stf {
          background-image: url("/assets/images/icons/icons_concierge.png");
          background-repeat: no-repeat;
          background-position-x: calc(100% - #{$base-unit});
          background-position-y: 100%;
          background-size: 48px;

          a {
            color: #047857;
            font-weight: $font-weight-regular;
            text-decoration: underline;
          }
        }

        &.anchored {
          z-index: 6;
          position: fixed;
          /* 64px = sf navbar */
          /* $base-unit = good spacing */
          top: 64px;
          right: $base-unit;

          @include screen(1152px) {
            right: calc((100vw - 72rem) - ((100vw - 72rem) / 2) + 0.5rem);
          }

          &.scroll-anchor-to-footer {
            z-index: 0;
            position: absolute;
            top: initial;
            bottom: $base-unit;
            right: 0;
          }
        }

        .message-storefront-directly {
          .title {
            @include header-lg;
            text-align: center;
            margin: 18px;
            margin-top: 10px;
          }
          .contact-storefront-icons {
            position: inherit;
            .fa-envelope {
              color: $white;
            }
          }
          .sf-btn-large {
            max-width: 400px;
          }
          .sf-btn-secondary {
            cursor: default;
            &:hover {
              background-color: inherit;
              box-shadow: none;
            }
          }
        }
      }

      .favorite-count {
        display: inline-block;
        vertical-align: middle;
        background-color: $sf-gray-light;
        @include label;
      }
      .add-favorite-btn {
        font-size: $font-size-md;
      }

      .favorite-tag {
        .favorite-banner {
          border: 1px solid $sf-green;
          display: inline-block;
          font-size: $base-unit;

          cursor: pointer;
          overflow: hidden;

          &:before {
            transition: border-color 0.1s ease;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
          }

          .listing-icon {
            @include listing-icon(1, $base-unit, $sf-green);
          }
        }

        .sf-btn-secondary {
          padding: 6px $padding-base;
          border-radius: $border-radius-base;
          i {
            font-size: $base-unit;
          }
        }
      }

      .listing-details-left .favorite-tag {
        text-align: center;
      }

      .favorite-tag {
        .favorite-count {
          margin: 0 10px;
          margin-top: 20px;
        }
        .sf-btn-secondary {
          margin: 0 10px;
          margin-top: 20px;
        }
        text-align: center;
        margin-bottom: 18px;
        margin-top: 10px;
      }

      .listing-details-panel {
        width: 100%;
        display: inline-block;

        .information-title {
          margin-bottom: $base-unit * 1.5;
        }

        .sf-form-subheader {
          display: none;
        }

        &.pricing {
          background-color: $white;
          position: relative;
          padding: $padding-base;
          transition: all ease 0.2s;
          border-radius: $border-radius-lg;


          &.price-displayed .pricing-preview {
            display: none;
          }

          .information {
            @include interface-title;
            .pricing-unit {
              @include interface-body-strong;
            }

            &.pricing-preview > div {
              margin-bottom: 0;
            }

            & > div {
              margin-bottom: $half-unit;

              &::after {
                display: block;
                content: "";
                clear: both;
              }
            }
            .pricing-value {
              display: inline-block;
              margin-right: 6px;
            }

            &.prices-list {
              margin: $base-unit 0;

              .pricing-unit {
                float: left;
                color: $secondary-2-grey;
              }

              .pricing-value {
                float: right;
                font-size: $font-size-sm;
                color: $sf-gray-dark;
                font-weight: $font-weight-regular;
                margin: 0;
              }
            }
          }

          .listing-action-items {
            padding-top: $padding-half;

            > a {
              width: 100%;
              display: block;
              margin: $half-unit auto;
            }

            > div.share-listing {
              padding: $half-unit;
              display: inline-block;
              width: 100%;
              text-align: center;
              color: $sf-gray-dark;
              cursor: pointer;
              font-size: $font-size-lg;
              transition: color 0.2s ease, background 0.3s ease;
              border-radius: $border-radius-base;
              position: relative;
              border: 1px solid rgba(153, 153, 153, 0.3);
            }

            .blocked-dates {
              color: $sf-red;
              font-size: $font-size-sm;
              margin: $half-unit 0 $base-unit;
              font-weight: $font-weight-regular;
              text-align: center;
              padding: 0 $half-unit;
            }

            .sf-btn-primary {
              margin: 0;
              border-radius: $border-radius-base;
              line-height: 10px;

              &.disabled {
                background-color: $sf-gray-base !important;
              }
            }

            .sf-btn-secondary--gray {
              margin-bottom: 0px;
              border-radius: $border-radius-base;
              line-height: $half-unit;
              font-weight: 400;
              margin-top: 10px;
              width: 100%;
            }

            .sf-form-row {
              padding-top: 0;
              padding-bottom: $padding-quarter;

              .calendar-dates {
                .sf-form-header {
                  display: none;
                }
              }
            }

            form .pricing-unit {
              vertical-align: top;
            }

            .sf-form-hourly-or-daily-tab {
              .radio {
                padding: $padding-half $padding-quarter;
                &:first-of-type {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }

                &:last-of-type {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                }

                &.selected {
                  font-weight: 400;
                }

                &:not(.selected) {
                  color: $sf-gray-dark;
                }
              }
            }

            .sf-listing-datepicker {
              padding-bottom: $padding-quarter;

              .sf-form-hourly-show-invalid {
                padding: 0;
              }

              .sf-form-hourly-select-times {
                margin: 0 0 8px
              }
            }
          }

          span.inquiry-message {
            display: block;
            @include small-legend;
            text-align: center;

            a {
              color: $primary-green;
            }
          }

          .price-estimation {
            margin-top: $half-unit;
          }
        }

        .listing-features {
          display: inline-block;
          width: 50%;
          padding: 0;
          margin: $half-unit 0;

          &:nth-child(odd) {
            padding-right: $half-unit;
          }

          &:nth-child(even) {
            padding-left: $half-unit;
          }
          &:nth-child(1) {
            margin-top: 0;
          }
          &:nth-child(2) {
            margin-top: 0;
          }
        }

        & + .listing-details-panel {
          margin-top: $base-unit;
        }

        hr {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        @include screen-max('sm') {
          display: block;
          float: none;
        }

        .listing-details-left .listing-details-panel {
          border: unset;
          border-bottom: 1px solid $sf-gray-base;
          padding: $base-unit 0px;
        }

        .sf-btn-secondary {
          font-size: $font-size-md;
          padding: 8px $base-unit;
          border-radius: $border-radius-base;
        }
      }

      .nearby-places {
        display: none;

        @include screen-max('md') {
          margin-left: $base-unit;
        }

        &.show {
          display: block;
        }

        h3 {
          font-weight: $font-weight-regular;
          margin-top: -$quarter-unit;
        }

        table {
          margin-left: $base-unit;

          thead th {
            padding-bottom: $half-unit;
          }

          tbody td {
            padding: 0 $base-unit;
          }
        }
      }

      .listing-photos-container {
        .photo-container {
          overflow: hidden;
          align-items: center;
          aspect-ratio: 1.45;
          position: relative;
          cursor: pointer;

          .listing-photo {
            height: fit-content;
            width: 100%;
            transition: all ease .2s;
            transform: scale(1.1);

            &:hover {
              transform: scale(1.2);
            }
          }
        }

        &.draft, &.draft-original {
          margin-left: $base-unit;
          width: calc(100% - #{$base-unit});
          padding-left: $base-unit;
        }

        &.draft {
          border-left: 3px solid $sf-green;
        }

        &.draft-original {
          border-left: 3px solid $sf-red;

          > .photo-container {
            opacity: 0.5;
            position: relative;

            &:before, &:after {
              content: '';
              height: 4px;
              width: 100%;
              background: $sf-black;
              position: absolute;
              top: 50%;
              left: 0;
              right: 0;
            }

            &:after {
              transform: rotate(35deg);
            }

            &:before {
              transform: rotate(-35deg);
            }
          }
        }
      }
      .map-panel {
        clear: left;
      }
      .listing-map {
        width: 100%;
        margin-top: 26px;
        margin-bottom: 0;
        padding-bottom: 0;
        .map-header {
          background: $white;
          font-size: $font-size-md;
          padding-bottom: 20px;
          @include screen('sm') {
            font-size: $font-size-md;
            padding-bottom: $base-unit * 2;
          }
        }

        .map-container {
          position: relative;
          height: $base-unit * 20;

          @include screen('lg') {
            height: $base-unit * 24;
          }

          .angular-google-map,
          .angular-google-map-container {
            height: 100%;
            width: 100%;
            z-index: 0;
          }
        }
      }

      #availabilities-panel {
        .availabilities-calendars {
          position: relative;
          margin-top: $base-unit * 2;

          .calendar-nav-btn {
            position: absolute;
            top: 0;
            padding: $padding-quarter $padding-half;
            border: 1px solid $sf-gray-base;
            color: $sf-gray-base;
            cursor: pointer;
            font-size: $font-size-xs;
            border-radius: $border-radius-base;

            &:hover {
              background-color: $secondary-6-grey;
            }

            &:active {
              @include box-shadow($box-shadow-faint, true);
            }

            &.calendar-btn-prev {
              left: 0;
            }

            &.calendar-btn-next {
              right: 0;
            }
          }

          .sf-dates {
            &:after {
              display: block;
              content: "";
              clear: both;
            }

            table.listing-calendar {
              &:last-child {
                margin-bottom: 0;
              }

              tr {
                td.blocked {
                  color: $sf-gray-base;
                  cursor: default;
                  pointer-events: none;
                }

                td:not(:empty).updated {
                  animation: none;
                }
              }

              @include screen('sm') {
                width: calc(50% - #{$base-unit});
                float: left;
                margin-bottom: 0;

                &:last-child {
                  float: right;
                }
              }
            }
          }
        }

        .calendar-legend {
          margin-top: $base-unit;
          display: flex;
          align-items: center;

          .caption-color {
            display: inline-block;
            font-size: $font-size-sm;

            &:before {
              display: block;
              content: "";
              width: $base-unit;
              height: $base-unit;
              border-radius: $half-unit;
              margin-right: $half-unit;
              border: 1px solid $sf-gray-base;
              float: left;
            }

            &.available:before {
              background-color: $sf-white;
            }

            &.unavailable:before {
              background-color: $sf-gray-light;
            }
          }

          .caption-separator {
            display: inline-block;
            margin: 0 $half-unit;
            font-size: $font-size-sm;
          }

          .calendar-last-updated {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
          }
        }
      }
    }
  }

  .photo-download-modal {
    a {
      &:nth-child(1n) > img {
        margin-left: 0;
      }

      &:nth-child(3n) > img {
        margin-right: 0;
      }

      & > img {
        border: none;
        margin: 5px;
        width: calc((100% - 20px) / 3);
        height: auto;
      }
    }
  }

  .listing-share-input {
    width: 100%;
    background: #f5f5f5;
    border-color: #ccc;
    margin-bottom: 10px;
    padding: 10px;
  }

  .listing-share-subheader {
    color: $sf-gray-dark;
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: 400;
  }

  .listing-widget {
    width: 400px;
    height: 300px;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }

  .user-locale-value {
    font-style: italic;
  }

  .listing-stats {
    > label {
      margin: 0 $half-unit;
    }
  }

  hr.listing-details-separator {
    margin: $base-unit 0;
    border: 0;
    border-top: 1px solid $sf-gray-light;

    @include screen-max('md') {
      margin: $base-unit;
    }

    @include screen-max('sm') {
      margin: $base-unit 0;
    }
  }
}

#listing-recommendations-wrapper {
  margin-top: $base-unit;
  padding: $base-unit 0;

  .recommendations-container {
    p.recommendations-header {
      padding: 0;
      margin-bottom: $base-unit;
    }

    .recommended-listings {
      margin: $base-unit auto;

      .listing-panel {
        background-color: transparent;
      }
    }

    .bottom-text-content {
      width: 1130px;
      margin: 32px auto;
      color: #4a494b;
    }
  }
}
