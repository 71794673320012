.admin-dashboard {
  .listing-container {
    padding-top: $half-unit;

    &.adjudicate {
      @include layout-scrollable-base;

      height: calc(100vh - #{$navbar-height});
      padding: $base-unit;

      .listing-panel {
        .listing-controls-container {
          position: relative;

          .created-at-timestamp {
            position: absolute;
            top: -66px;
            font-size: $font-size-sm;
            font-weight: $font-weight-heavy;
            right: 6px;
          }
        }
      }
    }
  }

  .admin-sidebar {
    margin-top: $base-unit;

    .admin-sidebar-option {
      @include card-list-item;
    }
  }
}
