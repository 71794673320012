.sort-dropdown-wrapper {
  width: auto !important;
  display: flex;
  align-items: center;

  @include screen('sm') {
    right: $padding-base * 1.5;
  }

  .ant-select {
    min-width: 200px;
    .ant-select-arrow {
      right: $half-unit;
    }
  }

  .sort-dropdown-label {
    margin-right: $half-unit;
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
  }
}

.advanced-filter-panel .sort-dropdown-label {
  display: none;
}

#sort-selected {
  font-weight: $font-weight-regular;
  color: $sf-white;
  background: $sf-green;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: $sf-white;
}

#sort-selected + .ant-select-arrow {
  color: $sf-white;
}
