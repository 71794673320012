.sf-message {
  margin: $half-unit 0;
  padding-left: 0;

  @include screen('sm') {
    margin-left: ($base-unit * 2) + $half-unit;
  }

  &.in-invoice {
    margin-bottom: 0px;

    .sf-message-container {
      @include border-bottom-radius(0px);
    }
  }

  .sf-message-container {
    overflow: initial;
    display: inline-block;
    width: 100%;

    &.with-bg {
      background-color: tint($sf-gray-light, 70%);

      &:hover {
        background-color: tint($sf-gray-light, 30%);
      }

      .attached-files li {
        background-color: tint($sf-gray-light, 95%);

        &:hover {
          background-color: tint($sf-gray-light, 70%);
        }
      }

      &.owned {
        background-color: tint($sf-green-dark, 95%);

        &:hover {
          background-color: tint($sf-green-dark, 90%);
        }

        .attached-files li {
          background-color: tint($sf-green-light, 95%);

          &:hover {
            background-color: tint($sf-green-light, 85%);
          }
        }
      }

      &.rejected {
        background-color: tint($sf-red, 95%);

        &:hover {
          background-color: tint($sf-red, 90%);
        }

        .attached-files li {
          background-color: tint($sf-red-light, 95%);

          a {
            color: tint($sf-red, 30%);
          }

          &:hover {
            background-color: tint($sf-red-light, 90%);
          }
        }
      }

      &.new {
        background-color: tint($sf-orange, 95%);

        &:hover {
          background-color: tint($sf-orange, 90%);
        }

        .attached-files li {
          background-color: tint($sf-orange, 95%);

          a {
            color: tint($sf-orange, 30%);
          }

          &:hover {
            background-color: tint($sf-orange, 85%);
          }
        }
      }
    }

    .attached-files {
      list-style: none;
      margin: $half-unit 0 0;
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      position: relative;
      padding: 0 0 0 $base-unit;
      display: flex;
      flex-wrap: wrap;

      &:after {
        display: block;
        content: "";
        clear: both;
      }

      li {
        margin: 0 $quarter-unit $half-unit 0;
        position: relative;
        cursor: pointer;
        padding: $quarter-unit $half-unit;
        display: flex;
        border-radius: $border-radius-lg;
        flex: 0 0 48%;
        overflow: hidden;

        @include screen('sm') {
          flex: 0 0 32%;
        }

        &::before {
          display: block;
          margin-right: $half-unit;
          font-family: FontAwesome;
          content: "\f0c6";
          -webkit-font-smoothing: antialiased;
          color: $sf-gray-dark;
        }

        a {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:active {
          box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
        }

        .remove-file {
          color: $sf-gray-base;
          font-size: $font-size-xs;
          cursor: pointer;
        }
      }
    }

    .sf-booking-request-adjudicate-status {
      margin: 5px -8px 0;
      padding: 8px 8px 0;
      border-top: 1px solid #ccc;
      position: relative;
      color: $sf-orange;
    }

    .sf-message-action {
      color: $sf-gray-dark;
      font-size: $font-size-xs;
      font-weight: $font-weight-medium;
      margin: $quarter-unit 0;
    }

    .timestamp {
      color: $sf-black;
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      align-items: center;
      justify-content: flex-end;
      display: flex;

      .side-note-text {
        justify-self: flex-start;
        flex-grow: 1;
      }

      .short-time {
        display: block;
      }

      .full-date {
        display: none;
      }

      .separator {
        margin: 0 $quarter-unit;
      }

      .status-icon {
        &.approved {
          color: $sf-green-dark;
        }

        &.rejected {
          color: $sf-red;
        }

        &.new {
          color: $sf-orange;
        }
      }

      &:hover {
        .short-time {
          display: none;
        }

        .full-date {
          display: block;
        }
      }
    }

    .sf-message-contents {
      @include body-text;

      padding: $padding-base;

      p {
        &:first-of-type {
          margin-top: 0;
        }

        margin-bottom: 0;
        font-weight: $font-weight-medium;
      }

      &.bottom {
        padding-top: 0;
        padding-bottom: $padding-half;
      }

      &.invoice-padding {
        margin-bottom: $half-unit;
      }

      .message-redacted {
        position: relative;
        display: inline-block;
        border-radius: 10px;
        font-size: 6px;
        line-height: 6px;
        vertical-align: middle;
        background-color: $sf-black;
        color: $sf-black;
        padding: 0 $base-unit;

        &:hover .redact-tooltip-content {
          display: block;
        }

        .redact-tooltip-content {
          @include z-layer-1;

          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
          line-height: normal;

          &:before {
            content: '';
            position: absolute;
            right: $base-unit;
            bottom: -10px;
            z-index: 1;
            @include arrow-down(5px, #FFF);
          }

          &:after {
            content: '';
            position: absolute;
            bottom: -11px;
            right: $base-unit;
            @include arrow-down(5px, $sf-gray-base);
          }

          display: none;
          background-color: $white;
          padding: $half-unit $half-unit;
          font-size: $font-size-sm;
          position: absolute;
          border: 1px solid $sf-gray-base;
          bottom: 22px;
          right: -14px;
          color: $sf-black;
          width: 235px;
          text-align: left;
          border-radius: $border-radius-base;
        }
      }
    }

    .redacted-rejected-btn-container {
      display: flex;
      justify-content: center;
      margin-top: 10px;

      .redacted-rejected-btn {
        padding: $padding-half;
      }
    }
  }

  &.incoming {
    .sf-message-avatar {
      margin-bottom: $half-unit;
      vertical-align: bottom;
    }

    .sf-message-container {
      border-bottom-left-radius: 0;
    }
  }

  &.outgoing {
    .sf-message-avatar {
      margin-top: $half-unit;
    }

    .sf-message-container {
      margin-right: $half-unit;
      border-top-right-radius: 0;
    }
  }
}
