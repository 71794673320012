
.sf-history-container {
  @include card-list-item;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  padding-right: 0;
  min-height: $base-unit * 4;

  .timestamp {
    @include timestamp;
  }

  .history-main-info {
    .main-info-text {
      width: calc(100% - #{$base-unit * 16});
      display: inline-block;
      float: left;

      @include screen-max('sm') {
        @include inbox-thin-width;
      }

      .main-info-title {
        @include header-sm;
        @include text-overflow;
        margin-bottom: - $half-unit;
      }

      .main-info-details {
        @include header-xs;
        @include text-overflow;
      }

      .main-info-listing {
        @include text-overflow;
        width: 100%;

        @include screen-max('sm') {
          width: 80%;
        }
      }

      .main-info-user {
        font-weight: $font-weight-regular;
      }
    }

    .main-info-separator {
      position: absolute;
      display: inline-block;
      height: 75%;
      width: 1px;
      background-color: $sf-gray-base;
    }

    .main-info-indicator {
      @include header-sm;
      position: relative;
      top: $base-unit;
      text-align: center;

      @include screen-max('sm') {
        @include inbox-low-text;
      }

      .green {
        color: $primary-green;
      }

      &.short {
        font-size: $font-size-xs;

        @include screen-max('sm') {
          font-size: $font-size-sub
        }
      }

      &.shorter {
        font-size: $font-size-sub;
      }
    }
  }
}
