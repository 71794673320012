.inbox-content-container {
  background-color: $white;
}

.inbox {
  margin: 0 auto;
  max-width: $max-content-width-md;

  .main-content {
    padding: $half-unit;

    @include screen('sm') {
      padding: $base-unit;
    }

    .inbox-top-menu {
      margin-bottom: $base-unit * 2;

      h1 {
        font-size: $font-size-xxl;
        font-weight: $font-weight-heavy;
      }
    }
    .inquiry-filter {
      margin-bottom: $base-unit;
      margin-left: 10px;

      .filter-label {
        font-size: $font-size-md;
        display: inline-block;
        margin-right: 20px;
      }

      select {
        height: 30px;
        vertical-align: middle;

        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 10px;
        padding-right: 30px;
      }

      .inquiry-filter-carrot {
        position: relative;
        top: 3px;
        right: 23px;

        font-size: 19px;
        pointer-events: none;
      }

    }

    .inbox-results-panel {
      padding: 0;
      border: 0;

      @include screen('sm') {
        border: 1px solid $sf-gray-light;
        padding: 0 $padding-base * 2;
      }
    }

    .sf-pagination.pagination-container {
      border-top: 1px solid $sf-gray-base;
      padding: $padding-base * 2 $padding-base;
      text-align: center;

      @include screen('sm') {
        border-top: 0;
      }

      .listing-pagebar {
        margin-top: 0;
      }
    }
  }
}
