.sf-inquiry-booked {
  padding: $padding-base $padding-base 0;

  .inquiry-booked-title {
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
  }

  .inquiry-booked-body {
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
    margin: $base-unit 0;
  }
}
