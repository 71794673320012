$toggle-on-color: $sf-green;
$toggle-off-color: #C0CCDA;
$toggle-on-disabled-color: #b0d7f5;
$toggle-off-disabled-color: #D3DCE6;

.sf-toggle, .sf-toggle-style,
.sf-toggle-style:before {
  box-sizing: border-box;
}
.sf-toggle {
  display: inline-block;
  font-size: 100%;
  height: 1.6em;
  position: relative;
}
.sf-toggle .sf-toggle-style {
  height: 1.6em;
  left: 0;
  background: $toggle-off-color;
  border-radius: 0.8em;
  display: inline-block;
  position: relative;
  top: 0;
  transition: all 0.3s ease-in-out;
  width: 3em;
  cursor: pointer;
}
.sf-toggle .sf-toggle-style:before {
  display: block;
  content: '';
  height: 1.4em;
  position: absolute;
  width: 1.4em;
  background-color: #fff;
  border-radius: 50%;
  left: 0.1em;
  top: 0.1em;
  transition: all 0.3s ease-in-out;
}
.sf-toggle > input[type="checkbox"] {
  display: none;
}
.sf-toggle > input[type="checkbox"][disabled] + .sf-toggle-style {
  background-color: $toggle-off-disabled-color;
}
.sf-toggle > input[type="checkbox"]:checked + .sf-toggle-style {
  background-color: $toggle-on-color;
}
.sf-toggle > input[type="checkbox"]:checked + .sf-toggle-style:before {
  left: 50%;
}
.sf-toggle > input[type="checkbox"]:checked[disabled] + .sf-toggle-style {
  background-color: $toggle-on-disabled-color;
}
.sf-toggle.sf-toggle-sm {
  font-size: 85%;
}
.sf-toggle.sf-toggle-lg {
  font-size: 125%;
}
