.sf-listing-datepicker {
  .calendar-info {
    border-top: 1px solid #eee;
    padding-top: $padding-half;
    margin-top: $half-unit;
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;

    .calendar-labels {
      margin: 5px 0;

      &.lower {
        color: #46AA79;
      }
    }
  }

  .price-estimation {
    font-size: $font-size-sm;

    &:not(:empty) {
      margin-bottom: $base-unit;
    }

    .pricing-line {
      font-weight: $font-weight-regular;

      &::after {
        display: block;
        content: '';
        clear: both;
      }

      &.days {
        border-bottom: 1px solid $sf-gray-light;
        font-weight: $font-weight-light;
        color: $sf-gray-dark;
        padding-bottom: $half-unit;
        margin-bottom: $half-unit;
      }

      .label {
        float: left;
      }

      .price {
        float: right;
      }
    }
  }

  .existing-request {
    text-align: center;
    font-size: $font-size-sm;

    a {
      color: inherit;
      font-weight: $font-weight-semi;
      text-decoration: underline;
      text-underline-offset: 2px;

      &:hover {
        color: #D97706;
      }
    }
  }

  .show-operating-hours {
    text-align: center;
    color: $sf-black;
    font-size: $font-size-xs;
    font-weight: $font-weight-regular;
    text-decoration: underline;
    margin-bottom: $half-unit;
    padding-bottom: $quarter-unit;
    cursor: pointer;
  }

  .price-warning {
    text-align: center;
    color: $sf-orange;
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
    margin: $half-unit 0 $base-unit;
    padding: 0 $half-unit;
  }

  .new {
    font-size: 0.9em;
    font-weight: $font-weight-heavy;
    color: $sf-white;
    background: $sf-green;
    padding: 1px $quarter-unit;
    border-radius: $border-radius-sm;

    &.left-m {
      margin-left: $quarter-unit;
    }

    &.right-m {
      margin-right: $quarter-unit;
    }
  }

  .sf-form-hourly-or-daily-tab {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: $quarter-unit;
    align-items: center;

    .radio {
      display: flex;
      width: 50%;
      text-align: center;
      border: 1px solid $sf-gray-base;
      padding: $quarter-unit;
      font-weight: $font-weight-regular;
      justify-content: center;
      cursor: pointer;
      font-size: $font-size-xs;
      color: $sf-gray-dark;

      &.selected {
        font-weight: $font-weight-heavy;
        color: $sf-green-dark;
        border-color: $sf-green-dark;
        border-right: 1px solid $sf-green-dark !important;
      }

      &.disabled {
        @include disabled-striped($sf-gray-light);
        cursor: not-allowed;

        .new {
          background: $sf-gray-dark;
        }
      }

      &:hover:not(.disabled) {
        background-color: $sf-gray-light;
      }

      &:active:not(.disabled) {
        background-color: $sf-gray-base;
      }

      &:first-of-type {
        border-top-left-radius: $border-radius-sm;
        border-bottom-left-radius: $border-radius-sm;
        border-right: none;
      }

      &:last-of-type {
        border-top-right-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
      }
    }
  }

  .sf-form-hourly-select-times {
    margin: -$quarter-unit 0 $half-unit;
    display: flex;
    width: 100%;

    select {
      display: flex;
      flex-grow: 1;
      border: 1px solid $sf-gray-base;
      color: $sf-gray-dark;
      height: 40px;

      &.has-value {
        color: $sf-black;
      }

      option:not(:first-of-type) {
        color: $sf-black;
      }

      option[disabled] {
        color: $sf-gray-dark;
      }

      &:first-of-type {
        border-right: 0;
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        border-left: 0;
        border-radius: 0 4px 4px 0;
      }
    }

    .arrow-dates {
      display: flex;
      width: 25px;
      color: $sf-gray-dark;
      align-items: center;
      flex-grow: 0;
      border-top: 1px solid $sf-gray-base;
      border-bottom: 1px solid $sf-gray-base;

      .arrow-icon {
        display: flex;
        align-items: center;
      }
    }
  }

  .sf-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;

    .sf-modal-contents {
      position: unset;

      .operating-hours-container {
        background-color: $sf-white !important;
        font-size: 1.1em;
        margin: 0 !important;
        padding: 0 !important;

        .day-label, .value {
          display: inline-block;

          &.day-label {
            margin-right: $half-unit;
          }
        }
      }
    }
  }
}

.sf-form-hourly-show-invalid {
  padding: 0 $base-unit;
  margin-bottom: $half-unit;

  &.full-width {
    padding: 0;
  }
}
