.sf-form-row-datepicker {
  position: relative;
  display: block;

  input {
    height: 40px;
    line-height: 40px;
    font-size: $font-size-md;
  }

  .sf-calendar {
    @include card-main(0);
    @include border-top-radius(0);

    overflow: hidden;
    top: -1px;
    border: 0;
    box-shadow: none;
  }

  .sf-form-row-datepicker-active-input {
    position: relative;

    @keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }

    @-webkit-keyframes blink-animation {
      to {
        visibility: hidden;
      }
    }

    &:after {
      display: block;
      content: "";
      background-color: $sf-green;
      left: 5px;
      bottom: 7px;
      height: 25px;
      width: 2px;
      position: absolute;
      animation: blink-animation 1.25s steps(2, start) infinite;
      -webkit-animation: blink-animation 1.25s steps(2, start) infinite;
    }

    input {
      @include border-bottom-radius(0px);
    }
  }

  .arrow-dates {
    width: 25px;
    line-height: 35px;
    height: 40px;
    border-top: 1px solid $sf-gray-base;
    border-bottom: 1px solid $sf-gray-base;
    display: inline-block;
    vertical-align: bottom;
    color: $sf-gray-dark;
    font-size: $font-size-lg;
  }

  .sf-form-row-datepicker-halfwidth {
    width: calc(50% - 10px);
    margin-left: 20px;
    display: inline-block;

    input {
      height: 40px;
      line-height: 40px;
      font-size: $font-size-sm;
    }

    &.border-collapse {
      width: calc(50% - 12.5px);
    }

    &:nth-child(odd) {
      margin-left: 0;
    }

    &.border-collapse {
      &.sf-form-row-datepicker-active-input input {
        border-color: $sf-gray-base;
        color: $sf-black;
      }

      input {
        border-color: $sf-gray-base;
        padding: $padding-half;

        &.start-date {
          border-width: 1px 0 1px 1px;
          border-radius: 4px 0 0 4px;
        }

        &.end-date {
          border-width: 1px 1px 1px 0;
          border-radius: 0 4px 4px 0;
        }
      }

      &:last-of-type {
        margin-left: 0;
      }
    }
  }

  .sf-form-row-datepicker-calendar {
    @include z-layer-2;

    position: absolute;
    left: 0;
    right: 0;

    @include screen('sm') {
      width: calc(50% - 10px);
      min-width: ($base-unit * 16);

      &.full-width {
        width: 100%;
      }

      &.sf-form-row-datepicker-calendar-start {
        right: auto;
      }

      &.sf-form-row-datepicker-calendar-end {
        left: auto;
      }
    }

    &.from-bottom {
      top: auto;
      bottom: 100%;

      .sf-calendar-container {
        box-shadow: $box-shadow-bottom;
        border: 1px solid $sf-gray-base;

        .sf-calendar {
          border-radius: 2px 2px 0px 0px;
        }
      }
    }
  }

  .so-cal-daterange-header {
    display: none;
  }
}
