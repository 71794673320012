.sf-booking-request-respond-message {

  .sf-booking-request-respond-btn {
    @include button-action-panel;
  }

  .preapprove-learn-more-link {
    margin-left: $half-unit;
    color: $sf-blue;
  }

  .preapprove-info-text {
    color: $sf-gray-dark;
    font-size: $font-size-sm;
    font-weight: 500;
    margin-bottom: $half-unit;

    @include screen-max('sm') {
      max-width: 100%;
    }
  }
}
