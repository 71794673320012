.edit-account.notifications {
  min-height: calc(100vh - #{$navbar-height});

  .sms-notification-number-input {
    width: auto;
    display: inline-block;
    vertical-align: bottom;
  }

  .verified-phone-number, .verified-copy {
    font-size: $font-size-sm;
  }

  .verified-phone-number {
    padding: $padding-base 0 $padding-xl;
    font-weight: $font-weight-regular;
  }

  .verified-copy {
    padding: $padding-base 0;
  }

  .sms-btn {
    padding: $padding-half ($padding-base * 2);
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;

    &.disabled {
      pointer-events: none;
    }

    &.pseudo-link {
      // need to do this because the base .pseudo-link class does :(
      color: $sf-green !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .legal {
    padding: $padding-base 0 0;
    color: $sf-gray-dark;
    font-size: $font-size-sm;
  }

  .sms-verify-prompt {
    div.auto-width {
      width: auto;
      display: inline-block;
    }

    div.actions {
      padding: 0 $padding-lg;

      > div {
        display: inline-block;
        vertical-align: middle;
        width: auto;
      }
    }

    span.link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
