.sf-booking-request-message {
  @include card-message;

  padding: $padding-half $padding-base;
  background-color: tint($sf-gray-light, 70%);
  border-radius: $border-radius-sm;
  overflow: visible;

  &:hover {
    background-color: tint($sf-gray-light, 30%);
  }

  &.owned {
    background-color: tint($sf-green-dark, 95%);

    &:hover {
      background: tint($sf-green-dark, 90%);
    }
  }

  .sf-booking-request-body {
    width: 100%;

    .sf-booking-request-changelog {
      @include header-sm;

      padding: 0;
      text-align: center;
      margin: $half-unit 0 $base-unit;
      font-size: $font-size-md;
      font-weight: $font-weight-semi;
    }

    .booking-request-details-section {
      padding-top: 0 !important;
    }

    .booking-request-details-body {
      @include card-body;

      &>body {
        padding: $half-unit;
      }
    }

    .booking-request-details-title {
      @include header-sm;
      font-weight: $font-weight-regular;
      font-size: $font-size-sm;
      margin: $half-unit 0 $quarter-unit;
    }

    .booking-request-details-row {
      .booking-request-details-label {
        @include header-sm;
        display: inline-block;
        vertical-align: top;
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
        color: $sf-gray-dark;
        margin-left: $base-unit;
      }
    }

    .booking-request-message-disclaimer {
      margin-top: $quarter-unit * 3;
    }
  }
}
