.sf-listing-badge {
  position: relative;
  cursor: default;
  .ribbon {
    font-weight: $font-weight-semi;

    &.interactive {
      cursor: pointer;
    }

    &.blue {
      color: $white;
      background: $sf-blue;
    }

    &.green {
      color: $white;
      background: $sf-green-dark;
    }
  }

  .badge-tooltip {
    @include card-popover(rgba(0, 0, 0, 0.75), transparent);

    color: $white;
    font-weight: 400;
    min-width: ($base-unit * 11.75);
    top: calc(100% + 10px);
    right: -105%;
    &:after {
      left: 25%;
    }

    a {
      color: $sf-green;
    }
  }
}

.listing-panel .sf-listing-badge {
  @include z-layer-1;
  @include header-xs;

  position: absolute;
  top: $half-unit;
  left: $half-unit;
  text-transform: uppercase;

  .ribbon {
    border-radius: $border-radius-base;
    font-weight: $font-weight-semi;
  }

  &.large {
    top: $base-unit;

    @include screen-max('lg') {
      right: $base-unit;

      &:after {
        content: '';
        display: none;
      }
    }

    .ribbon {
      @include header-sm;
      padding: $padding-compact;
    }
  }

  .ribbon {
    padding: ($padding-half / 2) $padding-half;
  }
}

.labels-container .sf-listing-badge {
  display: inline;
  margin-right: 7px;

  .ribbon {
    display: inline;
    font-size: $base-unit;
    font-weight: $font-weight-regular;
    padding: 5px $padding-half;
    border-radius: $border-radius-lg;
  }
}
