.project-type-container::after {
  display: block;
  content: '';
  clear: both;
}

.project-type-selector-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .button-selector-item {
    width: 20%;
    display: inline-block;
    text-align: center;
    font-size: $font-size-xs;
    white-space: normal;

    //Button overrides
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    @include screen('sm') {
      width: auto;
    }

    &.active {
      color: $sf-green;

      .project-type-icon {
        background: $sf-green !important;
        border-color: $sf-green !important;

        g,
        path,
        circle {
          fill: $white;
        }
      }
    }

    .project-type-icon {
      @include circle($base-unit * 3.5);
      margin: $half-unit auto 0 auto;
      border: 1px solid $sf-gray-dark;
      position: relative;
      transition: background 0.2s ease, transform 0.2s ease, border 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      @include screen-max("md") {
        @include circle($base-unit * 3);
      }

      &:hover {
        transform: scale(1.075);
        -webkit-transform: scale(1.075);
        border-color: $sf-black;
      }

      > div {
        height: $quarter-unit * 9;
        width: $quarter-unit * 9;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .project-type-name-wrapper {
      height: auto;
    }
  }
}

.project-type-popover {
  /* Fixes antd setting pointer-events to none */
  pointer-events: auto !important;
}
