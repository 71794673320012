.account-content-container {
  background-color: $sf-gray-light;
  padding-top: 40px;

  @include screen('sm') {
    padding-top: 0px;
  }

  .account-dashboard {
    .account-links {
      .header {
        font-size: $font-size-lg;
        border-bottom: 1px solid $sf-gray-dark;
        padding: #{$half-unit * 3} $half-unit $half-unit;
      }

      .account-section {
          margin: $base-unit;

          .option {
            font-size: $font-size-md;
            color: $sf-black;
            border-bottom: 1px solid $sf-gray-light;
            padding: $half-unit;
            display: block;

            padding: $base-unit;
            cursor: pointer;
            background: $white;
            margin: 0 auto;
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
            transition: box-shadow 0.3s ease;

            @include screen('xs') {
              border-bottom: 1px solid $sf-gray-base;
            }

            &:hover {
              box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
              position: relative;
              @include z-layer-1;
            }

            &:first-of-type {
              border-top: 1px solid $sf-gray-light;
            }

            .fa {
              font-size: $font-size-xl;
              width: 40px;
              text-align: center;
              vertical-align: middle;
              margin-right: 15px;
            }

            .title {
              vertical-align: middle;
            }
          }

          .light-info {
            color: $sf-gray-dark;
            font-size: $font-size-sm;
          }
      }
    }

    .view {
      @extend .col-sm-10;
      @extend .col-lg-10;
    }
  }

  .account-settings-panel {
    padding-top: 40px;
    max-width: $max-content-width-md;
    margin: auto;
  }
}
