.main-splash-container {
  position: relative;
  display: table;
  width: 100%;
  height: 100vh;
  top: 0;

  &.section-search {
    height: 80vh;

    .main-splash-content {
      padding-bottom: 120px;

      @include screen-max('sm') {
        padding-bottom: 120px;
      }
    }
  }

  .btn-list-space {
    position: absolute;
    width: 100%;
    text-align:center;
    bottom: 0;
    left: 0;
    padding: 10px;
    background: rgba(0,0,0,0.2);
    z-index: 3;

    .btn {
      font-size: 11px;
      width: calc(50% - 5px);
    }
  }

  .main-splash-content {
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 40px;
    text-align: center;
    vertical-align: bottom;
    position:relative;

    @include screen-max('sm') {
      padding: 10px;
    }

    .main-image-carousel {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height:80vh;
      background-image: url('/assets/images/main/home-01.jpg');
      background-size: cover;
      background-position: center center;
      opacity: 1;
    }

    .image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height:80vh;
      background-size: cover;
      background-position: center center;
      background-image: url('/assets/images/main/home-01.jpg');
      background-size: cover;
      -webkit-transition: opacity 0.8s ease;
      transition: opacity 0.8s ease;

      &.old {
        opacity: 0;
      }
    }

    .main-splash-header {
      color: #FFF;
      font-size: 64px;
      font-weight: 600;
      vertical-align: top;
      text-shadow: 0px 0px 10px $sf-black;
      position: relative;
      z-index: 3;

      @include screen-max('sm') {
        font-size: 30px;
      }
    }

    .space-name {
      display: inline-block;
      position: relative;
      transition: width 0.2s ease-in-out;
      overflow: hidden;
      width: 280px;
      height: 86px;
      vertical-align: top;

      @include screen-max('sm') {
        height: 40px;
        width: 120px;
      }

      .name {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        -webkit-transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);

        &.old {
          top: 100%;
        }

        &.new {
          top: -100px;
        }
      }
    }

    .main-splash-subheader {
      color: #FFF;
      font-size: 20px;
      font-weight: 400;
      text-shadow: 0px 0px 10px #000;
      position: relative;
      z-index: 3;

      @include screen-max('sm') {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    .btn-start-concierge {
      margin: 40px auto;
      position: relative;
      font-size: 30px;
      padding: 25px 40px;
      z-index: 2;
      font-weight: $font-weight-heavy;

      @include screen-max('sm') {
        font-size: 18px;
        padding: 20px;
        margin: 0px auto;
      }
    }

    .secondary-search {
      color: $white;
      position: absolute;
      z-index: 2;
      bottom: 0;
      right: 0;
      left: 0;

      background: rgba(0, 0, 0, 0.6);

      .core-markets {
        list-style: none;
        padding: 10px 0;

        a {
          font-weight: 400;
          color: $white;
        }

        li {
          display: inline-block;
          padding: 5px 20px;

          @media(max-width: 1250px) {
            padding: 5px 8px;
          }

          &.core-markets-heading {
            @media(max-width: 768px) {
              display: none;
            }
          }
        }
      }

      @include screen-max('sm') {
        font-size: 12px;
      }

      @include screen-max('xs') {
        bottom: $base-unit * 3;
      }

      a {

        @include screen-max('sm') {
          display: block;
        }
      }
    }
  }
}

.main-section-container {
  padding: 40px;
  padding-bottom: 0px;
  background: #FFF;
  color: #4A494B;
  text-align: center;

  @include screen-max('sm') {
    padding: 0px;
  }

  &.bottom {
    padding: 60px 40px;
    color: $secondary-3-grey;
    position: relative;
    margin: auto;

    &.here-to-help {
      max-width: 800px;

      p {
        font-size: $font-size-md;
        font-weight: $font-weight-light;
        text-align: center;
        color: $sf-gray-dark;
      }

      .contact-lines {
        div {
          & + div {
            margin-top: $base-unit;
          }
        }
      }
    }

    @include screen-max('sm') {
      padding: 20px 20px 40px 20px;
    }

    .sf-icon {
      color: $secondary-3-grey;
      display: inline-block;
    }
  }

  .main-section-header {
    padding: 5px;
    font-size: $font-size-xl;

    @include screen-max('sm') {
      font-size: 18px;
      padding:10px 20px;
      padding-top: 40px;
    }

    &.first {
      position: relative;
      bottom: 10px;

      @include screen-max('sm') {
        bottom: 5px;
        font-size: 24px;
      }
    }

    &.constrained {
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  .how-it-works-step-subheader {
    color: darken($secondary-3-grey, 10%);

    @include screen-max('sm') {
      font-size: 12px;
      padding: 10px 20px;
    }
  }

  .main-section-subheader {
    margin: 20px auto;
    font-size: 1em;
    color: darken($secondary-3-grey, 10%);

    @include screen-max('sm') {
      margin: 0 auto;
      padding: 20px;
    }
  }

  .get-started-cta {
    padding: 10px 12px;
    margin-top: 20px;
  }

  .btn-how-it-works {
    width: calc(50% - 40px);
    margin: 10px 10px 40px 10px;
    max-width: 400px;

    @include screen-max('sm') {
      margin: 15px;
      font-size: 12px;
      width: calc(100% - 30px);
    }
  }
}

.main-image-bar {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 0;

  @include screen-max('sm') {
    padding: 20px 0 0 0;
  }
}

.main-image-panel {
  display: inline-block;
  height: 27vw;
  width: calc((100% / 3) - 24px);
  min-width: 180px;
  min-height: 180px;
  max-width: 320px;
  max-height: 320px;
  margin: 15px;
  color: #FFF;
  background: #999;
  background-size: cover;
  background-position: center center;
  text-shadow: 0 2px 24px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 500px 500px rgba(0,0,0,0.3);
  opacity: 1.0;
  transition: all 0.3s ease;
  cursor: pointer;
  position:relative;

  &:hover {
    box-shadow: none;
    opacity: 0.85;

    .main-image-panel-header,
    .main-image-panel-subheader {
      display: none;
    }
  }

  @include screen-max('sm') {
    width: calc((100% / 3) - 20px);
    margin: 6px;
    max-width: none;
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
    display: block;
  }

  &.first {
    margin-left: 0px;
  }

  &.last {
    margin-right: 0px;
    clear: both;
  }

  &.tcho {
    background-image: url('/assets/images/backgrounds/homepage_panel_tcho.jpg');
  }

  &.nora-gardner {
    background-image: url('/assets/images/backgrounds/homepage_panel_nora_gardner.jpg');
  }

  &.indochino {
    background-image: url('/assets/images/backgrounds/homepage_panel_indochino.jpg');
  }

  &.nike {
    background-image: url('/assets/images/backgrounds/homepage_panel_nike.jpg');
  }

  &.new-york {
    background-image: url('/assets/images/backgrounds/homepage_panel_ny.jpg');
  }

  &.los-angeles {
    background-image: url('/assets/images/backgrounds/homepage_panel_la.jpg');
  }

  &.san-francisco {
    background-image: url('/assets/images/backgrounds/homepage_panel_sf.jpg');
  }

  &.hong-kong {
    background-image: url('/assets/images/backgrounds/homepage_panel_hk.jpg');
  }

  &.london {
    background-image: url('/assets/images/backgrounds/homepage_panel_london.jpg');
  }

  &.paris {
    background-image: url('/assets/images/backgrounds/homepage_panel_paris.jpg');
  }

  &.amsterdam {
    background-image: url('/assets/images/backgrounds/homepage_panel_ams.jpg');
  }

  &.milan {
    background-image: url('/assets/images/backgrounds/homepage_panel_milan.jpg');
  }

  &.half-size {
    width: calc(50% - 24px);
    max-width: 497px;

    @include screen-max('sm') {
      width: calc(50% - 22px);
      max-width: none;
    }

    @media (max-width: 640px) {
      width: 100%;
      margin: 0;
      display: block;
    }
  }

  &.coming-soon {
    cursor: default;

    .main-image-panel-header {
      &:after {
        position: absolute;
        top: calc(50% + 40px);
        left: 0;
        right: 0;
        content: 'Coming Soon';
        font-size: 20px;
      }
    }
  }

  .main-image-panel-header {
    position: absolute;
    font-size: 1.8em;
    font-weight: 500;
    width: 100%;
    top: calc(50% - 17px);
    text-shadow: 0px 1px 4px #000;
    color: #FFF;

    &.brand {
      top: calc(50% - 25px);
    }
  }

  .main-image-panel-subheader {
    font-size: 12px;
  }
}

.video {
  width: 80vw;
  height: 45vw;
  max-height: 576px;
  margin: 0 auto;
  margin-top: 80px;
  max-width: $max-content-width-md;

  @include screen-max('sm') {
    width: 100vw;
    height: 58vw;
    margin-top: 40px;
  }
}

.how-it-works-panel {
  background-image: url('/assets/images/backgrounds/how_it_works_splash_cta.jpg');
  background-size: cover;
  margin: 80px auto 20px auto;
  width: 80vw;
  height: 25vw;
  height: 300px;
  max-width: $max-content-width-md;

  @include screen-max('sm') {
    width: 100%;
    margin: 40px auto;
  }

  .how-it-works-label-wrapper {
    padding-top: 130px;
    width: 100%
  }

  .how-it-works-label {
    cursor: pointer;
    width: 145px;
    height: 35px;
    line-height: 35px;
    font-weight: 100;
    font-size: 18px;
    margin: 0 auto;
    text-align: center;
    color: #FFF;
    border: 1px solid #FFF;
    border-radius: 1px;
  }
}

.main-brand-panel {
  width: 100%;
  background-color: #DDD;

  .brand-logo-wrapper {
    margin: 0 auto;
    display: table;
    table-layout: fixed;
  }

  .brand-logo {
    display: table-cell;
    background-size: inherit;
    height: 60px;
    width: 60px;
    padding: 30px;
    vertical-align:middle;

    @include screen-max('sm') {
      height:40px;
      width: 40px;
      padding: 10px;
    }

    img {
      width: inherit;
    }
  }
}

.btn-sign-up {
  padding: 20px;
  margin-top: 20px;
  font-size: 20px;

  @include screen-max('sm') {
    margin-top: 10px;
  }
}

.arrow-more {
  position: absolute;
  font-size: 50px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);
  cursor: pointer;

  @include screen-max('sm') {
    bottom: 20px;
  }

  @include screen-max('xs') {
    bottom: 0px;
  }
}
