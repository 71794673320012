.sf-form {
  margin: auto;

  &.modal-form {
    padding: 0;
  }

  &.no-padding-xs {
    @include screen-max('sm') {
      padding: 0px;
    }
  }

  &[data-disabled=true] {
    .btn-submit-form {
      cursor: not-allowed;
    }
  }

  .sf-form-contents {
    position: relative;
    max-width:800px;
    margin:0 auto;

    @include screen('sm') {
      padding-top:10px;
    }
  }

  .sf-form-block {
    border-radius: $border-radius-base;
    border: 1px solid $sf-gray-base;

    &.white {
      background: #FFF;
    }

    &.grey {
      background: $sf-gray-base;
    }

    .sf-form-row-container {
      .input-prefix {
        border: 1px solid $sf-gray-base;
        border-right:none;
      }

      .input-postfix {
        border: 1px solid $sf-gray-base;
        border-left:none;
      }
    }
  }

  .sf-form-footer {
    max-width: 800px;
    margin: ($base-unit * 1.5) auto 0 auto;

    .btn {
      padding: $half-unit;
      width: inherit;
      position: relative;
      overflow: hidden;

      &.full {
        width: 100%;
      }

      &.action-panel {
        @include button-action-panel;
      }

      &.half {
        width: 50%;
      }

      &.destroy {
        margin-top: $base-unit * 2;
        background-color: #FFF;
        border: none;
        color: $sf-red;
        outline: none;

        @include screen('sm') {

          &:hover, &:active, &:hover:active {
            color: #d00000;
          }
        }
      }

      .loading {
        right:-40px;
        top: 5px;
        transition: all 0.3s ease-in-out;

        &.button-progressing {
          right: 8px;
        }
      }
    }
  }
}
