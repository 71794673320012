// Override Intercom CSS for smoother mobile experience
@include screen-max('sm') {
  .intercom-messenger-frame {
    // Moving Intercom Messenger frame 50px higher (and reducing height by 50px)
    height: calc(100% - 20px - 75px - 20px - 50px) !important;
    bottom: calc(20px + 75px + 50px) !important;
  }
}

@media only screen and (max-device-width: 667px), screen and (max-width: 768px) {
  body.mobile-bottom-buttons {
    // Moving Intercom a little bit higher by 50px (original bottom: 20px)
    #intercom-container .intercom-launcher-frame {
      bottom: 70px !important;
    }
  }
}

body.listing-page {
  @include screen-max(1024px) {
    .intercom-lightweight-app .intercom-launcher, #intercom-container .intercom-launcher-frame {
      &.intercom-launcher-frame {
        position: absolute !important;
      }

      bottom: 96px !important;
    }
  }
}

body {
  &.has-overlay {
    overflow: hidden;

    @include screen('sm') {
      overflow: auto;
    }
  }

  &.mobile-bottom-buttons .listing-wizard .listing-wizard-wrapper {
    padding-bottom: $base-unit * 8;
  }
}

.listing-wizard {
  background-color: $white;
  overflow: auto;
  position: relative;

  .listing-wizard-wrapper {
    max-width: $max-content-width-lg;
    margin: auto;
    min-height: calc(100vh - #{$navbar-height});
    padding: $base-unit 0;

    @include screen('sm') {
      padding: $base-unit * 2;
    }

    @include screen('md') {
      padding: ($base-unit * 2) ($base-unit * 4);
    }

    @include screen('lg') {
      padding: ($base-unit * 3) ($base-unit * 5);
    }

    h1 {
      margin: 0 0 $base-unit;
      font-weight: $font-weight-semi;

      &.tiny-margin {
        margin-bottom: $half-unit;
      }

      &.no-margin {
        margin-bottom: 0;
      }

      &.big {
        font-size: $font-size-xxl;
        font-weight: $font-weight-heavy;
        margin-bottom: $base-unit * 2;
      }
    }

    h2 {
      font-weight: $font-weight-semi;
      margin: $half-unit 0 $base-unit;
      color: $sf-gray-dark;
    }

    .subtitle {
      margin: -$half-unit 0 ($base-unit * 2);
      font-weight: $font-weight-medium;

      div {
        margin-bottom: $base-unit * 2;
      }
    }

    .milestone-step {
      &.completed {
        .title {
          color: $sf-gray-dark;

          i {
            display: inline-block;
          }
        }

        .description {
          color: $sf-gray-base;
        }
      }

      &.disabled {
        .title {
          color: $sf-gray-dark;
        }

        .description {
          color: $sf-gray-base;
        }

        a {
          display: none;
        }
      }

      .step {
        color: $sf-gray-dark;
        font-weight: $font-weight-semi;
        font-size: $font-size-sm;
      }

      .title {
        margin: $half-unit 0 $quarter-unit;
        font-weight: $font-weight-regular;

        i {
          float: right;
          display: none;
        }
      }

      .description {
        font-size: $font-size-sm;
        font-weight: $font-weight-medium;
      }

      a {
        margin-top: $base-unit;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
        display: inline-block;
      }
    }

    .listing-wizard-progress {
      height: 3px;
      background-color: $sf-yellow;
      position: fixed;
      left: 0;
      top: $navbar-height;
      transition: width ease .2s;
      min-width: 5%;
      z-index: 100;
    }

    .listing-wizard-screen {
      padding: 0 $base-unit ($base-unit * 2);

      @include screen('sm') {
        float: left;
        width: calc(100% - #{$base-unit} * 20 - #{$base-unit});
        padding-left: 0;
      }

      .sf-form {
        .project-type-column {
          width: 100%;
          padding-left: 0;
          padding-right: 0;

          .title {
            font-size: $font-size-sm;
            border-color: $sf-gray-light;
          }

          ul {
            display: flex;
            flex-wrap: wrap;
          }
        }

        .sf-form-row {
          padding: 0;

          @include screen('sm') {
            &.input-small {
              .sf-form-input-wrapper {
                max-width: $base-unit * 12;
              }
            }

            &.input-medium{
              .sf-form-input-wrapper {
                max-width: $base-unit * 20;
              }
            }
          }

          label {
            font-size: $font-size-sm;
            font-weight: $font-weight-regular;
          }

          input[type="checkbox"] {
            margin-right: $quarter-unit;

            &:checked, &:checked:hover {
              border-color: $sf-green;
              background: $sf-green;
            }

            &:hover {
              border-color: tint($sf-green, 50%);
              background: tint($sf-green, 50%);
            }
          }

          & + .sf-form-row {
            margin-top: $base-unit * 2;
          }

          &.sf-form-row-images {
            margin-top: $base-unit * 2;
          }

          .sf-form-duration-wrapper {
            display: flex;

            .sf-form-input-wrapper {
              margin-right: $half-unit;
              max-width: $base-unit * 10;
            }
          }

          .length-counter {
            bottom: $quarter-unit;
            right: $quarter-unit;
          }

          .image-upload {
            .uploaded-images {
              &:empty:before {
                display: block;
                font-family: FontAwesome;
                content: '\f03e';
                font-size: $font-size-xxxl;
                color: $sf-gray-base;
                margin-bottom: $base-unit;
              }
            }

            .sf-btn-primary {
              background-color: $white;
              color: $sf-black;
              border-color: $sf-gray-base;
              font-weight: $font-weight-regular;

              &:hover {
                background-color: $sf-gray-light;
              }
            }
          }
          .sf-form-errors {
            .error-list {
              background: tint($sf-red, 90%);
              padding: $padding-base;
              border-radius: 3px;

              .error-list-item {
                i {
                  display: none;
                }
              }
            }
          }
        }

        .sf-form-title-wrapper {
          margin-bottom: 0;

          .sf-form-header {
            font-weight: $font-weight-regular;
            margin-bottom: $half-unit;

            &:before {
              display: none;
            }
          }
        }

        .sf-form-input-wrapper {
          display: flex;
          flex-wrap: wrap;
        }

        .sf-form-tag {
          display: flex;
          margin: $base-unit 0 0;
          text-align: center;
          padding: 0 $half-unit;
          width: calc(100% / 2);

          &:nth-child(-n+2) {
            margin-top: 0;
          }

          @include screen('md') {
            width: calc(100% / 3);

            &:nth-child(-n+3) {
              margin-top: 0;
            }
          }

          .tag-check {
            display: none;
          }

          .tag-label {
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-weight: $font-weight-regular;
            width: 100%;
            padding: $base-unit 0;
            height: 100%;
            border: 1px solid $sf-gray-base;
            border-radius: 3px;
          }

          &.active {
            .tag-label {
              border-color: $sf-green;
            }
          }
        }

        input {
          &::-webkit-input-placeholder {
            color: $sf-gray-dark;
          }

          &:-moz-placeholder {
            color: $sf-gray-dark;
          }

          &::-moz-placeholder {
            color: $sf-gray-dark;
          }

          &:-ms-input-placeholder {
            color: $sf-gray-dark;
          }
        }

        .checkbox-smartify-contact {
          margin-top: 20px;
          display: flex;
          align-items: center;
        }
      }

      hr {
        border: 1px solid $sf-gray-light;
        margin: ($base-unit * 2) 0 $base-unit;
        border-bottom: 0;

        hr.milestone-separator {
          margin: $base-unit 0;
        }
      }

      .button-container {
        width: 100%;
        height: 60px;
        position: fixed;
        z-index: 100;
        border-top: 1px solid $sf-gray-base;
        bottom: 0;
        left: 0;

        @include screen('sm') {
          height: auto;
          position: inherit;
          border-color: $sf-gray-light;
          padding-top: $base-unit;
          margin-top: $base-unit * 3;
        }

        .sf-btn-primary, .sf-btn-secondary {
          height: 100%;
          width: 50%;
          margin: 0;
          font-weight: $font-weight-regular;
          padding: 20px 0;

          .loading-dots.submitting {
            top: $base-unit - 2px;
          }

          @include screen-max('sm') {
            border: 0;
            border-radius: 0;
          }

          @include screen('sm') {
            padding: $padding-base ($padding-base * 2);
            font-size: $font-size-sm;
            width: auto;
          }

          &.prev-btn {
            float: left;
            color: $sf-black;
            border-color: $sf-gray-base;

            &:hover {
              background-color: $sf-gray-light;
            }
          }

          &.next-btn {
            float: right;
          }
        }
      }

      &:after {
        display: block;
        content: "";
        clear: both;
      }
    }
  }

  .listing-wizard-success {
    max-width: $max-content-width-md;
    margin: auto;
    padding: #{$base-unit * 2} $base-unit;
    text-align: center;

    @include screen('sm') {
      padding: #{$base-unit * 2} 0;
    }

    h2 {
      font-size: $font-size-xxl;
    }

    p {
      text-align: center;
      font-size: $font-size-md;
      font-weight: $font-weight-light;
    }

    > .buttons {
      margin-top: $base-unit * 2;

      > .btn.btn-outline-only:hover {
        background-color: initial;
        color: initial;
      }
    }
  }
}

.sf-modal .sf-modal-contents {
  .sf-modal-title {
    font-size: $font-size-lg;
    color: $sf-black;
  }

  .sf-modal-contents-body {
    padding: ($base-unit * 2) 0;
  }

  .sf-modal-actions .btn {
    padding: $base-unit 0;
    font-size: $font-size-sm;
  }
}

.listing-wizard-hints {
  position: fixed;
  right: $half-unit;
  top: 7px;
  border-radius: 30px;
  border: 1px solid $sf-gray-light;
  width: 50px;
  height: 50px;
  overflow: hidden;
  padding: $padding-half ($quarter-unit * 3 + 2);
  transition: all .4s cubic-bezier(0.175, 0.885, 0.32, 1.1);
  z-index: 90;
  background-color: $white;
  max-height: 50px;
  max-width: 50px;

  .hint-content {
    p {
      font-weight: $font-weight-light;
      font-size: $font-size-md;
    }

    strong {
      font-weight: $font-weight-regular;
    }

    &.autosave {
      font-size: $font-size-sm;
      .autosave-status {
        color: $sf-green;
        font-weight: $font-weight-heavy;
      }
    }
  }

  @include screen('sm') {
    float: right;
    width: $base-unit * 20;
    height: auto;
    border: 1px solid $sf-gray-light;
    box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
    color: $sf-black;
    padding: $base-unit;
    max-width: none;
    max-height: calc(100vh - #{$navbar-height} - #{$base-unit} * 4);
    border-radius: 0;
    overflow: auto;
    bottom: auto;
    top: calc(#{$navbar-height} + #{$base-unit} * 2);

    .close-hint {
      display: none;
    }
  }

  @include screen('lg') {
    top: calc(#{$navbar-height} + #{$base-unit} * 3);
    left: calc(50% + #{$max-content-width-lg} / 2 - #{$base-unit} * 5 - #{$base-unit} * 20);
  }

  @include screen-between('md', 'lg') {
    right: $base-unit * 4;
  }

  @include screen-between('sm', 'md') {
    right: $base-unit * 2;
  }

  &.show {
    width: calc(100vw - #{$base-unit * 2});
    max-width: 330px;
    height: 60%;
    max-height: 400px;
    border-radius: 3px;
    overflow: auto;
    padding: $base-unit;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    top: calc(#{$navbar-height} + #{$half-unit});

    @include screen('sm') {
      width: $base-unit * 20;
      height: auto;
      max-height: calc(100vh - #{$navbar-height} - #{$base-unit} * 4);
      box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
      border-radius: 0;
      top: calc(#{$navbar-height} + #{$base-unit} * 2);
    }
  }

  .hint-content {
    width: calc(100vw - #{$base-unit} - 50px);
    max-width: 280px;
  }

  .close-hint {
    float: right;
    color: $sf-gray-base;
  }

  #hint-lightbulb {
    display: block;
    margin-bottom: $base-unit;
    color: $sf-gray-base;
    font-size: $font-size-xxxl;
  }
}

.listing-wizard-hints-underlay {
  position: fixed;
  background-color: $black;
  opacity: 0;
  z-index: 80;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: opacity .2s ease;
  pointer-events: none;

  &.visible {
    opacity: .2;
    pointer-events: auto;

    @include screen('sm') {
      opacity: 0;
      pointer-events: none;
    }
  }
}
