$open-filter-offset: 45px;
$filter-bar-bottom-line: 130px;
$button-selector-width-base: 500px;
$filter-bar-height: 66px;
.button-selectors {
  display: inline-block;
  padding: $padding-compact-lg;
  width: $button-selector-width-base;

  @include screen-max('sm') {
    position: fixed;
    padding: 44px 24px;
    width: 100vw;
    height: 100%;
    top: $filter-bar-bottom-line;
    left: 0;
    .project-type-title {
      font-size: $font-size-md;
    }
  }

  .button-selector-item {
    width: 20%;
    height: 100px;
    position: relative;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    font-size: $font-size-xs;
    white-space: normal;
    float: left;

    &.active {
      color: $sf-green;

      .project-type-icon {
        background: $sf-green !important;
        border-color: $sf-green !important;

        g, path, circle {
          fill: $white;
        }
      }
    }

    .project-type-icon {
      @include circle($base-unit * 3.5);

      margin: $half-unit auto;
      border: 1px solid $sf-gray-dark;
      position: relative;
      transition: background 0.2s ease, transform 0.2s ease, border 0.2s ease;

      cursor: pointer;

      @include screen-max('md') {
        @include circle($base-unit * 3);
      }

      &:hover {
        transform: scale(1.075);
        -webkit-transform: scale(1.075);
        border-color: $sf-black;
      }

      > div {
        @include absolute-middle;

        height: $quarter-unit * 9;
        width: $quarter-unit * 9;

        > svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.filter-bar-wrapper {
  @include z-layer-4;
  position: fixed;
  top: $base-unit * 4;
  padding: $padding-base 0;
  background: white;
  width: 100%;
  height: $filter-bar-height;
  overflow: hidden;
  border-bottom: solid 1px $sf-gray-light;

  @include screen('sm') {
    width: 65%;
  }

  &.open-filter {
    overflow: visible;
  }

  &.scroll-anchored {
    position: absolute;
    bottom: 0;
  }
  .filter-gradient {
    position: absolute;

    width: 20px;
    height: 100%;
    z-index: 1;
    pointer-events: none;
  }
  .left-filter-gradient {
    left: 0;
    background: transparent;
    background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  }
  .right-filter-gradient {
    right: 0;
    background: transparent;
    background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
  }
  .filter-bar {
    overflow-x: scroll;
    white-space: nowrap;
    padding-left: $base-unit;
    padding-right: $base-unit;
    padding-bottom: $padding-lg;
    &.open-filter {
      @include screen('sm') {
        height: auto;
        overflow-x: visible;
      }
      padding-bottom: $padding-lg;
    }

    .ui-widget {
      pointer-events: none;
    }

    .filter-wrapper.filter-project-type-wrapper {
      .filter-project-type {
        background-color: white;
        position: absolute;
        z-index: 99;
        width: 480px;
        top: $open-filter-offset;
        height: auto;

        @include screen-max('sm') {
          position: fixed;
          right: 0;
          top: $filter-bar-bottom-line;

          width: 100%;
          height: 100%;
        }

        .project-type-title {
          display: table-cell;
          color: $sf-gray-dark;
          text-align: center;
          vertical-align: top;
          height: 20px;
          line-height: 20px;
          font-weight: bold;
          margin-bottom: $base-unit;
        }
      }
    }

    .filter-wrapper,
    select {
      display: inline-block;
      margin: 0;
    }

    .filter-wrapper {
      position: relative;
      margin-right: $quarter-unit;
      .filter-card {
        @include screen('sm') {
          padding-bottom: $base-unit;
          border-radius: 2px;
          border: 1px solid $sf-gray-base;
          box-shadow: 4px 6px 20px 3px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .more-filters {
      .fa.fa-angle-down {
        position: relative;
        top: 1px;
        margin-left: 1px;
      }

      margin-right: 0;
    }

    .diagnostic-btn {
      margin-left: $quarter-unit;

      .diagnostic-btn-txt {
        span {
          @include screen-max(1500px) { display: none; }
        }
      }
    }

    .filter-date-picker-wrapper {
      .filter-date-picker {
        position: absolute;
        top: $open-filter-offset;

        z-index: 99;
        width: 400px;
        padding: 15px;
        background-color: white;

        @include screen-max('sm') {
          position: fixed;
          left: 0;
          top: $filter-bar-bottom-line;

          width: 100%;
          height: 100%;

        }
        .sf-form-row-datepicker-calendar {
          position: unset;
        }
        @include screen('sm') {
          border-radius: 2px;
          border: 1px solid $sf-gray-base;
          box-shadow: 4px 6px 20px 3px rgba(0,0,0,0.2);
        }
        .sf-calendar-container {
          border: 0;
          box-shadow: unset;
        }

        .sf-form-row {
          padding: 0;
        }
        .sf-form-row-datepicker-halfwidth .sf-form-header {
          display: none;
        }
        .filter-clear-button {
          margin-top: 0;
        }
      }
    }

    .ui-slider-handle {
      pointer-events: all;
    }
    .pricing-filter-wrapper .ui-slider-handle {
      display: none;
      pointer-events: none;
    }
    .pricing-filter-wrapper .ui-slider-handle+.ui-slider-handle {
      display: block;
      pointer-events: all;
    }

    .listing-filter-slider {
      position: absolute;
      z-index: 99;
      width: 250px;
      top: $open-filter-offset;
      background-color: white;
      padding: 0 $padding-lg 0 $padding-lg;
      border: 1px solid $sf-gray-light;

      @include screen-max('sm') {
        position: fixed;
        right: 0;
        top: $filter-bar-bottom-line;
        padding-left: $half-unit * 6;
        padding-right: $half-unit * 6;

        width: 100%;
        height: 100%;
        border: 0;
      }

      .sf-slider {
        width: 100%;
      }
      .slider-text {
        display:none;
      }
    }

    .pricing-filter {
      position: absolute;
      width: 300px;
      top: $open-filter-offset;
      background: white;

      padding-bottom: $padding-base;
      &.budget-filter {
        padding-bottom: 12px;
      }

      @include screen-max('sm') {
        position: fixed;
        right: 0;
        left: 0px;
        top: $filter-bar-bottom-line;
        padding-left: 48px;
        padding-right: 48px;
        width: 100%;
        height: 100%;
        border: 0;
      }

      .pricing-option-buttons {
        display: table;
        width: 100%;
        padding: 12px 12px 0px 12px;

        .pricing-option {
          position: relative;
          display: table-cell;
          width: 120px;

          padding: $padding-half 0px;
          text-align: center;
          font-weight: $font-weight-regular;
          cursor: pointer;
          color: $sf-green;
          border: 1px solid $sf-gray-base;
          +.pricing-option {
            border-left: 0px;
          }
          &.active {
            color: $sf-white;
            background-color: $sf-green;
          }
        }
      }
      .pricing-message-container {
        width: 230px;
        margin: 40px auto 0 auto;
        line-height: 1.6;
        @include interface-body;
        .pricing-message {
          text-align: center;
          white-space: normal;
          margin-bottom: 0;
          a {
            color: $sf-green;
            cursor: pointer;
          }
          &.top-message {
            margin-bottom: 0;
          }
          &.bottom-message {
            @include small-legend;
            margin: 0;
          }
        }
      }
      .disabling-layer {
        position: absolute;
        top: 140px; /* this is the height of the pricing filter without the slider */
        bottom: 0;
        width: 100%;
        @include z-layer-3;
        background: $sf-disabled
      }
      .listing-filter-slider {
        position: unset;
        width: 100%;
        border: none;
      }
    }
  }

  .sf-listing-sort-dropdown-wrapper {
    display: none;
    position: absolute;
    top: $base-unit + 1px;
    right: $base-unit;

    @include screen('md') {
      display: flex;
    }
  }

  .sf-listing-filter-foot-traffic {
    padding-top: ($base-unit * 2) !important;

    .slider-range {
      text-align: center;

      span {
        cursor: pointer;
      }
    }

    .ui-slider {
      background: $sf-green;
    }
  }

  .foot-traffic-filter-tooltip {
    position: absolute;
    left: $base-unit;
    bottom: $base-unit;
    width: $base-unit;
    height: $base-unit;
    padding-top: 1px;
    border-radius: $half-unit;
    border: 1px solid $sf-gray-dark;
    color: $sf-gray-dark;
    background-color: $sf-white;
    text-align: center;
    font-size: $font-size-sub;
    font-weight: $font-weight-light;
    cursor: pointer;

    &.active .foot-traffic-filter-tooltip-content {
      display: block;
    }

    .foot-traffic-filter-tooltip-content {
      @include z-layer-1;

      &:before {
        content: '';
        position: absolute;
        left: $base-unit;
        top: -10px;
        z-index: 1;
        @include arrow-up(5px, #FFF);
      }

      &:after {
        content: '';
        position: absolute;
        top: -11px;
        left: $base-unit;
        @include arrow-up(5px, $sf-gray-base);
      }

      display: none;
      background-color: $sf-white;
      padding: $half-unit;
      font-size: $font-size-xs;
      position: absolute;
      border: 1px solid $sf-gray-base;
      top: 22px;
      left: -14px;
      color: $sf-black;
      width: 230px;
      text-align: center;
      white-space: normal;
    }
  }
}
.open-filters-background {
  &.open-filter {
    @include z-layer-3;

    position: fixed;
    top: $filter-bar-bottom-line;
    right: 35%;
    left: 0;
    bottom: 0;

    height: 100vh;
    background: rgba(255, 255, 255, 0.85);
  }
  &.scroll-anchor-to-footer {
    position: absolute;
    top: auto;
    bottom: 0;
    height: 100vh;
  }
}

.advanced-filter-panel-wrapper {
  @include card-flat;
  @include z-index-base;
  position: relative;

  overflow: visible;
  will-change: position;
  border-radius: 0;

  &.open {
    @include z-layer-4;
    position: fixed;
    top: $filter-bar-bottom-line;
    bottom: 0;

    @include screen('sm') {
      right: 35%;
      left: 0;
    }

    @include screen('md') {
      right: 45%;
    }
  }

  .advanced-filter-panel {
    @include z-layer-5;
    overflow-y: auto;
    height: 0;

    .sf-slider {
      width: 100%;
      .slider-text {
        text-align: left;
        font-weight: bold;
        font-size: $font-size-sm;
        color: black;
      }
    }

    &.open {
      height: calc(100% - 86px); /* 86px = the height of 'listing-filter-actions' */
    }

    .sf-form {
      transition: padding 0.2s ease;
      .sf-form-row {
        margin: 0;
        border-top: solid 1px $sf-gray-light;
        .sf-form-title-wrapper.project-type-title {
          display: table-cell;
          @include screen-max('sm') {
            display: block;
          }
          vertical-align: middle;
        }

        .sf-form-input-wrapper {
          .sf-form-tag {
            max-height: 36px;
          }
          .tag-label {
            min-height: 32px;
          }
        }
      }
    }

    .sf-form-duration {
      @include screen('sm') {
        .sf-form-title-wrapper {
          vertical-align: middle;
        }
      }

      .sf-form-duration-wrapper {
        @include screen('sm') {
          max-width: $base-unit * 15;
          vertical-align: middle;
        }

        .sf-form-input-wrapper {
          .sf-form-input {
            padding: 11px;
          }
        }
      }
    }

    .sf-form-datepicker {
      @include screen('sm') {
        .sf-form-title-wrapper {
          vertical-align: middle;
        }
      }

      .sf-form-row-datepicker {
        @include screen('sm') {
          max-width: $base-unit * 30;
          vertical-align: middle;
        }

        input {
          font-size: $font-size-sm;
        }
      }
    }
  }

  .listing-filter-actions {
    @include clearfix;
    @include z-layer-1;

    background: $sf-gray-light;
    padding: $base-unit 0 $base-unit $base-unit * 1.5;
    overflow: visible;
    position: absolute;
    bottom: 0;
    width: 100%;

    &.open {
      background: $white;
      border-top: 1px solid $sf-gray-light;
    }

    @include screen-max('sm') {
      .sf-btn-primary, .sf-btn-secondary {
        float: unset;
        vertical-align: middle;
        padding: 10px 12px;
      }
    }
    .filter-clear-button {
      margin-right: 30px;
      margin-top: 18px;
      @include screen-max('sm') {
        float: unset;
        position: absolute;
        right: 10px;
        display: inline-block;

        max-width: 92px; /* Best fit for provided translations length */
        line-height: 1.5;
        margin-top: 0;
        margin-right: 10px;
        text-align: center;
        vertical-align: middle;
        .button-text-wrapper {
          display: table;
          height: 45px;
          .button-text {
            display: table-cell;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .size-filter .filter-clear-button {
    display: none;
  }

  .filter-project-type {
    display: table;
    position: unset;
    .project-type-title {
      text-align: left;
      font-weight: bold;
      font-size: $font-size-sm;
      color: black;
    }
    .project-types-wrapper {
      display: table-cell;
      @include screen-max('sm') {
        display: block;
      }
    }
    .button-selectors {
      width: 100%;
      border-top: 0;
    }
  }
}

.filter-clear-button {
  float: right;
  display: inline-block;

  cursor: pointer;
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
  margin-top: $quarter-unit * 3;
  color: $primary-green;
}
.project-type-name-wrapper {
  margin: 0 auto;
  @include screen(450px) {
    width: 75px;
    height: 35px;
  }
  span {
    width: 75px;
    height: 35px;
    margin: 0 auto;
    display: table-cell;
    vertical-align: middle;
  }
}

/* The following media queries aim at displaying as many filters as possible in the filter bar */
/* They're calculated with the italian/french versions of the website in mind as they make the longest filter buttons translations */
.filter-bar {
  .filter-wrapper {
    &.size-filter {
      @include screen-max(1160px) {
        display: none;
      }
      @include screen-max(450px) {
        display: inline-block;
      }
      @include screen-between(600px, 'sm') {
        display: inline-block;
      }
    }
    &.filter-project-type-wrapper {
      @include screen-between(450px, 1300px) {
        display: none;
      }
      @include screen-between(710px, 'sm') {
        display: inline-block;
      }
    }
  }
}
.advanced-filter-panel-wrapper {
  .advanced-filter-panel {
    .sf-form {
      .sf-form-row {
        &.size-filter {
          border-top: none;
          @include screen(910px) {
            display: none;
          }
          @include screen-max(450px) {
            display: none;
          }
          @include screen-between(600px, 'sm') {
            display: none;
          }
        }
        &.filter-project-type {
          display: none;
          @include screen-between(450px, 1090px) {
            display: table;
          }
          @include screen-between(600px, 710px) {
            border-top: none;
          }
          @include screen-between(710px, 'sm') {
            display: none;
          }
          @include screen(910px) {
            border-top: none;
          }
        }
        &.sf-form-duration {
          @include screen-max(450px) {
            border-top: none;
          }
          @include screen-between(710px, 'sm') {
            border-top: none;
          }
          @include screen(1090px) {
            border-top: none;
          }
        }
      }
    }
  }
}

.mobile-sort-dropdown {
  margin: ($filter-bar-height + $base-unit) ($quarter-unit * 3) 0 0;
  float: right;

  @include screen('sm') {
    margin-right: $half-unit * 3;
  }

  @include screen('md') {
    display: none !important;
  }
}
