.not-found-content-container {
  display:table;
  vertical-align:middle;
  text-shadow: 0px 8px 30px rgba(0,0,0,0.1);
  background-image: linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("/assets/images/backgrounds/error-page-background.jpg");
  background-size: cover;
  background-position: 0 50%;
  background-attachment: fixed;

  @include screen-max('xs') {
    background: rgba(0,0,0, 0.2);
    padding: 0 20px;
  }

  .not-found-content-copy {
    display:table-cell;
    vertical-align:middle;
    height:100%;
    height:100vh;
    width:100vw;
    text-align:center;

    .not-found-header {
      font-size: 50px;
      line-height: 80px;
      font-weight: $font-weight-heavy;
      color:#FFF;
      width: 100%;
      text-align:center;

      @include screen-max('sm') {
        font-size: 24px;
        line-height: 30px;
      }
    }

    .not-found-sub-header,
    a.not-found-home-page-link {
      font-size:16px;
      line-height: 20px;
      font-weight:300;
      color:#FFF;
      width: 100%;
      text-align:center;

      @include screen-max('sm') {
        font-size: 13px;
      }
    }

    .btn-homepage-redirect {
      padding:20px;
      margin-top: 10px;
    }
  }

  .not-found-footer {
    position: absolute;
    bottom:0;
    padding:20px;
    left:0;
    right:0;
    z-index:1;
    color: rgba(255, 255, 255, 0.8);
    text-align:center;

    .not-found-footer-link {
      font-size: 14px;
      margin: 0 10px;
      display: inline-block;

      @include screen-max('sm') {
        font-size: 12px;
      }
    }
  }
}
