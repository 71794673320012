.sf-inquiry-steps {
  border-top: 1px solid $sf-gray-base;
  padding: $padding-base;
  padding-bottom: 0;
  margin-top: $base-unit;

  @include screen('sm') {
    padding: unset;
    padding-top: $padding-base;
  }

  .inquiry-steps-header {
    font-weight: $font-weight-heavy;
  }

  .step-callback-info {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding: $half-unit ($quarter-unit * 3);
    flex-grow: 1;
    border-radius: $border-radius-base;
    background-color: $sf-gray-light;

    &.checked {
      background-color: tint($sf-green, 95%);
      color: tint($sf-green-darker, 50%);

      .callback-status {
        color: tint($sf-green-darker, 50%) !important;
      }
    }
  }

  .step-list {
    width: 100%;
    margin: $base-unit 0;

    .step {
      position: relative;
      display: flex;
      padding-bottom: ($quarter-unit * 3);
      align-items: center;

      &:last-of-type {
        padding-bottom: 0;
      }

      &:not(:first-of-type)::before {
        @include z-index-base;

        content: '';
        display: block;
        width: $quarter-unit;
        background: $sf-gray-base;
        position: absolute;
        left: 10px;
        top: 0;
        bottom: 50%;
      }

      &:not(:last-of-type)::after {
        @include z-index-base;

        content: '';
        display: block;
        clear: both;
        width: $quarter-unit;
        background: $sf-gray-base;
        position: absolute;
        left: 10px;
        top: 50%;
        bottom: 0;
      }

      &.prev-checked::before, &.checked::after {
        background-color: $sf-green;
      }

      &.checked + .step:not(.checked)::before {
        background: linear-gradient(to bottom, $sf-green 0%, $sf-gray-base 40%);
      }

      .step-action-wrapper, .step-number-wrapper, .step-description-wrapper {
        display: table-cell;
        vertical-align: middle;
      }

      .step-number-wrapper {
        margin-top: $quarter-unit;

        .step-number {
          float: left;
          margin-right: $half-unit;
          color: $sf-gray-base;
          width: 24px;
          text-align: center;

          &.checked {
            color: $sf-green;
          }
        }

        .step-svg .svg-inline--fa.fa-w-16 {
          @include z-layer-1;

          height: 1.5em !important;
          width: 1.5em !important;
          position: relative;
          background: $sf-white;
          border-radius: $border-radius-circular;
        }
      }

      .step-description {
        font-size: $font-size-sm;
        font-weight: $font-weight-bold;
        position: relative;

        .callback-status {
          color: $sf-blue;
        }

        .tooltip {
          left: calc(100% + #{$base-unit});
          width: $base-unit * 10;
          display: none;
          @include card-popover($sf-white, $sf-gray-base);

          color: $sf-black;
          font-weight: normal;
          font-size: $font-size-xs;
          top: calc(50% - 17px);
          padding: $half-unit $base-unit;
          -webkit-box-shadow: 0px 0px #{$half-unit} #{-$quarter-unit} $sf-black;
          -moz-box-shadow: 0px 0px #{$half-unit} #{-$quarter-unit} $sf-black;
          box-shadow: 0px 0px #{$half-unit} #{-$quarter-unit} $sf-black;

          a {
            color: $sf-green;
          }

          &:before, &:after {
            border-bottom-color: transparent;
          }

          &:before {
            top: $half-unit - 1;
            left: -18px;
            @include arrow-left($half-unit + 1, $sf-gray-base);
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }

          &:after {
            top: $half-unit;
            left: -16px;
            @include arrow-left($half-unit, $sf-white);
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }
        }

        &:hover {
          .tooltip {
            display: block;
          }
        }
      }

      .step-action-wrapper {
        .step-action {
          font-size: $font-size-sm;
          padding: $padding-quarter $padding-base;
          margin: $half-unit 0 auto !important;
          border-radius: $border-radius-base;

        }
      }
    }
  }

  .inquiry-steps-callback-container {
    border-top: 1px solid $sf-gray-base;
    margin: $base-unit 0 0;
    padding: $padding-base 0;

    .callback-contact-name {
      font-weight: bold;
    }

    .phone-number-title {
      font-size: $font-size-md;
      font-weight: $font-weight-medium;
    }

    .address-title {
      margin-bottom: 0;
    }

    .callback-details {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid $sf-gray-base;
      padding: 0 $padding-half $padding-base;

      @media (max-width: 767px) {
        & {
          flex-direction: row;
        }
      }

      @media (max-width:369px) {
        & {
          flex-direction: column;
        }
      }
    }
    .renter-callback-number-wrapper, .owner-callback-number-wrapper {
      display: flex;
      margin-top: $base-unit;

      .nav-icon {
        display: flex;
        align-items: center;
        margin-right: $half-unit;
      }

      @media (max-width: 767px) {
        & {
         margin: 8px auto 0;
        }
      }

      .phone-number {
        font-weight: $font-weight-regular;
        font-size: $font-size-sm;
      }
    }

    .callback-address-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 $padding-half;

      .callback-address {
        margin-bottom: $half-unit;
      }

      div {
        font-weight: $font-weight-medium;
        font-size: $font-size-sm;
      }
    }

    .callback-next-steps {
      font-size: $font-size-sm;
      border-bottom: 1px solid $sf-gray-base;
      padding: 0 $padding-half $padding-base;

      .next-steps-title {
        margin-bottom: $half-unit;
        font-size: $font-size-md;
        font-weight: $font-weight-regular;
      }

      .visit-steps {
        margin-bottom: $quarter-unit;

        .visit-steps-n {
          font-weight: $font-weight-regular;
          font-size: $font-size-md;
        }
      }
    }

  }

  .callback-owner-terms {
    color: $sf-black;
    font-size: $font-size-sm;
    border-top: 1px solid $sf-gray-base;
    padding: 0 $padding-half;
    margin-top: $base-unit;
    font-weight: $font-weight-regular;

    div {
      margin-top: $font-size-xs;
      padding: $padding-base;
      background-color: tint($sf-orange, 95%);
    }
  }

}

