.owned-listings-container {

  max-width: initial;
  padding: $padding-lg;
  background-color: $sf-white;

  @media (min-width: $max-content-width-md) {
    padding: $padding-lg ($base-unit * 15) ($padding-lg * 4);
  }

  .page-title {
    text-align: initial;
    max-width: initial;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: $max-content-width-sm) {
      flex-direction: row;
    }

    > h1 {
      font-size: $font-size-xxl;
      margin: 0;
      display: inline-block;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: $base-unit 0;
      padding: $half-unit ($base-unit + $half-unit);
      font-size: $font-size-sm;

      @media (min-width: $max-content-width-sm) {
        margin: initial;
      }
    }

    border-bottom: 1px solid $sf-gray-light;
  }
}
