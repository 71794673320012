.ant-picker-input {
  input[readonly] {
    background-color: $sf-white;
  }
}

.ant-picker-cell-in-range {
  color: $sf-white !important;

  &::before {
    background-color: #57d497 !important;
    opacity: .7 !important;
  }

  &.ant-picker-cell-range-hover::before {
    opacity: 1 !important;
  }
}

.ant-picker-cell-range-hover-end {

  &:not(.ant-picker-cell-selected) {
    &:hover .ant-picker-cell-inner, .ant-picker-cell-inner:hover {
      background: none !important;
    }
  }

  .ant-picker-cell-inner::after {
    background-color: #57d497 !important;
  }

  &::before {
    background-color: #57d497 !important;
  }
}

.ant-picker-cell:not(.ant-picker-cell-selected) .ant-picker-cell:hover .ant-picker-cell-inner {
  background: none !important;
}

.ant-picker-cell-range-hover-start {

  &:not(.ant-picker-cell-selected) {
    &:hover .ant-picker-cell-inner, .ant-picker-cell-inner:hover {
      background: none !important;
    }
  }

  .ant-picker-cell-inner::after {
    background-color: #57d497 !important;
  }

  &::before {
    background-color: #57d497 !important;
  }
}

.ant-picker-cell-range-start, .ant-picker-cell-range-end::before{
  background-color: #57d497 !important;
}

@include screen-max('sm') {
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none !important;
  }

  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}
