.sf-icon {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  margin: auto;
  font-size: 80px;
  text-align: center;
  color: inherit;

  &.location {
    background-image: url("/assets/images/icons/icons_location.png");
    @media only screen and (min-resolution: 1.5dppx),
      only screen and (min-resolution: 144dpi) {
      // Retina stuff
      background-image: url("/assets/images/icons/icons_location@2x.png");
    }
  }

  &.arrow-down { 
    background-image: url("/assets/images/icons/icons_arrow_down.png");
    @media only screen and (min-resolution: 1.5dppx),
      only screen and (min-resolution: 144dpi) {
      // Retina stuff
      background-image: url("/assets/images/icons/icons_arrow_down@2x.png");
    }
  }
}
