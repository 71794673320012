.sf-form-listing-pricing {
  padding-left: 0 !important;

  .pricing-container {
    width: 100%;

    .pricing-row {
      width: 100%;
      display: flex;
      align-items: center;
      padding: $half-unit 0 $half-unit $base-unit;
      border-radius: $border-radius-base;

      &:not(:first-of-type) {
        border-bottom: 1px solid $sf-gray-light;

        &:hover {
          background-color: $sf-gray-light;
        }
      }

      &:first-of-type {
        background-color: $sf-white;
      }

      > div {
        width: 35%;
        text-align: right;
        text-align: center;
        display: inline-block;
        padding: 0 $padding-half;
        font-size: $font-size-sm;

        vertical-align: top;

        &.padded {
          padding: 2px;
        }

        &.label {
          width: 30%;
          text-align: left;
          padding-left: 0;

          > label {
            font-size: inherit;
          }
        }

        &.heading {
          font-weight: $font-weight-regular;
          text-align: center;

          i.fa.fa-question-circle {
            position: relative;

            .tooltip {
              @include card-popover(rgba(0, 0, 0, 0.75), transparent);

              // NOTE: this needs to have display:none because we don't want to show
              // anything here until it's hovered (see `&:hover .tooltip` below)
              display: none;

              color: $white;
              font-weight: 400;
              min-width: ($base-unit * 11.75);
              top: calc(100% + 10px);
              left: -88px;

              font-family: $brown-pro;
              font-size: $font-size-xs;
            }

            &:hover {
              .tooltip {
                display: block;
              }
            }
          }
        }

        .suggestion {
          font-size: $font-size-xs;
          text-align: center;
          font-weight: $font-weight-regular;
        }

        input {
          &.error {
            border-color: $sf-red !important;
            background: tint($sf-red, 95%);
            color: $sf-black;
          }
        }
      }

      .sf-form-input-wrapper .sf-form-input {
        border-radius: $border-radius-base;
      }
    }
  }

  &.thin {
    .pricing-container {
      .pricing-row {
        padding: 2px 0;
      }
    }
  }
}
