.sf-map {
  height: 100%;
  width: 100%;

  .angular-google-map, .angular-google-map-container {
    width: 100%;
    height: 100%;
  }

  .listing-marker-pin {
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);

    border-radius: $border-radius-base;
    border: 1px solid $sf-white;
    background: $sf-green;
    text-align: center;
    font-weight: $font-weight-regular;
    color: $sf-white;
    transition: box-shadow ease .2s, transform ease .2s;

    &.selected {
      @include box-shadow(0 0 4px rgba(0, 0, 0, 0.3));

      background: #fdd73d;
      z-index: 1000 !important;

      $scale: 1.1;

      -webkit-transform: scale($scale, $scale);
      -moz-transform: scale($scale, $scale);
      -ms-transform: scale($scale, $scale);
      -o-transform: scale($scale, $scale);
      transform: scale($scale, $scale);
    }
  }

  .gm-bundled-control-on-bottom > .gmnoprint {
    top: 20px !important;
    left: -10px !important;
    & > div {
      display: flex;
      flex-direction: column;

      width: 1em !important;
      height: unset !important;
      margin: 6px;
      padding: 2px;

      font-size: 28px;
      background-color: white;
      box-shadow: rgba(0,0,0,0.3) 0px 1px 4px -1px;
      box-sizing: border-box;
      border-radius: 2px;
      bottom: 30px !important;
      button {
        width: 23px !important;
        height: 26px !important;
        border: 0;
        background-color: white;
        color: rgba(0, 0, 0, 0.6);
        font: 0.85em Arial;
        margin: 1px !important;
        padding: 0;
        &:hover {
          color: rgba(0, 0, 0, 0.9);
        }
        &:focus {
          outline: 0;
        }
        & + div {
          width: 21px !important;
          right: 4px;
        }
        img {
          width: 12px !important;
          margin: unset !important;
        }
      }
    }
  }
}
