@mixin nav-offset {
  top: $navbar-height;
}

@mixin nav-margin-offset {
  margin-top: $navbar-height;
}

@mixin nav-item {
  display: flex;
  align-items: center;
  padding: 0 $half-unit;
  position: relative;
  white-space: nowrap;
  cursor: pointer;

  &.first {
    padding-left: $padding-base * 1.5;

    @include screen-max('sm') {
      padding-left: $padding-base;
    }
  }
  &.last {
    padding-right: $padding-base * 1.5 !important;

    @include screen('sm') {
      padding-right: $padding-base !important;
    }
  }
}
