.listing-row {
  display: inline-block;
  width: 50%;
  vertical-align: top;

  @include screen-max('sm') {
    width: 100%;
    display: block;
  }

  .favorite-tag {
    @include favorite-tag($base-unit * 2, $font-size-md, $half-unit);
  }

  .listing-panel {
    @include card-main($half-unit);

    overflow:visible;
    border-radius: 0;
    box-shadow: none;
    margin: $half-unit ($half-unit * 1.5);

    @include screen-max('sm') {
      margin: $base-unit auto;
    }

    &:hover {
      .arrow {
        opacity: 1;
      }
    }
  }

  .listing-image-header {
    height: 18vw;
    width:100%;
    position: relative;
    background-color: $sf-white;
    cursor: pointer;

    @include screen-max('sm') {
      height: ($base-unit * 14);
    }
    .image {
      border-radius: 3px;
    }
    .lazy-loaded-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 3px;
    }

  }

  .listing-title-gradient {
    @include absolute-bottom-left(0);

    right: 0;
    padding: $padding-compact;
    background: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));
    pointer-events: none;
  }

  .listing-info {
    padding: 2px 0 $half-unit 0;
    position: relative;

    .listing-price {
      font-size: $font-size-sm;
      font-weight: normal;
      @include text-overflow;
    }

    .listing-title {
      width: 28vw;
      @include text-overflow;
      font-size: $font-size-md;
      font-weight: $font-weight-regular;
      cursor: pointer;
      padding: $quarter-unit 0;
    }

    .listing-location-size-container {
      @include subheader-md;
      @include text-overflow;
      padding-bottom: $quarter-unit;
    }
  }

  .listing-owner-contact-details {
    padding: $padding-base;
    position: relative;

    .contact-info-header {
      border: none;
      border-top: 1px solid $sf-gray-base;
      @include header-sm;
      text-align: center;
    }

    .contact-info-row {
      .contact-method {
        @include header-xs;
        display: inline-block;
      }

      .contact-detail {
        font-size: $font-size-sm;
      }
    }
  }

  .listing-adjudicate-actions {
    padding: $half-unit;
    background: $sf-gray-light;

    .ref-number-row,
    .button-row {
      width: 100%;
    }

    .ref-number-row {
      margin-bottom: $half-unit;

      label {
        width: 30%;
      }

      input {
        width: 70%;
      }
    }
  }

  @include screen-max('sm') {
    .favorite-tag {
      display: none;
    }

    .arrow {
      display: none;
    }

    .listing-panel {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .listing-image-header {
      display: table-cell;
      height: 88px;
      width: 120px;
    }

    .listing-info {
      padding: 0;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;

      .listing-title {
        @include text-overflow;
        margin: 0;
        padding: 0 0 $padding-quarter ($padding-quarter * 3);
      }

      .bottom-listing-info {
        width: 58vw;
        padding-left: $padding-quarter * 3;

        .listing-location-size-container {
          @include text-overflow;
        }
      }
    }

    .listing-panel .sf-listing-badge {
      display: none;
    }
  }
}
