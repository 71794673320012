.sf-approve-terms {
  padding: $base-unit $base-unit 0;

  .approve-terms-header {
    font-size: $font-size-md;
    font-weight: $font-weight-regular;
  }

  .sf-btn-primary {
    padding: $base-unit 0;
    width: 100%;
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;
  }

  .approve-terms-body {
    font-weight: $font-weight-light;
    font-size: $font-size-sm;
    margin: $base-unit 0;
  }
  
  .approve-book-online-info {
    text-align: left;

    .book-online-header {
      @include header-md;
      margin-bottom: $half-unit;

      .header-text {
        display: inline-block;
        width: calc(100% - 2 * #{$base-unit});
      }
    }

    .book-online-subheader {
      @include header-sm;
    }

    .book-online-body {
      @include body-text;
      color: $sf-gray-dark;
    }
  }
}
