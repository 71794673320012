.review-panel {

  .review-body {
    padding: $padding-base;

    .review-header {
      font-size: $font-size-md;
      font-weight: $font-weight-regular;
    }

    .review-subheader {
      font-weight: $font-weight-light;
      font-size: $font-size-sm;
      margin: $base-unit 0;
    }

    .sf-btn-primary {
      padding: $padding-half 0;
      width: 100%;
      font-weight: $font-weight-regular;
      font-size: $font-size-sm;
    }
  }
}

.sf-review-form {
  padding: $base-unit 0;

  .review-header {
    @include header-lg;
    text-align: center;
  }

  .review-subheader {
    @include body-text;

    color: $sf-gray-dark;
    padding: $half-unit $base-unit;
  }

  .review-feedback {
    .review-feedback-textarea {
      width: 100%;
    }
  }

  .sf-form-row {
    padding: $padding-compact;
  }

  .btn-submit-form {
    margin: 0 auto;
    margin-top: $base-unit;
  }
}
