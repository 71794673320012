.half-width {
  width: 50%;
}

.visible {
  display: block !important;
}

.inline-block {
  display: inline-block;
}
