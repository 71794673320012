.forgot-password-form {
  padding:80px 40px;
  max-width: 400px;
  margin: 0 auto;

  #form {
    margin-top: 40px;
  }

  .email {
    padding:10px;
    margin:20px auto;
    width:100%;
    max-width:400px;
  }

  .forgot-password-header {
    font-weight: 400;
    color: $sf-black;
    margin-top: 20px;
  }

  .password,
  .password-confirm {
    display: block;
    width: 100%;
    margin:5px auto 20px auto;
  }

  button[disabled] {
    background-color: $sf-gray-base;
    color: $sf-gray-dark;
    border-color: $sf-gray-base;

    &:hover {
      background-color: $sf-gray-base;
    }
  }
}
