.inquiry-content-container {
  padding-top: $navbar-height;
}

.inquiry-page {
  background: $white;
}

.inquiry-reassurance-message p {
  text-align: center;
}
.inquiry-container {
  margin: 0 auto;
  max-width: 800px;
  padding: 0;
  @include screen('sm') {
    padding: $base-unit;
  }
  @include screen('lg') {
    padding: $half-unit * 3;
  }

  .sf-inquiry-form, .main-content {
    .sf-form-checkbox-label {
      font-size: 14px;
    }

    position: relative;
    padding: $base-unit;
    border-bottom: 1px solid $sf-gray-light;

    .inquiry-form-checkbox {
      padding-top: $padding-half;
      padding-bottom: $padding-base;
    }

    @include screen('sm') {
      border: 1px solid $sf-gray-light;
    }

    .sf-form-checkbox-label {
      font-size: 14px;
    }

    // Overrides for the $sf-grey-base color on active that is set
    // in datepicker css
    .sf-form-row {
      .sf-form-errors {
        ul.error-list {
          margin: 6px 0;
        }
      }

      .sf-form-input,
      .sf-form-row-input,
      .sf-form-select-wrapper {
        border: 1px solid #ddd;

        &::-webkit-input-placeholder {
          font-weight: 100;
        }
        &:-moz-placeholder {
          font-weight: 100;
        }
        &::-moz-placeholder {
          font-weight: 100;
        }
        &:-ms-input-placeholder {
          font-weight: 100;
        }
      }

      .sf-form-textarea {
        textarea {
          border-radius: $border-radius-base;

          &:not(.form-errors) {
            border: 1px solid #ddd;
          }

          &::-webkit-input-placeholder {
            font-weight: 100;
          }
          &:-moz-placeholder {
            font-weight: 100;
          }
          &::-moz-placeholder {
            font-weight: 100;
          }
          &:-ms-input-placeholder {
            font-weight: 100;
          }
        }
      }

      .sf-form-select-wrapper {
        width: 100%;

        select {
          font-weight: normal;
          padding: inherit;
        }
      }

      .sf-form-row-datepicker {
        .start-date {
          border-right: none;
        }
        .end-date {
          border-left: none;
        }
        .arrow-dates {
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }

        .sf-form-row-datepicker-halfwidth {
          &.border-collapse {
            &.sf-form-row-datepicker-active-input input {
              border-color: #ddd;
            }
          }
        }

        .sf-calendar-container {
          border: 1px solid #ddd;
          border-top: none;

          &:after {
            border-bottom-color: #ddd;
          }
        }
      }
    }

    .sf-form-hourly-select-times {
      select, .arrow-dates {
        border-color: #ddd;
      }
    }

    .form-error {
      .sf-form-row-datepicker {
        .arrow-dates {
          background-color: #fef4f4;
          border-top: 1px solid $primary-red;
          border-bottom: 1px solid $primary-red;
        }
      }

      .sf-form-select-wrapper {
        border: 1px solid $primary-red;
        background-color: #fef4f4;

        select {
          background-color: #fef4f4;
        }
      }
    }

    .inquiry-create-account-header {
      font-weight: bold;
    }
    .project-type-selector {
      .sf-form-select-wrapper {
        width: 100%;
        padding: $padding-quarter;
        border-radius: $border-radius-base;
      }
    }

    .login-prompt {
      font-weight: 100;
      a {
        cursor: pointer;
      }
    }
  }

  .waiting-for-location {
    .btn-submit-form {
      display: none;
    }

    .sf-form-row[data-sf-form-select][data-model-field="location"] {
      position: fixed;
      @include z-layer-top;
      top: calc(50% - #{$base-unit * 3});
      left: $half-unit * 3;
      right: $half-unit * 3;
      width: calc(100% - #{$base-unit * 3});

      &:before {
        @include card-main($margin: 0);
        @include z-layer-under;

        position: absolute;
        content: '';
        background: #fff;
        top: -3 * $half-unit;
        right: -10px;
        left: -10px;
        bottom: -3 * $half-unit;
        border-radius: $border-radius-lg;
      }

      @include screen('sm') {
        left: calc(50% - 350px);
        right: calc(50% - 350px);
        width: 700px;

        &:before {
          left: -3 * $half-unit;
          right: -3 * $half-unit;
        }
      }
    }

    .sf-form-row:not([data-sf-form-select]) {
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -ms-filter: blur(2px);
      -o-filter: blur(2px);
      filter: blur(2px);

      pointer-events: none;

      position: relative;

      &:after {
        @include layout-absolute-full-size;
        @include z-layer-1;

        background: rgba(0, 0, 0, 0.2);

        content: '';
      }
    }
  }
}

.titleSmallNote {
  font-size: $font-size-xs;
  text-align: center;
  margin-bottom: $half-unit;
  font-weight: $font-weight-heavy;
}

.page-title, .modal-page-subtitle {
  font-weight: $font-weight-semi;
  text-align: center;
  margin: auto;
  padding: $padding-half $padding-base;

  &.modal-page-subtitle {
    font-weight: $font-weight-regular;
  }
}

#callback-check-terms {
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
  margin-bottom: $half-unit;
  padding: $padding-base;
}

#callback-phone-number {
  padding: $padding-half 24px;
}

.formStep {
  text-align: center;
  font-size: $font-size-sm;
  position: absolute;
  right: $half-unit;
  top: $half-unit;
  font-weight: $font-weight-heavy;
  color: $sf-green;

}

.page-subtitle {
  font-size: $font-size-xs;
  color: $secondary-2-grey;
}

.header-subtext {
  @include subheader-md;
  text-align: center;

  @include screen('sm') {
    max-width: 90%;
    margin: auto;
  }
}

.inquiry-form {
  @include card-flat;

  overflow: visible;
  margin: $base-unit auto 0;

  .sf-listing-datepicker {
    .inquiry-datepicker {
      .sf-form-row {
        padding: $base-unit;

        .sf-form-select-wrapper select {
          font-weight: normal;
          padding: $quarter-unit ($base-unit * 2) $quarter-unit $base-unit;
        }

        &[data-sf-form-checkbox] {
          @include screen('sm') {
            padding-left: 144px !important; // 128px from width + 16px from padding
          }
        }

        &:last-child {
          padding-bottom: $base-unit * 2;
        }

        &.sf-form-datepicker {
          padding-top: 0;
          padding-bottom: 0;
          margin-bottom: $base-unit;
        }

        @include screen('sm') {
          &.sf-form-row-inline {
            // This makes the form input labels appear vertically centered but
            // fails relatively gracefully on browsers that do not support flexbox.
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .sf-form-hourly-select-times {
      padding: 0 $base-unit;
    }

    .sf-form-hourly-or-daily-tab {
      padding: 0 $base-unit;

      .radio {
        margin: $base-unit 0 $half-unit;
        padding-top: $half-unit;
        padding-bottom: $half-unit;
        font-size: $font-size-sm;
      }
    }
  }

  .project-field-button {
    padding: 0 $base-unit;
    font-size: $font-size-sm;

    a {
      cursor: pointer;
    }
  }
}

.blocked-dates {
  color: $sf-red;
  font-size: $font-size-sm;
  margin: $half-unit 0 $base-unit;
  font-weight: $font-weight-regular;
  text-align: center;
  padding: 0 $half-unit;
}

.disclaimer {
  @include card-flat($sf-gray-light);
  margin: ($base-unit * -1);
  margin-top: $base-unit;
  padding: $base-unit;
}

.listing-confirmation-info {
  @include card-flat;

  padding: $padding-base;

  .inquiry-dates-container {
    text-align: center;

    p {
      text-align: center;
    }

    .inquiry-dates {
      @include header-lg;
    }
  }

  .inquiry-next-steps {
    border-top: 1px solid $sf-gray-base;
    margin-top: $base-unit;
  }

  .steps-title {
    @include header-md;

    &.main {
      @include header-lg;
      text-align: center;
    }
  }

  p {
    color: $sf-gray-dark;
  }

  ol {
    counter-reset: li;
    li {
      list-style-type: none;
      padding-left: ($base-unit * 1.5);

      &:before {
        @include circle($base-unit * 2.5);

        content: counter(li);
        counter-increment: li;
        color: $sf-green;
        border: 2px solid $sf-green;
        text-align: center;
        font-size: $font-size-lg;
        line-height: calc(#{$base-unit * 2.5} - 2px);
        position: absolute;
        left: $base-unit;

        @include screen-max('sm') {
          @include circle($base-unit * 2);

          line-height: calc(#{$base-unit * 2} - 2px);
        }
      }

      p {
        margin: $base-unit auto;
      }
    }
  }

  @include screen-max('sm') {
    .sf-btn-secondary+.sf-btn-primary {
      margin-left: 0;
    }
  }
}
