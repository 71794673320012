.sf-form-radio {
  .sf-form-radio-wrapper {
    padding-left: $half-unit;

    input[type="radio"] {
      border: 1px solid $sf-black;

      &:checked{
        border: none;
      }
    }

    & > div {
      margin-bottom: $half-unit;
      display: flex;

      label {
        font-size: $font-size-sm;
        font-weight: $font-weight-light;
        color: $sf-black;
        display: flex;
        align-items: end;
      }

      input {
        display: flex;
      }
    }
  }
}
