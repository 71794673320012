.sf-listing-sort-dropdown-wrapper {
  width: auto !important;
  display: flex;
  align-items: center;

  .sf-form-title-wrapper.sf-listing-sort-title {
    margin-right: $half-unit;
    font-size: $font-size-sm;
    font-weight: $font-weight-heavy;
  }

  .sf-listing-sort-select {
    position: relative;

    &::after {
      content: "\f107";
      position: absolute;
      top: 10px;
      right: $quarter-unit * 3;
      font-size: $font-size-sm;
      font-weight: $font-weight-heavy;
      font-family: FontAwesome;
      pointer-events: none;
    }

    select {
      font-family: $brown-pro;
      padding: $padding-half ($padding-quarter * 7) $padding-half $padding-half;
      cursor: pointer;
    }
  }
}
