.make-payment-wrapper {
  max-width: $max-content-width-lg;
  margin: auto;
  position: relative;
  min-height: calc(100vh - #{$navbar-height});
  display: flex;

  @include screen('sm') {
    padding: ($base-unit * 2) $base-unit;
  }

  @include screen('lg') {
    padding: 0 $half-unit * 3;
    align-items: center;
  }

  .make-payment-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include screen('sm') {
      flex-direction: row;
    }
  }

  .booking-request-infos {
    padding: $base-unit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include screen('sm') {
      float: left;
      width: 50%;
    }

    .details-wrapper {
      @include card-body;
      padding: 0px $half-unit;

      .event-info-section {
        .event-details-header {
          @include header-md;
        }
      }
    }
  }

  .make-payment {
    padding: $base-unit;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include screen('sm') {
      float: right;
      width: 50%;
      min-height: 50vh;
      box-shadow: $box-shadow-left;
    }

    @include screen('md') {
      min-height: 80vh;
    }

    .transfer-warning-message {
      width: 100%;
      padding: $base-unit;
      background-color: tint($sf-orange, 90%);
      color: tint($sf-orange, 20%);
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      text-align: center;
      margin-bottom: $base-unit;
    }

    .add-payment-details-header{
      text-align: center;

      @include screen-max('md') {
        font-size: $half-unit * 3;
        font-weight: $font-weight-regular;
        color: $sf-black;
        padding-bottom: $half-unit;
      }
    }

    .add-payment-details-subheader {
      font-size:  $base-unit;
      margin-bottom: $base-unit;
      text-align: center;

      @include screen('md') {
        font-size: $base-unit + 2;
      }
    }

    .payment-details {
      display: flex;
      width: 90%;
      justify-content: center;
      padding-right: 32px;
    }

    .payments-method {
      display: flex;
      justify-content: center;

      .close-action-btn {
        display: flex;
        justify-content: center;
      }

      .close-action-btn .sf-btn-secondary {
        display: block;
        padding: $half-unit ($half-unit + $quarter-unit);
        width: 90%;
      }

      .payment-header, .add-payment-header {
        font-size: $font-size-xl;
        font-weight: $font-weight-regular;
        color: $secondary-2-grey;
        padding-bottom: $half-unit;
        margin-bottom: $half-unit;
        text-align: center;
      }

      .add-payment-header {
        font-size: $font-size-xl - 2;
        text-align: center;

        @include screen('md') {
          font-size: $font-size-lg + 2;
        }
      }

      .payment-subheader {
        font-size: $base-unit + 2;

        @include screen('md') {
          margin-bottom: $base-unit;
          text-align: center;
        }
      }

      .add-payment-methods .sf-btn-secondary {
        display: block;
        padding: $half-unit ($half-unit + 4);
      }
    }

    .ba-info-wrapper {
      @include screen('sm') {
        padding: 0 ($base-unit * 1.5);
      }

      .ba-info-header-wrapper {
        margin-top: $base-unit;
        text-align: center;

        .lightbulb-wrapper {
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 100px;
          position: relative;
          vertical-align: middle;
          text-align: center;

          .sf-icon {
            font-size: 20px;
            color: $sf-blue;
            }
          }

        .ba-info-header {
          display: inline-block;
          font-weight: $font-weight-light;
          vertical-align: middle;
          font-size: $font-size-md;
        }
      }

      .ba-info-text {
        font-weight: $font-weight-light;
        color: $sf-gray-dark;
        font-size: $font-size-xs;
        text-align: justify;
        margin-bottom: $half-unit;
        padding: inherit;

        &.second-line {
          text-align: center;
        }
      }
    }

    .submit-payment {
      text-align: center;
      min-height: 150px;
      margin-left: -$base-unit;
      margin-right: -$base-unit;
      margin-bottom: -$base-unit;
      padding: 0 ($base-unit * 2.5) $base-unit;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &.bank-account {
        min-height: 100px;
      }

      .verification-warning {
        text-align: center;
        color: $sf-orange;
        font-size: $font-size-sm;
        padding-top: 5px;
      }

      .btn {
        margin-top: $base-unit;
        padding: $half-unit + $quarter-unit;

        .loading-dots {
          position: relative;
          top: -$quarter-unit;

          .dot {
            background: #FFF;
          }
        }
      }

      .prepay-copy {
        padding: $half-unit;
        margin-bottom: $base-unit;

        .info-icon {
          cursor: pointer;
          color: $sf-info;
      }
      }
    }

  }
}

//for successfull BA addition
.sf-modal {
  .sf-payment {
    text-align: left;
    padding: 0 20px;

    .sf-payment-copy {
      position: relative;

      .sf-payment-copy-subheader {
        margin-left: 50px;
        color: $sf-black;

        @include screen-max('xs') {
          margin-left: 30px;
        }
        .fa {
          font-size: 30px;
          position: absolute;
          left: 0px;
          color: $sf-gray-dark;

          @include screen-max('xs') {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.selection-panel {
  padding: $half-unit 5px;
  width: 100%;

  @include screen-max('md') {
    padding: 0px 20px;
    width: 80%;
    margin-bottom: $base-unit;
  }

  .add-payment-methods {
    text-align: center;
    .btn {
      margin-top: 20px;

      @include screen-max('sm') {
        margin-top: 5px;
      }
    }
  }

  .payment-methods-section {
    padding: 0;

    .payment-method.transaction {
      & > .payment-method {
        cursor: pointer;
        transition: background 0.1s ease, color 0.1s ease;
        margin: 5px 0;
        border-bottom: none;
        border-radius: $border-radius-base;

        .radio-btn-cover {
          position: absolute;
          width: 100%;
          height: 35px;
          opacity: 0;
          z-index: 5;

          @include screen-max('sm') {
            height: 25px;
          }
        }

        .pay-radio {
          padding-left: ($half-unit / 2);
          margin-top: $base-unit;

          @include screen-max('sm') {
            margin-top: $half-unit;
          }
        }

        .payment-method-primary-info {
          padding: $padding-compact;

          &:hover {
            background-color: $sf-gray-light;
            border-radius: 4px;
          }

          .fa-bank {
            font-size:32px;
            height:43px;
            line-height:40px;
            vertical-align:middle;
            @include screen-max('sm') {
              font-size: 24px;
              width: 30px;
              height: 20px;
              line-height: 20px;
            }
          }

          .cc-icon {
            font-size: 30px;
            @include screen-max('sm') {
              font-size: 24px;
              width: 30px;
            }
          }

          .payment-method-main-info {
            white-space: normal;
            display: inline-block;
            margin-left: $half-unit;
            width: calc(100% - #{$base-unit * 4});
            vertical-align: top;
            line-height: ($base-unit * 3);

            @include screen-max('sm') {
              font-size: 12px;
              line-height: ($base-unit * 2);
              margin-left: ($half-unit / 2);
              width: calc(100% - 38px);
            }

            .method-name {
              width: calc(100% - 75px);
              display: inline-block;
              vertical-align: middle;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &.active {
        & > .payment-method {
          background: #FFF;
          color: $sf-black;

          .fa {
            color: inherit;
          }
        }
      }

      .payment-method-selected {
        position:absolute;
        top:0;
        bottom:0;
        right:10px;
        overflow: hidden;
        color: #FFF;
        width:0px;
        text-align:center;
        transition: width 0.2s ease;

        &.active {
          width:40px;
        }

        span {
          display:block;
          margin-top:12px;
        }
        .fa {
          margin-top: 30px;
          font-size: 24px;
        }

      }
    }
  }
}
