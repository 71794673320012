.inquiry-success-page {
  background: $white;
  .loading-container {
    height: 100vh;
    padding-right: 25px;
    padding-top: 33vh;
    background: $white;
    .loading {
      display: inline-block;
      height: 50px;
      width: 50px;
    }
  }

  .non-prismic-container {
    max-width:1024px;
    margin: 0 auto;
    padding: 0 24px;
    padding-bottom: 50px;

    .buttons-container {
      padding-bottom: 20px;
    }
    .buttons-container div.sf-btn-secondary,
    .buttons-container div.sf-btn-primary {
      display: inline;
      @include screen-max('ss') {
        display: block;
        width: 100%;
      }
    }
    .buttons-container div.sf-btn-primary {
      line-height: 1.2;
      padding: 15px 24px;
    }
    .buttons-container div.sf-btn-secondary {
      margin-left: 20px;
      @include screen-max('ss') {
        margin-top: 20px;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
