.reject-listing-modal-title {
  margin-bottom: $base-unit;
  text-align:center;
}

.reject-listing-modal-text {
  padding: $base-unit 0;
  text-align: center;
}

.modal-reject-listing-rejection-reasons {
  text-align: center;

  select {
    width: 57%;
  }

  label {
    display: block;
  }

  .form-control {
    margin: 10px 0;
  }
}

.modal-reject-listing-allow-resubmission {
  width: 80%;

  span {
    width: 80%;
  }

  input[type="checkbox"] {
    float: right;
  }
}
