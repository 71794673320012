@mixin btn-full-width {
  width: 100%;
  display: block;
  margin: $half-unit auto !important;
}

@mixin button-base {
  padding: $padding-compact-lg;
  border-radius: $quarter-unit * 1.5;
  border: 1px solid;
  font-size: $font-size-sm;
  line-height: initial;
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  margin-right: $half-unit;
  -webkit-user-select: none;
  overflow: hidden;
  position: relative;
  vertical-align: middle;
  text-align: center;
  transition:
    border-color 0.1s ease,
    color 0.1s ease,
    background 0.1s ease,
    opacity 0.2s ease;

  @include screen('sm') {
    &:active {
      box-shadow: inset $box-shadow-base;
    }
  }

  + .sf-btn-primary,
  + .sf-btn-secondary,
  + .sf-btn-tertiary,
  + .btn {
    margin-left: $half-unit;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &.full-width {
    @include btn-full-width;
  }

  &.half-width {
    width: calc(50% - 11px);
  }

  &.btn-destroy {
    background-color: $white;
    border-color: $sf-red;
    color: $sf-red;

    &:hover {
      background-color: tint($sf-red, 90%);
    }

    &:active {
      background: $sf-red;
      color: $white;
    }
  }

  &.small {
    padding: $quarter-unit;
  }

  &.medium {
    padding: $half-unit;
  }
}

@mixin button-primary {
  @include button-base;

  color: $white;
  background-color: $sf-green;
  border-color: $sf-green;

  font-weight: $font-weight-heavy;
  font-size: $font-size-md;

  &.disabled {
    background-color: $sf-gray-light;
    color: $sf-gray-dark;
    border-color: transparent;
    cursor: default;
    cursor: not-allowed;
  }

  &:hover {
    border-color: $sf-green;
    background-color: $sf-green-dark;
    color: $white;
  }

  &:active {
    background-color: $sf-green-darker;
    color: $white;
  }
}

@mixin button-secondary($color: $sf-green, $border: $color) {
  @include button-base;

  color: $color;
  background: $white;
  border-color: $border;

  &:hover {
    background-color: tint($color, 90%);
  }
}

@mixin button-secondary-cta($color: $sf-green, $border: $color) {
  @include button-base;

  color: $color;
  background: $white;
  border-color: $border;

  &.active,
  &:hover {
    background-color: $sf-green;
    color: $white;
  }
}

@mixin button-tertiary {
  @include button-base;

  background: transparent;
  color: $sf-green;
  border: none;
  padding: ($padding-half + 4px) 0px;

  &:hover {
    background-color: $sf-gray-light;
  }
}

@mixin button-close-gutter {
  @include z-layer-1;
  @include absolute-top-right;
  @include square($base-unit);
  font-size: $font-size-xl;
  cursor: pointer;
  color: $sf-gray-dark;
  line-height: $quarter-unit * 3;
  text-align: center;
}

@mixin button-action-panel {
  @include button-primary;
  margin: auto;
  margin-bottom: $half-unit * 3;
  display: block;
  width: 100%;


  @include screen-max('xs') {
    width: 100%;
  }

}

.sf-btn-primary {
  @include button-primary;
}

.sf-btn-secondary {
  @include button-secondary;

  &--gray {
    @include button-secondary($color: $sf-black, $border: $sf-gray-base);
  }

  &--gray-cta {
    @include button-secondary-cta($color: $sf-black, $border: $sf-gray-base);
  }
}

.sf-btn-tertiary {
  @include button-tertiary;

  &.outline {
    border: 1px solid $white;
    border-radius: $border-radius-base;
    color: $white;

    &:hover {
      color: $sf-green;
    }
  }

  &.outline-green {
    color: $sf-green;
    border: 1px solid $sf-green;
    border-radius: $border-radius-base;
    padding: $half-unit ($base-unit * 1.5);

    &:hover {
      color: $sf-gray-dark;
    }
  }
}

.sf-btn-destroy {
  @include button-secondary($sf-red);
}

.sf-btn-large {
  @include button-primary;

  padding: $padding-base;
  font-size: $font-size-md;
  margin: auto;
  display: block;
  max-width: 75%;

  &.disabled {
    background-color: $sf-gray-light;
    color: $sf-gray-dark;
    border-color: transparent;
    cursor: default;
    cursor: not-allowed;
  }

  &:first-of-type {
    margin-left: auto;
  }

  &:last-of-type {
    margin-right: auto;
  }

  @include screen-max('sm') {
    max-width: none;
  }
}


.btn {
  @include button-primary;

  &:hover {
    color: #fff;
    background-color: darken($sf-green, 10%);
  }

  &.btn-light {

    background-color: $white;
    color: $sf-gray-dark;
    border-color: $sf-gray-base;

    &:focus, &:active {
      color: $sf-gray-dark;
    }

    &:hover {
      background-color: #fff;
      color: $sf-black;
    }

  }

  &.btn-destroy {
    background-color: #d00000;
    border-color: #d00000;
    color: $white;

    &:hover {
      background-color: darken(#d00000, 10%);
    }
  }

  &.btn-fullwidth {
    width: 100%;
    margin-top: 20px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  &.btn-primary {
    background-color: $sf-green;
    color: #fff;
  }

  &.btn-secondary {
    border-color: $sf-black;
    background-color: $sf-black;
    color: #fff;
  }

  &.btn-tertiary {
    border-color: $sf-gray-light;
    background-color: $sf-gray-light;
  }

  &.btn-disabled {
    background-color: $sf-gray-light;
    color: $sf-gray-dark;
    border-color: transparent;
    cursor: default;
    cursor: not-allowed;

    &:active {
      box-shadow:none;
    }

    &:hover {
      background-color: $sf-gray-light;
      color: $sf-gray-dark;
    }
  }

  &.btn-outline-only {
    background-color: transparent;
    border: 1px solid $sf-gray-base;
    color: darken($sf-gray-dark, 25%);
    font-size: 15px;
    font-weight: bold;

    &:hover {
      background-color: $sf-gray-light;
    }

    &:active {
      color: darken($sf-gray-dark, 25%);
    }
  }

  &.btn-pending {
    background-color: $sf-gray-light;
    border-color: $sf-gray-light;
    color: $sf-gray-dark;
  }
}

@mixin label {
  font-size: $base-unit;
  font-weight: $font-weight-light;
  padding: 5px $padding-half;
  border-radius: $border-radius-label;
}
