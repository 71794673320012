.sf-search-diagnostics {
  @include card-flat(rgba($black, 0.75));
  @include align-center;
  @include z-layer-2;

  font-size: ($font-size-sub + 1);
  color: $white;
  width: 100%;

  .diagnostics-list {
    padding: $quarter-unit $half-unit;
  }

  .diagnostic-name {
    @include header-xs;
  }
}
