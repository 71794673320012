.nav-container {
  position:fixed;
  top:0;
  left:0;
  height: 0px;
  width: 260px;
  z-index: -1;

  &.active {
    height: 470px;
  }
}

.navigation-dropdown {
  position:fixed;
  top:0;
  left:0;
  margin-top:80px;
  margin-left:20px;
  background: #FFF;
  border:1px solid $secondary-4-grey;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.2);
  opacity:0;
  transition: opacity 0.2s ease;
  display:none;
  z-index: 11;
  padding: 20px 10px;

  width: 220px;

  @include screen-max('sm') {
    margin-left: 10px;
  }

  &:before {
    content: '';
    position:absolute;
    top:-20px;
    left: 40px;
    z-index:1;
    @include arrow-up(10px, #FFF);

    @include screen-max('sm') {
      left: 10px;
    }
  }

  &:after {
    content: '';
    position:absolute;
    top:-21px;
    left: 40px;
    @include arrow-up(10px, $secondary-4-grey);

    @include screen-max('sm') {
      left: 10px;
    }
  }

  &.visible {
    display:block;
  }

  &.active {
    opacity: 1;
  }


  .close-nav {
    position: absolute;
    top:10px;
    right:12px;
    color: $secondary-3-grey;
    cursor: pointer;
    z-index: 1;
  }

  hr {
    margin: 10px 10px;
    border:none;
    border-top: 1px solid $secondary-5-grey;
  }

  .navigation-item {
    display: block;
    padding: 5px 20px;
    color: $secondary-3-grey;
    background: #FFF;
    font-size: 14px;
    transition: color 0.2s ease, background 0.2s ease;
    font-weight: 400;

    &:hover {
      color: $primary-green;
      text-decoration: underline;
    }
  }

}
