
.sf-icon-basket:before { content: '\e800'; } /* '' */
.sf-icon-brand-small:before { content: '\e801'; } /* '' */
.sf-icon-brand:before { content: '\e802'; } /* '' */
.sf-icon-calendar:before { content: '\e803'; } /* '' */
.sf-icon-chart:before { content: '\e804'; } /* '' */
.sf-icon-coffee:before { content: '\e805'; } /* '' */
.sf-icon-convo:before { content: '\e806'; } /* '' */
.sf-icon-desktop:before { content: '\e807'; } /* '' */
.sf-icon-flag:before { content: '\e808'; } /* '' */
.sf-icon-glasses:before { content: '\e809'; } /* '' */
.sf-icon-inquiry:before { content: '\e80a'; } /* '' */
.sf-icon-lightbulb:before { content: '\e80b'; } /* '' */
.sf-icon-location:before { content: '\e80c'; } /* '' */
.sf-icon-mobile:before { content: '\e80d'; } /* '' */
.sf-icon-moneybags:before { content: '\e80e'; } /* '' */
.sf-icon-newspaper:before { content: '\e80f'; } /* '' */
.sf-icon-owner:before { content: '\e810'; } /* '' */
.sf-icon-rocket:before { content: '\e811'; } /* '' */
.sf-icon-shopping-cart:before { content: '\e812'; } /* '' */
.sf-icon-space-small:before { content: '\e813'; } /* '' */
.sf-icon-steps:before { content: '\e814'; } /* '' */
.sf-icon-storefront:before { content: '\e815'; } /* '' */
.sf-icon-users:before { content: '\e816'; } /* '' */
.sf-icon-venn:before { content: '\e817'; } /* '' */
.sf-icon-wallet:before { content: '\e818'; } /* '' */
.sf-icon-watch:before { content: '\e819'; } /* '' */
.sf-icon-showroom:before { content: '\e824'; } /* '' */
.sf-icon-showroom-o:before { content: '\e825'; } /* '' */
.sf-icon-retail:before { content: '\e826'; } /* '' */
.sf-icon-event:before { content: '\e827'; } /* '' */
.sf-icon-art:before { content: '\e828'; } /* '' */
