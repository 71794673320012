.booking-request.booking-request-adjudicate-container {
  position: relative;

  .inbox-panel {
    position: absolute;
    left: 0;
    right: auto;
    width: 280px;
    top: $base-unit * 2;
    margin: 0 $base-unit;
    bottom: 0;

    .sf-country-selector {
      width: 100%;
      border-bottom: 1px solid #ccc;
      margin-bottom: $half-unit;
      padding-bottom: $half-unit;

      > label {
        border: none;
        width: 100%;
      }
    }
  }

  .sf-pagination {
    position: absolute;
    left: 16px;
    bottom: 0;
    padding: 0;
    margin: 0;
  }

  .main-content {
    top: $base-unit * 2;
    right: 0;
    left: $base-unit * 20;
    bottom: $base-unit * 4;
    width: calc(100% - #{$base-unit * 20});
    max-width: 100%;
    height: calc(100vh - #{$base-unit * 12});
    overflow: scroll;
    margin: 0 0 ($base-unit * 6);

    .sf-message {
      .sf-booking-request-adjudicate-actions {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        margin-top: $base-unit;
        padding: $padding-half 0 0;

        .btn {
          width: calc(33.3% - 12px);
          height: auto;
          padding: 5px;
          font-size: $font-size-sm;

          color: #fff;
        }
      }
    }
  }
}
