.sf-project {
  position: relative;

  .icon-unread {
    @include unread-icon;
    width: $base-unit;
    height: $base-unit;
    color: #FFF;
    font-size: $font-size-sub;
    padding-top: 1px;
    text-align: center;
    border: 1px solid #FFF;
    top: 0;
    left: -$quarter-unit;
  }

  .project-picture {
    width: $base-unit * 10;
    margin: 0 $base-unit 0 0;
    padding: $base-unit * 2;
    border: 1px solid $sf-gray-base;
    text-align: center;
    float: left;

    svg {
      width: 40%;
      height: auto;

      path {
        fill: $sf-gray-base;
      }
    }
  }

  .project-name {
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
  }

  .creation-date {
    color: $sf-gray-dark;
    font-size: $font-size-sm;
    margin-bottom: $base-unit;
  }

  .action {
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
    padding: $half-unit ($base-unit + $half-unit);
  }

  & > * {
    margin-bottom: $half-unit;
  }

  &.thin {
    .creation-date {
      font-size: $font-size-xs;
      margin-bottom: 0;
    }
  }
}
