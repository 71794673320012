@mixin input-base {
  font-family: $brown-pro;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  position: relative;
  vertical-align: top;
  display: inline-block;
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
  border-radius: $border-radius-base / 2;
  border: 1px solid $sf-gray-base;
  margin: 0;
  padding: $half-unit;
  background: $white;
  color: $sf-black;

  &:focus {
    outline: none;
  }

}

@mixin input-checkbox {
  @include input-base;
  @include square($base-unit);

  margin-right: $half-unit;
  color: $white;
  transition: color 0.2s ease, background 0.2s ease, opacity 0.2s ease;

  @media (hover) {
    &:hover {
      border-color: tint($sf-info, 45%);
      background: tint($sf-info, 45%);

      &:after {
        @include absolute-middle;

        content: '\f00c';
        font: normal normal normal $font-size-sm FontAwesome;
      }
    }
  }

  &:checked {
    border-color: $sf-info;
    background: $sf-info;
    animation: pulse_animation 400ms 1;
    -webkit-animation: pulse_animation 400ms 1;
    -moz-animation: pulse_animation 400ms 1;
    -o-animation: pulse_animation 400ms 1;

    &:after {
      @include absolute-middle;

      content: '\f00c';
      font: normal normal normal $font-size-sm FontAwesome;
    }
  }
}

@mixin input-radio-base {
  @include input-base;
  @include circle($base-unit);

  position: relative;
  border: 1px solid $sf-gray-base;
  line-height: ($base-unit * 2.5);
  margin-top: ($half-unit / 4);
  margin-right: $half-unit;
  transition: background 0.1s ease;

  &:checked{
    box-shadow: inset $box-shadow-base;
    background: $sf-green;
    border: none;
    height:($base-unit + 2);
    width: ($base-unit + 2);

    &:after {
      content:'';
      @include circle($half-unit);
      position: absolute;
      background-color: $white;
      left: 5px;
      top: 5px;
    }
  }

  @include screen-max('sm') {
    margin-top: 0px;
  }
}

@mixin input-select-base {
  @include input-base;

  &:after {
    @include align-middle;
    content: '\f107';
    font-family: 'FontAwesome';
    right: $base-unit;
    font-size: $font-size-lg;
    z-index: 1;
    color: inherit;
    pointer-events: none;
  }

  select {
    width: 100%;
    padding: $half-unit ($base-unit * 2) $half-unit $base-unit;
    line-height: initial;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: $border-radius-base;
    border: none;
    background: $white;
    cursor: pointer;
    font-weight: bold;

    &::-ms-expand {
        display: none;
    }

    &:focus {
      outline: none;
      -webkit-outline: none;
      -moz-outline: none;
    }
  }

}

@mixin input-select-underlined {
  @include input-select-base;
  background: transparent;
  select {
    border: 1px solid transparent;
    background: transparent;
    text-decoration: underline;
  }
}
