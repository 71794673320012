.dropdown-help-menu,
.dropdown-user-menu,
.dropdown-notification-menu {
  line-height: initial;
  width: $base-unit * 21;

  @include screen-max('sm') {
    max-width: none;
    width: 100%;
    left: 0;
    right: 0;
  }

  &.dropdown-user-menu {
    width: 100%;
    padding: $half-unit 0;

    @include screen('sm') {
      width: $base-unit * 15;
    }

    .menu-item {
      padding: $half-unit $base-unit;

      &:after {
        display: block;
        content: '';
        clear: both;
      }

      & + .menu-item {
        border-top: 0;
      }

      > span {
        float: left;
      }

      .notification-count {
        position: relative;
        top: 0;
        left: 0;
        float: right;
        margin-right: $quarter-unit;
      }
    }

    .menu-divider {
      width: calc(100% - #{$base-unit * 2});
      height: 1px;
      border: 0;
      margin: ($quarter-unit * 3) auto;
      background-color: $sf-gray-light;
    }
  }

  .menu-item {
    display: block;
    padding: $padding-base;
    padding-left: $padding-base * 2;
    color: $sf-black;
    text-align:left;
    cursor: pointer;

    &:hover {
      background: $sf-gray-light;
    }

    &.disabled {
      background: $sf-gray-light;
    }

    +.menu-item {
      border-top: 1px solid $sf-gray-base;
    }

    .fa-angle-right {
      font-size: $font-size-xl;
      line-height: $font-size-sm;
      color: $sf-gray-base;
    }

    .chat {
      color: $sf-gray-base;

      &.online {
        color: $sf-green;
      }
    }
  }

  .menu-divider {
    height: $half-unit;
    background: $sf-gray-light;
    border-top: 1px solid $sf-gray-base;
    border-bottom: 1px solid $sf-gray-base;
  }
}

.dropdown-header {
  @include header-sm;

  color: $sf-black;
  text-align:left;
  padding: $padding-base;
  vertical-align: top;
  position: relative;

  @include screen-max('xs') {
    line-height: $base-unit * 2.5;
  }

  .phone-number {
    color: $sf-gray-dark;
    font-size: $font-size-xs;
  }

  .close {
    @include align-middle;

    bottom: $quarter-unit;
    right: $base-unit + $quarter-unit;
    font-size: $font-size-xxxl;
    line-height: $font-size-sm;
    font-weight: 100;
  }
}

.dropdown-notification-results {
  max-height: 400px;
  overflow-y: auto;
  background: $sf-gray-light;

  @include screen-max('sm') {
    max-height: calc(100vh - #{$navbar-height});
  }
}

.user-menu-contents {
  @include screen-max('sm') {
    overflow-y: auto;
    max-height: calc(100vh - #{$navbar-height});
  }
}

.navbar-loader {
  position: fixed;

  .loading {
    top: calc(50% - 15px);
  }
}
