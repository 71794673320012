.sf-inquiry-booking-info {

  .book-online-content {
    @include card-body;
  }

  &.cancel-inquiry {
    margin-top: $base-unit;
    padding: $base-unit * 2;
  }

  .fa,
  .sf-icon {
    font-size: $font-size-xxl;
    width: ($base-unit * 2);
    text-align: left;
    margin-right: 0px;

    &:before {
      margin: 0;
    }
  }

  .booking-inquiry-info-wrapper {
    padding: $base-unit;

    .book-online-header {
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      text-align: center;
      margin-bottom: $half-unit;
    }

    .book-online-row {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      i {
        margin: 0 $half-unit 0;
      }

      div {
        display: flex;
        flex-direction: column;

        .book-online-subheader {
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
        }

        .book-online-body {
          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
        }
      }
    }
  }

  .admin-section {
    line-height: 2;
    padding: $base-unit;
    border-bottom: 1px solid $white;

    .admin-section-wrapper {
      padding: $half-unit $base-unit $base-unit;
      background-color: tint($sf-orange, 95%);

      .admin-section-title, .admin-sub-section-title {
        color: $secondary-2-grey;
        font-weight: $font-weight-regular;

        &.admin-section-title {
          font-size: $font-size-sm;
          font-weight: $font-weight-heavy;
        }
      }

      .info-line {
        margin-left: $quarter-unit;
      }

      .info-sub-line {
        margin-left: 17px;
      }

      .info-sub-line + .info-sub-line {
        margin-left: 23px;
      }

      ul {
        padding: 0 0 0 ($base-unit * 2);
        margin: 0;

        li {
          line-height: $font-size-lg;
          font-size: $font-size-sm;
        }
      }
    }
  }
}
