.recommendations-header {
  font-size: $font-size-xl;
  margin-bottom: $base-unit + $quarter-unit;
  @include screen('sm') {
    padding-left: $padding-quarter * 3;
  }
  @include headline;
}

.recommendations-container {
  content-visibility: auto;
  width: 100%;
  &.two-columns {
    .listing-row {
      @include screen('sm') {
        width: 50%;
      }

      &:nth-child(2n + 1) .listing-panel {
        margin-right: $quarter-unit * 1.5;
      }

      &:nth-child(2n) .listing-panel {
        margin-left:  $quarter-unit * 1.5;
      }
    }
  }

  &.three-columns .listing-row {
    width: 33%;
  }

  .listing-row {
    width: 25%;

    .listing-info .listing-title {
      width: 100%;
    }

    .listing-image-header {
      height: 180px;
    }

    @include screen-max('sm') {
      width: 100%;
      display: block;

      .listing-image-header {
        height: 90px;
      }
    }
  }
}

.listing {
  .recommendations-container {
    .listing-row {
      .listing-panel {
        @include screen-max('sm') {
          margin: 16px auto;
        }
      }
    }
  }
}
