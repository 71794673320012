.sf-inquiry-progress-tracker {
  @include card-anchored-top;

  overflow: visible;
  padding: $padding-base;
  padding-bottom: $padding-base / 2;

  @include screen-max('md') {
    padding: $padding-compact;
    padding-bottom: $padding-base / 2;
  }

  &.canceled {
    @extend .error;
  }

  &.complete {
    @extend .success;
  }

  &.active {
    @extend .border-box-shadow;
  }

  &.inactive {
    background: $sf-gray-light;
    color: $sf-warning;
    font-weight: $font-weight-heavy;
  }

  &.archived {
    @extend .archived;
  }

  .inquiry-steps-container {
    text-align: center;

    .inquiry-step-container {
      position: relative;
    }

    @include screen-max('md') {
      .inquiry-step-container {
        width: $base-unit * 2.5;
        height: $base-unit * 2.5;
        display: inline-block;
        text-align: center;
      }
    }

    @include screen('md') {
      .inquiry-step-container {
        width: $base-unit * 5.5;
        height: $base-unit * 3;
        display: inline-block;
        text-align: center;
      }
    }

    .inquiry-step-name {
      font-size: $font-size-xs;
      color: $sf-gray-base;
      max-width: $base-unit * 5.5;
      height: $base-unit;
      text-align: center;
      display: inline-block;
    }

    .inquiry-step-name-current {
      color: $sf-black;
      font-weight: bold;
    }
  }

  .inquiry-step {
    @include square($base-unit * 2);
    display: inline-block;
    text-align: center;
    line-height: 30px;
    border: 1px dotted $sf-gray-base;
    color: $sf-gray-base;
    border-radius: 50%;
    font-size: $font-size-md;

    &.active {
      @include payment-statuses;

      background: $sf-green;
      border: 1px solid $sf-green;
      color: #FFF;
    }

    &.outline-white {
      border: 2px solid $white;
      margin-right: $base-unit;
      color: $white;
    }

    &.outline-warning {
      border: 2px solid $sf-warning;
      margin-right: $base-unit;
      color: $sf-warning;
    }
  }

  .inquiry-divider {
    position: relative;
    display: inline-block;
    margin: 5px;

    &:after {
      position: absolute;
      top: calc(50% - 9px);
      right: -9px;
      content: '';
      @include arrow-right($half-unit, $sf-gray-base);
    }

    &.active {
      border-top: 2px solid $sf-green;

      &:after {
        @include arrow-right($half-unit, $sf-green);
      }
    }
  }

  @include screen-max('md') {
    .inquiry-divider {
      width: calc(100% / 3 - 100px);
      border-top: 1px dotted $sf-gray-base;

      &.active {
        border-top: 2px solid $sf-green;
      }
    }
  }

  @include screen('md') {
    .inquiry-divider {
      width: calc(100% / 3 - 130px);
      border-top: 1px dotted $sf-gray-base;
      margin-bottom: $base-unit * 1.5;
    }
  }
}
