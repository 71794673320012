.manage-listings {
  min-height: calc(100vh - #{$navbar-height});
  padding: 0 0 $padding-base * 2;
  background: white;

  .sf-form-row {
    padding: 0;

    @include screen('sm') {
      &.input-small {
        .sf-form-input-wrapper {
          max-width: $base-unit * 12;
        }
      }

      &.input-medium{
        .sf-form-input-wrapper {
          max-width: $base-unit * 20;
        }
      }
    }

    label {
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
    }

    input[type="checkbox"] {
      margin-right: $quarter-unit;

      &:checked, &:checked:hover {
        border-color: $sf-green;
        background: $sf-green;
      }

      &:hover {
        border-color: tint($sf-green, 50%);
        background: tint($sf-green, 50%);
      }
    }

    & + .sf-form-row {
      margin-top: $base-unit * 2;
    }

    &.sf-form-row-images {
      margin-top: $base-unit * 2;
    }

    .sf-form-duration-wrapper {
      display: flex;

      .sf-form-input-wrapper {
        margin-right: $half-unit;
        max-width: $base-unit * 10;
      }
    }

    .length-counter {
      bottom: $quarter-unit;
      right: $quarter-unit;
    }

    .image-upload {
      .uploaded-images {
        &:empty:before {
          display: block;
          font-family: FontAwesome;
          content: '\f03e';
          font-size: $font-size-xxxl;
          color: $sf-gray-base;
          margin-bottom: $base-unit;
        }
      }

      .sf-btn-primary {
        background-color: $white;
        color: $sf-black;
        border-color: $sf-gray-base;
        font-weight: $font-weight-regular;

        &:hover {
          background-color: $sf-gray-light;
        }
      }
    }
    .sf-form-errors {
      .error-list {
        background: tint($sf-red, 90%);
        padding: $padding-base;
        border-radius: 3px;

        .error-list-item {
          i {
            display: none;
          }
        }
      }
    }
  }

  .sf-form-title-wrapper {
    margin-bottom: 0;

    .sf-form-header {
      font-weight: $font-weight-regular;
      margin-bottom: $half-unit;

      &:before {
        display: none;
      }
    }
  }

  .sf-form-input-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .sf-form-tag {
    display: flex;
    margin: $base-unit 0 0;
    text-align: center;
    padding: 0 $half-unit;
    width: calc(100% / 2);

    &:nth-child(-n+2) {
      margin-top: 0;
    }

    @include screen('md') {
      width: calc(100% / 4);

      &:nth-child(-n+4) {
        margin-top: 0;
      }
    }

    .tag-check {
      display: none;
    }

    .tag-label {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-weight: $font-weight-regular;
      width: 100%;
      padding: $half-unit $base-unit;
      height: 100%;
      border: 1px solid $sf-gray-base;
      border-radius: 3px;
    }

    &.active {
      .tag-label {
        border-color: $sf-green;
      }
    }
  }

  .select-wrapper {
    @include input-select-base;
    @include body-text;

    padding: ($half-unit / 2);
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    float: none;
    margin-top: $base-unit;

    @include screen('sm') {
      width: auto;
      float: right;
      margin: 0;
    }

    select {
      padding: $quarter-unit ($base-unit * 2) $quarter-unit ($quarter-unit * 3);
      font-size: $font-size-xs;
      font-weight: 500;
    }
  }

  > .header-container {
    position: relative;
    padding: $base-unit $base-unit 0 $base-unit;
    overflow: hidden;

    > .header-background-loading {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: $white;
      opacity: 1;
      transition: opacity ease .5s;

      &.loaded {
        opacity: 0;
      }
    }

    > .header {
      @include z-layer-1;
      max-width: $max-content-width-md;
      margin: $base-unit auto ($base-unit * 2);
      position: relative;

      h1 {
        font-size: $font-size-xxl;
        margin-top: 0;
        float: left;
      }

      .select-wrapper {
        @include z-layer-1;
        float: left;

        @include screen('sm') {
          margin: 5px 0 0 $base-unit;
        }
      }

      &:after {
        display: block;
        content: "";
        clear: both;
      }
    }

    > .nav-header {
      @include z-layer-1;
      max-width: $max-content-width-md;
      margin: ($base-unit * 3) auto 0;
      border-bottom: 1px solid $sf-gray-light;
      position: relative;

      .boosted-badge {
        float: right;
        font-weight: $font-weight-regular;
        color: #dbb123;
        font-size: $font-size-lg;
        margin: 40px 0 !important;

        @include screen('sm') {
          margin: 0 !important;
        }
      }

      > a {
        font-size: $font-size-lg;
        font-weight: $font-weight-regular;
        color: $sf-black;
        margin: 0 ($base-unit * 1.5) $quarter-unit 0;
        padding: 0 $quarter-unit $quarter-unit;
        white-space: nowrap;
        display: inline-block;

        &.active {
          border-bottom: 3px solid rgb(254, 217, 50);
        }
      }
    }

    .listing-main-header {
      max-width: $max-content-width-md;
      margin: 0 auto ($base-unit * 2);

      .listing-info-header {
        padding: $half-unit $base-unit;

        .listing-owner-info {
          color: $sf-black;
          padding: $half-unit 0;
          font-size: $font-size-lg;
          font-weight: $font-weight-regular;

          &.text {
            font-weight: $font-weight-light;
            font-size: $font-size-md;

            a {
              padding: 0;
              color: $black;
            }
          }

          a {
            color: $white;
            font-size: $font-size-md;
            padding: $half-unit $base-unit;
            font-weight: $font-weight-regular;
          }
        }
      }
    }
  }

  > .content {
    max-width: $max-content-width-md;
    margin: 0 auto;
    padding: 0 $base-unit;

    > .loading-dots {
      margin: 32vh auto;
      position: static;
    }

    .page-view {
      width: 100%;
      max-width: $base-unit * 40;
    }

    .manage-listing-details, .manage-listing-calendar {
      > .manage-listing-section {
        padding: ($base-unit * 1.5) 0;

        & + .manage-listing-section {
          border-top: 1px solid $sf-gray-base;
        }

        > .section-header {
          margin-bottom: $base-unit;

          > .section-title {
            font-size: $font-size-lg;
            font-weight: $font-weight-regular;
            display: inline-block;
            vertical-align: top;
            width: calc(100% - #{$base-unit * 7});
          }

          > .edit-button {
            width: $base-unit * 7;
            display: inline-block;
            vertical-align: top;
            font-weight: $font-weight-regular;
          }
        }

        .manage-space-description, .space-descrption-description-row {

          .space-image-row {
            flex-direction: column;
          }

          .listing-description-photos {
            list-style: none;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0;

            > li {
              margin: 0 $half-unit $half-unit 0;
              overflow: hidden;

              > img {
                height: 100%;
              }
            }
          }
        }

        > .section-body {
          font-size: $font-size-sm;
          font-weight: $font-weight-light;

          > .manage-listing-visibility {
            > .visibility-status {
              font-weight: $font-weight-regular;
            }

            &.positive {
              > .visibility-status {
                color: $sf-green;
              }
            }
          }

          .calendar-legend {
            margin-bottom: $base-unit;

            .caption-color {
              width: 16px;
              height: 16px;
              border-radius: 8px;
              display:inline-block;
              margin-right:10px;
              vertical-align: sub;
            }

            .caption-color.available {
              background-color: #fff;
              border: 1px solid #ddd;
            }

            .caption-color.unavailable {
              background-color: #f0f0f0;
              border: 1px solid #ddd;
              margin-left:16px;
            }

            .caption-color.booked {
              background-color: $sf-yellow;
              border: 1px solid #ddd;
              margin-left: 16px;
            }
          }

          > .manage-space-characteristics,
          > .manage-space-description,
          > .manage-space-eligibility,
          .manage-space-calendar {
            > .space-row, > .space-rows {
              margin: $quarter-unit 0;

              > p {
                display: inline;
                font-weight: $font-weight-light;
                margin: $quarter-unit 0;
                padding-left: $half-unit;

                &.space-row-header {
                  color: $sf-gray-dark;
                  padding: 0;

                  &.pending-drafts-message {
                    color: $sf-red;
                    font-weight: $font-weight-regular;
                  }
                }

                &.no-data {
                  color: $sf-gray-base;
                }
              }

              .eligibility-question {
                margin: $quarter-unit 0;
              }
            }

            .space-rows {
              display: flex;
              flex-direction: column;

              > p {
                display: initial;
                padding-left: 0;
              }
            }
          }
        }
      }
    }

    .manage-listing-edit {
      .manage-listing-seasonal-pricing {
        .sf-form-listing-pricing {
          margin-top: $base-unit * 2;
        }
      }

      .edit-header {
        font-weight: $font-weight-regular;

        h1 {
          margin: 0
        }
      }

      > .section-title {
        border-bottom: 1px solid $sf-gray-light;

        > h1 {
          font-weight: $font-weight-regular;
        }

        + .sf-form {
          padding-top: $padding-base;
        }
      }

      .space-row {
        > p {
          display: inline;
          font-weight: $font-weight-light;
          margin: $quarter-unit 0;
          padding-left: $half-unit;

          &.space-row-header {
            color: $sf-gray-dark;
            padding: 0;

            &.pending-drafts-message {
              color: $sf-red;
              font-weight: $font-weight-regular;
            }
          }
        }
      }

      .group {
        > .group-header {
          font-weight: $font-weight-regular;
          font-size: $font-size-lg;
          margin: $base-unit 0;

          .new {
            display: inline-block;
            font-size: $font-size-xs;
            padding: 2px $half-unit;
            color: $sf-white;
            font-weight: $font-weight-regular;
            margin-left: $half-unit;
            background: $sf-green;
            border-radius: $border-radius-sm;
          }

          .info {
            padding: $base-unit;
            background: tint($sf-green, 75%);
            color: $sf-black;
            margin-top: $base-unit;
            border-radius: $border-radius-sm;
            font-weight: $font-weight-light;
            font-size: $font-size-sm;
          }
        }

        > .group-info {
          margin: 0 0 $base-unit * 2;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $sf-gray-base;
          padding-bottom: ($padding-base + $padding-half);
        }

        &.actions {
          padding-top: $padding-base;
        }

        .sf-form-header {
          font-size: $font-size-sm;
          margin-top: $half-unit;
          margin-bottom: 0;
          font-weight: $font-weight-regular;
        }

        .add-another-question-link {
          color: $sf-green;
          cursor: pointer;
        }

        .sf-form-operating-hours {
          padding-left: 0 !important;

          .opening-hours-container {
            background: $sf-white;
            margin-top: $base-unit;
            margin-bottom: $half-unit;
            width: 100%;
            align-items: center;

            tbody {
              width: 100%;

              tr {
                &:not(:last-of-type) {
                  border-bottom: 1px solid $sf-gray-light;
                }

                &:hover {
                  background-color: $sf-gray-light;
                }

                td {
                  padding: $half-unit 0;

                  &:first-of-type {
                    padding-left: $base-unit;
                  }

                  &:last-of-type {
                    padding-right: $base-unit;
                  }
                }
              }
            }

            .day-label {
              margin-right: $base-unit;
              font-size: $font-size-md;
              font-weight: $font-weight-regular;
              align-items: center;
            }

            .sf-form-boolean {
              width: auto;
              margin-left: $base-unit;
              margin-right: $base-unit;
            }

            .opening-hours-select {
              display: flex;
              justify-content: end;
              align-items: center;

              &.disabled div {
                visibility: hidden;
              }

              .sf-form-row {
                .sf-form-input-wrapper {
                  .sf-form-errors {
                    display: none;
                  }

                  .sf-form-select-wrapper {
                    border-radius: $border-radius-base;
                    padding: 0;
                  }
                }

                &.form-error .sf-form-input-wrapper .sf-form-select-wrapper {
                  border-color: #e52b21;
                }
              }

              .sf-form-row {
                margin-top: 0;
              }

              .to-label {
                margin-left: $half-unit;
                margin-right: $half-unit;
                font-weight: $font-weight-regular;
              }
            }
          }
        }

        .opening-hours {
          display: flex;

          & > .sf-form-row {
            margin-top: 0;

            &:first-of-type {
              margin-right: $base-unit;
            }

            .sf-form-errors {
              width: 100%;

              ul.error-list {
                margin-top: 0;
                padding: $half-unit;
              }
            }
          }
        }
      }

      .sf-form-project-type-selector {
        > .sf-form-title-wrapper {
          > .sf-form-header {
            font-size: $font-size-md;
            margin-top: $half-unit;
          }
        }

        > .sf-form-project-type-selector-container {
          border-bottom: 1px solid $sf-gray-base;
          padding-bottom: 16px;
          display: flex;
          flex-direction: column;

          > .project-type-column {
            width: 100%;

            &:not(:first-child) {
              padding: $padding-base 0 0;
            }

            > .title {
              border-bottom: none;
              font-size: $font-size-sm;
              font-weight: $font-weight-light;
              margin-bottom: $quarter-unit;
            }

            > ul {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;

              > li {
                margin: 0 0 $half-unit;
                padding: 0 $half-unit 0 0;
                width: auto;
              }
            }
          }
        }
      }

      > .sf-form {
        margin: 0;

        .sf-form-row {
          padding-left: 0;
          padding-right: 0;
        }

        p.edit-error {
          color: red;
          font-weight: $font-weight-light;
          padding: 0;
          margin: $half-unit 0;
        }

        .sf-form-row-images {
          padding: $half-unit 0;
        }

        .listing-visibility-other-reason {
          margin-top: $base-unit;
        }

        .archive-btn {
          display: block;
          margin-top: $base-unit;
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
          cursor: pointer;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          border-top: 1px solid $sf-gray-light;
          margin-top: $base-unit;
          padding-top: $base-unit;

          > .spacer {
            flex: 1;
          }

          > .btn {
            @media (min-width: $max-content-width-md) {
              width: $base-unit * 8;
            }
            padding: $base-unit ($base-unit + $quarter-unit);
            font-weight: $font-weight-regular;
            font-size: $font-size-sm;
            margin: 0;

            &.manage-listing-edit-save {
              .btn-content.submitting {
                display: none;
              }

              .loading-dots.submitting {
                position: static;
                top: 0;
              }
            }

            &.cancel {
              &:hover {
                color: $sf-gray-dark;
              }
            }
          }
        }

        .form-caption {
          padding: $half-unit;
          color: $sf-gray-dark;
        }
      }

      .question-row {
        position: relative;
        margin: 0 0 $base-unit;

        > .delete {
          position: absolute;
          right: 0;
          height: 20px;
          bottom: $half-unit;
          width: $base-unit * 2;
          text-align: center;
        }

        > .sf-form-row {
          width: calc(100% - #{$base-unit * 2});
        }
      }
    }

    .manage-listing-pricing {
      .manage-listing-section {
        padding: ($base-unit * 1.5) 0;

        & + .manage-listing-section {
          border-top: 1px solid $sf-gray-base;
        }

        .section-header {
          margin-bottom: $base-unit;

          > .section-title {
            font-size: $font-size-lg;
            font-weight: $font-weight-regular;
            display: inline-block;
            vertical-align: top;
            width: calc(100% - #{$base-unit * 7});

            > h2 {
              margin-bottom: 0;
            }
          }

          > .edit-button {
            width: ($base-unit * 7);
            display: inline-block;
            vertical-align: top;
            font-weight: $font-weight-regular;
          }

          .new {
            display: inline-block;
            font-size: $font-size-xs;
            padding: 2px $half-unit;
            color: $sf-white;
            font-weight: $font-weight-regular;
            margin-left: $half-unit;
            background: $sf-green;
            border-radius: $border-radius-sm;
          }

          .info {
            padding: $quarter-unit * 3;
            background: tint($sf-green, 75%);
            color: $sf-black;
            margin-top: $base-unit;
            border-radius: $border-radius-sm;
          }
        }

        .opening-hours-container {
          padding: $half-unit;
          border-radius: $border-radius-sm;
          margin-top: $half-unit;
          color: $sf-black;

          &.with-bg {
            margin-top: $base-unit;
            background: #F9F9F9;
          }

          &.disabled-block {
            .space-row-header {
              color: $sf-gray-dark;
            }

            .space-row-value {
              color: $sf-gray-dark;
            }
          }

          p {
            font-size: $font-size-sm;
            margin-left: $quarter-unit;
          }
        }

        .group {
          > .sf-form-row {
            padding: 0 0 0 10px;
          }

          + .group {
            > .section-header {
              margin-top: $base-unit;
            }
          }

          .sf-form-listing-pricing {
            margin-top: $base-unit;
          }
        }

        .manage-listing-section-seasonal {
          margin-top: ($base-unit * 2.5); // this will overlap with the bottom margin of the header to be ~ 24px of new margin

          .group {
            & + .group:not(.actions) {
              //background-color: red;
            }

            .temporary-pricing-header {
              display: flex;
              align-items: center;
              margin-top: ($half-unit * 1.5);
              margin-bottom: $base-unit;

              .dates {
                font-weight: $font-weight-regular;
                flex-grow: 1;
                vertical-align: middle;
              }

              .actions {
                display: flex;
                vertical-align: middle;
                text-align: right;

                .edit, .delete {
                  display: flex;
                  padding: 0 $half-unit;
                  border: 1px solid $sf-black;
                  border-radius: $border-radius-sm;
                  font-weight: $font-weight-regular;
                  cursor: pointer;
                  align-items: center;

                  &.edit {
                    margin-right: $half-unit;

                    &:hover {
                      background: $sf-gray-light;
                    }

                    &:active {
                      background: $sf-gray-base;
                    }
                  }

                  &.delete {
                    color: darken($sf-red, 5%);
                    border-color: darken($sf-red, 5%);

                    &:hover {
                      background-color: tint($sf-red, 90%);
                    }

                    &:active {
                      background-color: tint($sf-red, 80%);
                    }
                  }
                }

                > span {
                  margin: 0;
                  cursor: pointer;

                  & + span {
                    margin-left: $half-unit;
                  }

                  > i.fa {
                    font-size: 20px;
                  }
                }
              }
            }
          }
        }
      }

      .space-row, .space-rows {
        margin: $quarter-unit 0;
        padding: 0 0 0 10px;

        > p {
          display: inline;
          font-weight: $font-weight-light;
          margin: $quarter-unit 0;
          padding-left: $half-unit;
          font-size: initial;

          &.bold {
            font-weight: $font-weight-regular;
          }

          &.space-row-header {
            padding: 0;
            font-weight: $font-weight-regular;
          }
        }
      }

      .space-rows {
        display: flex;
        flex-direction: column;

        > p {
          display: initial;
          padding-left: 0;
        }
      }
    }

    .manage-listing-preview {
      h2 {
        font-weight: $font-weight-regular;
      }

      p {
        font-weight: $font-weight-light;
      }

      .preview-section + .preview-section {
        margin-top: $base-unit * 1.5;
        padding-top: $half-unit;
        border-top: 1px solid $sf-gray-light;
      }

      .preview-btn {
        padding: $base-unit ($base-unit + $quarter-unit);
        font-weight: $font-weight-light;
        font-size: $font-size-sm;
        margin: 0;
      }

      .preview-card {
        width: 75%;
      }
    }

    .manage-listing-calendar {
      .listing-calendar-loading {
        position: relative;
      }

      .manage-listing-section {
        .section-header {
          .section-title {
            width: auto;
          }
        }

        .manage-space-calendar {
          margin-bottom: 3 * $base-unit;

          .sf-btn-primary, .sf-btn-secondary {
            margin: $half-unit $half-unit 0 0;
            padding: $padding-half $padding-base;
            font-weight: $font-weight-light;
            font-size: $font-size-sm;
            width: 100%;

            @include screen('sm') {
              width: auto;
            }
          }
        }

        .btn-container {
          text-align: center;

          .previous-btn, .next-btn {
            cursor: pointer;
            display: inline-block;
            padding: $half-unit $padding-base;
            color: $sf-black;
            border: 1px solid $sf-gray-base;
            border-radius: 3px;
            margin: 0 $half-unit;
            background-color: $sf-white;
            font-weight: $font-weight-regular;

            &:active {
              background-color: $sf-gray-light;
            }
          }
        }
      }
    }
  }
}

.manage-listing-calendar-modal, .manage-listing-archive-modal {
  .loader-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius-base;
    background: rgba(255, 255, 255, .6);
    z-index: 1000;

    .loading {
      top: calc(50% - 15px);
    }
  }

  .btn-submit-form {
    margin-bottom: 0;
  }

  &.manage-listing-calendar-modal {
    .sf-form {
      .sf-form-row + .sf-form-row {
        padding-top: $padding-half;
      }
    }

    .sf-form-input-wrapper {
      display: flex;
      flex-wrap: wrap;

      .sf-form-tag {
        display: flex;
        margin: $base-unit 0 0;
        text-align: center;
        padding: 0 $half-unit;
        width: calc(100% / 2);

        &:nth-child(-n+2) {
          margin-top: 0;
        }

        @include screen('md') {
          width: calc(100% / 3);

          &:nth-child(-n+3) {
            margin-top: 0;
          }
        }

        .tag-check {
          display: none;
        }

        .tag-label {
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-weight: $font-weight-regular;
          width: 100%;
          padding: $base-unit 0;
          height: 100%;
          border: 1px solid $sf-gray-base;
          border-radius: 3px;
        }

        &.active {
          .tag-label {
            border-color: $sf-green;
          }
        }
      }
    }

    .sf-form-errors {
      .error-list {
        background: tint($sf-red, 90%);
        padding: $padding-base;
        border-radius: 3px;

        .error-list-item {
          i {
            display: none;
          }
        }
      }
    }
  }

  &.manage-listing-archive-modal {
    .sf-form-radio {
      padding-bottom: $half-unit;
    }

    .listing-visibility-other-reason {
      border-top: 0;
      padding: 0 ($half-unit * 3) $half-unit;
    }

    .btn-submit-form {
      margin-top: $base-unit;
    }
  }
}
