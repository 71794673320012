.popup-container {
  position:fixed;
  top:20px;
  right:0px;
  bottom:-20px;
  left:0px;
  z-index: 1000;
  transition: all 0.2s ease;
  opacity:0;
  pointer-events: none;

  &.show {
    top:0px;
    bottom:0px;
    opacity: 1;
    .popup-message {
      box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    }
  }

  &.hide {
    top:-20px;
    bottom:20px;
    opacity: 0;
    .popup-message {
      box-shadow: 0 2px 4px rgba(0,0,0,0.3);
    }
  }

  .popup-message {
    padding: 10px;
    max-width:50%;
    border-radius: $border-radius-base;
    background: #FFF;
    position:absolute;
    margin: auto;
    color: $sf-black;
    text-align:center;
    opacity: 1;
    box-shadow: 0 0 0 rgba(0,0,0,0);
    transition: all 0.5s ease;
    cursor: pointer;

    @include screen-max('sm') {
      max-width: 100%;
    }

    &.center {
      width:120px;
      top:40%;
      right:0;
      left:0;
    }

    &.top-right {
      top:20px;
      right:20px;
    }

    &.top-left {
      top:20px;
      left:20px;
    }

    &.bottom-right {
      bottom:20px;
      right:20px;

      @include screen-max('xs') {
        bottom: 40px;
        left: 20px;
      }
    }

    &.bottom-left {
      bottom:20px;
      left:20px;

      @include screen-max('xs') {
        bottom: 40px;
        right: 20px;
      }
    }

    &.left-center {
      right:auto;
      left:20px;
    }

    &.right-center {
      left:auto;
      right:20px;
    }

    &.bottom-center {
      top:auto;
      bottom:20px;

      @include screen-max('xs') {
        bottom: 40px;
        left:20px;
        right:20px;
      }
    }

    &.top-center {
      bottom:auto;
      top:20px;
    }

    &.success {
      opacity: 1;
      background: darken($sf-green, 10%);
      color: #FFF;
    }

    &.error {
      opacity: 1;
      background: $sf-red-light;
      color: #FFF;
    }

    &.dark {
      opacity: 1;
      background: $sf-black;
      color: #FFF;
    }

    .popup-icon {
      .fa {
        font-size: 30px;
      }
    }
  }

}
