@mixin header-base {
  font-weight:bold;
}

@mixin header-xl {
  @include header-base;
  font-size: $font-size-xl;
}

@mixin header-lg {
  @include header-base;
  font-size: $font-size-lg;
}

@mixin header-md {
  @include header-base;
  font-size: $font-size-md;
}

@mixin header-sm {
  @include header-base;
  font-size: $font-size-sm;
}

@mixin header-xs {
  @include header-base;
  font-size: $font-size-xs;
}

@mixin subheader {
  font-size: $font-size-sub;
  color: $sf-gray-dark;
  font-weight: $font-weight-regular;
}

@mixin subheader-md {
  font-size: 14px;
  font-weight: $font-weight-light;
}

@mixin timestamp {
  @include subheader;
  @include absolute-top-right;
}

@mixin body-text($color: $sf-black) {
  color: $color;
  font-size: $font-size-base;
  font-weight: 400;
  text-align: left;
}

@mixin header-image-overlay {
  color: $white;
  text-shadow: $box-shadow-base;
}

h1, h2, h3, h4 {
  margin: $base-unit auto;
  line-height: 1.1;
}

h1 {
  @include header-xl;
}

h2 {
  @include header-lg;
}

h3 {
  @include header-md;
}

h4 {
  @include header-sm;
}
