.sf-search-form {
  $row-height: 60px;

  text-align: left;

  .sf-form-row {
    height: $row-height;
    float: left;
    position: relative;
    padding: 0;
  }

  @include screen('sm') {
    width: 754px;

    & > form {
      margin-left: $half-unit * 7; //= 56px
    }

    .sf-form-location {
      width: 320px;
    }

    .sf-form-project-type-dropdown {
      width: 252px;
      margin-left: 1px;
    }

    .btn-submit-form {
      width: 116px;
      height: $row-height;
      font-size: $font-size-md;
      padding: 0;
      margin-left: $half-unit;
      line-height: $row-height;
    }
  }

  @include screen-max('sm') {
    .sf-form-project-type-dropdown {
      margin-top: 1px;
    }

    .btn-submit-form {
      width: 100%;
      font-size: $font-size-md;
      margin-top: $base-unit;
    }
  }
}
