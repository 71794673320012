.sf-fakeit {
  .fake-content {
    @keyframes placeHolderShimmer {
      0% {
        background-position: -300px 0
      }

      100% {
        background-position: 600px 0
      }
    }

    .fake-title, .fake-paragraph, .fake-block {
      border-radius: 5px;

      /* Fake content loading animation */
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: $sf-gray-base;
      background: linear-gradient(to right, $sf-gray-light 8%, $sf-gray-base 18%, $sf-gray-light 33%);
      background-size: 800px $base-unit;
      position: relative;
    }

    .fake-block {
      border-radius: 2px;
      width: 100%;
      height: 120px;
    }

    .fake-title {
      width: 40%;
      height: $base-unit;
      margin-bottom: $base-unit;
    }

    .fake-paragraph {
      width: 100%;
      height: $half-unit;
      margin-bottom: $half-unit;

      &.small {
        width: 30%;
      }
    }

    .fake-block {
      width: 100%;
      height: $base-unit * 4;
      margin-bottom: $base-unit;
    }

    .no-margin {
      margin: 0;
    }
  }
}
