.projects {
  background-color: $white;

  .admin-bar {
    padding: 10px;
    line-height: 35px;
    text-align: center;

    > label {
      color: $sf-black;
      margin: 0 $half-unit;
    }
  }

  .show-content {
    max-width: $max-content-width-md;
    margin: auto;
    padding: 0;
    @include clearfix;

    @include screen('sm') {
      padding: $base-unit;
    }

    @include screen('lg') {
      padding: $half-unit * 3;
    }

    h2 {
      margin: 0;
      margin-bottom: $base-unit;
      padding: 0 0 $half-unit;
      font-weight: $font-weight-regular;
      @include clearfix;

      .edit-button {
        float: right;
        cursor: pointer;
        color: $sf-green;
        font-size: $font-size-xs;
        text-transform: lowercase;
      }
    }

    .panel {
      padding: $base-unit;
    }

    .project-infos-panel {
      width: 100%;
      padding: 0;

      .infos {
        padding: $padding-base;

        @include screen('sm') {
          border: 1px solid $sf-gray-light;
          border-bottom: 0;
        }
      }

      h2 {
        font-size: $font-size-md;
        font-weight: $font-weight-semi;
        color: $secondary-3-grey;
        margin-bottom: $quarter-unit;
      }

      .project-loader .fake-title {
        margin-bottom: $half-unit;
      }

      @include screen('sm') {
        float: left;
        width: 280px;
      }

      ul.project-nav {
        padding: 0;
        margin: 0;
        list-style: none;
        border: 1px solid $sf-gray-light;
        border-bottom: 0;

        li {
          border-bottom: 1px solid $sf-gray-light;
          padding: $padding-base $padding-base $padding-base ($padding-half * 3);
          color: $secondary-2-grey;
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
          cursor: pointer;
          position: relative;

          &.active::before {
            position: absolute;
            content: "";
            display: block;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
            background: $sf-yellow;
          }

          .unread_messages_count {
            @include status-base($sf-green);
            float: right;
            vertical-align: bottom;
            padding: 3px 6px;
            line-height: 10px;
            font-size: $font-size-sub;
            border: 1px solid $white;
            border-radius: $base-unit * 2;
          }
        }
      }
    }

    .project-main-panel {
      width: 100%;
      border: 0;

      @include screen('sm') {
        float: right;
        width: calc(100% - 280px - #{$base-unit});
        padding: 0;
      }

      @include screen('lg') {
        width: calc(100% - 280px - #{$half-unit * 3});
      }

      .project-data {
        .inquiries-loader {
          margin-top: $base-unit;

          &:after {
            clear: both;
            display: block;
            content: "";
          }

          .inquiries-loader-content {
            margin-left: $padding-base;
            float: left;
            width: calc(100% - 250px);
            padding-top: $quarter-unit;

            .fake-title {
              margin-bottom: $quarter-unit * 3;
            }
          }
        }

        .sf-conversation {
          .conversation-actions {
            padding: 0;
          }
        }

        .sf-inbox {
          .sf-inbox-container {
            padding-top: $padding-half;

            .icon-unread {
              top: $half-unit;
              left: -$half-unit;
            }
          }
        }

        .project-tab-recommendations h2 {
          margin-bottom: $base-unit;
        }

        h2 {
          margin-bottom: 0;
          border-bottom: 0;
          padding: 0;
        }

        .subtitle {
          font-size: $font-size-sm;
          margin-top: $half-unit;
        }
      }
    }
  }

  .list-content {
    margin: 0 auto;
    max-width: $max-content-width-md;
    padding: $half-unit;

    @include screen('sm') {
      padding: $base-unit;
    }

    .title {
      padding: $base-unit 0;
      border-bottom: 1px solid $sf-gray-light;

      h1 {
        font-size: $font-size-xxl;
        font-weight: $font-weight-heavy;
      }
    }

    .sf-pagination.pagination-container {
      text-align: center;
      padding: $padding-base * 2 $padding-base;
      border-top: 1px solid $sf-gray-base;

      @include screen('sm') {
        border-top: 0;
      }
    }
  }
}
