.sf-form-row-header {
  @include header-sm;
  margin-bottom: 10px;
  color: $sf-gray-dark;

  &.indented {
    @include indented;
  }
}

.sf-form-row {
  width: 100%;

  .sf-form-radio-option {
    label {
      line-height: 26px;
      padding-left: ($base-unit * 2);
    }

    input {
      margin-left: ($base-unit * -2)
    }
  }

  .sf-form-row-container {
    .sf-form-row-input-container {
      &.prefix {
        margin-left: 40px;
        border-left:none;

        .sf-form-row-input {
          border-left:none;
          @include border-left-radius(0px);
        }
      }
      &.postfix {
        margin-right: 40px;
        border-right:none;

        .sf-form-row-input {
          border-right:none;
          @include border-right-radius(0px);
        }
      }
    }

    .input-prefix {
      position:absolute;
      bottom:0;
      left:0;
      height: 45px;
      padding:10px;
      line-height:24px;
      border: 1px solid $sf-gray-base;
      border-right: none;
      min-width: 40px;
      text-align:center;
      background: #FFF;
      color: $sf-gray-dark;
      font-weight: 400;
      @include border-left-radius($border-radius-base);

      &.address {
        color:$sf-green;
      }
    }

    .input-postfix {
      position:absolute;
      bottom:0;
      right:0;
      height: 45px;
      padding:10px;
      line-height:25px;
      border: 1px solid $sf-gray-base;
      border-left: none;
      font-size:12px;
      min-width: 40px;
      text-align:center;
      background: #FFF;
      color: $sf-gray-dark;
      font-weight: 400;
      @include border-right-radius($border-radius-base);
    }

    .sf-form-row-content {
      width: 100%;
      position:relative;

      &.error {
        color: $sf-red !important;
        font-weight: 400;

        .input-postfix {
          color: #FFF;
          background: $sf-red;

          @include screen-max('sm') {
            font-size: 10px;
          }
        }

        .input-prefix {
          color: $sf-red;

          @include screen-max('sm') {
            font-size: 10px;
          }
        }

        input,
        select,
        .input-prefix,
        .input-postfix {
          border-color: $sf-red-light;
        }
      }

      .sf-form-row-subheader {
        font-size:12px;
        margin-bottom: 10px;
        color: $sf-gray-dark;
        font-weight: 100;
      }

      .sf-form-row-input {
        width:100%;
        height:45px;

        &.address-valid {
          margin-left: 38px;
          width: calc(100% - 38px);
          @include border-left-radius(0px);
        }
      }

      .sf-form-row-select {
        width: 100%;
        height: 45px;
        border-color: $sf-gray-base;
        background-color: #fff;
      }

      .sf-form-row-textarea {
        width:100%;
        border-color: $sf-gray-base;
      }

      .textarea-count {
        position: absolute;
        font-size: 10px;
        color: $sf-gray-dark;
        bottom: 15px;
        right: 10px;
      }

      .sf-form-row-boolean {
        position: relative;
        width: 100%;
        background: #FFF;
        padding: 15px 20px;
        border-radius: $border-radius-base;
        font-weight: 400;
        color: $sf-gray-dark;
        border: 1px solid $sf-gray-base;
        cursor: pointer;
        -webkit-user-select: none;  /* Chrome all / Safari all */
        -moz-user-select: none;     /* Firefox all */
        -ms-user-select: none;      /* IE 10+ */

        .boolean-toggle {
          position: absolute;
          height: 30px;
          width: 60px;
          border-radius: 30px;
          right: 20px;
          top: calc(50% - 15px);
          background: $sf-gray-base;
          box-shadow: inset 0px 2px 2px rgba(0,0,0,0.1);
          transition: background 0.1s ease;

          &.inverted {
            background: $sf-green;

            &.true {
              background: $sf-gray-base;
            }
          }

          &.true {
            background: $sf-green;
          }

          .boolean-toggle-button {
            position: absolute;
            background: #FFF;
            height: 30px;
            width: 30px;
            border-radius: 50%;
            text-align: center;
            line-height: 26px;
            color: $sf-gray-base;
            font-size: 12px;
            border: 1px solid $sf-gray-base;
            box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
            left: -1px;
            transition: left 0.1s ease;

            &.true {
              left:31px;
            }
          }
        }
      }

      .sf-form-row-checkbox {
        display: block;

        .sf-form-row-checkbox-label {
          padding: 0;
          display: block;

          .sf-form-row-checkbox-label-text {
            display: inline-block;
            width: calc(100% - #{$base-unit});
            margin-left: ($base-unit * 2);
          }

          .sf-form-row-checkbox-input {
            margin: 0;
          }
        }
      }

      .sf-form-row-tags {

        .sf-form-row-tag-option {
          padding: 5px 10px;
          display: inline-block;
          margin: 5px;
          border:1px solid $sf-gray-base;
          border-radius: 2px;
          color: $sf-gray-dark;
          cursor:pointer;

          &.option-enabled {
            background: $sf-green;
            color: #FFF;
            border-color: $sf-green;
          }

          .tag-icon {
            display: inline-block;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
