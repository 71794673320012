#psm-content {
  overflow: auto;

  /* General style */
  p, h1, h2, h3, h4, h5, h6 {
    line-height: 1.5em;
    color: $sf-black;
  }

  h1 {
    font-size: 52px;
    font-weight: $font-weight-heavy;
  }

  h2 {
    font-size: $font-size-xxl;
    font-weight: $font-weight-heavy;
  }

  h3 {
    font-size: $font-size-xl;
    font-weight: $font-weight-regular;
  }

  h4, h5, h6 {
    font-size: $font-size-md;
    font-weight: $font-weight-light;
  }

  p {
    font-size: $font-size-md;
    font-weight: $font-weight-light;

    strong {
      font-weight: $font-weight-regular;
    }
  }

  .info-header {
    padding: 10px;
    line-height: 35px;
    text-align: center;
    font-weight: $font-weight-light;
  }

  .info {
    padding: 10px;
    line-height: 35px;
    text-align: center;
    font-weight: $font-weight-light;

    &.error {
      background-color: $sf-red-light;
      color: $white;
      font-weight: $font-weight-regular;
    }
  }

  .psm-choice {
    &.padding-before-none, .padding-before-none {
      padding-top: 0;
    }

    &.padding-before-double, .padding-before-double {
      padding-top: $padding-base * 3;
    }

    &.padding-after-none, .padding-after-none {
      padding-bottom: 0;
    }

    &.padding-after-double, .padding-after-double {
      padding-bottom: $padding-base * 3;
    }
  }

  div.psm-listing-by-ref {
    padding: #{$padding-half * 3} 0;

    &.dark {
      background-color: $sf-gray-light;
    }

    & > div {
      padding: 0 #{$padding-half * 3};
      max-width: $max-content-width-md;
      margin: auto;

      .listing.widget {
        float: left;
        max-width: 100%;
        counter-increment: index;
        width: 100%;
        margin: 0 10px $base-unit 0;
        height: auto;
        .listing-photo-container {
          position: relative;
          overflow: hidden;
          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            -webkit-transform: translate(-50%, -50%);
          }
        }
        @include screen('sm') {
          margin: 0 #{$half-unit * 3} 0 0;

          @for $i from 1 through 4 {
            &:first-child:nth-last-child(#{$i}),
            &:first-child:nth-last-child(#{$i}) ~ .listing.widget {
              width: calc(#{100% / $i} - #{($half-unit * 3) * ($i - 1) / $i});
            }
          }
          img {
            height: 100%;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }

  div.psm-listing-selection {
    padding: #{$padding-half * 3} 0;

    &.dark {
      background-color: $sf-gray-light;
    }

    & > div {
      padding: 0 #{$padding-half * 3};
      max-width: $max-content-width-md;
      margin: auto;

      &.cta {
        text-align: center;
      }

      .listing.widget {
        float: left;
        max-width: 100%;
        counter-increment: index;
        width: 100%;
        margin: 0 10px $base-unit 0;
        height: auto;

        @include screen('sm') {
          margin: 0 #{$half-unit * 3} $base-unit 0;
          width: calc(#{100% / 3} - #{$half-unit * 3});
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(10) {
          margin-left: 0;
        }

        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(9),
        &:nth-child(12) {
          margin-right: 0;
        }

        div.listing-details {
          div.details {
            > span {
              display: inline-block;
              margin: 0;

              + span {
                margin-left: $half-unit;
              }
            }
          }
        }
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }

  div.psm-text {
    box-sizing: border-box;
    padding: #{$padding-half * 3} 0;

    &.dark {
      background-color: $sf-gray-light;
    }

    &.center {
      text-align: center;

      p {
        text-align: center;
      }

      & > div img {
        float: none;
      }
    }

    & > div {
      padding: 0 #{$padding-half * 3};
      max-width: $max-content-width-md;
      margin: auto;

      li {
        font-weight: $font-weight-light;
        color: $sf-black;
        font-size: $font-size-md;
        line-height: 1.5em;
      }

      img {
        max-width: 100%;
        float: left;
      }

      pre {
        white-space: normal;
      }

      &:after {
        content: "";
        display: block;
        clear: both;
      }
    }
  }

  div.psm-hero {
    position: relative;
    overflow: hidden;

    &.padding-default {
      padding: #{$half-unit * 5} #{$half-unit * 3};
    }

    &.padding-double {
      padding: #{$half-unit * 10} #{$half-unit * 3};
    }

    &.padding-triple {
      padding: #{$half-unit * 15} #{$half-unit * 3};
    }

    @include screen('sm') {
      &.padding-default {
        padding: #{$base-unit * 5} #{$half-unit * 3};
      }

      &.padding-double {
        padding: #{$base-unit * 10} #{$half-unit * 3};
      }

      &.padding-triple {
        padding: #{$base-unit * 15} #{$half-unit * 3};
      }
    }

    @include screen('md') {
      padding-bottom: 0;
      padding-top: 0;
    }

    &.align-right {
      text-align: right;
    }

    &.align-left {
      text-align: left;
    }

    &.align-center {
      text-align: center;
    }

    .background-img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .overlay {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.1;
    }

    .content {
      max-width: $max-content-width-md;
      margin: auto;
      position: relative;


      h1, h2, h3, h4, h5, h6 {
        color: $sf-white;
      }

      .cta {
        margin-top: $base-unit * 2;
      }
    }
  }

  div.psm-button {
    padding: #{$padding-half * 3} 0;
    text-align: center;

    &.dark {
      background-color: $sf-gray-light;
    }
  }

  div.psm-pictures-grid {
    padding: #{$padding-half * 3} 0;

    &.dark {
      background-color: $sf-gray-light;
    }

    & > div {
      padding: 0 #{$padding-half * 3};
      max-width: $max-content-width-md;
      margin: auto;

      & > div {
        display: inline-block;
        position: relative;
        width: 100%;
        margin: 0 0 $half-unit;

        @include screen('sm') {
          margin: 0 10px;

          @for $i from 1 through 6 {
            &:first-child:nth-last-child(#{$i}),
            &:first-child:nth-last-child(#{$i}) ~ div {
              width: calc(#{100% / $i} - #{20px * ($i - 1) / $i});
            }
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          margin-bottom: -$quarter-unit;
        }

        .image-link {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          text-align: center;
          transition: opacity ease .25s;

          &:not(:empty) {
            background-color: rgba(0, 0, 0, .4);

            &:hover {
              opacity: 0;
            }

            .image-link-label {
              width: 100%;
              position: absolute;
              left: 0;
              top: calc(50% - 17px);
              font-size: $font-size-xxl;
              font-weight: $font-weight-regular;
              text-shadow: 0px 1px $quarter-unit #000;
              color: #FFF;
              .image-link-sublabel {
                font-size: $font-size-xs;
              }
            }
          }
        }
      }
    }
  }

  div.psm-title-subtitle {
    text-align: center;
    box-sizing: border-box;
    padding: $padding-half * 3;
    color: $secondary-2-grey;
    background-size: cover;
    overflow: auto;

    &.dark {
      background-color: $sf-gray-light;
    }

    .block {
      max-width: $max-content-width-md;
      margin: auto;

      &.padding-after-none .no-subtitle {
        h1, h2, h3, h4, h5, h6 {
          margin-bottom: 0;
        }
      }
    }
  }

  ul.psm-featured-items {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      padding: #{$half-unit * 3} 0;

      &.dark {
        background-color: $sf-gray-light;
      }

      & > div {
        padding: 0 #{$padding-half * 3};
        max-width: $max-content-width-md;
        margin: auto;

        .featured-img {
          float: left;
          width: 100%;
          margin: 0 auto $base-unit;

          @include screen('sm') {
            margin: 0 #{$half-unit * 3} 0 0;
            max-width: 100%;
            width: initial;
            zoom: 75%;
          }

          @include screen('md') {
            zoom: 100%;
          }

          &.right {
            margin: 0 auto $base-unit;

            @include screen('sm') {
              float: right;
              margin: 0 0 0 #{$half-unit * 3};
            }
          }
        }

        h3 {
          margin-top: 0;
        }

        li {
          font-weight: $font-weight-light;
          color: $sf-black;
          font-size: $font-size-md;
          line-height: 1.5em;
        }

        p:last-child, a.read-more:last-child {
          margin-bottom: 0;

          &.read-more {
            color: $sf-black;
            font-size: $font-size-sm;
            border: 1px solid $sf-gray-dark;
            font-weight: $font-weight-regular;
            padding: $base-unit #{$half-unit * 3};
            margin-top: $base-unit;
            white-space: nowrap;
            display: inline-block;
            border-radius: $border-radius-sm;
          }
        }

        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
  }

  div.psm-key-features {
    &.dark {
      background-color: $sf-gray-light;
    }

    & > div {
      max-width: $max-content-width-md;
      margin: 0 auto;
      padding: ($padding-half * 3) 0px;

      &.centered {
        text-align: center;
        .psm-key-features-elem .content p {
          text-align: center;
        }
      }

      &.left {
        text-align: left;
        .psm-key-features-elem .content p {
          text-align: left;
        }
      }

      &.right {
        text-align: right;
        .psm-key-features-elem .content p {
          text-align: right;
        }
      }

      .psm-key-features-elem {
        display: inline-block;
        padding: $padding-base ($padding-base * 2);
        vertical-align: top;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @include screen('sm') {
          margin: $base-unit $base-unit 0;
          padding: 0;

          @for $i from 1 through 6 {
            &:first-child:nth-last-child(#{$i}),
            &:first-child:nth-last-child(#{$i}) ~ .psm-key-features-elem {
              width: calc(#{100% / $i} - #{($padding-base * 2) * ($i - 1) / $i});
            }
          }
        }

        img {
          max-width: 100%;
        }

        .content {
          .title {
            margin-bottom: $base-unit;
            font-size: $font-size-xl;
            font-weight: $font-weight-regular;
          }
        }

        .content {
          margin-top: $base-unit;
        }
      }
    }
  }

  div.psm-how-it-works {
    padding: ($padding-half * 3) 0;
    text-align: center;

    h2 {
      text-align: center;
      box-sizing: border-box;
      margin: $padding-half 0;
      color: $secondary-2-grey;
      background-size: cover;
      overflow: auto;
    }

    .container {
      max-width: $max-content-width-md;
      margin: 0 auto;
      padding: $padding-base $padding-base;

      .psm-how-it-works-step {
        display: inline-block;
        padding: $padding-base ($padding-base * 2);
        vertical-align: top;
        text-align: center;
        border-bottom: 1px solid $sf-gray-light;
        width: 100%;

        .content {
          text-align: left;
          font-size: $font-size-sm;
          color: $sf-gray-dark;

          .title {
            font-size: $font-size-lg;
            font-weight: $font-weight-regular;
            color: $sf-black;
            margin-bottom: $half-unit;
          }
        }

        &:first-child {
          border-top: 1px solid $sf-gray-light;
        }

        @include screen('ss') {
          width: 50%;
          border-top: 1px solid $sf-gray-light;

          &:first-child, &:nth-child(2) {
            border-bottom: 0;
          }

          .content {
            height: 64px;
          }
        }

        @include screen('sm') {
          margin: $base-unit $base-unit 0;
          padding: ($base-unit * 2) 0;

          &:first-child {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          &:first-child, &:nth-child(2) {
            border-bottom: 1px solid $sf-gray-light;
          }

          &:first-child:nth-last-child(4),
          &:first-child:nth-last-child(4) ~ .psm-how-it-works-step {
            width: calc(#{100% / 4} - #{$padding-half * 3});
          }
        }

        img {
          max-width: 100%;
          margin-bottom: $base-unit;
        }
      }
    }

    .cta.btn {
      font-size: $font-size-sm;
      padding: $padding-base ($padding-base * 4);
      margin-top: $base-unit * 2;
    }
  }

  .psm-embed {
    margin-top: $half-unit * 5;

    @include screen('sm') {
      margin-top: $base-unit * 5;
    }

    & iframe {
      display: block;
      margin: 0 auto;
      width: 80vw;
      height: 45vw;
      max-height: 576px;
      max-width: $max-content-width-md;
    }
  }

  .psm-full-width-image {
    img {
      width: 100%;
    }
  }

  .psm-email-capture {
    padding: (3 * $padding-half) 0;

    &.dark {
      background-color: $sf-gray-light;
    }

    &>div {
      max-width: $max-content-width-md;
      margin: auto;

      form {
        $btn-height: 35px;

        width: 100%;
        max-width: 500px;
        margin: auto;
        padding: 0 (3 * $padding-half);

        &.successed {
          label {
            max-height: 0;
          }

          .input-container {
            input {
              flex-grow: 0;
              height: 0;
              padding: 0;
              border: 0;

              @include screen('sm') {
                height: auto;
                width: 0;
              }
            }

            .btn {
              flex-grow: 1;
              background-color: tint($sf-green, 85%);
              border-radius: 2px;
              border-color: tint($sf-green, 85%);
              cursor: default;

              .label, .loading-dots {
                top: -$btn-height;
              }

              .success-message {
                top: 0;

                .message {
                  max-width: 150px;
                  margin-left: $half-unit;
                }
              }
            }
          }
        }

        .error {
          padding: $padding-half $padding-base;
          font-weight: $font-weight-light;
          font-size: $font-size-sm;
          border-radius: 2px;
          display: none;
          margin-bottom: $half-unit;
          background-color: tint($sf-red, 85%);
          color: $sf-red;
        }

        label {
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
          display: block;
          overflow: hidden;
          max-height: 20px;
          transition: max-height ease .5s;
          margin-bottom: $half-unit;
        }

        .input-container {
          @include screen('sm') {
            display: flex;
          }

          input {
            width: 100%;
            border-bottom: 0;
            border-radius: 2px 2px 0 0;
            transition: all ease .5s;
            display: block;

            @include screen('sm') {
              display: flex;
              flex-grow: 1;
              width: auto;
              border: 1px solid $sf-gray-base;
              border-right: 0;
              border-radius: 2px 0 0 2px;
            }
          }

          .btn {
            width: 100%;
            padding: 0 (3 * $padding-half);
            height: $btn-height;
            font-size: $font-size-sm;
            font-weight: $font-weight-light;
            border-radius: 0 0 2px 2px;
            transition: all ease .5s;
            flex-grow: 0;

            .label {
              display: block;
              position: relative;
              transition: top ease .5s;
              top: 0;
            }

            .loading-dots {
              display: block;
              position: absolute;
              height: 100%;
              padding-top: $padding-half;
              top: $btn-height;
              transition: top ease .5s;
            }

            .success-message {
              height: 100%;
              width: 100%;
              text-align: center;
              position: absolute;
              top: $btn-height;
              left: 0;
              padding-top: $padding-half;
              color: $sf-green;

              .message {
                display: inline-block;
                max-width: 0;
                transition: all ease 1s;
                transition-delay: .7s;
                white-space: nowrap;
                overflow: hidden;
                margin-left: 0;
                direction: rtl;
              }

              &:before {
                font-family: FontAwesome;
                content: '\f00c';
                font-size: $font-size-sm;
                display: inline-block;
                overflow: auto;
              }
            }

            &.submitting {
              .label {
                top: -$btn-height;
              }

              .loading-dots {
                top: 0;
              }
            }

            &:focus {
              outline: none;
            }

            @include screen('sm') {
              border-radius: 0 2px 2px 0;
              width: auto;
            }
          }
        }
      }
    }
  }

  .psm-testimonial-carousel {
    padding: $padding-base 0;

    .carousel {
      .success-story-panel {
        padding-top: 0;
        padding-bottom: 0;

        .success-story-image {
          width: auto;
          height: auto;

          img {
            width: auto;
          }
        }

        .success-story-text p {
          text-align: center;
        }
      }
    }
  }

  .psm-breadcrumbs {
    max-width: $max-content-width-md;
    margin: 0 auto;
    padding: #{$half-unit * 3};

    .crumb {
      display: inline-block;

      a {
        font-size: $font-size-md;
        color: $sf-gray-dark;

        &:hover {
          text-decoration: underline;
        }
      }

      &+.crumb:before {
        content: '/';
        color: $sf-gray-dark;
        margin: 0 $quarter-unit;
      }
    }
  }

  .psm-marketo-form {
    padding: (3 * $padding-half) 0;

    &.dark {
      background-color: $sf-gray-light;
    }

    &>div {
      padding: 0 (3 * $padding-half);
      max-width: $max-content-width-md;
      margin: auto;

      iframe {
        height: 0;
        border: 0;
        width: 100%;
      }
    }
  }

  &.search-footer-content {
    margin: $base-unit 0;
    color: $sf-black;
    font-size: $font-size-md;

    h1 {
      font-size: $font-size-xl;
      font-weight: initial;
      line-height: initial;
      margin: ($base-unit * 1.5) 0;
    }

    h4 {
      margin-top: $base-unit;
    }

    padding: $padding-base 0;

    div.slice {
      > div.label, > div.content {
        display: inline-block;
        vertical-align: top;

        margin: $half-unit $half-unit $half-unit 0;

        > p {
          margin: 0;
        }
      }
    }
  }
}
