.inquire-faq-panel {
  .inquire-point {
    font-size: 14px;
    padding: 10px 0px;
    text-align: left;
    font-weight: 200;
    line-height: 20px;

    b {
      font-weight: 700;
    }
  }
}
