.sf-form-project-type-selector {
  .sf-form-project-type-selector-container {
    .project-type-column {
      width: 33%;
      display: inline-block;
      vertical-align: top;
      padding: 10px;

      @include screen-max('xl') {
        width: 33%;
      }

      @include screen-max('sm') {
        width: 50%;

        &:nth-child(odd) {
          padding-left: 0;
        }

        &:nth-child(even) {
          padding-right: 0;
        }
      }

      @include screen('md') {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      > span.title {
        margin-bottom: 15px;
        display: block;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid black;
        padding: 0 0 5px;
      }

      > ul {
        list-style: none;
        list-style-type: none;
        margin: 0;
        padding: 0;

        > li {
          @extend .sf-form-tag;
          width: 100%;
          display: block;
          margin: 10px 0;
          list-style: none;
          list-style-type:  none;

          .tag-label {
            color: $secondary-2-grey;
            font-weight: $font-weight-light;
            font-family: $brown-pro;
          }
        }
      }
    }
  }
}
