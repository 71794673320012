@mixin giant-hero {
  font-size: 52px;
  font-weight: bold;
  @include screen-max('xs') {
    font-size: 30px;
  }
}

@mixin headline {
  font-size: $font-size-xxl;
  font-weight: bold;
  @include screen-max('xs') {
    font-size: $font-size-xl;
  }
}

@mixin content-title {
  font-size: 20px;
  font-weight: $font-weight-regular;
}

@mixin content-body {
  font-size: $font-size-md;
  font-weight: $font-weight-light;
}

@mixin content-body-strong {
  font-size: $font-size-md;
  font-weight: $font-weight-regular;
}

@mixin interface-title {
  font-size: $font-size-lg;
  font-weight: $font-weight-regular;
}

@mixin interface-body {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}

@mixin interface-body-strong {
  font-size: $font-size-sm;
  font-weight: $font-weight-regular;
}

@mixin small-legend {
  font-size: $font-size-xs;
  font-weight: $font-weight-light;
}

@mixin metadata {
  font-size: $font-size-sub;
  font-weight: $font-weight-light;
}

@mixin screen($point) {
  @media (min-width: map-get($screen-breakpoint, $point)) { @content; }
}

@mixin screen-max($point) {
  @media (max-width: #{ map-get($screen-breakpoint, $point) - 1 }) { @content; }
}

@mixin screen-between($smPoint, $lgPoint) {
  @media (min-width: map-get($screen-breakpoint, $smPoint)) and (max-width: map-get($screen-breakpoint, $lgPoint)) { @content; }
}

@mixin minHeight {
  min-height: 100vh;
}

@mixin minHeightOffset($offset) {
  min-height: calc(100vh - #{$offset});
}

@mixin horizontal-align {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

@mixin background-cover($url, $attr: center center no-repeat) {
  background: url($url) $attr;
  background-size: cover;
}

@mixin border($color) {
  border: 1px solid $color;
}

@mixin border-left-radius($size) {
  border-top-left-radius: $size;
  border-bottom-left-radius: $size;
}

@mixin border-right-radius($size) {
  border-top-right-radius: $size;
  border-bottom-right-radius: $size;
}

@mixin border-top-radius($size) {
  border-top-right-radius: $size;
  border-top-left-radius: $size;
}

@mixin border-bottom-radius($size) {
  border-bottom-right-radius: $size;
  border-bottom-left-radius: $size;
}

@mixin text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin rotate($deg) {
  -ms-transform: rotate($deg); /* IE 9 */
  -webkit-transform: rotate($deg); /* Chrome, Safari, Opera */
  transform: rotate($deg);
}

@mixin retina-background-image($image_url, $image_ext) {
  background-image: url("#{$image_url}.#{$image_ext}");
  background-image: -webkit-image-set(url("#{$image_url}.#{$image_ext}") 1x,
  url("#{$image_url}_2x.#{$image_ext}") 2x);
}

@mixin arrow-right($px, $color) {
  border: $px solid transparent;
  border-left-color: $color;
}

@mixin arrow-left($px, $color) {
  border: $px solid transparent;
  border-right-color: $color;
}

@mixin arrow-down($px, $color) {
  border: $px solid transparent;
  border-top-color: $color;
}

@mixin arrow-up($px, $color) {
  border: $px solid transparent;
  border-bottom-color: $color;
}

@mixin more-arrow {
  position: absolute;
  right: $half-unit;
  top: calc(50% - 10px);
  font-size: $font-size-md;
  color: lighten($sf-gray-dark, 10%);
  text-align: right;
  width: $half-unit;
}

@mixin square($unit) {
  height: $unit;
  width: $unit;
}

@mixin circle($unit) {
  @include square($unit);
  border-radius: $border-radius-circular;
}

@mixin indicator-icon {
  vertical-align: top;
  line-height: 24px;
  font-size: 12px;
  color: #fff;
}

@mixin listing-icon($size, $font, $color) {
  float: left;
  position: static;
  margin: 0;
  line-height: $size;
  text-align: center;
  @include square($size);

  @if $color {
    color: $color;
  }

  .favorite {
    transition: color 0.1s ease;

    &.active {
      color: $sf-red-light;
      opacity: 1;
    }

    &.added {
      color: $sf-red-light;
      animation: pulse_animation 700ms 1;
      -webkit-animation: pulse_animation 700ms 1;
      -moz-animation: pulse_animation 700ms 1;
      -o-animation: pulse_animation 700ms 1;
    }
  }
}

/* parameters are applied for large screens ONLY */
@mixin favorite-tag($size, $font, $left) {
  .favorite-banner {
    @include absolute-top-right($half-unit);
    @include z-layer-2;

    font-size: $font-size-sm;
    cursor: pointer;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius-base;

    &:before {
      transition: border-color 0.1s ease;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }

    .listing-icon {
      @include listing-icon($size, $font, $sf-white);
    }
  }

  .favorite-count {
    color: white;
    font-weight: 400;
    font-size: $base-unit;
    float: left;
    line-height: 2 * $base-unit;
    padding: 0 $half-unit 0 0;

    @include screen('sm') {
      font-size: $font;
      line-height: $size;
    }
  }
}

@mixin listing-desktop-left {
  padding: 0px;
  max-width: none;
  display: inline-block;
  width: calc(65% - 20px);
  margin-top: 20px;

  @include screen-max('sm') {
    display: block;
    margin-bottom: 15px;
    float: none;
  }
}

@mixin inbox-thin-width {
  width: calc(100% - #{$base-unit * 6});
}

@mixin inbox-low-text {
  top: $base-unit * 2;
  font-size: $font-size-xs;
}

@mixin indented {
  margin-left: $base-unit * 1.5;
}

@mixin arrow-down-bg {
  background-image: url("/assets/images/icons/icons_arrow_down.png");
  @include retina-screen {
    background-image: url("/assets/images/icons/icons_arrow_down@2x.png");
  }
}

@mixin select-arrow {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

@mixin retina-screen {
  @media only screen and (min-resolution: 1.5dppx),
  only screen and (min-resolution: 144dpi) {
    @content;
  }
}

@mixin landscape-mode {
  @media screen and (max-width: #{ map-get($screen-breakpoint, 'sm') - 1 }) and (max-height: 500px) {
    @content;
  }
}

