//image based
.sf-image-icon {
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  margin: auto;
  font-size: 80px;
  text-align: center;
  color: inherit;

  &.grey {
    color: $secondary-3-grey;
  }

  &.img {
    height:60px;
    width:60px;
  }

  &.storefront-dark {
    background-image: url("/assets/images/icons/icons_storefront_dark.png");
  }

  &.storefront-grey {
    background-image: url("/assets/images/icons/icons_storefront_grey.png");
  }

  &.glasses-dark {
    background-image: url("/assets/images/icons/icons_glasses_dark.png");
  }

  &.glasses-grey {
    background-image: url("/assets/images/icons/icons_glasses_grey.png");
  }

  &.wallet-dark {
    background-image: url("/assets/images/icons/icons_wallet_dark.png");
  }

  &.wallet-grey {
    background-image: url("/assets/images/icons/icons_wallet_grey.png");
  }

  &.watch-dark {
    background-image: url("/assets/images/icons/icons_watch_dark.png");
  }

  &.watch-grey {
    background-image: url("/assets/images/icons/icons_watch_grey.png");
  }

  &.calendar-dark {
    background-image: url("/assets/images/icons/icons_calendar_dark.png");
  }

  &.calendar-grey {
    background-image: url("/assets/images/icons/icons_calendar_grey.png");
  }

  &.moneybags-dark {
    background-image: url("/assets/images/icons/icons_moneybags_dark.png");
  }

  &.moneybags-grey {
    background-image: url("/assets/images/icons/icons_moneybags_grey.png");
  }

  &.shopping-cart-dark {
    background-image: url("/assets/images/icons/icons_shopping_cart_dark.png");
  }

  &.shopping-cart-grey {
    background-image: url("/assets/images/icons/icons_shopping_cart_grey.png");
  }

  &.basket-dark {
    background-image: url("/assets/images/icons/icons_basket_dark.png");
  }

  &.basket-grey {
    background-image: url("/assets/images/icons/icons_basket_grey.png");
  }

  &.chart-dark {
    background-image: url("/assets/images/icons/icons_chart_dark.png");
  }

  &.chart-grey {
    background-image: url("/assets/images/icons/icons_chart_grey.png");
  }

  &.venn-dark {
    background-image: url("/assets/images/icons/icons_venn_dark.png");
  }

  &.venn-grey {
    background-image: url("/assets/images/icons/icons_venn_grey.png");
  }

  &.rocket-dark {
    background-image: url("/assets/images/icons/icons_rocket_dark.png");
  }

  &.rocket-grey {
    background-image: url("/assets/images/icons/icons_rocket_grey.png");
  }

  &.users-dark {
    background-image: url("/assets/images/icons/icons_users_dark.png");
  }

  &.users-grey {
    background-image: url("/assets/images/icons/icons_users_grey.png");
  }

  &.convo-dark {
    background-image: url("/assets/images/icons/icons_convo_dark.png");
  }

  &.convo-grey {
    background-image: url("/assets/images/icons/icons_convo_grey.png");
  }

  &.flag-dark {
    background-image: url("/assets/images/icons/icons_flagship_dark.png");
  }

  &.flag-grey {
    background-image: url("/assets/images/icons/icons_flagship_grey.png");
  }

  &.newspaper-dark {
    background-image: url("/assets/images/icons/icons_newspaper_dark.png");
  }

  &.newspaper-grey {
    background-image: url("/assets/images/icons/icons_newspaper_grey.png");
  }

  &.sf-white {
    background-image: url("/assets/images/icons/icons_sto_white.png");
  }

  &.brand-dark {
    background-image: url("/assets/images/icons/icons_brand_dark.png");
  }

  &.brand-grey {
    background-image: url("/assets/images/icons/icons_brand_grey.png");
  }

  &.brand-white {
    background-image: url("/assets/images/icons/icons_brand_white.png");
  }

  &.steps-dark {
    background-image: url("/assets/images/icons/icons_steps_dark.png");
  }

  &.steps-grey {
    background-image: url("/assets/images/icons/icons_steps_grey.png");
  }

  &.desktop-dark {
    background-image: url("/assets/images/icons/icons_desktop_dark.png");
  }

  &.desktop-grey {
    background-image: url("/assets/images/icons/icons_desktop_grey.png");
  }

  &.mobile-dark {
    background-image: url("/assets/images/icons/icons_mobile_dark.png");
  }

  &.mobile-grey {
    background-image: url("/assets/images/icons/icons_mobile_grey.png");
  }

  &.inquiry-dark {
    background-image: url("/assets/images/icons/icons_inquiry_dark.png");
  }

  &.inquiry-grey {
    background-image: url("/assets/images/icons/icons_inquiry_grey.png");
  }

  &.owner-dark {
    background-image: url("/assets/images/icons/icons_owner_dark.png");
  }

  &.owner-grey {
    background-image: url("/assets/images/icons/icons_owner_grey.png");
  }

  &.coffee-dark  {
    background-image: url("/assets/images/icons/icons_coffee_dark.png");
  }

  &.location {
    background-image: url("/assets/images/icons/icons_location.png");
    @media only screen and (min-resolution: 1.5dppx),
      only screen and (min-resolution: 144dpi) {
      // Retina stuff
      background-image: url("/assets/images/icons/icons_location@2x.png");
    }
  }

  &.arrow-down {
    @include arrow-down-bg;
  }
}
