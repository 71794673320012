.sf-photo-viewer {
  @include layout-absolute-full-size;

  font-family: $brown-pro;
  $scroll-thumbnails-height: 67px;
  $thumbnails-info-height: $base-unit;
  $slideshow-height: $scroll-thumbnails-height + $thumbnails-info-height + (2 * $padding-base);
  z-index: 2147483020;

  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  cursor: pointer;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);

  > .carousel-container {
    position: absolute;
    top: ($base-unit * 5);
    bottom: $half-unit * 15;
    left: ($base-unit * 5);
    right: ($base-unit * 5);

    @include screen-max('sm') {
      top: 0px;
      height: 100%;
      left: $base-unit;
      right: $base-unit;
    }

    @include screen-max('xs') {
      top: $half-unit;
    }

    > .sf-carousel {
      width: $max-content-width-lg;
      max-width: 100%;
      height: 100%;
      margin: 0 auto;
      cursor: pointer;

      .image {
        background-size: contain;
        background-repeat: no-repeat;
      }

      @include screen-max('sm') {
        @include align-middle;

        height: 50vh;
      }

      @include screen('sm') {
        .arrow {
          &.left {
            left: ($base-unit * -3.5);
            font-size: $font-size-xxl;
          }

          &.right {
            right: ($base-unit * -3.5);
            font-size: $font-size-xxl;
          }
        }
      }
    }
  }

  .slideshow-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: $slideshow-height;

    > .slideshow-inner {
      position: relative;
      margin: 0 auto;
      max-width: 100%;
      width: 1024px;
      height: 100%;

      > .sf-scroll-thumbnails {
        transition: top 0.5s;
        height: $scroll-thumbnails-height;
        width: 100%;

        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - #{$scroll-thumbnails-height + $padding-base});
      }

      > .slideshow-info {
        @include body-text($white);

        transition: top 0.5s ease;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
        position: absolute;
        top: 0%;
        max-width: 100%;
        width: 1024px;
      }

      &.collapsed {
        > .sf-scroll-thumbnails {
          top: 100%;
        }
        > .slideshow-info {
          top: calc(100% - #{$thumbnails-info-height + 2 * $padding-base});
        }
      }
    }
  }

  > .close-btn {
    @include absolute-top-right($base-unit);

    cursor: pointer;

    i {
      font-size: $font-size-xxxl;
    }
  }
}
