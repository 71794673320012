$error-container-width: 400px;
.error-page {
  background-image: url("/assets/images/backgrounds/error-page-background.jpg");
  background-size: cover;
  background-position: 0 50%;
  background-attachment: fixed;
  @include screen('sm') {
    padding-bottom: $padding-double * 1.5;
  }
  .error-wrapper {
    padding: 0.05px; /* prevents margin collapsing */
    text-align:center;
    @include screen('sm') {
      height: calc(100vh - #{$navbar-height});
    }
    p {
      text-align: center;
      margin-bottom: $base-unit;
    }
    ol {
      margin: 0;
      padding: 0;
      text-align: left;
      li {
        display: inline-block;
        margin: 0 $quarter-unit;
        a {
          color: $sf-black;
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
        }
      }
    }
    .error-container, .wide-error-container{
      margin: 0 auto;
      max-width: $error-container-width;
      background-color: $sf-white;
      font-size: $font-size-sm;
      hr {
        margin: $double-unit auto;
        width: 65%;
        border: 0;
        border-top: 1px solid $sf-gray-base;
        @include screen('sm') {
          margin: $base-unit auto;
        }
      }
      .sf-btn-tertiary {
        border: none;
        &:active {
          box-shadow: none;
        }
        &:hover {
          background: inherit;
          color: $sf-green-darker
        }
      }
      .sf-btn-primary {
        padding: $padding-half $padding-base;
        margin: $half-unit 0;
      }
      .sf-btn-secondary {
        padding: $padding-half calc(#{$padding-base + $padding-half});
        min-width: unset;
        @include screen-max('sm') {
          min-width: 272px;
          margin: $half-unit;
        }
      }
      .sf-btn-primary, .sf-btn-secondary {
      }
      .not-found-sub-header {
        line-height: 1.5;
      }
    }
    .error-container {
      margin: $double-unit * 4 auto;
      padding: $padding-double;
      .fa-search {
        font-size: $font-size-xxxl;
      }
      .request-id-message {
        font-size: $font-size-xs;
        margin-top: $base-unit + $quarter-unit;
        margin-bottom: 0;
      }
      .request-id {
        margin-top: $half-unit;
        margin-bottom: $base-unit;
      }
    }
    .wide-error-container {
      .error-title {
        margin: 0 auto;
      }
      .error-subtitle, .search-links-title {
        margin-bottom: $base-unit + $quarter-unit;
      }
      @include screen('sm') {
        margin-top: $base-unit * 3;
      }
      max-width: $error-container-width * 2;
      padding: $padding-base;
      padding-bottom: $padding-base * 3;
      @include screen('sm') {
        padding: $padding-base * 1.5;
        padding-bottom: $padding-base * 3;
      }
      ol.breadcrumbs {
        padding-bottom: $padding-double;
        .fa-chevron-right {
          color: $sf-gray-base;
          font-size: $font-size-xs;
        }
      }

      .recommendations-wrapper {
        margin-top: $base-unit + $half-unit;
        a {
          color: $sf-black;
        }
        .recommendations-container .listing-row {
          width: 25%;
          .listing-image-header {
            height: $double-unit * 3;
            background-size: cover;
            @include screen-max('sm') {
              width: 35vw;
              height: 25vw;
            }
          }
          @include screen-max('sm') {
            width: 100%;
            margin: 0 auto;
          }
          .listing-info {
            text-align: left;
            .listing-title {
              @include screen-max('sm') {
                width: 55vw;
              }
            }
          }

        }
      }
    }
  }
}
