.info-page {
  background: $secondary-4-grey;
  padding: 80px 40px;
  padding-top: 150px;

  @include screen-max('sm') {
    padding: 0px;
  }
}

.info-container {
  padding: 20px 40px;
  margin: 0 auto;
  max-width:900px;
  background: #FFF;

  &.contact {
    max-width: none;
    padding: 0px;
  }

  .info-main-section {
    height: 100vh;
    width: 100%;
    background-image:
      linear-gradient(to top, rgba(0,0,0,0.6), transparent),
      url("/assets/images/about/about_header_image_05.jpg");
    background-size: cover;
    background-position: center center;
    color: #FFF;
    padding: 66px 40px 40px 40px;
    display: table;


    .info-main-section-content {
      display: table-cell;
      max-width: 800px;
      margin: 0 auto;
      height: 100%;
      vertical-align: middle;
      text-align: center;

      .info-main-header {
        font-size: 50px;
        font-weight: $font-weight-heavy;
      }
      .info-main-subheader {
        max-width: 800px;
        font-size: 20px;
        margin: 10px auto;
      }
    }
  }

  .info-header {
    font-size:24px;
    font-weight: $font-weight-heavy;
    margin:30px 0;
    padding-bottom:5px;
    text-align: center;
    color: $secondary-2-grey;
  }

  .info-subheader {
    font-size: 18px;
    font-weight: bold;
    margin-bottom:10px;
  }

  p {
    line-height:24px;
    margin-bottom:24px;

    a {
      color: $primary-green;
    }
  }

  .indent {
    margin:80px auto;
    margin-left: 120px;
  }

  .contact-info {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;

    @include screen-max('sm') {
      padding: 20px;
    }
  }

  .info-bullet-point {
    font-size:16px;
    font-weight: bold;
    margin:40px 0;
    span {
      margin-left: 40px;
    }
  }

  .info-bullet-subheader {
    font-weight:bold;
    font-size:14px;
    margin-bottom:20px;
  }

  .address-layout {
    width:340px;
    margin-left:40px;
    margin-bottom:40px;

  }

  .info-label {
    width: 120px;

    @include screen-max('sm') {
      display:block;
      float:none;
    }
  }

  .info {
    display: block;
    padding: 10px 0px;

    &.big {
      font-size: 30px ;

      @include screen-max('sm') {
        font-size: 20px;
      }
    }
  }
}
