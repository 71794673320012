.datepicker-toggle {
  position: relative;
  padding: 12px 40px;
  font-size: 12px;
  cursor: pointer;
  color: $sf-gray-dark;
  background: #FFF;
  @include border-top-radius($border-radius-base);

  @include screen-max('sm') {
    padding: 15px 20px 15px 40px;
    font-size: 12px;
  }

  &.collapsed {
    border-radius: $border-radius-base;
  }

  .fa {
    position:absolute;
    top: calc(50% - 8px);
    left: 20px;
  }

  .clear-date-range {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 20px;
    background: #FFF;
    width: 30px;
    height: 30px;
    line-height: 26px;
    border-radius: 50%;
    text-align: center;

    @include screen-max('sm') {
      top: 9px;
      right: 5px;
    }
  }

  .clear-date-range:hover {
    background: #EEE;
  }
}

.clickable {
  cursor: pointer;
}

.sf-form-row-datepicker-calendar-end.full-width .sf-calendar-container {
  &:before, &:after {
    left: calc(50% + 12.5px + #{$base-unit});
  }
}

.sf-calendar-container {
  background-color: $white;
  border-radius: $border-radius-base;
  border: 1px solid $sf-gray-base;
  border-top: 0;
  padding: $base-unit;
  position: relative;

  &.collapsed {
    display: none;
  }

  .sf-calendar-footer {
    display: block;
    background-color: $white;
  }

  &:before {
    content: '';
    position:absolute;
    left: $base-unit;
    top: -19px;
    z-index:1;
    @include arrow-up(10px, #FFF);
  }

  &:after {
    content: '';
    position:absolute;
    left: $base-unit;
    top: -20px;
    @include arrow-up(10px, $sf-gray-base);
  }
}

.sf-calendar {
  margin: 0 auto;
  background: #FFF;
  border:1px solid $sf-gray-base;
  border-top: none;

  &.read-only {
    .so-cal-day:hover {
      cursor: default;
    }
  }

  &.has-footer {
    border-bottom: 0;
  }

  &.so-cal {
    position: relative;
    margin: 0 auto;
    color: #666;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  &.so-cal.collapsed {
    display: none;
  }

  .so-cal-input {
    width: 100%;
  }

  .date-input {
    width: inherit;
    padding: 12px 20px;
    font-size: 14px;
    color: #666;
  }

  .so-cal-daterange-header {
    overflow: hidden;
  }

  .so-cal-daterange-subheader {
    font-size: 10px;

    @include screen-max('sm') {
      font-size: 8px;
    }
  }

  .remove-date {
    position: absolute;
    right: 10px;
    top: 8px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    line-height: 28px;
    color: #CCC;
    font-size: 16px;
    z-index: 2;

    @include screen-max('sm') {
      font-size: 14px;
      top: 4px;
      right: 2px;
    }
  }

  .remove-date:hover {
    background: #EEE;
  }

  .so-cal-date {
    padding: 5px;
    width: 50%;
    display: inline-block;
    float: left;
    text-align: center;
    cursor: pointer;
    position: relative;

    @include screen-max('sm') {
      font-size: 11px;
    }
  }

  .so-cal-date.active {
    color: #10BF81;
    font-weight: $font-weight-regular;
    position: relative;
  }

  .so-cal-date.active:after {
    content: '';
    border: 8px solid transparent;
    border-bottom-color: $sf-gray-light;
    position: absolute;
    bottom: 0px;
    left: calc(50% - 8px);
  }

  .so-cal div{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  .so-cal-header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    text-align: center;
    font-weight: $font-weight-regular;
    color: $sf-black;
    border-bottom: 1px solid #eee;
  }

  .so-cal-changer {
    width: 14.2857%;
    float: left;
    cursor: pointer;
    font-size: 25px;
    text-align: center;
    height: 40px;
    line-height: 30px;
  }

  .arrow-icon {
    display: inline-block;
    border-radius: 2px;
    height: 27px;
    width: 27px !important;
    line-height: 27px;
    text-align: center !important;
    transition: background 0.25s ease;
    font-size: $font-size-sm !important;
  }

  .so-cal-changer:hover .arrow-icon{
    background: $sf-gray-light;
  }

  .so-cal-month-title {
    width: 71.4285%;
    height: 38px;
    position: relative;
    top: 1px;
    float: left;
    font-size: $font-size-sm;
  }

  .so-cal-month-title.year-view {
    width: 100%;
    font-size: $font-size-sm;
  }

  .so-cal-days-header {
    width: 100%;
    overflow: hidden;
  }

  .so-cal-date-grid {
    width: 100%;
  }

  .so-cal-date-grid:after {
    content: '';
    display: block;
    height: 0px;
    clear: both;
  }

  .so-cal-day-title {
    width: 14.2857%;
    display: inline-block;
    padding: 10px;
    text-align: center;
    font-size: $font-size-sm;
    color: $sf-gray-dark;
  }

  .so-cal-day {
    background: #FFF;
    width: 14.2857%;
    display: inline-block;
    padding: ($padding-half * 1.5) 0;
    text-align: center;
    float: left;
    cursor: pointer;
    position: relative;
    font-size: 12px;
    color: #EEE;
    font-weight: $font-weight-regular;
    user-select: none;

    @include screen-max('sm') {
      font-size: 10px;
    }

    &.range-start, &.range-end, &.within-range, &.preview-range {
      position: relative;
      background: #10BF81;
      color: $sf-white;

      &.preview-range {
        background: rgba(16, 191, 129, 0.8);
      }

      &.range-start {
        border-radius: $border-radius-base 0 0 $border-radius-base;

        &.range-end {
          border-radius: $border-radius-base;
        }
      }

      &.range-end:not(.range-start) {
        border-radius: 0 $border-radius-base $border-radius-base 0;
      }
    }

    &.blocked {
      background: $sf-gray-light;
      position: relative;
      cursor: not-allowed;
      pointer-events: none;

      &:after {
        position: absolute;
        content: '';
        width: 1px;
        height: 60%;
        background: rgb(156, 156, 156);
        transform: rotate(60deg);
        left: 50%;
        top: 20%;
      }
    }

    &.has-tooltip {
      > .tooltip {
        @include card-popover(rgba(0, 0, 0, 0.75), transparent);

        // NOTE: this needs to have display:none because we don't want to show
        // anything here until it's hovered (see `&:hover .tooltip` below)
        display: none;
        pointer-events: none;

        color: $white;
        font-weight: $font-weight-regular;
        width: 120px;

        font-family: $brown-pro;
        font-size: $font-size-xs;

        top: auto;
        left: -64px;
        bottom: 38px;

        &:after {
          top: auto;
          bottom: -16px;
          border-top-color: rgba(0,0,0,0.75);
          border-bottom-color: transparent;
          left: 84px;
        }
      }

      &:hover {
        > .tooltip {
          display: block;
        }
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        > .tooltip {
          top: 100%;
          bottom: auto;

          &:after {
            top: -16px;
            bottom: auto;
            border-bottom-color: rgba(0,0,0,0.75);
            border-top-color: transparent;
          }
        }
      }

      &:nth-child(1),
      &:nth-child(8),
      &:nth-child(15),
      &:nth-child(22),
      &:nth-child(29),
      &:nth-child(36) {
        > .tooltip {
          left: 8px;

          &:after {
            left: 12px;
          }
        }
      }

      &:nth-child(2),
      &:nth-child(9),
      &:nth-child(16),
      &:nth-child(23),
      &:nth-child(30),
      &:nth-child(37) {
        > .tooltip {
          left: -32px;

          &:after {
            left: 52px;
          }
        }
      }

      &:nth-child(7),
      &:nth-child(14),
      &:nth-child(21),
      &:nth-child(28),
      &:nth-child(35),
      &:nth-child(42) {
        > .tooltip {
          left: -84px;

          &:after {
            right: 0;
            left: auto;
          }
        }
      }
    }

    &.special-price {
      // TODO: somehow add styling for up/down arrows
      // we can't use  :after because the selection arrows use it
      // and :before is used for the borders
      // up/down arrow: 74, 73, 75

      &.price-lower,
      &.price-higher {
        &:after {
          position: absolute;
          content: '';
          top: 7px;
          right: 1px;
          height: $base-unit;
          width: $base-unit;
          font-family: FontAwesome;
          font-weight: $font-weight-light;
          font-size: 8px;
        }
      }

      &.price-lower {
        color: #46AA79;

        &:after {
          content: '\f063';
        }

        &.preview-range,
        &.within-range {
          color: $sf-white;
        }

        &.disabled {
          color: $sf-gray-base;
        }
      }

      &.price-higher {
        &:after {
          content: '\f062';
        }
      }

      // overridden minimums borders
      &.min-start, &.min-end, &.min-internal {
        &::before {
          border: 0 solid rgb(70, 170, 121);
          position: absolute;
          content: '';
          top: $quarter-unit;
          left: 0;
          right: 0;
          bottom: $quarter-unit;
        }

        &:not(.disabled) {
          &.within-range::before, &:hover::before, &.preview-range::before {
            border-color: $sf-white;
          }
        }
      }

      &.min-start {
        &:before {
          border-width: 1px !important;
          border-right-width: 0 !important;

          border-top-left-radius: $border-radius-base;
          border-bottom-left-radius: $border-radius-base;

          left: ($quarter-unit / 2) !important;
        }
      }

      &.min-end {
        &:before {
          border-width: 1px !important;
          border-left-width: 0 !important;

          border-top-right-radius: $border-radius-base;
          border-bottom-right-radius: $border-radius-base;

          right: ($quarter-unit / 2) !important;
        }
      }

      &.min-internal {
        cursor: default;

        &:hover {
          border-color: transparent;
        }

        &:before {
          border-width: 1px !important;
          border-left-width: 0 !important;
          border-right-width: 0 !important;
        }
      }
    }
  }

  .so-cal-day:not(.disabled):hover {
    background-color: rgba(16, 191, 129, 0.8);
    color: $sf-white;
    z-index: 1;
  }

  .so-cal-day.restricted,
  .so-cal-day.disabled {
    color: $sf-gray-base !important;
    font-weight: $font-weight-regular;
  }

  .so-cal-day.restricted:hover,
  .so-cal-day.disabled:hover {
    cursor: default;
    border-color: #FFF !important;
  }

  .so-cal-day.inactive {
    color: $sf-gray-base;
  }

  .so-cal-day.selected-day,
  .so-cal-day.within-range,
  .so-cal-day.start-cap,
  .so-cal-day.end-cap {
    background: #10BF81;
    color: #FFF;
  }

  .so-cal-day.within-range:hover {
    border-color: #10BF81;
  }

  .so-cal-day.inactive.within-range {
    background: lighten(#10BF81, 10%);
    color: #FFF;
  }

  .so-cal-day.inactive.within-range:hover {
    border-color: lighten(#10BF81, 10%);
  }

  .so-cal-day.today {
    font-weight: bold;
    color: #10BF81;

    &:not(.disabled):hover, &.range-start {
      color: $sf-white;
    }
  }

  .so-cal-day:not(.special-price).today {
    &::before {
      @include circle(24px);
      @include absolute-middle;

      content: '';
      border: 1px solid #10BF81;

      @include screen-max('sm') {
        height: 20px;
        width: 20px;
      }
    }

    &.range-start::before, &:not(.disabled):hover::before {
      border-color: $sf-white;
    }
  }

  .so-cal-day.today.selected-day,
  .so-cal-day.today.within-range,
  .so-cal-day.today.start-cap,
  .so-cal-day.today.end-cap {
    color: #FFF;
  }

  .so-cal-day.today.selected-day:before,
  .so-cal-day.today.within-range:before,
  .so-cal-day.today.start-cap:before,
  .so-cal-day.today.end-cap:before {
      border-color: #FFF;
  }

  .so-cal-day.today.restricted {
    color: #FFF;
  }

  .so-cal-day.today.restricted:before {
    border-color: #DDD;
  }

  .so-cal-day.within-range > .start-cap, .range-start .start-cap {
    content: '';
    position: absolute;
    border: 18px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid rgba(255,255,255, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
  }

  .so-cal-day.within-range > .end-cap {
    content: '';
    position: absolute;
    border: 18px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid rgba(255,255,255, 0.3);
    top: 0;
    bottom: 0;
    right: 0;
  }

  .blocked:not(.disabled), .booked:not(.disabled) {
    color: $sf-gray-base;
  }

  .current-month {
    background: #FFF;
    color: #666;
  }

  .current-month:not(.disabled):hover {
    background-color: rgba(16, 191, 129, 0.8);
    z-index: 1;
    border-color: $sf-gray-light;
  }

  .so-cal-month,
  .so-cal-year {
    width: 33.33%;
    display: inline-block;
    float: left;
    padding: 22px 0;
    text-align: center;
    cursor: pointer;
    position: relative;

    @include screen-max('sm') {
      padding: 15px 0;
    }
  }

  .so-cal-month:not(.disabled):hover,
  .so-cal-year:not(.disabled):hover {
    border-radius: $border-radius-base;
    background-color: rgba(16, 191, 129, 0.8);
    color: #FFF;
  }

  .so-cal-month.current-month,
  .so-cal-year.current-year {
    font-weight: bold;
    color: #10BF81;
  }

  .so-cal-month.disabled,
  .so-cal-year.disabled {
    color: #DDD;
  }

  .so-cal-month.disabled:hover {
    border-color: #FFF;
  }

  .so-cal-daterange {
    position: relative;
    padding: 20px 30px;
    background: #10BF81;
    color: #FFF;
  }

  .so-cal-remove-daterange {
    position: absolute;
    right: 20px;
    top: 16px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: #10BF81;
    transition: background 0.25s ease;
    background: #FFF;
  }

  .so-cal-remove-daterange:hover {
    background: #EEE;
  }
}
