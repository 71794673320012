.create-listing {
  @include card-flat($sf-gray-light);
  position: inherit;
  margin: auto;
  max-width: $max-content-width-lg;
  padding-bottom: ($base-unit * 2.5);

  .create-listing-form-container {
    @include card-flat;

    margin: $base-unit auto;
    max-width: $max-content-width-sm;
  }

  .btn-deactivate-listing {
    @include button-secondary($sf-red);

    margin: $base-unit;
  }

  .create-listing-form-row-header {
    font-size: 14px;
    font-weight: 400;
    color: $sf-gray-dark;

    span {
      float:right;
      font-size: 10px;
      font-weight: 200;
    }
  }

  .pricing-copy {
    font-size: 12px;
    color: $sf-gray-dark;
  }

  .pricing-advanced-options {
    color: $sf-gray-dark;
    cursor:pointer;
    font-size:11px;
    text-decoration: underline;
    padding: 0 $base-unit * 1.5;

    &:hover {
      color: $sf-green;
    }

    &.open {
      cursor: default;
      &:hover {
        color: $sf-gray-dark;
      }
    }
  }

  .loading-container {
    margin-top: (5 * $base-unit);
  }

  .waiting-for-location {
    .btn-submit-form {
      display: none;
    }

    .sf-form-row[data-sf-form-address][data-model-field="location"] {
      position: fixed;
      @include z-layer-top;
      top: calc(50% - #{$base-unit * 3});
      left: $half-unit * 3;
      right: $half-unit * 3;
      width: calc(100% - #{$base-unit * 3});

      &:before {
        @include card-main($margin: 0);
        @include z-layer-under;

        position: absolute;
        content: '';
        background: #fff;
        top: -3 * $half-unit;
        right: -10px;
        left: -10px;
        bottom: -3 * $half-unit;
        border-radius: $border-radius-lg;
      }

      @include screen('sm') {
        left: calc(50% - 350px);
        right: calc(50% - 350px);
        width: 700px;

        &:before {
          left: -3 * $half-unit;
          right: -3 * $half-unit;
        }
      }
    }

    .sf-form-row:not([data-sf-form-address]),
    .pricing-advanced-options {
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -ms-filter: blur(2px);
      -o-filter: blur(2px);
      filter: blur(2px);

      pointer-events: none;

      position: relative;

      &:after {
        @include layout-absolute-full-size;
        @include z-layer-1;

        background: rgba(0, 0, 0, 0.2);

        content: '';
      }
    }
  }
}
