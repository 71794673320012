$map-top: 128px;
$map-top-when-banner-displayed: calc(128px + 64px); /* 64px = banner height */
$listing-carousel-height: 108px;
$carousel-listing-card-height: 88px;

.browse-listings {

  .browse-listing-map {
    position: fixed;
    top: $navbar-height;
    left: auto;
    bottom: 0;
    right: 0;
    width: 35%;

    &.scroll-anchor-to-footer {
      position: absolute;
      top: auto;
      bottom: 0;
      height: calc(100vh - #{$navbar-height});
    }

    @include screen-max('sm') {
      display: none;
    }
  }

  .map-listing-panel {
    @include z-layer-4;
    @include absolute-bottom-left(0);

    bottom: ($base-unit * 1.5);
    right: ($base-unit * 3);
    width: auto;
    max-width: ($base-unit * 22.5);
  }

  .map-redo-search-panel {
    @include z-layer-1;
    @include card-main(0);
    @include absolute-top-right($half-unit);

    padding: $half-unit;
    border-radius: $border-radius-base;

    label {
      margin: 0;
      cursor: pointer;
    }

    &.primary {
      @include button-primary;
      @include absolute-top-right($half-unit);
    }
  }

  .listings-loading {
    position: fixed;
    top: $filter-bar-bottom-line;
    left: 0;
    right: 35%;
    bottom: 0;
    @include z-layer-4;
    background: rgba(0,0,0,0.4);
    opacity: 0.8;

    &.scroll-anchor-to-footer {
      position: absolute;
      top: auto;
      bottom: 0;
      height: 100vh;

      .loading-dots {
        top: 87%;
      }
      .loading-text {
        top: 93%;
      }
    }

    @include screen-max('sm') {
      right: 0;
    }

    .dot {
      background: $sf-white;
    }

    .loading {
      @include absolute-middle;
    }

    .loading-dots {
      @include absolute-middle;
    }
    .loading-text {
      @include absolute-middle;
      top: 42%;
      font-size: 20px;
      font-weight: $font-weight-regular;
      color: $sf-white;
      line-height: 30px;
    }
  }

  .browse-listing-results {
    background: $sf-white;
    width: 65%;
    margin-right: 35%;
    margin-left: 0;
    min-height: calc(100vh - #{$navbar-height});
    overflow: initial;

    .search-christmas-banner {
      width: 100%;
      background: url('/assets/images/backgrounds/holiday-banner.jpg') repeat 444px 65px;
      text-align: center;
      color: white;
      margin: $half-unit auto;
      border-radius: $border-radius-base;
      padding: $padding-half;
      position: relative;

      @include screen('sm') {
        width: calc(100% - #{$base-unit * 1.5}); // to match the padding of the listing cards
      }

      > .label {
        display: inline-block;
        vertical-align: middle;
        text-shadow: 0px 0px 10px $sf-black;
        font-weight: $font-weight-regular;
        margin: $half-unit $base-unit;
      }

      > .cta {
        margin: $half-unit $base-unit;
        color: #000;
        padding: 7px $base-unit;
      }

      > .close-btn {
        position: absolute;
        top: $base-unit;
        right: 0;
        bottom: 0;
        display: block;
        width: $base-unit * 3;
        cursor: pointer;
      }
    }

    .listing-row {
      .listing-panel {
        .listing-info {
          .sf-fakeit {
            .fake-title {
              width: 60%;
              margin: 0 0 ($quarter-unit * 3);
            }

            .fake-paragraph {
              width: 40%;
              margin: 0;

              &.small {
                width: 30%;
                margin: $half-unit 0 0;
              }
            }
          }
        }
      }
    }

    .listing-row {
      @include screen('md') {
        width: calc(100%/3);
      }
      .listing-image-header {
        @include screen('sm') {
          height: 18vw;
        }
        @include screen('md') {
          height: 14vw;
        }
      }
      .listing-title {
        @include screen('md') {
          width: 19vw;
        }
      }
    }

    @include screen-max('sm') {
      @include z-layer-2;
      width: 100%;
      left: 0;
      margin-left: 0;
    }

    .sf-listing-filter-panel.open {
      right: 35%;
      left: 0;

      // Map is hidden on mobile, so We should only offset the filter panel
      // by 35% on non-mobile sizes.
      @include screen-max('sm') {
        right: 0;
      }
    }

    &.padded {
      padding-top: 208px;
    }
  }

  .relaxed-results {
    @include z-index-base;
    position: relative;
    top: 68px;

    color: $sf-black;
    text-align: center;
    padding: $padding-half;
  }

  .results-footer {
    padding: ($padding-base * 2) $padding-lg;

    @include screen-max('sm') {
      padding: $base-unit $half-unit;
    }

    > .pagination-rule {
      border: none;
      border-top: 2px solid $sf-gray-base;
      margin: $base-unit $quarter-unit;
    }

    > .sf-pagination {
      padding: 32px 0px 16px 16px;
    }

  }
}

.browse-listings {
  .browse-listing-results .filter-bar-wrapper .map-toggle {
    display: none;
  }

  @include screen('sm') {
    &.map-disabled-by-default {
      .listings-loading {
        right: 0%;
      }
      .browse-listing-results {
        .filter-bar-wrapper {
          width: 100%;
          .sf-listing-sort-dropdown-wrapper {
            right: 240px;
          }
          .map-toggle {
            display: block;
            position: absolute;
            top: $base-unit + 5; /* + 5 for centering on the filter bar*/
            right: $base-unit;
            .angular-fix {
              width: 100%;
              height: 100%;
            }
            .show-map-toggle-label {
              font-weight: $font-weight-regular;
              line-height: 1.8;
              font-size: $font-size-sm;
              margin-right: 10px;
            }
          }
        }
      }
      .browse-listing-map {
        display: none;
        width: 50%;
        &.scroll-anchor-to-footer {
          top: auto;
          height: calc(100vh - #{$filter-bar-bottom-line + 4px});
        }
      }
      .browse-listing-results {
        width: 100%;
        margin-right: unset;
        .listing-row {
          width: calc(100%/3);
          @include screen('md') {
            width: 25%;
          }
          @include screen('xl') {
            width: calc(100%/6)
          }
          .listing-image-header {
            @include screen('sm') {
              height: 18vw;
            }
            @include screen('md') {
              height: 14vw;
            }
            @include screen('xl') {
              height: 10vw;
            }
          }
          .listing-title {
            @include screen('md') {
              width: 22vw;
            }
            @include screen('lg') {
              width: 22vw;
            }
            @include screen('xl') {
              width: 15vw;
            }
          }
        }
        .advanced-filter-panel-wrapper {
          &.open {
            @include screen('sm') {
              right: 0%;
            }
          }
        }
        .open-filters-background {
          &.open-filter {
            right: 0%;
          }
        }
      }

      &.map-is-visible {
        .listings-loading {
          right: 50%;
        }
        .browse-listing-map {
          display: block;
        }
        .browse-listing-results {
          width: 50%;
          margin-right: 50%;
        }
        .listing-row {
          width: calc(50%);
          @include screen('md') {
            width: 50%;
          }
          @include screen('lg') {
            width: calc(100%/3)
          }
          .listing-image-header {
            height: 14vw;
            @include screen('lg') {
              height: 10vw;
            }
          }

          .listing-title {
            width: 20vw;
            @include screen('md') {
              width: 22vw;
            }
            @include screen('lg') {
              width: 14vw;
            }
            @include screen('xl') {
              width: 14vw;
            }
          }
        }
        .advanced-filter-panel-wrapper {
          &.open {
            @include screen('sm') {
              right: 50%;
            }
          }
          .advanced-filter-panel {
            .sf-form-tag {
              width: 50%;
              @include screen('xl') {
                width: calc(100% / 3);
              }
            }
          }

        }
        .open-filters-background {
          &.open-filter {
            right: 50%;
          }
        }
      }
    }
  }
  &.mobile-map-experiment {
    .map-redo-search-panel {
      max-width: 75vw;
    }
    .sf-listing-sort-dropdown-wrapper.mobile-sort-dropdown {
      max-width: 70vw;
      .sf-listing-sort-select {
        select {
          padding-bottom: $half-unit - 1px; /* for alignement with map toggle button */
          @include screen('ss') {
            padding-bottom: $half-unit;
          }
        }
      }
      @include screen('ss') {
        max-width: unset;
      }
    }
    @include z-layer-1;
    .mobile-map-open-btn {
      display: block;
      margin: ($filter-bar-height + $base-unit) 0 0 $half-unit * 1.5;
      float: left;
      height: $base-unit * 2;
      font-size: $font-size-sm;
      padding: $padding-half;
      padding-bottom: $padding-half;
      border-radius: $border-radius-sm;
      font-weight: $font-weight-regular;
      border: 1px solid $sf-gray-base;
      cursor: pointer;
      display: table;
      span {
        display: table-cell;
        margin-left: 10px;
      }
      .btn-label {
        padding-left: $half-unit;
      }
      @include screen('sm') {
        display: none;
      }
    }
    .sf-listing-sort-dropdown-wrapper {
      .sf-form-title-wrapper.sf-listing-sort-title {
        display: none;
        @include screen(420px) {
          display: block;
        }
      }
    }
    &.mobile-map-is-visible {
      .mobile-map-close-btn {
        display: block;
        position: fixed;
        top: $map-top - ($quarter-unit / 2);
        left: $half-unit;
        width: $double-unit + $base-unit;
        height: $double-unit + $base-unit;
        cursor: pointer;
        border-radius: 30px;
        margin-top: 14px;
        text-align: center;
        background: $sf-white;
        @include z-layer-4;

        li {
          margin: 0;
          text-align: left;
          display: block;
        }

        .close {
          position: relative;
          display: inline-block;
          height: 100%;
          width: $double-unit + $quarter-unit;
          overflow: hidden;
          &:hover {
            &::before, &::after {
              background: $sf-green;
            }
          }
          &::before, &::after {
            content: '';
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            background: #4A494B;
            height: 5px;
            margin-top: -2px;
            border-radius: 5px;
          }
          &::before {
            transform: rotate(45deg);
          }
          &::after {
            transform: rotate(-45deg);
          }
        }
      }
      .browse-listing-map {
        display: block;
        width: 100%;
        @include z-layer-3;
        position: fixed;
        top: $map-top;
        left: 0;
        bottom: 0;
        right: 0;
      }
      .mobile-map-listing-carousel {
        visibility: hidden;
        bottom: 0;
        left: 0;
        right: 0;
        height: $listing-carousel-height;
        position: absolute;
        overflow-x: visible;
        white-space: nowrap;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        &.scrolling {
          scroll-snap-type: unset;
          .mobile-map-listing-wrapper {
            .map-listing-panel {
              scroll-snap-align: unset;
            }
          }
        }
        .mobile-map-listing-wrapper {
          width: 90%;
          margin: 0 5%;
          display: inline-block;
          .map-listing-panel {
            @include z-layer-4;
            position: initial;
            max-width: none;
            overflow: hidden;
            height: $carousel-listing-card-height;
            border-radius: $border-radius-lg * 2;
            scroll-snap-align: center;
            .listing-panel {
              margin: 0 auto;
              .listing-image-header {
                border-top-left-radius: $border-radius-lg * 2;
                border-bottom-left-radius: $border-radius-lg * 2;
                .image {
                  border-top-left-radius: $border-radius-lg * 2;
                  border-bottom-left-radius: $border-radius-lg * 2;
                }
              }
            }
          }
        }
      }
      &.mobile-map-carousel-is-visible {
        .mobile-map-listing-carousel {
          visibility: visible;
        }
        .sf-carousel {
          pointer-events: none;
          touch-action: none;

          @include screen('sm') {
            pointer-events: unset;
            touch-action: unset;
          }
        }
        .gm-bundled-control {
          display: none;
        }
      }
    }
  }
}

.search-also {
  font-weight: $font-weight-regular;
  margin-right: $half-unit;
}

.search-links-wrapper {
  padding: $padding-base * 1.5;
  border-top: 1px solid $sf-gray-base;
  line-height: 2;
  background-color: $sf-white;
}
