.sf-form-phone-number {
  .sf-form-phone-number-wrapper {
    .intl-tel-input {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
}
