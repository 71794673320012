.booking-request {
  position: relative;
  background-color: $white;

  .booking-request-main-header {
    .booking-request-info-header {
      padding: 10px;
      line-height: 35px;
      text-align: center;

      > label {
        color: $sf-black;
        margin: 0 $half-unit;
      }

      a {
        margin: 0 $half-unit;
      }
      .user-role {
        margin-right: $quarter-unit;
      }
      i {
        margin-right: $quarter-unit;
      }
    }

    .btn {
      margin: 0 3px;
      vertical-align: top;
    }

    .btn-secondary:hover {
      background: rgb(77, 76, 77);
    }
  }

  .conversation-button-wrapper {
    padding-top: $padding-base;
  }
  .callback-warning-message {
    padding: $padding-half;
  }

  .inbox-panel {
    @include layout-scrollable-main;
    @include card-anchored-left;
    @include nav-offset;
    @include z-layer-5;

    position: fixed;
    max-width: ($base-unit * 19);
    min-width: ($base-unit * 6);
    max-height: calc(100% - #{$navbar-height + ($base-unit * 2)});
    bottom: auto;
    left: calc(50vw - 384px - #{$base-unit * 21});
    margin: $base-unit;
    overflow-y: auto;

    &.scroll-anchor-to-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      top: auto;
    }

    .sf-pagination.pagination-container {
      padding: #{$base-unit * 1.5} $base-unit;

      .listing-pagebar {
        padding: 0;
        text-align: center;

        &>li {
          width: 22px;
          margin: 0 3px;
        }
      }


    }

    @media (max-width: 1440px) {
      &:hover {
        left: 0;
      }
    }
  }

  .main-content {
    max-width: $max-content-width-md;
    margin: auto;
    position: relative;
    min-height: calc(100vh - #{$navbar-height});
    background-color: #FFF;
    padding: 0;

    @include screen('sm') {
      padding: $base-unit;
    }

    @include screen('lg') {
      padding: $half-unit * 3;
    }

    &:after {
      display: block;
      content: '';
      clear: both;
    }

    .sf-inquiry-booking-info {
      width: 100%;
      clear: left;
      border-top: 1px solid $sf-gray-light;

      @include screen('sm') {
        float: left;
        width: $base-unit * 20;
        border: 1px solid $sf-gray-light;
      }

      .sf-inquiry-booking-info-contact {
        padding: 0 $base-unit;
        font-size: $font-size-sm;

        .sf-inquiry-booking-info-contact-data {
          padding: $base-unit $half-unit;
          border-bottom: 1px solid $sf-gray-light;

          & > div:not(:first-child) {
            margin-top: $base-unit;
          }

          .info-title {
            color: $sf-gray-dark;
            margin-bottom: $quarter-unit;
            font-weight: $font-weight-semi;
          }

          .info-text {
            font-weight: $font-weight-regular;
          }

          .message-redacted {
            display: inline-block;
            border-radius: 10px;
            background-color: $sf-black;
            width: $base-unit * 7;
            height: $half-unit;
          }

          .contact-data-warning {
            color: $sf-gray-dark;
            font-size: $font-size-xs;
            margin-top: $base-unit;
          }

          ul {
            padding-left: ($half-unit * 3);
            margin: $quarter-unit 0 0;
            font-weight: $font-weight-semi;

            li + li {
              margin-top: $quarter-unit;
            }
          }
        }
      }
    }

    #booking-request-infos {
      border: 0;
      width: 100%;
      border-bottom: 1px solid $sf-gray-light;
      margin-bottom: $base-unit;

      @include screen('lg') {
        margin-bottom: $half-unit * 3;
      }

      @include screen('sm') {
        float: left;
        width: $base-unit * 20;
        border: 1px solid $sf-gray-light;
      }

      .loader {
        padding: $padding-base;
      }

      #booking-request-users-info {
        padding: 0 $padding-base;
        margin: $half-unit 0 $base-unit;

        &>div {
          padding: $padding-half;
          border: 1px solid $sf-gray-base;
          margin-bottom: $half-unit;
        }
      }

      .supply-preapproved-panel, .action-payment-panel {
        padding: $padding-base;

        .preapproved-header, .action-payment-header {
          font-size: $font-size-md;
          font-weight: $font-weight-regular;
        }

        .preapproved-text, .action-payment-text {
          font-weight: $font-weight-light;
          font-size: $font-size-sm;
          margin: $base-unit 0;
        }

        .sf-btn-primary {
          padding: $padding-half 0;
          width: 100%;
          font-weight: $font-weight-regular;
          font-size: $font-size-sm;
        }
      }

      .sf-booking-request-action {
        padding-top: 0;
      }
    }

    .right-panel {
      @include screen('sm') {
        float: right;
        width: calc(100% - #{$base-unit * 20} - #{$base-unit});
      }

      @include screen('lg') {
        width: calc(100% - #{$base-unit * 20} - #{$half-unit * 3});
      }
    }

    #booking-request-chat {
      width: 100%;
      border-top: 1px solid $sf-gray-light;
      border-bottom: 1px solid $sf-gray-light;
      margin: 2 * $base-unit 0;

      @include screen('sm') {
        border: 1px solid $sf-gray-light;
        margin: 0;
      }

      h2 {
        font-weight: $font-weight-semi;
        padding: $padding-base $padding-half 0;
        margin: 0 $half-unit;
      }

      .booking-request-chat-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .callback-button {
          margin: $half-unit;
          font-size: $font-size-sm;
          max-width: auto;
        }
      }


      #conversation-selector {
        padding: 0 $padding-base $quarter-unit;

        ul {
          padding: 0;
          margin-bottom: 0;
          border-bottom: 1px solid $sf-gray-light;

          li {
            display: inline-block;
            margin-right: $half-unit;
            padding: $quarter-unit;

            &.loader {
              width: 120px;
              vertical-align: bottom;
            }

            &.active {
              border-bottom: 2px solid $sf-yellow;
            }

            .unread_messages_count {
              @include status-base($sf-green);
              margin-right: $quarter-unit;
              display: inline-block;
              vertical-align: bottom;
              padding: 3px 6px;
              line-height: 10px;
              font-size: $font-size-sub;
              border: 1px solid $white;
              border-radius: $base-unit * 2;
            }

            a {
              color: $sf-black;
              font-size: $font-size-sm;
              font-weight: $font-weight-regular;
              cursor: pointer;
            }
          }
        }

        .sf-fakeit {
          .fake-block {
            margin: $base-unit 0;
          }
        }
      }
    }

    .managed-by-stf {
      width: 100%;
      border-top: 1px solid $secondary-4-grey;
      border-bottom: 1px solid $secondary-4-grey;
      background-color: $secondary-6-grey;
      background-image: url("/assets/images/icons/icons_concierge.png");
      background-repeat: no-repeat;
      background-position-x: 97%;
      background-position-y: 97%;
      margin-bottom: $base-unit;
      padding: $padding-base * 2;

      h2 {
        margin-top: 0;
        @include interface-title;
      }

      .information {
        max-width: 77%;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;

        a {
          text-decoration: underline;
        }
      }
    }

    .sf-inquiry-progress-tracker {
      position: fixed;
      top: $navbar-height;
      left: 0;
      right: 0;

      @include screen('sm') {
        max-width: $max-content-width-sm;

        left: calc(50vw - 384px);
        right: calc(50vw - 384px);
      }
    }
  }

  .booking-request-details-body {
    @include z-layer-2;

    &.br-pending {
      padding: #{$base-unit * 3} 0 #{$base-unit * 5};
    }

    .sf-payment {
      margin: $base-unit;
    }

    .sf-message {
      margin: $half-unit 0;
      padding-left: 0;

      @include screen('sm') {
        margin-left: ($base-unit * 2) + $half-unit;
      }

      &:last-of-type {
        border-bottom:none;
      }
    }

    .sf-message:last-of-type {
      background:none;
    }

    .sf-message:last-child {
      background:none;
    }
  }

  .booking-request-messaging-body {
    padding: 0 $base-unit;
    overflow: auto;

    .booking-request-messages {
      padding: $base-unit;
      position: relative;
    }
  }
}

.review-check {
  font-size: $base-unit * 8;
  color: $sf-green;
  display: block;
  margin: auto;
  text-align: center;
  margin-bottom: $base-unit;
}
