.sf-projects-list {
  .empty-list {
    margin: $base-unit auto;
    text-align:center;
    color: $sf-gray-dark;
  }

  .project-item {
    padding: $base-unit;
    border-bottom: 1px solid $sf-gray-light;

    &:after {
      display: block;
      content: "";
      clear: both;
    }

    &:last-child {
      border-bottom: 0;
    }

    @include screen('sm') {
      padding: $base-unit * 2;

      &:last-child {
        border-bottom: 1px solid $sf-gray-light;
      }
    }
  }
}
