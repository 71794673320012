.sf-form-boolean {
  .sf-form-input-wrapper {
    .sf-form-boolean-option {
      padding: $half-unit $base-unit;
      border-radius: $border-radius-base;
      border: 1px solid $sf-gray-base;
      font-weight: $font-weight-regular;
      font-size: $font-size-sm;
      color: $sf-gray-dark;
      display: inline-block;
      cursor: pointer;

      & + .sf-form-boolean-option {
        margin-left: $base-unit;
      }

      &.active {
        border-color: $sf-green;
        color: $sf-green;
      }
    }
  }

  .sf-form-boolean-slider-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .sf-form-boolean-slider {
      width: 40px;
      height: 24px;
      background-color: $sf-white;
      border-radius: 30px;
      border: 2px solid #d1d5db;
      position: relative;
      cursor: pointer;
      transition: all ease .2s;

      .sf-form-boolean-slider-dot {
        width: 18px;
        height: 18px;
        border-radius: 20px;
        background-color: #d1d5db;
        position: absolute;
        top: 1px;
        left: 2px;
        transition: all ease-in-out .2s;
      }

      &.active {
        background-color: $sf-green;
        border-color: $sf-green;

        .sf-form-boolean-slider-dot {
          left: 17px;
          background-color: $sf-white;
        }
      }
    }

    .sf-form-boolean-slider-text {
      margin-left: $half-unit;
      font-weight: $font-weight-regular;
      font-size: $font-size-md;
    }
  }
}
