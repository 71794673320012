// full page
.signup-page {
  background: $white;

  .signup-page-container {
    margin: 0 auto;
    max-width: $max-content-width-xs;

    padding: 0;
    @include screen('sm') {
      padding: $base-unit;
    }
    @include screen('lg') {
      padding: $half-unit * 3;
    }

    .auth-form {
      .btn-submit-form {
        margin: 24px auto 4px;
      }

      .has-error-message {
        background: tint($sf-red, 90%);
        padding: $padding-base;
        border-radius: 3px;
        color: $sf-red;
        font-weight: $font-weight-regular;
        font-size: $font-size-sm;
        margin: $base-unit auto;
      }

      .sf-form-row {
        padding: $padding-half $padding-base;
        margin-bottom: $quarter-unit * 3;

        .sf-form-header:before {
          display: none;
        }

        .sf-form-title-wrapper {
          margin-bottom: $half-unit;
        }

        input {
          padding: $quarter-unit * 3;
          border-radius: $border-radius-base;
        }

        .intl-tel-input input {
          padding-left: $base-unit * 3;
        }

        .sf-form-phone-number-wrapper {
          margin-top: $half-unit;
        }
      }

      input[type="checkbox"] {
        padding: 8px;
      }
    }

    .auth-form {
      padding: $base-unit;

      border-bottom: 1px solid $sf-gray-light;
      @include screen('sm') {
        border: 1px solid $sf-gray-light;
      }
    }
  }
}

// Modal
.sf-login {
}

.auth-form {
  .sf-form .sf-form-row {
    padding: $base-unit;
    border-top: 0;
  }

  .loading-container {
    padding-top: 100px;
  }

  .btn-signup {
    padding: $padding-compact;

    &.facebook {
      background: #3B5998;
      border-color: #3B5998;
    }
  }

  .switch-logins {
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
    cursor: pointer;
    text-align: center;
    padding-top: $base-unit;

    .pseudo-link b {
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      color: $sf-green;
    }
  }

  .forgot-password {
    text-align: center;
    padding-top: $base-unit;
  }
}
