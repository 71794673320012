.boost-modal .sf-modal-contents {
  position: absolute;
  top: $navbar-height + $half-unit;
  margin-top: $quarter-unit;
  @include screen('lg') {
    width: 1000px;
  }

  .boost-listing-address {
    font-size: $font-size-sm;
  }

  .boost-selected-listing {
    text-align: center;
    padding: 0 8px;
  }

  .boost-info {
    text-align: center;
    margin-bottom: $half-unit;

    a {
      font-weight: $font-weight-regular;
    }
  }

  .boost-description {
    border-radius: $border-radius-base;
    background-color: #f3f3f3;
    margin: $base-unit 0 0;
    padding: $base-unit $base-unit $half-unit;

    p {
      margin-bottom: $half-unit;
      font-size: $font-size-md;
    }
  }

  .sf-modal-contents-body {
    padding-top: $base-unit !important;
    padding-bottom: $base-unit !important;
  }
}

// Button
.btn-boost {
  display: inline-block;
  position: relative;

  .btn-modal-btn {
    display: flex;
    align-items: center;
    padding: $quarter-unit $base-unit;
    font-weight: $font-weight-regular;
    color: #fff !important;
    font-size: $font-size-sm !important;
    background-color: #ffcf2d;
    border: 1px solid #f9db20;
    border-radius: $border-radius-base;
    cursor: pointer;

    @include screen('sm') {
      padding: 2px 12px !important;
    }

    &:hover {
      background-color: $sf-yellow;
    }

    &:active {
      background-color: #dbb123;
    }
  }

  &:hover .boost-info-ctn {
    display: block;
  }

  .boost-info-ctn {
    height: 30px;
    position: absolute;
    top: -30px;
    display: none;

    .boost-info {
      @include z-layer-1;

      &:before {
        @include arrow-down($quarter-unit + 1, $sf-gray-base);

        content: '';
        position: absolute;
        bottom: -8px;
        right: $base-unit;

        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }

      &:after {
        @include arrow-down($quarter-unit, $sf-white);

        content: '';
        position: absolute;
        bottom: -6px;
        right: $base-unit;

        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }

      display: block;
      padding: $padding-quarter $padding-half;
      background-color: $sf-white;
      border-radius: $border-radius-base;
      border: 1px solid $sf-gray-base;
      color: $sf-green;
      text-decoration: underline;
      font-size: $font-size-xs;
      top: -$base-unit;
      font-weight: $font-weight-regular;
    }
  }
}
