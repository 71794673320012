.sf-booking-request-action {
  @include z-layer-2;

  position: relative;
  padding: 0 $base-unit $base-unit;
  background: tint($sf-gray-light, 70%);
  border: 1px solid $sf-gray-base;
  border-radius: $border-radius-sm;
  margin-bottom: $quarter-unit;

  @include screen-max('sm') {
    padding: $padding-compact;
  }

  @include z-layer-3;

  .sf-btn-primary {
    margin: 0 auto;
  }

  .sf-btn-secondary {
    padding: $quarter-unit * 3;
  }

  .sf-btn-tertiary {
    border: 1px solid $sf-gray-base;
    color: $sf-black;
  }

  &.scroll-anchor-to-footer {
    position: absolute;
    top: calc(100% - #{$base-unit * 4} - 3px);
    bottom: auto;
  }

  &.br-pending {
    top: calc(100% - #{$base-unit * 4} - 5px);
  }

  &.selected {
    height: auto;
    top: auto;
    bottom: 0;

    .inquiry-form {
      background-color: transparent;

      .sf-form-hourly-or-daily-tab {
        padding: 0;

        .radio {
          margin: $quarter-unit 0 $half-unit;
          background-color: $sf-white;
          padding: $quarter-unit 0;

          .new {
            font-size: $font-size-xs;
          }
        }
      }

      .sf-form-hourly-select-times, .sf-form-datepicker {
        padding: 0 !important;

        .arrow-dates {
          background-color: $sf-white;
        }
      }

      .sf-form-hourly-show-invalid {
        padding: 0;
      }

      .sf-form-row {
        padding: 0;
      }

      .sf-form-title-wrapper {
        margin-bottom: $half-unit;
      }
    }
  }

  .action-panel-screen {
    background: rgba(0, 0, 0, 0);

    &.visible {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .close-action-body {
    @include button-close-gutter;

    background-color: $sf-gray-dark;
    border-radius: $border-radius-sm;
    color: $sf-gray-light;
  }

  .sf-booking-request-action-subheader {
    @include header-sm;
  }

  .info-fees {
    @include body-text;

    color: $sf-gray-dark;

    .info-text {
      display: inline-block;
      width: calc(100% - #{$base-unit * 2});
    }

    .sf-icon {
      vertical-align: top;
      font-size: $font-size-lg;
      color: $sf-info;
      display: inline-block;
    }
  }

  .approve-request-text {
    @include body-text;

    color: $sf-gray-dark;
    margin-bottom: $half-unit;
  }

  .sf-booking-request-action-radios {
    padding: $padding-compact;

    @include screen-max('sm') {
      padding: $half-unit 0;
    }
  }

  .sf-booking-request-action-initial-response {
    text-align: center;

    &.body-shown {
      visibility: hidden;
    }
  }

  .sf-booking-request-action-btn {
    width: calc(50% - #{$half-unit});

    &.message-modify {
      @include button-secondary;
      padding: $half-unit;
    }

    &.reject {
      @include button-secondary;
      padding: $half-unit;
    }

    &.send-response {
      @include button-primary;
      padding: $half-unit;
    }

    &.demand-modify {
      @include button-primary;
      padding: $half-unit;
      width: 100%;
    }
  }

  .sf-action-panel-btn {
    @include button-action-panel;

    > .loading-dots {
      position:relative;
    }
    &.btn-secondary {
      @include button-secondary;
      margin: auto;
    }
  }
  .sf-booking-request-action-body .btn-submit-form {
    width: 100%;
    margin-top: $half-unit;
    padding: $half-unit 0;
  }
}

.request-visit-modal, .sf-booking-request-cancel {
  .loader-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius-base;
    @include z-layer-3;

    .loading {
      top: calc(50% - 15px);
    }
  }

  .listing-calendar {
    margin: $base-unit 0 $half-unit;
  }

  .sf-form {
    padding: $padding-half;

    .sf-form-header, .selected-times-header {
      font-size: $font-size-sm;
      font-weight: $font-weight-heavy;
      margin-bottom: $quarter-unit;
    }

    .time-slot {
      display: flex;
      margin-bottom: $half-unit;
      font-size: $font-size-sm;
      height: 35px;

      .time-slot-date:before, .time-slot-time:before {
        @include z-layer-1;
        display: flex;
        padding: 0 $half-unit;
        align-items: center;
        height: 100%;
        font-family: FontAwesome;
        font-size: $font-size-xs;
        color: $sf-gray-dark;
        background-color: $sf-gray-light;
        position: absolute;
        left: 0;
        top: 0;
        border-right: 1px solid $sf-gray-base;
      }

      .time-slot-date {
        display: flex;
        position: relative;
        padding: $padding-half;
        padding-left: 35px;
        border-radius: $border-radius-sm;
        border: 1px solid $sf-gray-base;
        width: 50%;
        margin-right: $half-unit;
        white-space: nowrap;

        span {
          overflow: hidden;

          &:empty:after {
            content: attr(data-placeholder);
            color: $sf-gray-dark;
          }
        }

        &:before {
          display: flex;
          content: '\f133';
          font-size: $font-size-xs;
        }

        @include screen(500px) {
          span {
            font-weight: $font-weight-regular;
          }
        }

        @include screen('xs') {
          width: 60%;
          padding-left: $base-unit * 3;

          &:before {
            font-size: $font-size-md;
            padding: 0 ($quarter-unit * 3);
          }
        }
      }

      .time-slot-time {
        display: flex;
        flex-grow: 1;
        border-radius: $border-radius-sm;
        border: 1px solid $sf-gray-base;
        position: relative;

        &:before {
          display: none;
          pointer-events: none;
        }

        select {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          border: 0;
          font-weight: $font-weight-light;
          font-family: $brown-pro;

          &.pristine {
            color: $sf-gray-dark;
          }
        }

        @include screen(500px) {
          &:before {
            display: flex;
            content: '\f017';
            font-size: $font-size-md;
            padding: 0 ($quarter-unit * 3);
          }

          select {
            padding-left: $half-unit * 6;
            font-weight: $font-weight-regular;
          }
        }
      }

      .time-slot-remove {
        display: flex;
        width: 30px;
        background: $sf-red-light;
        color: $sf-white;
        margin-left: $half-unit;
        border-radius: $border-radius-sm;
        position: relative;
        cursor: pointer;

        &:hover {
          background: darken($sf-red-light, 5%);
        }

        &:active {
          background: $sf-red-light;
        }

        i {
          font-size: $font-size-sm;
          position: absolute;
          left: calc(50% - 5px);
          top: calc(50% - 8px);
        }
      }

      .datepicker {
        position: absolute;
        min-width: 250px;
        max-width: 300px;
        padding: 0 $half-unit $half-unit;
        background-color: #fff;
        border: 1px solid $sf-gray-base;
        top: 40px;
        left: 0;
        @include z-layer-2;

        .arrow {
          position: absolute;
          display: flex;
          cursor: pointer;
          padding: $half-unit;
          top: 10px;

          &.left {
            left: $half-unit;
          }

          &.right {
            right: $half-unit;
          }

          &.disabled {
            color: $sf-gray-base;
            cursor: default;
          }
        }
      }

      .remove-slot {
        position: absolute;
        right: $half-unit;
        color: $sf-red;
        cursor: pointer;
      }
    }

    .sf-form-row {
      padding: 0;
      margin: $base-unit 0;

      .sf-form-title-wrapper {
        margin-bottom: $half-unit;
      }

      .sf-form-input-wrapper {
        display: flex;
        flex-wrap: wrap;

        .sf-form-tag {
          display: flex;
          margin: $base-unit 0 0;
          text-align: center;
          padding: 0 $half-unit;
          width: calc(100% / 4);

          &:nth-child(-n+4) {
            margin-top: 0;
          }

          .tag-check {
            display: none;
          }

          .tag-label {
            display: flex;
            justify-content: center;
            flex-direction: column;
            font-weight: $font-weight-regular;
            width: 100%;
            padding: $base-unit 0;
            height: 100%;
            border: 1px solid $sf-gray-base;
            border-radius: 3px;
          }

          &.active {
            .tag-label {
              border-color: $sf-green;
            }
          }
        }
      }
    }

    .add-time {
      padding: $half-unit;
    }

    .btn-submit-form {
      width: 100%;
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  .error {
    background: tint($sf-red, 90%);
    padding: $padding-base;
    border-radius: 3px;
    color: $sf-red;
    font-size: $font-size-sm;
    font-weight: $font-weight-regular;
  }

  .btn-container {
    text-align: center;

    &:after {
      display: block;
      content: '';
      clear: both;
    }

    .previous-btn, .next-btn {
      cursor: pointer;
      display: inline-block;
      padding: $padding-half $padding-base;
      color: $sf-black;
      border: 1px solid $sf-gray-base;
      border-radius: 3px;
      background-color: $sf-white;
      font-weight: $font-weight-regular;
      font-size: $font-size-sm;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &.disabled {
        cursor: default;
        background-color: $sf-gray-light;
        color: $sf-gray-dark;
      }

      &.previous-btn {
        float: left;
      }

      &.next-btn {
        float: right;
      }

      &:active {
        background-color: $sf-gray-light;
      }
    }
  }
}

.request-visit-modal {
  .sf-form {
    textarea {
      height: 132px;
      pointer-events: none;
      background-color: $sf-white;
    }
  }
}

.callback-request-modal {
  .sf-form {
    margin-top: $base-unit;

    .sf-form-row, .sf-form-phone-number {
      padding: $padding-half !important;

      .sf-form-header {
        margin-left: $padding-half;
        margin-bottom: $half-unit;
      }

      .sf-form-title-wrapper {
        margin: 0;
      }
    }

    textarea {
      height: 132px;
      background-color: $sf-white;
    }
  }
}
