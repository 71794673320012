.sf-inbox-adjudicate {
  .sf-inbox-container {
    @include card-list-item;
    box-shadow: 0 1px 2px rgba(0,0,0,0.2);
    padding-right: 0;
    min-height: $base-unit * 4;

    .timestamp {
      @include timestamp;
    }

    .main-info-text {
      @include inbox-thin-width;
      display: inline-block;
      float: left;

      .main-info-details {
        @include header-xs;
        @include text-overflow;
      }

      .main-info-listing {
        @include text-overflow;
        width: 100%;

        @include screen-max('sm') {
          width: 80%;
        }
      }

      .main-info-user {
        font-weight: $font-weight-regular;
      }
    }
  }
}
