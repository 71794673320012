#search-results-container {
  min-height: calc(100vh - #{$filter-bar-height - 2px}); // Remove top and bottom border size
  background-color: $sf-white;

  & > div {
    width: 100%;
    min-height: calc(100vh - #{$filter-bar-height - 2px}); // Remove top and bottom border size
    padding: ($filter-bar-height + $padding-half) $padding-half $padding-half;
    background-color: $sf-white;

    @include screen('sm') {
      border-right: 1px solid $sf-gray-light;
      width: 65%;
    }

    @include screen('md') {
      border-right: 1px solid $sf-gray-light;
      width: 55%;
    }

    .react-filter-wrapper {
      margin: $half-unit 0;
      padding-left: $padding-half;
    }

    &.full-width {
      width: 100%;
    }

    .search-pagination {
      text-align: center;
      margin: $base-unit 0;
    }

    .listings-no-results {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: $base-unit * 2;
    }
  }

  /* Google optimize experiments placeholders between listing cards */
  div > .oxp {
    display: inline-block;
  }

  @include screen('sm') {

    .reassurance {
      flex-direction: column;
    }

    .reassurance-message {
      margin: $half-unit;
      padding: 0;
    }
  }
}
