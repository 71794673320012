.payout-methods-content-container {
  background-color: $white;
}

.payout-method-loading {
  position:relative;
  height: 60px;
  width:100%;

  .loading-payouts-copy {
    display:block;
    margin:0 auto;
    text-align:center;
    position:relative;
    line-height: 100px;
  }
}

.getting-paid-container {
  margin: 0 auto;
  max-width: $max-content-width-md;

  padding: 0;
  @include screen('sm') {
    padding: $base-unit;
  }
  @include screen('lg') {
    padding: $half-unit * 3;
  }

  .main-content {
    padding: $base-unit;

    border-bottom: 1px solid $sf-gray-light;
    @include screen('sm') {
      border: 1px solid $sf-gray-light;
    }
  }

  .payout-method-header {
    text-align: center;
    border-bottom: 1px solid $sf-gray-light;
    padding: 20px;

    & > strong,
    & > span.title {
      display: inline-block;
      width: calc(100% - 75px);
    }
  }

  .add-payout-button {
    cursor: pointer;
    padding: 20px;

    .fa {
      font-size: 16px;
      color:$sf-green;
      margin-right: 10px;
    }

    border: 2px solid $sf-gray-light;
    width: 80%;
    margin: $half-unit auto $half-unit;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;

    &:hover {
      box-shadow:  0 0 8px rgba(0,0,0,0.1)
    }
  }

  .payout-account-info {
    padding: 10px 20px;
  }

  .payout-account-mark-as-active {
    color: $sf-green;
    cursor: pointer;
  }
}

.stripe-info-needed {
  background-color: $sf-yellow;
  padding: $base-unit;
  margin-bottom: $base-unit;

  .info-text {
    display: flex;
    color: $sf-black;

    .fa {
      margin-right: $base-unit;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .call-to-action {
    display: block;
    margin: $base-unit auto 0px auto;
  }
}
