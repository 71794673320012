.sf-dropdown-container {
  position:absolute;
  top: 50px;
  right: 0;

  &.notification-menu .dropdown-header {
    border-bottom: 1px solid #ddd;
    cursor: default;
  }

  &.notification-menu,
  &.help-menu {
    .sf-dropdown::before,
    .sf-dropdown::after {
      right: $half-unit * 5;

      @include screen-max('lg') {
        right: $half-unit * 1.5;
      }
    }
  }

  &.user-menu {
    top: $navbar-height;

    .sf-dropdown:before,
    .sf-dropdown:after {
      right: $half-unit * 3.5;
    }

    @include screen-max('sm') {
      left: $base-unit * -1;
      right: auto;
      .sf-dropdown:before,
      .sf-dropdown:after {
        right: auto;
        left: $half-unit * 3.5;
      }
    }

    @include screen-max('sm') {
      left: 0;
    }

    .nav-icon {
      width: 20px;
      height: 20px;
      float: right;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  @include screen-max('sm') {
    @include z-layer-top;
    background: $sf-gray-light;
    position: fixed;
    left: 0 ;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}

.sf-dropdown {
  @include border-bottom-radius($border-radius-sm);

  @include screen('sm') {
    border-radius: $border-radius-base;
  }

  background: #FFF;
  width: 100%;
  position:relative;
  border: 1px solid $sf-gray-base;

  @include screen-max('sm') {
    margin: 0;
  }

  @include screen('sm') {
    &:before {
      content: '';
      position: absolute;
      top: -19px;
      z-index: 1;
      @include arrow-up(10px, #FFF);
    }

    &:after {
      content: '';
      position: absolute;
      top: -20px;
      @include arrow-up(10px, $sf-gray-base);
    }
  }

  .menu-item {
    position: relative;
    display: flex;
    align-items: center;

    & > span {
      flex-grow: 1;
    }

    .notification-count {
      flex-grow: 0;
      font-size: $font-size-sub;
    }

    .fa.fa-angle-right {
      position: absolute;
      right: $padding-base;
    }
  }
}
