$border-card-divider: 1px solid $sf-gray-light;

@mixin card-base {
  overflow: auto;
  position: relative;
  transition:
    height 0.2s ease,
    padding 0.2s ease,
    margin 0.2s ease,
    box-shadow 0.2s ease,
    background 0.2s ease,
    transform 0.2s ease;
  -webkit-transition:
    height 0.2s ease,
    padding 0.2s ease,
    margin 0.2s ease,
    box-shadow 0.2s ease,
    background 0.2s ease,
    transform 0.2s ease;
  -moz-transition:
    height 0.2s ease,
    padding 0.2s ease,
    margin 0.2s ease,
    box-shadow 0.2s ease,
    background 0.2s ease,
    transform 0.2s ease;
}

@mixin card-anchored-base($bg-color: $white) {
  @include card-base;
  @include z-layer-4;
  position: absolute;
  left:0;
  right:0;
  box-shadow: $box-shadow-base;
  background-color: $bg-color;
}

@mixin card-anchored-top($bg-color: $white) {
  @include card-anchored-base($bg-color);
  top: 0;
}

@mixin card-anchored-bottom($bg-color: $white) {
  @include card-anchored-base($bg-color);
  box-shadow: $box-shadow-bottom;
  bottom: 0;
}

@mixin card-anchored-left($bg-color: $white) {
  @include card-anchored-base($bg-color);
  left: 0;
  overflow-y: scroll;
}

@mixin card-anchored-right($bg-color: $white) {
  @include card-anchored-base($bg-color);
  right: 0;
  overflow-y: scroll;
}

@mixin card-flex-header($bg-color: $white) {
  @include card-anchored-top($bg-color);
  @include z-layer-4;

  padding: 0;
}

@mixin card-flex-footer($bg-color: $white) {
  @include card-anchored-bottom($bg-color);
  @include z-layer-4;

  padding: 0;
}

@mixin card-message {
  @include card-base;
  border-radius: $border-radius-lg;
  padding: $padding-half;
  background: #FFF;
}

@mixin card-flat($bg-color: $white) {
  @include card-base;

  margin: auto;
  background: $bg-color;
  border-radius: ($half-unit / 4);
}

@mixin card-main($margin: $base-unit) {
  @include card-flat;

  margin: $margin;
  box-shadow: $box-shadow-base;
}

@mixin card-list-item {
  @include card-main;
  padding: $padding-half;
  margin: 0 auto;
  margin-bottom: ($half-unit / 2);
  max-width: $max-content-width-sm;
  cursor: pointer;
  box-shadow: $box-shadow-faint;
  overflow: hidden;

  &:hover {
    @include animation-grow;
  }
}

@mixin card-modal {
  @include card-base;
  @include z-layer-top;
  background: $white;

  @include screen-max('sm') {
    @include nav-offset;

    position: fixed;
    right:0;
    bottom:0;
    left:0;
  }
}

@mixin card-popover($bg-color: $white, $border-color: $sf-gray-light) {
  @include z-layer-1;

  position: absolute;
  background: $bg-color;
  top: calc(100% + #{ $base-unit * 1.5 });
  padding: $half-unit;
  border-radius: $border-radius-sm;
  border: 1px solid $border-color;

  &:before {
    @include align-center;
    @include arrow-up($half-unit, $border-color);

    content: '';
    position: absolute;
    top: calc(#{ $base-unit * -1 } - 1px);
  }

  &:after {
    @include align-center;
    @include arrow-up($half-unit, $bg-color);

    content: '';
    position: absolute;
    top: #{ $base-unit * -1 };
  }
}

@mixin card-body {
  >div {
    padding: $padding-compact;
  }

  >div:first-child {
    padding-top: $padding-base;
  }

  >div:last-child {
    padding-bottom: $padding-base;
  }
}

@mixin card-info {
  @include card-base;
  background: $sf-info;
  padding: $padding-base;
  color: $white
}


.card-body {
  @include card-body;
}
