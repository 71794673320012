.sf-slider {
  padding: $padding-compact-lg;

  .histogram {
    position: relative;
    height: inherit;

    > .histogram-item {
      position: relative;
      float: left;
      width: 2%;
      height: 100%;

      &:hover {
        > .histogram-bar {
          background: $sf-gray-dark;
        }

        .histogram-popover {
          display: block;
        }
      }

      > .histogram-bar {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: $sf-gray-base;
      }

      .histogram-popover {
        @include card-popover;
        @include align-center;
        @include header-sm;

        z-index: 0;
        box-shadow: $box-shadow-base;
        display: none;
      }
    }

    .histogram-average-price {
      @include subheader;
      @include align-center;

      padding: $half-unit;
      top: calc(100% + #{ $base-unit });

      @include screen('md') {
        @include card-popover;
        top: calc(100% + #{ $base-unit });
      }
    }
  }

  .slider-range {
    @include header-sm;
    @include clearfix;

    padding-top: $base-unit;
  }

  .slider-range-subtitles {
    @include clearfix;
    font-size: $font-size-xs
  }
}
