.archive-listing-modal-title {
  padding-top:20px;
  text-align:center;
}

.archive-listing-modal-text {
  padding: 20px 0;
  text-align:center;
}

.modal-archive-listing-archive-reasons {
  .form-control {
    margin: 10px 0;
  }
}
