.listing-location-address-input {
  width: 100%;
  display: block;
  margin:0;

  input {
    @include input-base;
    width: 100%;

  }
}

.location-search {
  position: relative;
}

.address-results {
  @include border-bottom-radius($border-radius-base);

  position: absolute;
  box-shadow: $box-shadow-faint;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  font-weight: $font-weight-light;
  border: 1px solid $sf-gray-light;

  .address-result-entry, .address-result-header {
    padding: ($padding-quarter * 3) $padding-base;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .address-result-header {
    text-decoration: underline;
  }
  .address-result-entry {
    cursor: pointer;

    .address-details {
      display: inline;
      margin-left: $quarter-unit;
      font-size: $font-size-xs;
      color: $secondary-3-grey;
    }

    & + .address-result-entry {
      border-top: 1px solid $sf-gray-light;
    }

    &.hover {
      background-color: $sf-gray-light;
    }
  }
}

.extended-address-form {
  padding: $half-unit 0;
  margin-top: $half-unit;

  .input-row {
    display: inline-block;
    width: calc(50% - #{$quarter-unit});
    margin-top: $half-unit;
    position: relative;

    &:after {
      top: $base-unit * 1.5;
    }
  }

  input {
    width: 100%;
  }

  .location-address-secondary {
    width: 100%;
  }

  .location-street-number {
    width: 25%;
    margin-right: $half-unit;
  }

  .location-route {
    width: calc(75% - #{$half-unit});
  }

  .location-city, .location-country {
    margin-right: $half-unit;
  }
}
