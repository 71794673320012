.advanced-filter-panel-wrapper {

  .advanced-filter-panel {
    .overlay-filter {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-top: solid 1px #f0f0f0;

      @include screen('sm') {
        flex-direction: row;
      }

      .filter-title {
        display: inline-block;
        font-weight: $font-weight-heavy;
        vertical-align: top;
        padding-right: 20px;
        width: 100%;

        @include screen('sm') {
          width: 140px;
          text-align: right;
        }
        p {
          white-space: break-spaces;
        }
      }
      .filter {
        display: inline-block;
        width: 100%;

        @include screen('sm') {
          width: calc(100% - 140px);
        }
      }

      .duration-number-input {
        margin-right: 16px;
        width: 30%;
      }

      &.sort-by-filter .sort-dropdown-label {
        display: none;
      }

      &.duration-filter {
        align-items: center;

        .ant-input-number-handler-wrap {
          @include z-layer-1;
        }

        .duration-filter-inputs {
          display: flex;
        }
      }

      &.space-features-filter, &.space-type-filter, &.floor-access-filter {
        .filter-title {
          margin-bottom: $half-unit * 3;
        }
      }
    }
  }

  right: 35%;
  left: 0;

  @include screen-max('sm') {
    right: 0;
  }

  // Hide the clear buttons that exist on the size sliders by default
  .clear-button {
    display: none;
  }

  .listing-filter-actions .clear-filters {
    margin-top: $quarter-unit;
    margin-right: $half-unit;
  }
}
