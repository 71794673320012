.add-payment-form {
  padding: 20px 10px 0px 10px;
  width: 90%;

  @include screen-max('sm') {
    padding: 15px 5px 0px 5px;
  }

  .add-method-form {
    .StripeElement {
      padding: $half-unit;
      border: 1px solid $sf-gray-base;
      border-radius: $border-radius-sm;
    }

    .StripeElement--invalid {
      border: 1px solid $sf-red;
    }
  }

  .sf-form-row {
    margin: 0 auto;

    &.address-line-2 {
      margin-top: -15px !important;
      margin-bottom: 0px;
    }
  }

  .formatted-section {
    width: 100%;

    &.payment-method-toggle {
      padding: 0px 20px 0px 20px;
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      vertical-align: top;
      color: $sf-gray-dark;
      width: 50%;
      margin: 0px;

      @include screen-max('sm') {
        font-size: $font-size-sm;
        display: block;
      }

      @include screen-max('xs') {
        font-size: $font-size-xs;
        width: 100%;
      }

      label {
        color: $sf-black;
        font-size: $font-size-base;
        font-weight: 600;
        display: inline-block;
        margin-bottom: $quarter-unit;
      }

      &::after {
        content: '\f107';
        font-family: 'FontAwesome';
        position: absolute;
        right: 30px;
        top: 50%;
        font-size: 18px;
        z-index: 1;
        color: #999999;
        pointer-events: none;
      }

      select {
        width: 100%;
        padding: 7px 30px 7px 10px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 3px;
        background: #FFF;
      }
    }

    .half-size-field {
      width: 50%;
      display: inline-block;

      &.left {
        padding-right: 10px;
      }
      &.right {
        padding-left: 10px;
      }
      .sf-form-row {
        margin: 0px auto;
      }
    }
  }

  .add-method-input {
    padding: 0px ($base-unit * 1.5);
    margin: ($half-unit + $quarter-unit) 0;
  }

  .payment-card-element {
    width: 90%;
    margin: $half-unit auto;
  }
}
