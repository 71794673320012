.background-educational-page {
  background-image:
    linear-gradient(to top, rgba(0,0,0,0.4), transparent),
    url("/assets/images/top_spaces/top_spaces_info_page_main_image.jpg");
  background-size:cover;
  background-position: 50% 0%;

  .main-header {
    @include screen-max('sm') {
      font-size: 36px;
    }
  }
}
.educational-page-section {
  padding: 32px;
  max-width: 968px;
  margin: auto;

  @include screen-max('sm') {
    padding: 16px;
  }

  &.no-icon {
    padding-top: 0px;
  }

  &.bottom {
    text-align: center;
    p {
      font-size: 16px;
    }

    .btn {
      margin-top: 32px;
      width: 120px;
    }
  }

  &.center {
    text-align: center;
  }

  .block {
    display: block;
  }

  .pseudo-link {
    color: $sf-green;
    text-decoration: underline;
  }

  .header-icon {
    width: 120px;
    margin: auto;

    @include screen-max('sm') {
      width: 80px;
    }

    &.large {
      width: 180px;

      @include screen-max('sm') {
        width: 120px;
      }
    }
  }

  h1, h2{
    color: $sf-black;
    text-align: center;
    font-weight: $font-weight-heavy;
  }

  .info-row {
    h3 {
      margin: 32px auto;
      color: $sf-black;

      @include screen-max('sm') {
        margin: 16px auto;
      }
    }

    p {
      color: $sf-gray-dark;
      display: inline-block;
      width: calc(100% - 100px);
      vertical-align: top;
      padding-left: 32px;
      margin: 0;

      b {
        color: $sf-black;
      }

      @include screen-max('sm') {
        padding-left: 16px;
        width: calc(100% - 54px);
        font-size: 12px;
      }
    }

    .info-row-icon {
      width: 92px;
      display: inline-block;
      vertical-align: top;

      @include screen-max('sm') {
        width: 48px;
      }

      &.large {
        width: 180px;
      }
    }
  }
}

.info-footer-icon {
  width: 92px;
  margin: auto;
}
