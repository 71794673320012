#search-results-container > div.full-width {
  .listing-card, .listing-card-skeleton-container {
    @include screen('sm') {
      width: calc(100% / 3);

      .photos-carousel-container, .photo {
        height: 18vw;
      }
    }

    @include screen('md') {
      width: calc(100% / 4);

      .photos-carousel-container, .photo {
        height: 14vw;
      }
    }

    @include screen('xl') {
      width: calc(100% / 6);

      .photos-carousel-container, .photo {
        height: 10vw;
      }
    }
  }
}

/* Suggestions cards on top of /search page */
.suggestions-row {
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  .suggestion::marker {
    content: '';
  }
  a {
    @include screen('sm') {
      width: calc(100% / 3);
      height: 18vw;
    }

    @include screen('md') {
      width: calc(100% / 4);
      height: 14vw;
    }

    @include screen('xl') {
      width: calc(100% / 6);
      height: 10vw;
    }

    @include screen('sm') {
      width: calc(100% / 3);
      height: 10vw;
      display: list-item;
      min-width: 75px;
      min-height: 75px;
    }

    position: relative;
    border-radius: $border-radius-base;
    margin: $half-unit;
    background-size: cover;
    background-position: center;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .content {
      @include z-layer-2;

      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: $padding-quarter;
      left: 0;
      top: 0;

      h3, h6 {
        color: white;
        margin: 0;
      }
    }

    .gradient-overlay {
      @include z-layer-1;

      width: 100%;
      height: 100%;
      background-color: $black;
      -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
      border-radius: $border-radius-base;
    }
  }
}

/**
 * ListingCard component style
 */
.listing-card {
  padding: $half-unit;
  width: 100%;
  display: flex;

  @include screen('sm') {
    width: calc(100% / 2);
    display: inline-block;
  }

  @include screen('xl') {
    width: calc(100% / 3);
  }

  .listing-badges {
    margin-top: $quarter-unit;
  }

  .listing-badge {
    @include z-layer-2;

    pointer-events: none;
    font-weight: $font-weight-semi;
    text-transform: uppercase;
    border-radius: $border-radius-base;
    font-size: $font-size-xs;
    padding: 1px $padding-half;
    display: inline-block;

    &.sponsored i {
      font-size: 10px;
    }

    i {
      margin-right: $quarter-unit;
    }

    &.in-card {
      display: none;
      position: absolute;
      margin: 0;
      top: $half-unit;
      left: $half-unit;

      @include screen('sm') {
        display: block;
      }
    }

    &.sponsored svg {
      margin-right: 0 !important;
    }

    svg {
      font-size: 11px;
      margin-right: $quarter-unit;
    }
  }

  .listing-head-container {
    position: relative;
    width: 33%;

    @include screen('sm') {
      width: auto;
    }

    &::after {
      @include z-layer-1;
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 40px;
      width: 100%;
      border-radius: 4px;
      background: linear-gradient(0deg, rgba(0,0,0,0.3), transparent);
      opacity: 0;
      transition: opacity ease-in .2s;
    }

    &:hover {
      .photos-carousel .slick-dots {
        @include z-layer-2;
        opacity: 1;
      }

      .carousel-navigation .carousel-button {
        opacity: .6;
      }

      &::after {
        opacity: 1;
      }

      .listing-see-on-map {
        opacity: 0.7;
      }
    }

    .listing-see-on-map {
      @include z-layer-2;

      display: none;
      position: absolute;
      top: $half-unit;
      right: $half-unit;
      font-weight: $font-weight-regular;
      cursor: pointer;
      opacity: 0;
      border-radius: $border-radius-base;
      transition: opacity ease .2s;
      margin-right: 0;

      @include screen('sm') {
        display: block;
      }

      &:hover {
        opacity: 1;

        div {
          max-width: 100px;
          margin-left: $quarter-unit;
        }
      }

      div {
        float: right;
        overflow: hidden;
        max-width: 0;
        transition: all ease .2s;
      }
    }

    .photos-carousel-container, .photos-carousel {
      position: relative;
      height: 19vw;
      min-height: 100px;
      border-radius: $border-radius-base;
      overflow: hidden;

      @include screen('sm') {
        height: 18vw;
      }

      @include screen('xl') {
        height: 10vw;
      }


      &.photos-carousel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .slick-dots {
        transition: opacity ease-in .2s;
        opacity: 0;
        margin-bottom: 0;
      }

      .photo {
        width: 100%;
        height: 19vw;
        min-height: 100px;
        background-size: cover;
        background-position: center;
        border-radius: $border-radius-base;

        @include screen('sm') {
          height: 18vw;
        }

        @include screen('xl') {
          height: 10vw;
        }
      }
    }

    .carousel-navigation {
      @include z-layer-1;

      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      color: $sf-white;
      flex-direction: row;
      pointer-events: none;

      .carousel-button {
        height: 100%;
        padding: 0 ($padding-quarter * 3);
        font-size: $font-size-xl;
        cursor: pointer;
        display: flex;
        align-items: center;
        pointer-events: all;
        filter: drop-shadow(0 1px 2px rgba(0, 0, 0, .5));
        opacity: 0;
        transition: all ease-in .2s;

        &:hover {
          opacity: 1;
        }

        &.right-button {
          margin-left: auto;
        }
      }
    }
  }

  .infos {
    width: calc(100% - 33%);
    padding: 0 $padding-half;

    @include screen('sm') {
      width: auto;
      padding: 0;
      margin: $quarter-unit 0 $half-unit;
    }

    a.listing-title {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    .listing-size-location {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    .listing-price {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
  }
}

.marker-card {
  @include z-layer-top;
  padding: 0;
  -webkit-filter: drop-shadow(0 0 10px rgba(0,0,0,.3));
  filter: drop-shadow(0 0 10px rgba(0,0,0,.3));

  .mapboxgl-popup-content {
    padding: 0;

    .listing-card, .listing-card-skeleton-container {
      @include z-layer-top;

      width: 250px;
      height: 250px;
      background-color: $sf-white;
      padding: 0;
      border-radius: $border-radius-base;

      &.listing-card-skeleton-container {
        height: auto;

        .ant-skeleton-content {
          padding: 0 16px;
        }
      }

      .listing-head-container .photos-carousel-container {
        height: 170px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .photo, .photos-carousel {
          height: 170px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .infos {
        padding: 0 $padding-half;
      }
    }
  }
}

/**
 *  ListingCardSkeleton component style
 */
.listing-card-skeleton-container {
  width: 100%;
  display: inline-block;
  padding: $padding-half;

  @include screen('sm') {
    width: calc(100% / 2);
  }

  @include screen('xl') {
    width: calc(100% / 3);
  }

  .listing-card-skeleton {
    display: flex;

    @include screen('sm') {
      display: block;
    }

    .ant-skeleton-header {
      display: block;
      padding-right: 0;
      height: 19vw;
      min-height: 100px;
      margin-bottom: $base-unit;
      width: 33%;

      @include screen('sm') {
        height: 18vw;
        width: 100%;
      }

      @include screen('md') {
        height: 10vw;
      }

      .ant-skeleton-avatar {
        width: 100%;
        margin-right: $base-unit;
        height: 100%;
        border-radius: $border-radius-base;
      }
    }

    .ant-skeleton-content {
      display: block;
      padding-left: $padding-base;
      width: calc(100% - 33%);

      @include screen('sm') {
        padding-left: 0;
        width: 100%;
      }
    }
  }
}
