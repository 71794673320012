
label {
  vertical-align: top;
  position: relative;
}

input[type="radio"] {
  @include input-radio-base;
}

// The following :not selector prevents collisions of this base styling
// with the styles provided by ant design
input[type="checkbox"]:not([class*="ant-"]) {
  @include input-checkbox;
}

.sf-form {
  p {
    padding: 0 ($base-unit * 1.5);
  }

  .sf-form-row-tight {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sf-form-row {
    width: 100%;
    position: relative;
    padding: $base-unit * 1.5;

    &.half-width {
      width: 50%;
      display: inline-block;
    }

    &[data-sf-form-checkbox] {
      .sf-form-checkbox-input, .sf-form-checkbox-label {
        display: table-cell;
      }
    }

    &[data-required="true"],
    &.required {
      .sf-form-header:before {
        @include z-layer-1;

        content: '*';
        color: $sf-red;
        position: absolute;
        left: -10px;
        text-align: center;
        font-size: $font-size-sm;
        font-weight: bold;
      }
      &[data-sf-form-checkbox] {
        .sf-form-checkbox-label:after {
          content: '*';
          color: $sf-red;
        }
      }
    }

    &.form-error {
      .sf-form-header,
      &[data-sf-form-checkbox] {
        color: $sf-red;
        font-weight: bold;

        input {
          color: $sf-red;
        }
      }

      textarea,
      input,
      select {
        border-color: $sf-red !important;
        background: tint($sf-red, 95%);
      }

      .required:after {
        color: $sf-red !important;
      }
    }

    .length-counter {
      @include absolute-bottom-right($base-unit);
      @include z-layer-1;
      @include subheader;

      width: 100%;
      text-align: right;

      &.sf-warning {
        color: $sf-warning;
        font-size: $font-size-xs;
      }

      &.sf-error {
        color: $sf-error;
      }
    }

    .sf-form-row-datepicker .sf-form-row-datepicker-calendar {
      // Capping the datepicker calendar to 360px
      max-width: 360px;
    }

    @include screen('sm') {
      &.sf-form-row-inline {
        .sf-form-title-wrapper {
          display: inline-block;
          width: ($base-unit * 8);
          vertical-align: top;
          text-align: right;
          padding-right: ($base-unit * 1.5);
          margin-bottom: 0;
        }

        .sf-form-errors {
          .error-list {
            margin-top: 0;
          }
        }

        .sf-form-input-wrapper,
        .sf-form-select-wrapper,
        .sf-form-date-of-birth-wrapper,
        .sf-form-duration-wrapper,
        .sf-form-row-datepicker {
          display: inline-block;
          width: calc(100% - #{$base-unit * 8});
        }

        &.sf-form-name {
          .sf-form-input-wrapper {
            width: calc(50% - #{$quarter-unit} - #{$base-unit * 4});
          }
        }
      }
    }

    .sf-form-header {
      @include header-sm;

      position: relative;
    }

    .sf-form-title-wrapper {
      margin-bottom: $base-unit;
    }

    .sf-form-subheader-important,
    .sf-form-subheader {
      @include subheader;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .sf-form-subheader-important {
      font-weight: bold;
      color: $sf-warning;
    }

    .sf-form-errors {
      ul.error-list {
        margin: ($quarter-unit * 3) 0;
        padding: 0 $half-unit;

        .error-list-item {
          list-style-type: none;
          color: $sf-red;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    //New Form Styles
    .sf-form-textarea {
      width: 100%;

      textarea {
        width: inherit;
      }
    }

    .sf-form-input-wrapper {
      position: relative;

      .sf-form-input {
        width: 100%;
        font-size: $font-size-base;

        &.padded-left {
          padding-left: $base-unit * 2.5;
        }
        &.padded-right {
          padding-right: $base-unit * 2.5;
        }
      }

      .prefix {
        @include subheader;
        @include align-middle;
        @include z-layer-1;

        left: $half-unit;
        max-width: $base-unit * 2.5;
        font-size: $font-size-sm;
      }

      .postfix {
        @include subheader;
        @include align-middle;
        @include z-layer-1;

        text-align: right;
        right: $half-unit;
        max-width: $base-unit * 2.5;
        font-size: $font-size-sm;
      }
    }

    .sf-form-select-wrapper,
    .sf-form-date-of-birth-wrapper {
      @include input-select-base;
      @include body-text;

      padding: ($half-unit / 2);
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 100%;
    }

    .sf-form-date-of-birth-wrapper {
      width: calc(100% / 3);
    }
  }
  .checkbox-website-requirement {
    padding-left: 150px;
    @include screen-max('sm') {
      padding-left: $padding-lg;
    }
  }
  .btn-submit-form {
    position: relative;
    overflow: hidden;
    margin-bottom: ($base-unit * 1.5);

    .btn-content {
      position: relative;
      bottom: 0px;
      transition: bottom 0.1s ease;

      &.submitting {
        bottom: 80px;
      }
    }

    .loading-dots {
      top: 100%;
      transition: top 0.1s ease;

      .dot {
        background: #FFF;
      }

      &.submitting {
        top: calc(50% - 6px);
      }
    }
  }
}
