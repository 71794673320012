.heading-star-rating-wrapper {
  position: absolute;
  top: 10px;
  float: left;
  line-height: initial;
  line-height: normal;
  transition: top 0.3s ease;
  cursor: pointer;

  @include screen-max('sm') {
    top: 5px;
  }

  @include screen('sm') {
    &.anchored {
      top: -50px;
    }
  }

  .review-count {
    top: -20px;
    display: block;
  }
}
.sf-listing-review-section {
  transition: max-height 0.5s ease-out;
  display: block;
  &.closed {
    max-height: 0;
    overflow: hidden;
  }
  &.open {
    max-height: 1200px;
    overflow: auto;
  }
}
.sf-listing-review {
  padding: 0;
  margin-bottom: 15px;
  position: relative;
  @include screen-max('sm') {
    width: 100%;
  }
  .review-image-section {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    @include screen-max('sm') {
      width: 40px;
      height: 40px;
    }
    .review-image {
      width: inherit;
      height: inherit;
      float: left;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      &.profile-image {
        border-radius: 50%;
      }
    }
  }
  .clickable {
    cursor: pointer;
    hr {
      margin: 0;
      width: calc(100% - 15px);
      &.overall {
        margin-bottom: 10px;
      }
    }
  }
  .review-content {
    vertical-align: top;
    display: inline-block;
    width: calc(100% - 80px);
    @include screen-max('sm') {
      width: 100%;
      padding-bottom: 10px;
    }
    .mobile-header {
      margin-bottom: 5px;
      .review-image-section {
        display: inline-block !important;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        vertical-align: top;
      }
      .mobile-header-text {
        display: inline-block;
        .review-title {
          height: 30px;
          line-height: 30px;
        }
      }
    }
    .review-date {
      position: absolute;
      top: 0;
      right: 15px;
      font-size: $font-size-xs;
    }
    .review-title {
      cursor: pointer;
      &:hover {
        color: $sf-green;
      }
    }
    .rating-text {
      padding: 5px 0px;
      @include screen-max('sm') {
        font-size: 12px;
      }
    }
  }
  .review-expand-label {
    position: relative;
    bottom: 0;
    right: 15px;
    text-align: right;
    font-size: $font-size-xs;
    cursor: pointer;
    .review-expand-arrow {
      font-size: $font-size-xs;
    }
  }
}
.see-more-reviews {
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  @include screen-max('sm') {
    padding-bottom: 15px;
  }
}
.star-rating-field {
  width: 100%;
  display: inline-block;
  padding-bottom: 0px;
}
.star-rating-title {
  display: inline-block;
  font-weight: 600;
  color: $sf-gray-dark;
  font-size: $font-size-sm;
  min-width: 240px;

  &.hide-xs {
    @include screen-max('xs') {
      display: none;
    }
  }

  &.show-xs {
    @include screen('xs') {
      display: inline-block;
    }
  }

  @include screen-max('md') {
    font-size: $font-size-sm;
    width: 185px;
    min-width: 185px;
  }
  @include screen-max('sm') {
    min-width: 170px;
    width: 170px;
  }
  @include screen-max('xs') {
    min-width: 170px;
    width: 170px;
  }
}
.star-rating-section {
  display: inline-block;
  line-height: initial;
  line-height: normal;
  font-size: $font-size-sm;
  float: right;

  &.header {
    display: block;
  }
  @include screen-max('sm') {
    font-size: $font-size-base;
    float: right;
  }
  .rating-star {
    position: relative;
    color: rgba(0, 0, 0, 0.1);
    margin-right: 2px;
    font-size: 17px;

    @include screen-max('md') {
      font-size: 14px;
    }

    &.full {
      color: #F2D55D;
    }

    &.half-filled::after {
      content: '\f089';
      font-family: 'FontAwesome';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      color: #F2D55D;
    }
  }
  .review-count {
    vertical-align: top;
  }
}

.sf-large-star {
  font-size: 25px;
  @include screen-max('sm') {
    font-size: 20px;
  }
}
.review-count-wrapper {
  display: inline-block;
  width: calc(100% - 135px);
  position: relative;
  top: 3px;

  @include screen-max('md') {
    width: calc(100% - 90px);
  }

  @include text-overflow;

}
.star-rating-review-count {
  display: inline-block;
  font-size: $font-size-sm;
}
.no-reviews {
  text-align: center;
  color: $sf-black;
}
.review-form {
  padding-top: 0;
}
