.sf-event-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  .event-details-row {
    clear: both;

    .event-details-label {
      float: left;
    }

    .event-details-value {
      float: right;
    }
  }

  .checkout-listing-image {
    border-radius: 3px;
    overflow: hidden;
    box-shadow: $box-shadow-base;
    margin-bottom: $half-unit;
    width: 100%;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include screen('sm') {
      width: 80%;
    }
  }

  .inquiry-info {
    overflow: auto;
    width: 80%;
    padding: 0;
    border-top: 0;

    @media(max-width:  991px) {
      width: 100%
    }

    .dates {
      padding: $padding-base 0;
      margin-top: $half-unit;
      border-top: 1px solid $sf-gray-light;
      border-bottom: 1px solid $sf-gray-light;

      .move-in, .move-out {
        color: $sf-gray-dark;
        font-weight: $font-weight-light;

        .title {
          margin-bottom: $half-unit;
        }

        &.move-in {
          margin-right: $base-unit * 3;
        }
      }

      &:after {
        display: block;
        content: "";
        clear: both;
      }

      .date {
        color: $black;
        font-weight: $font-weight-regular;
        font-size: $font-size-lg;
      }
    }

    .invoice-details {
      padding: $padding-base 0;

      .invoice-details-row {
        font-size: $font-size-sm;
        margin-bottom: $half-unit;
        color: $sf-gray-dark;

        .invoice-details-label {
          display: inline-block;
        }
      }

      .invoice-details-discount-warning {
        color: $sf-orange;
        font-size: $font-size-xs;
        margin-top: $base-unit;
        text-align: center;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .invoice-details-fees-warning {
        color: $sf-gray-dark;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        margin-top: $base-unit;
      }

      .invoice-details-total {
        font-weight: $font-weight-regular;

        .invoice-details-row {
          font-size: $font-size-md;
          color: $black;
          margin-bottom: 0;
        }
      }
    }
  }
}
.event-details-total-row {
  position: relative;
  font-weight: bold;
  top: 6px;
}
