.selector-group {
  white-space: normal;
  .sf-form-tag {
    margin-top: 0;
    margin-bottom: 24px;
    .ant-checkbox-wrapper {
      .ant-checkbox {
        float: left;
      }

      & > span {
        &:not(.ant-checkbox) {
          margin-top: -1.5px;
          padding-left: 22px;
          display: block;
          min-height: 36px;
        }
      }
    }
  }
}
