.sf-payment {
  @include card-main($margin: $base-unit auto);

  &.review-message {
    cursor: pointer;
    &:hover {
      @include animation-grow;
    }
  }

  .sf-payment-body {
    @include card-body;

    .sf-payment-content {
      text-align: center;
      padding-top: 0px;

      .star-rating-section {
        float: none;
      }
    }
  }

  .sf-payment-date {
    @include timestamp;
  }

  .sf-payment-status {
    @include header-lg;
    text-align: center;
  }

  .sf-payment-price {
    @include header-xl;
    text-align: center;
  }

  .sf-payment-actions {
    text-align: center;
  }

  .sf-payment-copy {
    background: $sf-gray-light;
    padding: $half-unit;

    .sf-payment-copy-header {
      @include header-md;
      margin-bottom: $half-unit;
    }
  }
}
