/*
    ∙ Measurement_Units
    ∙ Padding
    ∙ Border_Radius
    ∙ Fonts
    ∙ Assets
    ∙ Screen_Sizes
    ∙ Box_Shadows
    ∙ Colors
 */

/* ********************* */
/*   Measurement_Units   */
/* ********************* */

$base-unit: 16px;
$double-unit: 32px;
$half-unit: ($base-unit / 2);
$quarter-unit: ($base-unit / 4);

$max-content-width-xs: ($base-unit * 40); // = 640px;
$max-content-width-sm: ($base-unit * 48); // = 768px;
$max-content-width-md: ($base-unit * 64); // = 1024px;
$max-content-width-lg: ($base-unit * 75); // = 1200px;

$navbar-height: $base-unit * 4;

$messaging-panel-height-desktop: 92px;
$messaging-panel-height-mobile: 51px;

/* *********** */
/*   Padding   */
/* *********** */

$padding-base: $base-unit;
$padding-double: $double-unit;
$padding-half: $half-unit;
$padding-quarter: $quarter-unit;
$padding-lg: $base-unit * 1.5;
$padding-xl: $base-unit * 3;
$padding-compact: $half-unit $base-unit;
$padding-compact-lg: $base-unit ($base-unit * 1.5);


/* ***************** */
/*   Border_Radius   */
/* ***************** */

$border-radius-sm: $half-unit / 4; // = 2px
$border-radius-base: $half-unit / 2; // = 4px;
$border-radius-lg: $half-unit;
$border-radius-circular: 50%;
$border-radius-label: $base-unit * 2;


/* ********* */
/*   Fonts   */
/* ********* */

$font-size: (
  'xxxl': 36px,
  'xxl': $base-unit * 2,
  'xl': $base-unit * 1.5,
  'lg': 18px,
  'md': $base-unit,
  'sm': 14px,
  'xs': 12px,
  'sub': 10px,
);

$font-size-xxxl: map-get($font-size, 'xxxl');
$font-size-xxl: map-get($font-size, 'xxl');
$font-size-xl: map-get($font-size, 'xl');
$font-size-lg: map-get($font-size, 'lg');
$font-size-md: map-get($font-size, 'md');
$font-size-sm: map-get($font-size, 'sm');
$font-size-xs: map-get($font-size, 'xs');
$font-size-sub: map-get($font-size, 'sub');

$font-size-base: $font-size-sm;

$font-weight-heavy: 600;
$font-weight-bold: 500;
$font-weight-semi: 400;
$font-weight-regular: 300;
$font-weight-medium: 200;
$font-weight-light: 100;


/* ********** */
/*   Assets   */
/* ********** */

$images-url: "/assets/images/";
$background-images-url: "/assets/images/backgrounds";

$banner-heights: (
  'lg': 100vh,
  'md': 75vh,
  'sm': 50vh,
  'xs': 25vh
);


/******************/
/*  Screen_Sizes  */
/******************/

$screen-breakpoint: (
  'xl': 1400px,
  'lg': 1200px,
  'md': 992px,
  'sm': 768px,
  'ss': 545px,
  'xs': 321px
);


/* *************** */
/*   Box_Shadows   */
/* *************** */

$box-shadow-base: 0 2px 6px rgba(0,0,0,0.2);
$box-shadow-hover: 0 4px 8px rgba(0,0,0,0.4);
$box-shadow-bottom: 0 -2px 6px rgba(0,0,0,0.2);
$box-shadow-right: 2px 0px 6px rgba(0,0,0,0.2);
$box-shadow-left: -2px 0px 6px rgba(0,0,0,0.2);
$box-shadow-faint: 0 1px 2px rgba(0,0,0,0.1);
$box-shadow-line: 0px 1px 0px rgba(0,0,0,0.05);
$box-shadow-line-top: 0px -1px 0px rgba(0,0,0,0.05);
$box-shadow-line-left: -1px 0px 0px rgba(0,0,0,0.05);
$box-shadow-line-right: 1px 0px 0px rgba(0,0,0,0.05);
$box-shadow-invisible: 0 0 0 transparent;

/* ********** */
/*   Colors   */
/* ********** */

$white: rgb(255, 255, 255); //#FFFFFF
$black: rgb(51, 51, 51);    //#333333

$sf-green: rgb(87, 212, 151);  //#57D497
$sf-yellow: rgb(255, 219, 0);   //#FFDB00
$sf-blue: rgb(71, 159, 252);  //#479FFC
$sf-black: rgb(74, 73, 75);    //#4A494B
$sf-red: rgb(229, 43, 33);   //#E52B21
$sf-orange: rgb(240, 152, 29);  //#F0981D

$sf-gray-dark: rgb(153, 153, 153); //#999999
$sf-gray-base: rgb(221, 221, 221); //#DDDDDD
$sf-gray-light: rgb(240, 240, 240); //#F0F0F0
$sf-white: rgb(255, 255, 255); //#FFFFFF
$sf-disabled: rgba(255,255,255,0.60);

// Tints and Shades
$sf-green-darker: shade($sf-green, 30%);
$sf-green-dark: shade($sf-green, 20%);
$sf-green-light: tint($sf-green, 10%);
$sf-green-lighter: tint($sf-green, 50%);

$sf-red-light: tint($sf-red, 25%);

$off-white: shade($white, 4%);

$sf-info: $sf-blue;
$sf-success: $sf-green;
$sf-pending: $sf-yellow;
$sf-warning: $sf-orange;
$sf-error: $sf-red;

$primary-yellow: #FFCE00; //RGB: 255, 219, 0
$primary-green: #57D497; //RGB: 87, 212, 151
$primary-blue: #419DFF; //RGB: 65, 157, 255
$primary-light-blue: #F2F7FF;
$primary-dark-blue: #327ED0;
$primary-darkest-blue: #1A4E85; //RGB: 26, 78, 133
$primary-red: #e52b21;
$primary-red-2: lighten(#e52b21, 5%);
$primary-black: #666666;

$secondary-1-grey: #252528;
$secondary-2-grey: #4A494B;
$secondary-3-grey: #999999;
$secondary-4-grey: #DDDDDD;
$secondary-5-grey: #EEEEEE;
$secondary-6-grey: #FAFAFA;

$primary-background-color: $secondary-4-grey;

$brown-pro: 'brown-pro', Tahoma, Arial, sans-serif;
