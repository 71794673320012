.sf-form-name {
  .sf-form-input-wrapper {
    display: inline-block;
    width: calc(50% - #{$quarter-unit});
    margin-top: 0;
    position: relative;

    &.first-name {
      margin-right: $quarter-unit;
    }

    &.last-name {
      margin-left: $quarter-unit;
    }
  }
}
