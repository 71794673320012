.daily-price-popover {
  /* Fixes antd setting pointer-events to none */
  pointer-events: auto !important;
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
    .ant-slider.price-slider {
      margin-left: 0;
      margin-right: 0;
      width: 250px;
    }
  }
}

.price-slider-container {
  @include clearfix;

  .price-subtitle {
    font-weight: $font-weight-heavy;
  }

  .clear-button {
    padding: 0;
    margin-top: $half-unit;
  };
}

