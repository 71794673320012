.sf-form-checkbox {
  .sf-form-checkbox-input {
    input {
      &:hover:not(:checked) {
        border-color: $sf-green;
        background: white;
        transition: border-color 0.2s ease;
      }
      &[checked] {
        border-color: $sf-green;
        background-color: $sf-green;
        transition: border-color 0.2s ease, background-color 0.3s ease;
      }
    }
  }
}
