body, html {
  width: 100%;
  min-height: 100vh;
  // overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch; // better scrolling on iOS webkit devices
  -ms-touch-action: manipulation; /* IE10  */
  touch-action: manipulation;     /* IE11+ */
  scroll-padding-top: ($base-unit * 4) + $base-unit; // Navbar height + some addition spacing
  scroll-behavior: smooth;
}

html {
  margin: 0;
  padding: 0;
}

body {
  background-color: #ffffff;
  color: $sf-black;
  font-family: $brown-pro !important;
  font-weight: 100;
  margin: 0;
  padding: $navbar-height 0 0;
  line-height: 1.1;

  &.has-header-banner {
    padding-top: $navbar-height * 2;
  }

  &.scroll-block {
    overflow: hidden;
  }
}

body .app-container {

  position: relative;
  width: 100%;
  min-height: calc(100vh - #{$navbar-height});
  background: $sf-gray-light;
  -webkit-overflow-scrolling: touch; // better scrolling on iOS webkit devices
  -ms-touch-action: manipulation; /* IE10  */
  touch-action: manipulation;     /* IE11+ */

}

.view-content-container {
  background: $sf-gray-light;

  @include screen-max('sm') {
    padding: 40px 0 0 0;
  }
}

p {
  @include body-text;
}

input[type="text"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

ol {
  @include screen-max('sm') {
    padding-left: $base-unit;
    margin-left: $half-unit;
  }

  li {
    padding-bottom: $half-unit;
  }
}

input:not(.MuiAutocomplete-input):not(.ant-checkbox-input):not(.ant-input-number-input):not(.listing-location-address-input),
textarea,
select,
.pseudo-input {
  @include input-base;

  @include placeholder {
    font-family: $brown-pro;
    color: $sf-gray-dark;
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;
  }

  &[disabled]:not(.disabled-display-normal),
  &[readonly]:not(.readonly-display-normal) {
    background-color: $sf-gray-base;
    border-color: $sf-gray-base;
  }
}

textarea {
  resize: none;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.ant-picker-input > input {
  position: relative !important;
  display: inline-block !important;
  width: 100% !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
  line-height: 1.5715 !important;
  background-image: none !important;
  border-radius: 2px !important;
  transition: all 0.3s !important;
  flex: auto !important;
  min-width: 1px !important;
  height: auto !important;
  padding: 0 !important;
  background: transparent !important;
  border: 0 !important;
}
