.booking-request-modal-title {
  padding-top:20px;
  text-align:center;
}

.booking-request-modal-text {
  padding: 20px 0;
  text-align:center;
}

.modal-booking-request-rejection-reasons {
  margin-bottom: 10px;
}

.btn-confirm-rejection {
  margin: 10px auto;
}
