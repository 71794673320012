.listing-inline {
  padding: $padding-lg 0;
  border-bottom: 1px solid $sf-gray-light;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: $max-content-width-sm) {
    flex-direction: row;
  }


  font-family: $brown-pro;

  > .listing-image {
    position: relative;
    flex-grow: 1;
    height: $base-unit * 5;
    margin: 0 $base-unit;
    background-size: cover;

    @media (min-width: $max-content-width-sm) {
      flex-grow: initial;
      min-width: $base-unit * 15;
      height: auto;
    }

    overflow: hidden;
  }

  > .listing-info {
    flex-grow: 1;
    padding: $base-unit;

    @media (min-width: $max-content-width-md) {
      padding: 0;
    }

    > :not(:first-child) {
      margin-top: $half-unit + $quarter-unit;
      font-size: $font-size-xs;

      @media (min-width: $max-content-width-md) {
        font-size: $font-size-sm;
      }

      margin-bottom: 0;
    }

    > h2 {
      font-weight: $font-weight-regular;
      margin: 0;
    }

    > .listing-updated-at {
      font-weight: $font-weight-light;
      color: $sf-gray-dark;
    }

    > a {
      padding: $padding-base ($padding-base * 2);
      margin-top: $base-unit;
      font-weight: $font-weight-regular;
    }

    > .listing-status {
      font-weight: $font-weight-regular;

      &.yellow, .yellow {
        color: #dbb123;
      }

      &.green, .green {
        color: $sf-green;
      }

      &.blue, .blue {
        color: $sf-blue;
      }

      &.orange, .orange {
        color: $sf-orange;
      }

      &.red, .red {
        color: $sf-red;
      }
    }

    > .actions {
      display: flex;
      flex-direction: row;

      .btn-boost .btn-modal-btn {
        height: 100%;
        margin-right: $half-unit;
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $half-unit;
        @media (min-width: $max-content-width-xs) {
          padding: $half-unit ($base-unit + $half-unit);
          font-size: $font-size-sm;
        }

        font-weight: $font-weight-regular;
        font-size: $font-size-xs;

        @media (min-width: $max-content-width-sm) {
          width: auto;
        }

        &.btn-boost {
          background-color: #ffcf2d;
          border-color: #f9db20;

          &:hover {
            background-color: $sf-yellow;
          }

          &:active {
            background-color: #dbb123;
          }
        }
      }
    }
  }
}
