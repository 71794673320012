.payment-history {
  margin: 0 auto;
  max-width: $max-content-width-sm;

  .main-content {
    padding: $half-unit;

    @include screen('sm') {
      padding: $base-unit;
    }

    .top-menu {
      margin: $half-unit auto;
      font-size: $font-size-xs;
      border: 1px solid $sf-gray-base;
      border-radius: 2px;

      .menu-item {
        display: inline-block;
        width: 50%;
        text-align: center;
        padding: $base-unit 0;
        vertical-align: middle;
        font-weight: 400;

        &.clickable {
          cursor: pointer;
          color: $sf-gray-dark;

          &.selected {
            background-color: $sf-green;
            color: #FFF;
          }
        }
        &.half {
          width: 50%;
        }
        &.whole {
          width: 100%;
        }

        .tab-title {
          font-size: $font-size-xl;

          @include screen-max('sm') {
            font-size: $font-size-lg;
          }

          @include screen-max('xs') {
            font-size: $font-size-sm;
          }
        }

        .tab-sub {
          font-size: $font-size-lg;

          @include screen-max('sm') {
            font-size: $font-size-md;
          }

          @include screen-max('xs') {
            font-size: $font-size-sm
          }
        }

        .numeric-value {
          font-size: $font-size-xl;

          @include screen-max('sm') {
            font-size: $font-size-lg;
          }

          @include screen-max('xs') {
            font-size: $font-size-md;
          }
        }

        .title {
          font-size: $font-size-lg;

          @include screen-max('sm') {
            font-size: $font-size-md;
          }

          @include screen-max('xs') {
            font-size: $font-size-sm
          }
        }
      }
    }
  }

  .sf-pagination.pagination-container {
    border-top: 1px solid $sf-gray-base;
    padding: $padding-base * 2 $padding-base;
    text-align: center;

    @include screen('sm') {
      border-top: 0;
    }

    .listing-pagebar {
      margin-top: 0;
    }
  }
}
