.sf-message-adjudicate-actions {
  .sf-message-redact {
    .sf-message-redact-mode {
      width: 100%;
      margin-top: $half-unit;
    }

    .sf-message-redactable {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding-top: $base-unit;
      font-size: $font-size-sm;

      div {
        color: $sf-black;
        cursor: pointer;
        display: inline-block;
        position: relative;

        .entity-icon {
          width: $base-unit;
          height: $base-unit;
          position: absolute;
          font-size: $font-size-sub;
          background-color: $sf-yellow;
          left: -$half-unit;
          top: -$half-unit;
          border-radius: $border-radius-circular;
          pointer-events: none;
          border: 1px solid $sf-black;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: transparent;
          }
        }

        &.line-break {
          cursor: default;
          display: block;
          height: $base-unit;
        }

        &:not(.line-break):hover {
          background-color: tint($sf-green, 70%);
        }

        &.selected {
          background-color: $sf-green;
          color: $sf-white;

          &:hover {
            background-color: tint($sf-green, 30%);
          }
        }

        &.unselect {
          background-color: $sf-gray-light;
          border-radius: 0;
        }

        &.redacted {
          text-decoration: line-through;
          background-color: tint($sf-red, 85%);
        }

        &.highlight {
          border-bottom: 2px dashed $sf-black;
        }
      }
    }

    .sf-message-redact-info {
      margin-top: $base-unit;
      color: $sf-red;
      font-size: $font-size-xs;
    }

    .btn-container {
      overflow: hidden;
      height: 0;
      margin-top: 0;
      transition: all .2s ease;

      &.visible {
        height: 35px;
        margin-top: $half-unit;
      }

      .btn {
        width: auto;
        height: 100%;
        padding: $half-unit $base-unit;
        font-size: $font-size-sm;
        margin-left: 0;

        &.disabled {
          background-color: $sf-gray-base;
          border-color: $sf-gray-base;
          cursor: default;
        }
      }
    }
  }

  .sf-message-adjudicate-buttons {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin: $half-unit 0;
    padding: 10px 0 0;

    .btn {
      &.redact-mode-btn {
        background-color: $sf-gray-base;
        border-color: $sf-gray-light;
      }

      width: calc(50% - 8px);
      height: auto;
      padding: 5px;
      font-size: $font-size-sm;
      color: #fff;
    }
  }
}
