.search {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  max-width: 680px;
  margin: 10px auto;
  position:relative;
  z-index: 4;
  text-align: center;

  @include screen-max('sm') {
    padding: 10px;
    margin: 5px auto;
  }
  .listing-event-type-input {
    position: relative;
    margin-top: 15px;

    @include screen-max('sm') {
      float:none;
      width: 100%;
    }

    select {
      height: 60px;
      background-color: #fff;
      border: none;
      font-size: 20px;
      font-weight: 500;
      border-radius: 4px;
      padding: 20px;
      padding-right: 40px;
      width: 100%;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      @include text-overflow;

      @include screen-max('sm') {
        height: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 12px;
      }
    }
    &:after {
      content: '﹀';
      position: absolute;
      top: 25px;
      right: 20px;
      pointer-events: none;
      font-weight: 500;
      font-size: 18px;
      @include screen-max('sm') {
        top: 20px;
        font-size: 12px;
      }
    }
  }

  .search-input {
    display:inline-block;
    width: 100%;

    @include screen-max('sm') {
      float:none;
      width: calc(100% - 50px);
      width: 100%;
    }

    input {
      border: none;
      padding:20px;
      height: 60px;
      width: 100%;
      outline:none;
      -webkit-outline:none;
      font-size: 20px;
      position: relative;
      border-radius: 4px;

      @include text-overflow;

      @include screen-max('sm') {
        height: 50px;
        font-size: 12px;
      }

      &.results {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }

  .search-results {
    @include border-bottom-radius($border-radius-lg);

    position:absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #FFF;
    z-index: 30;

    @include screen-max('sm') {
      top: 40px;
    }
    .search-result-header, .search-result-item {
      width:100%;
      padding: ($padding-quarter * 3) $padding-base;
      font-size: 14px;
      color: $secondary-1-grey;
      border-top: 1px solid $sf-gray-light;
    }
    .search-result-header {
      text-decoration: underline;
    }
    .search-result-item {
      cursor: pointer;

      &.active, &:hover {
        background: $sf-gray-light;
      }

      .place-subtext {
        font-size: $font-size-xs;
        color: $secondary-3-grey;
        margin-left: 2px;
      }
    }
  }

  .btn-submit-search {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    margin-top: 40px;
    font-size: 20px;
    padding: 20px;
    display: block;

    @include screen-max('sm') {
      height: 50px;
      text-align:center;
      line-height: 28px;
      padding:10px;
    }

    @include screen-max('xs') {
      width: 100%;
      font-size: 14px;
      margin-top: 20px;
    }
  }
}

//Condensed nav search
.nav-search {
  position:relative;
  width:100%;
  top: 14px;

  &.light {
    .search {
      .search-input input {
        background: rgba(255,255,255,0.2);
        color: #FFF;

        &:focus {
          background: rgba(255,255,255,0.3);
        }

        &::-webkit-input-placeholder {
           color: rgba(255,255,255,0.7);
        }

        &:-moz-placeholder {
           color: rgba(255,255,255,0.7);
        }

        &::-moz-placeholder {
           color: rgba(255,255,255,0.7);
        }

        &:-ms-input-placeholder {
           color: rgba(255,255,255,0.7);
        }
      }

      .btn-submit-search {
        color: rgba(255,255,255,0.7);
        background: rgba(255,255,255,0.2);
      }
    }

  }

  .search {
    margin: 0 auto;
    padding: 0px;
    background: none;
    min-width: 200px;
    max-width: 400px;

    @include screen-max('sm') {
      width: calc(100% - 130px);
    }

    .search-input {
      width: calc(100% - 40px);
      display: inline-block;

      input {
        appearance:none;
        -moz-appearance: none;
        -webkit-appearance: none;
        height: 40px;
        background: rgba(230,230,230,0.3);
        border-right:none;
        padding: 10px;
        font-size: 14px;

        @include border-right-radius(0px);

        @include screen-max('sm') {
          font-size: 11px;
        }

        &::-webkit-input-placeholder {
           color: rgba(0,0,0,0.4);
        }

        &:-moz-placeholder {
           color: rgba(0,0,0,0.4);
        }

        &::-moz-placeholder {
           color: rgba(0,0,0,0.4);
        }

        &:-ms-input-placeholder {
           color: rgba(0,0,0,0.4);
        }

        &.results {
          border-bottom: none;
        }
      }
    }

    .search-results {
      top: 39px;
      left: 0px;
      width: calc(100% - 40px);
    }

    .btn-submit-search {
      @include border-left-radius(0px);
      float:right;
      width: 40px;
      height: 40px;
      line-height: 38px;
      font-size: 16px;
      text-align:center;
      padding:0px;
      color: rgba(0,0,0,0.4);
      margin-top: 0px;
      background: rgba(230,230,230,0.3);

      &:hover {
        color: #FFF;
        background: $primary-green;
      }
    }
  }
}
