.fee-panel {
  clear: both;

  .fee-title {
    @include header-md;

    @include screen-max('xs') {
      padding-bottom: 0px;
    }
  }

  .fee-line {
    position: relative;
    padding-left: $base-unit * 1.5;

    .fee-key {
      display: inline-block;
      font-weight: 500;
      color: $sf-gray-dark;
      min-width: 120px;

      @include screen-max('sm') {
        min-width: 120px;
        line-height: 22px;
      }

      @include screen-max('xs') {
        font-size: $font-size-sm;
      }
    }

    .fee-value {
      display: inline-block;
      font-weight: 600;
      float: right;

      &.discount {
        color: $sf-green;
      }

      @include screen-max('xs') {
        font-size: $font-size-sm;
      }
    }

    .fa {
      position: absolute;
      left: 0px;
      top: 3px;
      color: $sf-blue;
    }
  }
}
