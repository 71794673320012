.locations-container {
  background: $sf-white;

  .content {
    max-width: $max-content-width-md;
    margin: auto;
    padding: $base-unit;

    @include screen('md') {
      padding-top: 2 * $base-unit;
      padding-bottom: 2 * $base-unit;
    }

    .header {
      margin-bottom: 2 * $base-unit;

      h1 {
        margin-bottom: 0;
      }
    }

    h2 {
      border-bottom: 1px solid $sf-gray-dark;
      padding-bottom: $half-unit;
    }

    ul.locations-list {
      padding: 0;
      list-style: none;

      @include screen('sm') {
        max-width: 80%;
      }

      @include screen('md') {
        max-width: 90%;
      }

      li.location-el {
        margin-bottom: $half-unit;
        font-weight: $font-weight-regular;
        display: inline-block;
        width: 33%;

        @include screen('md') {
          width: 25%;
        }
      }
    }
  }
}
