.sf-inquiry-info {
  position: relative;

  .sf-inquiry-info-container {
    padding: 0;

    .listing-image-wrapper {
      text-align: center;

      .listing-image {
        height: 120px;
        width: 100%;
        margin-bottom: $base-unit;
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
      }
    }

    .main-info-listing {
      width: 100%;
      font-weight: $font-weight-regular;
      text-shadow: 0px 1px $quarter-unit #000;

      a {
        color: $white;
      }
    }

    .inquiry-info-main {
      width: 100% !important;
      height: auto;
      margin: $half-unit 0 0;
      @include screen('sm') {
        padding: 0 $padding-base;
      }

      .sf-btn {
        text-align: right;
      }

      .sf-btn-secondary--gray {
        padding: 5px;
        width: 100%;
        display: block;
        margin: $half-unit auto 0;
      }

      .inquiry-status {
        text-align: center;

        .status {
          color: $sf-blue;
          font-weight: $font-weight-regular;
          padding: $padding-half 0;
          padding-left: $padding-base;
          @include screen('sm') {
            padding: 0;
          }
        }
      }
    }

    .last-message {
      display: none;
    }
  }
}

.inquiry-info {
  padding: 0 $padding-base $padding-base;
  border-top: 1px solid $sf-gray-base;
  border-bottom: 1px solid $sf-gray-base;

  @include screen('sm') {
    padding: 0 $padding-half $padding-base;
  }

  .dates {
    padding: $padding-base 0;
    border-bottom: 1px solid $sf-gray-light;

    .dates-wrapper, .times-wrapper {
      &.times-wrapper {
        margin-top: $half-unit;
      }

      &::after {
        display: block;
        content: '';
        clear: both;
      }
    }

    .move-in, .move-out {
      color: $sf-gray-dark;
      font-weight: $font-weight-semi;

      .title {
        margin-bottom: $quarter-unit;
      }

      &.move-out .title, &.move-out .date {
        text-align: right;
      }
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }

    .date {
      color: $black;
      font-weight: $font-weight-regular;
      font-size: $font-size-lg;
    }
  }

  .invoice-info {
    .invoice-details {
      padding: $padding-base 0 0;

      .renter-details-header {
        margin-top: 20px;
      }

      .details-header {
        margin-bottom: $base-unit;
        font-weight: $font-weight-regular;
        font-size: $font-size-md;
      }

      .invoice-details-row {
        font-size: $font-size-sm;
        margin-bottom: $half-unit;
        color: $sf-gray-dark;

        .invoice-details-label {
          display: inline-block;
          font-weight: $font-weight-regular;
        }
      }

      .invoice-details-total:nth-of-type(2) {
        border-bottom: 1px solid $sf-gray-light;
        padding-bottom: $base-unit;
      }
    }

    .owner-total-row {
      margin-top: 13px;
      font-size: $font-size-lg;
    }
    .renter-total-row {
      margin-top: 15px;
      font-size: $font-size-sm;
    }
    .renter-only-total-row {
      margin-top: 15px;
      font-size: $font-size-lg;
    }

    .invoice-details-discount-warning {
      color: $sf-orange;
      font-size: $font-size-xs;
      margin-top: $base-unit;
      text-align: center;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .invoice-details-fees-warning {
      color: $sf-gray-dark;
      font-size: $font-size-xs;
      font-weight: $font-weight-regular;
      margin-top: $base-unit;
    }

    .invoice-details-total {
      font-weight: $font-weight-regular;

      .invoice-details-row {
        font-size: $font-size-md;
        color: $black;
        margin-bottom: 0;
      }
    }
  }

  .sf-ics-link {
    text-align: center;
  }
}
