.sf-search-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 0 $padding-base;

  margin: auto;

  input {
    border-radius: $border-radius-base;
    height: 50px;
  }

  @include screen('sm') {
    flex-direction: row;
    align-items: flex-end;
    padding: 0;
    max-width: 500px;
  }

  .sf-form-row {
    height: auto;
    margin-bottom: $half-unit;

    @include screen('sm') {
      margin-bottom: 0;
    }

    flex-grow: 1;

    &.sf-form-row {
      box-shadow: 0 3px 2px rgba(0, 0, 0, .4);
      margin-bottom: 0;
      input {
        margin-top: 1px;
      }

      &.sf-form-location {
        margin-bottom: $quarter-unit * 3;

        @include screen('sm') {
          margin-bottom: 0;
          margin-right: $half-unit;
        }
      }

      &.sf-form-location::after {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 100%;
        content: "\f002";
        font-family: FontAwesome;
        position: absolute;
        right: 0;
        top: 0;
        font-size: $font-size-md;
        color: $sf-gray-dark;
        pointer-events: none;
      }

      input {
        width: 100%;
        padding-right: 45px;
        padding-left: $base-unit;
      }
    }

    input, .selection {
      height: 50px;

      @include screen('ss') {
        height: 50px;
      }

      @include landscape-mode {
        height: 50px;
      }

      &.results {
        @include border-bottom-radius(0);
      }
    }

    .search-results {
      top: $half-unit * 5;

      @include screen('ss') {
        top: $base-unit * 3;
      }
    }
  }

  &.with-project-type {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: $max-content-width-sm;

    @include screen('ss') {
      flex-direction: row;
    }

    .sf-form-row {
      input {
        margin-top: 0;
        padding: $padding-half $padding-half $padding-half $padding-base;
      }

      &.sf-form-location {
        @include screen('ss') {
          margin-bottom: 0;
          margin-right: $half-unit;
        }

        &::after {
          display: none;
        }
      }
    }

    .sf-form-project-types, .sf-form-duration-options {
      display: inline-flex;
      flex-shrink: 0;
      flex-grow: 0;
      align-items: center;
      position: relative;
      width: 100%;
      height: 50px;
      margin-bottom: $quarter-unit * 3;
      background: $sf-white;
      border: 1px solid $sf-gray-base;
      border-radius: $border-radius-base;
      font-size: $font-size-sm;
      font-weight: $font-weight-regular;
      padding: 0 ($padding-base * 3) 0 $padding-base;
      cursor: pointer;

      .label {
        @include z-index-base;

        position: absolute;
        left: $base-unit;
        right: 0;
        white-space: nowrap;
        overflow: hidden;

        span {
          color: $sf-gray-dark;

          &.has-value {
            color: $sf-black;
          }
        }
      }

      @include screen('ss') {
        margin-bottom: 0;
        margin-right: $half-unit;
        width: 180px;
      }

      @include screen('sm') {
        width: 250px;
      }

      &::before {
        @include z-layer-1;

        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0), $sf-white 40%); /* 'transparent' doesn't work with Safari */
      }

      &::after {
        @include z-layer-2;

        content: "\f107";
        position: absolute;
        top: calc(50% - 7px);
        right: $quarter-unit * 3;
        font-size: $font-size-sm;
        font-weight: $font-weight-heavy;
        font-family: FontAwesome;
      }

      &:hover::after {
        color: $sf-green;
      }

      .project-types-list, .duration-lengths-list {
        @include z-layer-1;

        display: none;
        position: absolute;
        width: 100%;
        height: auto;
        top: 49px;
        right: 0;
        box-shadow: 0 3px 2px rgba(0, 0, 0, .4);
        overflow: hidden;
        border-radius: $border-radius-base;
        background: $sf-white;
        cursor: default;

        &.project-types-list, .duration-lengths-list {
          height: 35vh;

          ul {
            overflow-y: scroll;

            li {
              display: inline-block;
              vertical-align: top;
              float: none;

              &.bold {
                font-weight: $font-weight-heavy;
              }
            }
          }

          @include screen('ss') {
            padding: $padding-base ($padding-half * 3);
            right: -105px;
            width: 500px;
            height: auto;

            &::after {
              display: none;
            }

            ul {
              padding: 0;
              overflow: hidden;

              li {
                width: 50%;

                &:first-of-type, &:last-of-type {
                  padding: ($padding-quarter * 3) $padding-base !important;
                }
              }
            }
          }

          @include screen('sm') {
            right: 0;
          }
        }

        &::after {
          @include z-layer-1;

          display: block;
          content: "";
          width: 100%;
          height: 20px;
          background: linear-gradient(0, $sf-gray-base, rgba(255, 255, 255, 0));
          position: absolute;
          pointer-events: none;
          bottom: 0;
          left: 0;
        }

        &.scrolled-to-bottom::after {
          display: none;
        }

        &.opened {
          display: block;
        }

        ul {
          width: 100%;
          height: 100%;
          margin: 0;
          list-style: none;
          padding: 0;
          overflow-y: visible;

          li {
            float: left;
            width: 100%;
            cursor: pointer;
            padding: ($padding-quarter * 3) $padding-base;

            &:hover {
              color: $sf-green;
            }

            &:first-of-type {
              padding-top: $padding-base;
            }

            &:last-of-type {
              @include z-layer-2;

              position: relative;
              background: linear-gradient(0, $sf-white 45%, rgba(255, 255, 255, 0));
              padding-bottom: $padding-base;
            }
          }
        }
      }
    }
    @include screen('sm') {
      .sf-form-duration-options {
      max-width: 150px;
    }
    }
    .submit-btn {
      flex-shrink: 0;
      margin: 0;
      border: 0;
      padding: $padding-base ($padding-half * 3);
      box-shadow: 0 3px 2px rgba(0, 0, 0, .4);
      height: 50px;
    }
  }

  .submit-btn {
    border: 0;
  }
}
