.sf-form-location {
  .sf-form-location-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: $border-radius-base;
    background: white;

    input {
      width: 100%;
      height: 100%;
      padding-left: $padding-xl;
      border: none;
      font-size: $font-size-sm;
      background: $sf-white;
      border-radius: $border-radius-base;
    }

    .sf-icon {
      @include z-layer-1;

      position: absolute;
      left: $base-unit;
      width: 20px;
      height: 100%;
    }
  }
}

@include screen('sm') {
  .sf-form-location.inline {
    > .sf-form-location-wrapper input {
      border-radius: 0;
      height: 100%;
    }

    &.last {
      > .sf-form-location-wrapper input {
        @include border-right-radius($border-radius-base);

        &.results {
          @include border-bottom-radius(0);
        }
      }
    }

    &.first {
      > .sf-form-location-wrapper input {
        @include border-left-radius($border-radius-base);

        &.results {
          @include border-bottom-radius(0);
        }
      }
    }
  }
}

@include screen-max('sm') {
  .sf-form-location.inline {
    > .sf-form-location-wrapper input {
      border-radius: 0;
      height: 100%;
    }

    &.last {
      > .sf-form-location-wrapper:not(.results) input {
        @include border-bottom-radius($border-radius-base);
      }
    }

    &.first {
      > .sf-form-location-wrapper input {
        @include border-top-radius($border-radius-base);
      }
    }
  }
}
