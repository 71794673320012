.listing-calendar {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 2 * $base-unit;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @mixin update-animation($name, $ending-color) {
    @include keyframes(updated-day-#{$name}) {
      0% {
        background-color: $sf-gray-dark;
        color: $white;
      }

      100% {
        background-color: #{$ending-color};
        color: $sf-black;
      }
    }
  }

  @include update-animation('regular', $sf-white);
  @include update-animation('blocked', $sf-gray-light);

  caption {
    margin-bottom: $half-unit;
    font-weight: $font-weight-regular;
    font-size: $font-size-sm;
  }

  tr {
    th {
      text-align: left;
      font-weight: $font-weight-light;
      font-size: $font-size-sm;
      padding: $padding-half $quarter-unit;
      color: $sf-gray-dark;
    }

    td:not(:empty) {
      position: relative;
      border: 1px solid $sf-gray-base;
      cursor: pointer;
      transition: background ease .2s;

      &:before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        box-shadow: 0 0 0 rgba(0, 0, 0, .0);
        transition: all ease .5s;
      }

      &:not(.disabled):hover {
        z-index: 10;

        &:before {
          box-shadow: 0 0 20px rgba(0, 0, 0, .2);
          transition: all ease .25s;
        }
      }

      &.selected {
        background-color: #10BF81;
        color: $white;
      }

      &.updated {
        @include animation('updated-day-regular .5s ease');

        &.blocked {
          @include animation('updated-day-blocked .5s ease');
        }
      }

      &.disabled {
        cursor: default;
        background-color: $sf-gray-light;
        color: $sf-gray-base;
      }

      &.booked {
        cursor: default;

        &:after {
          display: block;
          content: "";
          position: absolute;
          left: 3px;
          top: 22px;
          height: 5px;
          width: 25px;
          background-color: $sf-yellow;
        }
      }

      &.booked_admin {
        cursor: pointer;
      }

      &.blocked {
        background-color: $sf-gray-light;

        &.selected {
          background-color: #10BF81;
        }
      }

      .day-container {
        width: 100%;
        padding: 40% 0;

        .day-number {
          position: absolute;
          left: 6px;
          top: 4px;
          font-size: $font-size-sm;
        }
      }
    }
  }
}
