$message-left-padding: $padding-half * 5;
.sf-conversation {
  padding: $base-unit 0;

  .conversation-loader {
    padding: $padding-base;
  }

  .conversation-actions {
    padding: 0 $padding-base;
    overflow-wrap: break-word;

    .approved-response {
      position: relative;

      &:after {
        display: block;
        content: '';
        clear: both;
      }

      .blocked {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
        color: $sf-white;

        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;

        @include z-layer-1;

        i {
          font-size: $font-size-xxxl;
        }

        div {
          font-size: $font-size-xs;
        }
      }

      #conversation-recipients {
        width: 100%;
        padding: $half-unit;
        border: 1px solid $sf-gray-base;
        border-bottom: 0;
        font-size: $font-size-sm;
        font-weight: $font-weight-regular;
        position: relative;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;

        #conversation-recipients-loader {
          position: absolute;
          width: 20%;
          top: calc(50% - #{$quarter-unit});
          left: 32px;
        }

        .recipient_role {
          font-weight: $font-weight-regular;
        }

        #conversation-tooltip {
          position: absolute;
          right: $half-unit;
          top: calc(50% - #{$half-unit});
          width: $base-unit;
          height: $base-unit;
          border-radius: $half-unit;
          color: $white;
          background-color: $sf-gray-base;
          font-size: $font-size-sub;
          font-weight: $font-weight-light;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover #conversation-tooltip-content {
            display: block;
          }

          #conversation-tooltip-content {
            @include z-layer-1;

            &:before {
              content: '';
              position: absolute;
              right: $base-unit;
              top: -10px;
              z-index: 1;
              @include arrow-up(5px, #FFF);
            }

            &:after {
              content: '';
              position: absolute;
              top: -11px;
              right: $base-unit;
              @include arrow-up(5px, $sf-gray-base);
            }

            display: none;
            background-color: $white;
            padding: $half-unit;
            font-size: $font-size-sm;
            position: absolute;
            border: 1px solid $sf-gray-base;
            top: 22px;
            right: -14px;
            color: $sf-black;
            width: 230px;
            text-align: left;
          }
        }
      }

      .new-message-text {
        height: $half-unit * 5;
        display: inline-block;
        transition: width 0.2s ease;
        padding: 10px;
        border-radius: 0;

        &::-webkit-input-placeholder {
          color: $sf-gray-dark;
          font-weight: $font-weight-light;
        }

        &:-moz-placeholder {
          color: $sf-gray-dark;
          font-weight: $font-weight-light;
        }

        &::-moz-placeholder {
          color: $sf-gray-dark;
          font-weight: $font-weight-light;
        }

        &:-ms-input-placeholder {
          color: $sf-gray-dark;
          font-weight: $font-weight-light;
        }
      }

      &.options-visible {
        .new-message-text {
          width: 100%;
          border-bottom: 0;
        }
      }

      .attached-files {
        padding: 0 0 $half-unit;
        list-style: none;
        margin: 0;
        font-size: $font-size-sm;
        border-left: 1px solid $sf-gray-base;
        border-right: 1px solid $sf-gray-base;
        position: relative;

        &:before {
          position: absolute;
          width: 90%;
          top: 0;
          height: 1px;
          background-color: $sf-gray-light;
          left: 5%;
        }

        &:after {
          display: block;
          content: '';
          clear: both;
        }

        li {
          float: left;
          margin: 0 $half-unit 0 ($half-unit * 3);
          position: relative;

          &:before {
            display: block;
            position: absolute;
            left: -$base-unit;
            top: 2px;
            font-family: FontAwesome;
            content: '\f0c6';
            -webkit-font-smoothing: antialiased;
            color: $sf-gray-dark;
          }

          .remove-file {
            color: $sf-gray-base;
            font-size: $font-size-xs;
            cursor: pointer;
          }
        }
      }

      .btn-actions {
        position: relative;
        padding: $half-unit 0;
        width: 100%;
        background: $sf-white;
        border: 1px solid $sf-gray-base;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        min-height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-send-message {
          margin-right: $half-unit;
          padding: 2px 10px $padding-quarter;
          color: $sf-white;
          border-radius: $base-unit;
          font-size: $font-size-sm;

          &.primary-btn {
            cursor: pointer;
            background-color: $sf-green;

            &:hover {
              background-color: $sf-green-dark;
            }

            &:active {
              box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
            }
          }

          &.secondary-btn {
            cursor: default;
            background-color: $sf-gray-base;
          }
        }

        .btn-attach-files {
          margin-right: $quarter-unit;
          padding: 2px $half-unit;
          color: #999;
          border-radius: 10px;
          font-size: $font-size-lg;
          cursor: pointer;

          &:hover {
            background: $sf-gray-light;
          }

          &:active {
            box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
          }
        }
      }

      .btn-response-options {
        position: absolute;
        right: $base-unit;
        padding: $half-unit + 2px;
      }
    }

    .sf-booking-request-message-warning {
      font-weight: $font-weight-regular;
      color: $secondary-3-grey;
      font-size: $font-size-xs;
      clear: both;
      padding-top: $half-unit;
    }
  }

  .date-grouped-messages {
    .date-separator {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: $base-unit;

      &::before {
        @include z-index-base;

        position: absolute;
        left: 0;
        top: 50%;
        display: block;
        content: '';
        height: 1px;
        width: 100%;
        background-color: $sf-gray-base;
      }

      .date {
        @include z-layer-1;

        position: relative;
        color: $sf-black;
        border: 1px solid $sf-gray-base;
        border-radius: $border-radius-label;
        padding: $padding-quarter $padding-base;
        font-size: $font-size-xs;
        font-weight: $font-weight-regular;
        background-color: $sf-white;
      }
    }
  }

  .conversation-messages {
    margin-top: $base-unit * 2;
    position: relative;
    transition: padding-top ease .5s;

    &.chaining {
      margin-top: 0;
    }

    &:before {
      position: absolute;
      display: block;
      content: '';
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      opacity: 0;
      transition: opacity ease .5s;
      background-color: $sf-red;
    }

    &.unread-message {
      padding-top: 2 * $padding-base;
      padding-left: $base-unit;

      &:after {
        position: absolute;
        left: $base-unit;
        top: 0;
        color: $sf-red;
        font-size: $font-size-xs;
        content: attr(data-new-message-text);
      }
    }

    &.unread-message + .unread-message {
      padding-top: $base-unit;

      &:after {
        display: none;
      }
    }

    &.unread-message:before {
      opacity: 1;
    }

    &.first-message {
      margin-top: $base-unit;
    }

    .sf-message-timestamp {
      @include timestamp;
      position: inherit;
      display: inline-block;
      vertical-align: baseline;
      font-weight: $font-weight-regular;
      font-size: $font-size-xs;

      &.chaining {
        margin-left: 0;

        @include screen('sm') {
          margin-left: ($base-unit * 2) + $half-unit;
        }
      }
    }

    .message-user-name {
      @include header-sm;
      display: flex;

      .avatar-name-container {
        display: flex;

        .right-side {
          display: flex;
          justify-content: space-between;
          flex-direction: column;

          .user-name-role {
            font-size: $font-size-xs;
            color: $sf-gray-dark;
            font-weight: $font-weight-regular;
          }
        }
      }

      .fa-ellipsis-v {
        color: $sf-black;
        font-size: $base-unit;
      }

      .message-adjucate-menu {
        position: absolute;
        right: $base-unit;
        top: 38px;
        padding: $padding-half $base-unit;
        border-radius: $border-radius-base;
        box-shadow: $box-shadow-right;
        color: $primary-red;
        font-size: $font-size-sm;
      }

      .user-avatar {
        float: left;
        width: 2 * $base-unit;
        text-align: center;
        color: $white;
        background: $sf-gray-base;
        height: 2 * $base-unit;
        border-radius: $base-unit;
        padding-top: $padding-half;
        font-weight: $font-weight-regular;
        margin-right: $half-unit;
      }

      .name-and-role {
        margin-bottom: -2px;
        color: $sf-blue;
        display: flex;

        &.owner {
          color: $sf-green;
        }

        &.admin {
          color: $sf-black;
        }
      }

      .sf-message-is-user {
        color: $sf-black;
      }
    }
    .sf-visit-request-message {
      margin: $quarter-unit $base-unit 0 $message-left-padding;
      p {
        font-size: $font-size-sm;
        font-weight: $font-weight-light;
        line-height: 2;
      }
    }
    .sf-visit-request-wrapper {
      position: relative;
      margin-top: $half-unit;
      margin-left: 0;

      @include screen('sm') {
        margin-left: ($padding-base * 2) + $padding-half;
      }

      .sf-message-contents {
        @include body-text;

        padding-left: $message-left-padding;

        p {
          margin-bottom: 0;
          font-weight: $font-weight-light;
        }
      }

      .disabling-layer {
        background: $sf-disabled;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
        bottom: 0;
      }

      .sf-visit-request {
        @include card-message;

        border: 1px solid $sf-gray-base;
        border-radius: $border-radius-sm;
        overflow: visible;
        padding: 14px 20px 20px;
        margin-top: $base-unit;

        .callback-first-line {
          text-align: center;
        }
        .first-line, .second-line {
          font-size: $font-size-xs;
          margin-top: 0;
          line-height: 2;
        }
        .first-line, .callback-first-line {
          font-size: $font-size-sm;
          margin: 0 0 $quarter-unit;
          font-weight: $font-weight-regular;
        }
        .second-line, .callback-reminders p {
          margin-bottom: 12px;
          font-size: $font-size-sm;
          font-weight: $font-weight-light;
        }
        .callback-reminders {
          margin-top: 12px;
        }
        .callback-invite-confirmation {
          padding: $padding-base 0;
        }
        .suggest-another-time-button, .confirm-button {
          cursor: pointer;

          &:hover {
            text-decoration: none;
          }
        }
        .suggest-another-time-button {
          color: $sf-black;
        }
        .confirm-button {
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
          background-color: $sf-gray-base;
          color: $sf-white;
          border: none;
          transition: all 0.3s ease;
          padding: $quarter-unit $half-unit;
          border-radius: $border-radius-base;
          justify-self: flex-end;

          &:hover {
            background-color: #10BF81;
          }

          &.sf-btn-primary {
            display: block;
            background-color: #10BF81;
            padding: $base-unit;
            font-size: $font-size-base;

            &:hover {
              background-color: #34d399;
            }
          }
        }
        .time-slot {
          font-size: $font-size-sm;
          border-bottom: 1px solid #ddd;
          display: flex;
          align-items: center;

          &:last-of-type {
            border-bottom: 0;
          }

          p {
            font-size: $font-size-sm;
          }

          .datetime {
            font-size: $font-size-sm;
            flex-grow: 1;
          }
        }
        .address-wrapper, .time-slot-wrapper, .phone-number-wrapper {
          .left-icon, .right-content {
            vertical-align: middle;
            display: table-cell;
          }
          .right-content {
            padding-left: $padding-half;
          }
          .right-content {
            .address-title, .phone-number-title, .callback-address-title {
              font-size: $font-size-sm;
              font-weight: $font-weight-regular;
              margin-bottom: $quarter-unit;
            }

            .address, .phone-number {
              display: inline;
              line-height: 0;
              font-size: $font-size-sm;
              font-weight: $font-weight-regular;
            }
          }
        }

        .address-wrapper, .time-slot-wrapper, .phone-number-wrapper {
          p {
            margin-top: $quarter-unit;
            margin-bottom: 0;
          }
          .left-icon {
            position: relative;
            width: 28px;
          }
        }
        .address-wrapper, .phone-number-wrapper {
          .left-icon {
            top: 4px;
          }
        }
        .time-slot-wrapper {
          .left-icon {
            top: 2px;
          }
        }
        svg {
          width: 20px;
          height: 20px;
        }

        .sf-ics-link {
          .add-to-calendar-button {
            visibility: visible;
          }
        }

        .callback-next-steps {
          padding: $padding-base;
          margin: $half-unit ($base-unit * 1.5);

          .next-steps-title {
            font-weight: $font-weight-regular;
            font-size: $font-size-md;
            text-align: center;
            text-decoration: underline;
            margin-bottom: $base-unit;
          }

          .visit-steps {
            font-weight: $font-weight-regular;
          }

          .next-steps-link {
            margin-left: $half-unit;
          }

          @media (max-width: 767px) {
            & {
              margin: 8px 0;
            }
          }
        }

        .callback-owner-terms {
          color: $secondary-3-grey;
          font-size: $font-size-xs;
          padding: 0 $padding-base;
          text-align: justify;

          div {
            margin-top: $base-unit;
          }
        }
      }

      .callback-address-wrapper {
        margin-top: $base-unit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .callback-address-title {
          margin-bottom: $quarter-unit;
        }

        div {
          font-size: $font-size-sm;
          font-weight: $font-weight-medium;
        }
      }
    }
  }

  .empty-placeholder:not(:empty) {
    text-align: center;
    font-weight: $font-weight-light;
    padding: ($padding-half * 3) $padding-base;
  }

  .renter-owner-callback-details {
    display: flex;
    justify-content: space-evenly;
    align-items: start;
  }

  @media (max-width: 767px) {
    .renter-owner-callback-details {
      flex-direction: column;
    }
  }
}
