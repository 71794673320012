.sf-form {
  .sf-form-row {
    .sf-form-duration-wrapper {
      .sf-form-input-wrapper {
        width: calc(100% - #{$half-unit * 15});
        margin-right: $base-unit;
        display: inline-block;
      }

      .sf-form-select-wrapper {
        width: $half-unit * 13;
        display: inline-block;
        padding: $quarter-unit / 2 $quarter-unit;

        select {
          padding-left: $quarter-unit;
        }
      }
    }
  }
}
