.align-middle {
  @include align-middle;
}

.align-center {
  @include align-center;
}

.relative {
  position: relative;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.inline-block {
  display:inline-block;
}

.full-height {
  @include minHeightOffset($navbar-height);
}

.full-width {
  width: 100%;
  margin: 0;
}

.half-width {
  width: 50%;
}

.half-width-minus-10 {
  width: calc(50% - 10px);
}

.inline-button-container {
  @include screen-max('sm') {
    .half-width-minus-10 {
      width: 100%;
      margin: 0;

      &:first-of-type {
        margin-bottom: 10px;
      }
    }
  }
}

.pseudo-link {
  cursor:pointer;
  color: $sf-gray-dark !important;
  font-size: $font-size-xs;
}

.font-green {
  color: $sf-green;
}

.border-box-shadow {
  box-shadow: $box-shadow-line;
}

.bold {
  font-weight: bold;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.side-note-text {
  font-style: italic;
  font-size: $font-size-xs;
}

.negative-text {
  color: $sf-red;
}

.warning-text {
  color: $sf-orange;
}

.emphasis-text {
  font-weight: bold;
}
