$listing-image-width: 230px;

.sf-inbox {
  position: relative;

  .sf-inbox-container {
    cursor: pointer;
    font-size: $font-size-sm;
    padding-top: $padding-half;

    @include screen('sm') {
      padding: $padding-base 0;
    }

    .timestamp, .inquiry-nb {
      position: static;
      margin-top: $quarter-unit;
      font-weight: $font-weight-light;
      font-size: $font-size-sm;
    }
    .timestamp {
      display: none;
      @include screen('sm') {
        display: block;
      }
    }

    .price-for {
      margin-right: 5px;
    }

    .status {
      color: $sf-blue;
      font-size: $font-size-sm;
      font-weight: $font-weight-light;

      &.pending_adjudication {
        color: $sf-blue;
      }

      &.admin_rejected {
        color: $sf-red-light;
      }

      &.pending {
        color: $sf-blue;
        background: none;
      }

      &.rejected {
        color: $sf-red-light;
      }

      &.cancelled {
        color: $sf-orange;
      }

      &.inactive {
        color: $sf-red-light;
      }

      &.expired {
        color: $sf-red-light;
      }

      &.paid {
        color: $sf-green;
      }

      &.completed {
        color: $sf-green;
      }

      &.completed_by_admin {
        color: $sf-green;
      }
    }

    .last-message {
      position: relative;
      width: 100%;
      margin-top: 0;
      font-size: $font-size-md;
      font-weight: $font-weight-regular;

      .message-info {
        .message-user-name {
          color: $sf-green-dark;
          font-weight: $font-weight-regular;
          margin-right: 3px;

          &.sf-message-is-user {
            color: $sf-black;
          }
        }

        .message-date {
          color: $sf-gray-dark;
          font-size: $font-size-sub;
        }
      }

      .message-content {
        font-size: $font-size-sm;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding: $padding-half $padding-base $padding-half 0;
        color: $sf-gray-dark;
        display: none;

        @include screen('sm') {
          display: block;
        }

        i {
          color: $sf-gray-dark;
        }
      }
    }

    .listing-image-wrapper {
      vertical-align: middle;
      @include screen('sm') {
        display: inline-block;
      }
      .listing-image {
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 180px;

        @include screen('sm') {
          width: $listing-image-width;
          height: $listing-image-width * 2/3;
        }

      }
    }

    .icon-unread {
      position: absolute;

      color: $sf-white;
      font-weight: $font-weight-regular;
      font-size: $font-size-xs;
      border: 1px solid $sf-white;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $sf-green;
      border-radius: $border-radius-lg;

      top: $half-unit;
      left: -$half-unit;
      padding: 0 $half-unit;

      @include screen('sm') {
        top: $half-unit;
      }
    }

    .inbox-main-info {
      display: inline-block;
      width: 100%;
      vertical-align: top;
      padding: $padding-base 0;

      @include screen('sm') {
        width: calc(100% - #{$listing-image-width});
        height: $listing-image-width * 2/3;
        padding: 0 0 0 ($padding-quarter * 3);
      }

      .main-info-text {
        width: 100%;
        padding-right: 0;
        border-top: unset;
        padding-top: $padding-quarter;

        @include screen('sm') {
          padding-right: $padding-base;
          padding-top: $padding-half;
          border-top: 1px solid $sf-gray-light;
        }

        .main-info-listing {
          @include text-overflow;
          padding-top: $padding-quarter;
        }

        .main-info-details {
          @include text-overflow;
          @include header-xs;
          @include text-overflow;
          font-size: $font-size-sm;
          font-weight: $font-weight-regular;
        }

        .main-info-user {
          font-weight: $font-weight-regular;

          .loader {
            padding-top: $padding-base;
          }
        }
      }

      .main-info-separator {
        position: absolute;
        display: inline-block;
        height: 75%;
        width: 1px;
        background-color: $sf-gray-base;
      }
    }
  }
}
