.sf-carousel {
  position: relative;
  height: inherit;
  width: inherit;
  &.lazy {
    overflow: hidden;
  }

  .image {
    position: relative;
    height: inherit;
    background-size: cover;
    background-position: center center;

    .loader {
      position: absolute;
      width: 100px;
      height: 100px;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: -1;

      i {
        font-size: 100px;
      }
    }
  }

  .arrow {
    @include z-layer-1;
    position: absolute;
    height: 100%;
    width: $double-unit + $quarter-unit;
    color: #FFF;
    padding: $half-unit;
    font-size: ($base-unit * 1.5);
    top: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
    opacity: .5;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    i {
      font-size: $font-size-xxxl;
      position: relative;
      top: calc(50% - #{$base-unit});
    }
  }
}
