.sf-notification {
  position: relative;
  padding: $padding-base;
  background: $sf-white;
  border-bottom: 1px solid $sf-gray-base;
  width: 100%;
  white-space: normal;
  transition: all ease .2s;

  &:hover {
    background-color: #f5f5f4;
  }

  &.read {
    background-color: #f5f5f4;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  .sf-notification-container {
    display: flex;

    .notification-image {
      background: $sf-gray-light;
      margin-right: $half-unit;

      div {
        height: 100%;
      }

      .notification-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: $font-size-lg;
        width: ($base-unit * 2);
        padding: $padding-half;

      }

      .notification-avatar {
        background-size: 200%;
        background-position: center center;
        height: 100%;
        width: $base-unit * 3;
        border-radius: $border-radius-sm;
      }

      &[data-type^="rejected_"] {
        background: $sf-red;
      }

      &[data-type^="approved_"] {
        background: $sf-green;
      }
    }

    .notification-main-info {
      color: $sf-black;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      line-height: $font-size-md;

      b {
        font-weight: $font-weight-regular;
      }

      @include screen-max('sm') {
        padding: 0 $half-unit;
        padding-left: ($base-unit * 2.5);
      }

      .info-listing {
        @include text-overflow;
      }

      .info-sub-text {
        @include subheader;
      }
    }
  }
}
