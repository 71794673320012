.app-container {
  .homepage {
    position: absolute;
    height:100%;
    width:100%;
    top:0;
    right:0;
    bottom:0;
    left:0;
  }

  .search-container {
    position: relative;
    max-width: $max-content-width-xs;
    margin: $base-unit auto;
    text-align: left;

    input {
      padding: $base-unit ($base-unit * 2);
      font-size: $font-size-lg;
      border-radius: $border-radius-base;
      border: none;
    }
  }

  .main-splash-container {
    display: table;
    height: 100%;
    width: 100%;
    transition: height 0.3s ease;

    .main-splash-background {
      position: absolute;
      height: 100%;
      width: 100%;
      top:0;
      bottom:0;
      left:0;
      background: #999;
      background-image:
      linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("#{$background-images-url}/header_hero_image.jpg");
      background-size: cover;
      background-position: center center;
      transition: height 0.3s ease;
    }

    &.signed-out {
      height: 100%;
    }

    .main-splash-content {
      display: table-cell;
      height: 100%;
      width: 100%;
      text-align: center;
      vertical-align: middle;

      @include screen-max('sm') {
        padding: $base-unit;

        .sf-search-form {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .sf-search-form-or-separator {
        position: relative;
        width: 66%;
        text-align: center;
        margin: 0 auto;
        color: #fff;
        font-size: $font-size-lg;

        margin-bottom: $half-unit * 3;

        @include screen('sm') {
          width: 466px;
        }

        &:after,
        &:before {
          position: absolute;
          top: 50%;
          height: 1px;
          background: #fff;
          content: '';
        }

        &:after {
          left: calc(50% + 40px);
          right: 0;
        }

        &:before {
          right: calc(50% + 40px);
          left: 0;
        }
      }

      .main-splash-header {
        color: #FFF;
        font-weight: bold;
        font-size: ($base-unit * 4.5);
        position:relative;

        @include screen-max('sm') {
          font-size: ($base-unit * 4);
        }

        @include screen-max('xs') {
          font-size: ($base-unit * 3);
        }
      }
    }
  }
}
