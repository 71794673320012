.footer {
  position: relative;
  color: #FFF;
  padding: 0 !important;

  .prefoot {
    padding: 0 40px;
  }

  .footer-container {
    background: darken($secondary-2-grey, 5%);
    padding: 40px;
    width: 100%;
    overflow: hidden;

    @include screen-max('sm') {
      padding: 20px;
    }

    .footer-content {
      display: table;
      width: 100%;
      table-layout: fixed;
      font-size: 14px;
      max-width: $max-content-width-md;
      margin: 0 auto;

      @include screen-max('sm') {
        table-layout: auto;
        vertical-align: top;
      }

      .footer-content-column {
        display: table-cell;
        font-weight: 400;
        vertical-align: top;

        @include screen-max('sm') {
          display: inline-block;
          vertical-align: top;
          width: 50%;
          margin-bottom: 10px;
        }

        &.social {
          @include screen-max('sm') {
            width: 100%;
            display: block;
          }
        }

        .footer-item {
          padding: 5px 0;
          color: #FFF;
          font-size: 15px;

          @include screen-max('sm') {
            padding: 4px 0;
            font-size: 12px;
          }

          a {
            color: #FFF;
            border-bottom: 2px solid transparent;
            transition: border 0.2s ease;

            &:hover {
              border-color: $primary-green;
            }

            &.logo {
              &:hover {
                border: none;
              }
            }
          }

          &.center {
            text-align: center;
          }

          .footer-social-item {
            width: calc(100% / 8);
            height: 40px;
            line-height: 40px;
            margin: 2px;
            text-align: center;
            border-bottom: none;

            @include screen-max('sm') {
              width: calc(100% / 7);
              margin: 0px;
            }

            .fa {
              color: #FFF;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              border: 1px solid #FFF;
              text-align: center;
              line-height: 28px;
              transition: background 0.2s ease, color 0.2s ease;

              &:hover {
                background: #FFF;
                color: $secondary-2-grey;
              }
            }
          }

          .lang-widget-label {
            position: absolute !important;
            display: block !important;
            border: 0px !important;
            margin: -1px !important;
            padding: 0px !important;
            width: 1px !important;
            height: 1px !important;
            overflow: hidden !important;
            clip: rect(0, 0, 0, 0) !important;
          }

          .lang-widget-container {
            position: relative;
            width: 76% !important;
            height: $base-unit * 3 !important;
            font-size: $font-size-lg  !important;
            line-height: $base-unit + $half-unit;
            color: #FFF;
            overflow: hidden !important;

            .lang-widget-wrapper {
              border: 1px solid #FFF;
              position: inherit;
              height: 100%;

              #lang-widget {
                padding: $half-unit $base-unit * 2 $half-unit $base-unit;
                background-color: transparent !important;
                border: none !important;
                border-radius: 0px !important;
                font-size: inherit;
                line-height: inherit;
                color: inherit;
                width: 100% !important;
                height: 100% !important;
                display: inline-block !important;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                position: absolute;
                z-index: 2;

                .lang-option {
                  color: $black;
                }

                @include screen-max('sm') {
                  font-size: $font-size-sm;
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .fa {
              position: absolute;
              right: $base-unit;
              top: $base-unit - 2;
              z-index: 1;
            }
          }
        }

        .footer-logo {
          height: 35px;
          aspect-ratio: 199 / 35;
        }
      }
    }

    hr {
      max-width: $max-content-width-md;
      margin: 0 auto;
      margin-top: 10px;
      border: none;
      border-top: 2px solid $secondary-3-grey;

      @include screen-max('sm') {
        margin-top: 10px;
      }
    }

    .footer-copyright {
      p {
        margin: 0;
        padding-top: 30px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        display: block;
        color: $secondary-3-grey;
      }

      p+p {
        padding-top: $half-unit;
      }
    }

    .contact-info {
      font-size: 13px;
      color: #FFF;
      max-width: $max-content-width-md;
      text-align: center;
      margin: 0 auto;
      padding-top: 30px;
      overflow: hidden;

      &+.contact-info {
        padding-top: 20px;
      }

      .contact-info-item {
        padding: $quarter-unit $half-unit;
        display: inline-block;

        @include screen-max('sm') {
          width: 100%;
        }
      }
    }
  }

  .footer-reviews-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .footer-review-rating {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: auto;
      border-radius: $border-radius-base;
      padding: $padding-quarter $padding-half;
      justify-content: center;

      .footer-review-container {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 112px;
        border-radius: $border-radius-circular;
        padding: $padding-base;
        background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
      }

      h2 {
        font-size: $font-size-md;
        font-weight: $font-weight-heavy;
        margin: 0;
      }

      p {
        color: black;
        font-size: $font-size-xs;
        line-height: 1.4;
        margin-bottom: $quarter-unit;
        opacity: .8;
        margin-top: $quarter-unit;
      }

      p.leave-review {
        font-size: $font-size-md;
        color: white;
      }
    }
  }
}
