.react-filter-bar-wrapper {

  @include z-layer-3;
  position: fixed;
  top: $navbar-height;
  padding: $padding-base 0;
  background: white;
  width: 100%;
  height: $filter-bar-height;
  overflow: hidden;
  border-bottom: solid 1px $sf-gray-light;
  border-top: solid 1px $sf-gray-light;

  &.scroll-anchor-to-footer {
    position: absolute;
    top: auto;
    bottom: 77px;
  }

  .react-filter-bar {
    & > div {
      display: inline-block;
    }

    white-space: nowrap;
    padding-left: $base-unit;
    padding-right: $base-unit;
    padding-bottom: $padding-lg;
  }

  .right-content {
    position: absolute;
    top: $base-unit + 1px;
    right: $base-unit;
  }
}

/* Following CSS acts as a white panel covering
listings results before the angular navbar shows up */
  div[data-sf-navbar] {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 64px;
    z-index: 100;
    background-color: $sf-white;
  }

  /* This trick prevents the 'more filters' button from temporary showing an empty square when page loads */
  .fa.fa-angle-down {
    transition: visibility 1s, opacity 0.5s linear;
  }
