#consent-manager {
  display: none;

  button {
    font-size: $font-size-md;
    padding: $base-unit;
    background-color: $sf-green;
    border: 1px solid darken($sf-green, 10%);
    color: $sf-white;
    margin-top: $base-unit;
    border-radius: $border-radius-sm;
    cursor: pointer;
    white-space: nowrap;

    &.accept, &.learn {
      margin-top: 0;
      width: 100%;

      @include screen('ss') {
        width: auto;
      }

      &.learn {
        background: $sf-gray-light;
        color: $sf-black;
        font-weight: $font-weight-regular;
        padding: 0 $base-unit;
        font-size: $font-size-sm;
        border-radius: $border-radius-label;
        border: 0;
        margin-right: $half-unit;

        &:hover {
          background-color: $sf-gray-base;
        }

        &:active {
          background-color: $sf-gray-dark;
        }
      }

      &.accept {
        padding: $padding-half $padding-base;
        font-size: $font-size-base;
        border-radius: $border-radius-label;

        &:hover {
          background-color: darken($sf-green, 10%);
        }

        &:active {
          outline: none;
          background-color: darken($sf-green, 15%);;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  .banner {
    z-index: 2147483002;
    border-radius: $border-radius-lg;
    font-size: $font-size-md;
    position: fixed;
    left: $quarter-unit;
    right: $quarter-unit;
    bottom: $quarter-unit;
    padding: $half-unit;
    background-color: $sf-white;
    border-top: 1px solid $sf-gray-base;
    font-family: $brown-pro;
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow-base;
    overflow: hidden;

    @include screen('ss') {
      left: $base-unit;
      right: $base-unit;
      bottom: $base-unit;
      padding: $base-unit;
      max-width: 360px;
    }

    .cookie-bg {
      position: absolute;
      bottom: -($base-unit * 3);
      right: -$base-unit;
      opacity: 0.2;
      font-size: 140px;
      z-index: -1;
      transform: rotate(-90deg);
    }

    & > div {
      &.cta {
        display: flex;
        flex-direction: row;
        margin-top: $base-unit;
        justify-content: flex-end;

        @include screen('ss') {
          justify-content: flex-start;
        }
      }

      p {
        font-weight: $font-weight-regular;
        font-size: $font-size-sm;
        line-height: 1.2;
        margin: 0 $base-unit 0 0;
        text-align: center;

        @include screen('ss') {
          margin-right: 0;
          text-align: left;
        }
      }
    }
  }

  #consent-manager-modal-container {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2147483003;
    overflow: auto;
    padding-bottom: $base-unit;

    .modal {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      min-height: 100%;
      background-color: $sf-white;
      padding-bottom: $base-unit * 6;

      @include screen('sm') {
        position: relative;
        max-width: $max-content-width-sm;
        min-height: auto;
        height: auto;
        margin: ($base-unit * 3) auto 0;
        border-radius: $border-radius-lg;
        padding-bottom: 0;
      }

      .header {
        border-bottom: 1px solid $sf-gray-light;
        padding: $base-unit;
        display: flex;
        align-items: center;

        &::after {
          display: block;
          content: '';
          clear: both;
        }

        .title {
          font-weight: $font-weight-regular;
          font-size: $font-size-lg;
          flex-grow: 1;
        }

        .close-modal {
          border-radius: $border-radius-base;
          padding: $quarter-unit;
          font-size: 20px;
          font-weight: $font-weight-regular;
          cursor: pointer;
          flex-grow: 0;

          &:hover {
            background-color: $sf-gray-light;
          }

          &:active {
            background-color: $sf-gray-base;
          }
        }
      }

      .body {
        padding: $base-unit;

        p {
          padding: 0;
          margin: 0 0 $base-unit;
          font-weight: $font-weight-regular;
          font-size: $font-size-sm;
        }

        .table-gradients {
          position: relative;
          margin: $base-unit (-$base-unit) 0;

          &::before {
            @include gradient-horizontal(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

            display: block;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: $base-unit;
          }

          &::after {
            @include gradient-horizontal(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));

            display: block;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: $base-unit;
          }

          .table-container {
            overflow-x: auto;

            table {
              border-collapse: collapse;
              border-radius: $border-radius-sm;

              th, td {
                padding: $base-unit;
                border: 1px solid $sf-gray-base;
                font-size: $font-size-sm;

                input {
                  margin: 0 $quarter-unit ($quarter-unit * 2) 0;
                }

                &.center {
                  text-align: center;
                  vertical-align: middle;
                }

                &.large {
                  min-width: 200px;
                }

                &.empty {
                  width: 0;
                  padding: 0 $half-unit;
                  border: 0;
                  background-color: transparent;
                }
              }

              th {
                padding: $half-unit $base-unit;
                background-color: $sf-gray-light;
              }

              td {
                vertical-align: top;
              }
            }
          }
        }

        .btn-container {
          text-align: right;

          button {
            font-size: $font-size-sm;
            padding: $half-unit $base-unit;
            font-weight: $font-weight-regular;
            border-radius: $border-radius-label;

            &.sf-btn-secondary {
              margin-right: 0;
              background-color: $sf-gray-light;
              border: 1px solid transparent;
              color: $sf-black;

              &:hover {
                background-color: $sf-gray-base;
              }

              &:active {
                background-color: $sf-gray-dark;
              }
            }

            &.sf-btn-primary {
              &:hover {
                background-color: darken($sf-green, 10%);
              }

              &:active {
                outline: none;
                background-color: darken($sf-green, 15%);;
              }
            }
          }
        }
      }
    }
  }
}

body.has-footer-banner {
  .intercom-launcher-frame {
    @include screen-max('sm') {
      display: none;
    }
  }
}
