@mixin screen($point) {
  @if map-has-key($screen-breakpoint, $point) {
    @media (min-width: map-get($screen-breakpoint, $point)) { @content; }
  } @else {
    @media (min-width: $point) { @content; }
  }
}

@mixin screen-max($point) {
  @if map-has-key($screen-breakpoint, $point) {
    @media (max-width: map-get($screen-breakpoint, $point)) { @content; }
  } @else {
    @media (max-width: $point) { @content; }
  }
}

@mixin screen-between($smPoint, $lgPoint) {
  @if map-has-key($screen-breakpoint, $smPoint) {
    @if map-has-key($screen-breakpoint, $lgPoint) {
      @media (min-width: map-get($screen-breakpoint, $smPoint)) and (max-width: map-get($screen-breakpoint, $lgPoint)) { @content; }
    } @else {
      @media (min-width: map-get($screen-breakpoint, $smPoint)) and (max-width: $lgPoint) { @content; }
    }
  } @else {
    @if map-has-key($screen-breakpoint, $lgPoint) {
      @media (min-width: $smPoint) and (max-width: map-get($screen-breakpoint, $lgPoint)) { @content; }
    } @else {
      @media (min-width: $smPoint) and (max-width: $lgPoint) { @content; }
    }
  }
}

@mixin padding($size: 'base'){
  @if $size == 'lg' {
    padding: $padding-lg-vertical $padding-lg-horizontal;
  } @else if $size == 'md' {
    padding: $padding-md-vertical $padding-md-horizontal;
  } @else if $size == 'sm' {
    padding: $padding-sm-vertical $padding-sm-horizontal;
  } @else if $size == 'xs' {
    padding: $padding-xs-vertical $padding-xs-horizontal;
  } @else {
    padding: $padding-base-vertical $padding-base-horizontal;
  }
}
@mixin border-left-radius($size) {
  border-top-left-radius: $size;
  border-bottom-left-radius: $size;
}

@mixin border-right-radius($size) {
  border-top-right-radius: $size;
  border-bottom-right-radius: $size;
}

@mixin border-top-radius($size) {
  border-top-right-radius: $size;
  border-top-left-radius: $size;
}

@mixin border-bottom-radius($size) {
  border-bottom-right-radius: $size;
  border-bottom-left-radius: $size;
}

@mixin border-top-radius($value) {
  border-top-right-radius: $value;
  border-top-left-radius: $value;
}

@mixin text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin arrow-right($px, $color) {
  border:$px solid transparent;
  border-left-color: $color;
}

@mixin arrow-left($px, $color) {
  border:$px solid transparent;
  border-right-color: $color;
}

@mixin arrow-down($px, $color) {
  border:$px solid transparent;
  border-top-color: $color;
}

@mixin arrow-up($px, $color) {
  border:$px solid transparent;
  border-bottom-color: $color;
}

@mixin box-shadow($args, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $args;
    -moz-box-shadow: inset $args;
    box-shadow: inset $args;
  } @else {
    -webkit-box-shadow: $args;
    -moz-box-shadow: $args;
    box-shadow: $args;
  }
}

@mixin font-smoothing(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin image-retina($image){
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: cover;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin placeholder {
  &::-webkit-input-placeholder { @content }
  &:-moz-placeholder { @content }
  &::-moz-placeholder { @content }
  &:-ms-input-placeholder { @content }
}

@mixin columns($count: 4, $gap: 8px) {
  -moz-column-count: $count;
  -moz-column-gap: $gap;
  -webkit-column-count: $count;
  -webkit-column-gap: $gap;
  column-count: $count;
  column-gap: $gap;
}

@mixin transition-duration($duration) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  -ms-transition-duration: $duration;
  transition-duration: $duration;
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin gradient-horizontal($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(left, $start-color $start-percent, $end-color $end-percent); // Opera 12
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1); // IE9 and down
}

@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0);
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-repeat: repeat-x;
  background-image: -webkit-linear-gradient($deg, $start-color, $end-color);
  background-image: -o-linear-gradient($deg, $start-color, $end-color);
  background-image: linear-gradient($deg, $start-color, $end-color);
}

@mixin gradient-horizontal-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient(left, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=1);
}

@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -o-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0);
}

@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: -webkit-radial-gradient(circle, $inner-color, $outer-color);
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}

@mixin gradient-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
  background-image: -webkit-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
  background-image: linear-gradient($angle, $color 25%, transparent 25%, transparent 50%, $color 50%, $color 75%, transparent 75%, transparent);
}

@mixin disabled-striped($color: rgba(255,255,255,.15), $angle: 45deg) {
  background-image: -webkit-linear-gradient($angle, $color 5%, transparent 5%, transparent 10%, $color 10%, $color 15%, transparent 15%, transparent 20%, $color 20%, $color 25%, transparent 25%, transparent 30%, $color 30%, $color 35%, transparent 35%, transparent 40%, $color 40%, $color 45%, transparent 45%, transparent 50%, $color 50%, $color 55%, transparent 55%, transparent 60%, $color 60%, $color 65%, transparent 65%, transparent 70%, $color 70%, $color 75%, transparent 75%, transparent 80%, $color 80%, $color 85%, transparent 85%, transparent 90%, $color 90%, $color 95%, transparent 95%, transparent);
  background-image: -o-linear-gradient($angle, $color 5%, transparent 5%, transparent 10%, $color 10%, $color 15%, transparent 15%, transparent 20%, $color 20%, $color 25%, transparent 25%, transparent 30%, $color 30%, $color 35%, transparent 35%, transparent 40%, $color 40%, $color 45%, transparent 45%, transparent 50%, $color 50%, $color 55%, transparent 55%, transparent 60%, $color 60%, $color 65%, transparent 65%, transparent 70%, $color 70%, $color 75%, transparent 75%, transparent 80%, $color 80%, $color 85%, transparent 85%, transparent 90%, $color 90%, $color 95%, transparent 95%, transparent);
  background-image: linear-gradient($angle, $color 5%, transparent 5%, transparent 10%, $color 10%, $color 15%, transparent 15%, transparent 20%, $color 20%, $color 25%, transparent 25%, transparent 30%, $color 30%, $color 35%, transparent 35%, transparent 40%, $color 40%, $color 45%, transparent 45%, transparent 50%, $color 50%, $color 55%, transparent 55%, transparent 60%, $color 60%, $color 65%, transparent 65%, transparent 70%, $color 70%, $color 75%, transparent 75%, transparent 80%, $color 80%, $color 85%, transparent 85%, transparent 90%, $color 90%, $color 95%, transparent 95%, transparent);
}

@mixin layout-absolute-full-size {
  position: absolute;
  top: 0px;
  left:0px;
  right: 0px;
  bottom: 0px;
}

@mixin align-middle {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin absolute-top-right($offset: $half-unit) {
  position:absolute;
  right: $offset;
  top: $offset;
  vertical-align:top;
}

@mixin absolute-top-left($offset: $half-unit) {
  position:absolute;
  left: $offset;
  top: $offset;
  vertical-align:top;
}


@mixin body-text($color: $sf-black) {
  color: $color;
  font-size: $font-size-base;
  font-weight: 400;
  text-align: left;
}

@mixin header-base {
  font-weight:bold;
}

@mixin header-md {
  @include header-base;
  font-size: $font-size-md;
}

@mixin header-sm {
  @include header-base;
  font-size: $font-size-sm;
}

@mixin z-layer-top {
  z-index: 100;
}
