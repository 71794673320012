.account-content-container {

  .edit-account-container {
    background: $white;
  }

  .edit-account {
    background: $white;
    margin: 0 auto;
    padding-bottom: $double-unit;

    @include screen('md') {
      width: 60%;
    }

    .sf-btn-large {
      margin-top: $base-unit;
      width: 80%;
    }

    .credit-card-icon {
      height: ($double-unit * 2) !important;
      width: 56px!important;
      margin: $half-unit 0;
    }

    @include screen('sm') {
      padding: $base-unit;
    }
    @include screen('lg') {
      padding: $half-unit * 3;
    }

    .add-owner-button {
      cursor: pointer;
      padding: 20px;

      .fa {
        font-size: 16px;
        color:$sf-green;
        margin-right: 10px;
      }
    }

    .edit-company-form-container {
      margin: $base-unit auto;
      padding: $base-unit;

      border-bottom: 1px solid $sf-gray-light;
      @include screen('sm') {
        border: 1px solid $sf-gray-light;
      }

      .company-form {
        @include card-flat;

        &+[data-sf-btn-submit-form] {
          margin-top: $half-unit * 3;
        }

        .sf-form-row {
          padding: $base-unit;
        }
      }

      .section-header {
        font-size: $font-size-lg;
        border-bottom: 1px solid $sf-gray-dark;
        padding: #{$half-unit * 3} $half-unit $half-unit;

        .subtitle {
          padding: $half-unit 0 0;
          font-size: $font-size-sm;
        }
      }

      .identity-forms {
        margin: $base-unit auto;
        max-width: $max-content-width-sm;

        .company-owners-list {
          margin-bottom: $base-unit * 2;
        }

        .header {
          font-size: $font-size-lg;
          border-bottom: 1px solid $sf-gray-dark;
          padding: #{$half-unit * 3} $half-unit;
          background: $sf-gray-light;
        }

        .owner {
          font-size: $font-size-md;
          color: $sf-gray-dark;
          border-bottom: 1px solid $sf-gray-light;
          padding: $half-unit;
          display: block;

          padding: $base-unit;
          cursor: pointer;
          background: $white;
          margin: 0 auto;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0);
          transition: box-shadow 0.3s ease;

          @include screen('xs') {
            border-bottom: 1px solid $sf-gray-base;
          }

          &:hover {
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            position: relative;
            @include z-layer-1;
          }

          &:first-of-type {
            border-top: 1px solid $sf-gray-light;
          }

          .fa {
            font-size: $font-size-xl;
            width: 40px;
            text-align: center;
            vertical-align: middle;
            margin-right: 15px;
          }

          .title {
            vertical-align: middle;
          }
        }

        .add-owner-button {
          cursor: pointer;
          padding: 20px;

          .fa {
            font-size: 16px;
            color:$sf-green;
            margin-right: 10px;
          }
        }
      }
    }

    .edit-company-owner-form-container {
      .btn-submit-form {
        width: calc(33.3% - 12px);

        &:first-child:nth-last-child(2),
        &:first-child:nth-last-child(2) ~ div {
          width: calc(50% - 12px);
        }
      }
    }

    .skip-form {
      position: absolute;
      right: 10px;
      top: 10px;
      color: #666;
      z-index: 100;

      &:before {
        content: "\00BB";
        position: absolute;
        left: -10px;
      }
    }
  }
}
