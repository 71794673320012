.application {
  width: 100%;
  top: 0px;
  left: 0px;

  .application-container {
    position:relative;
    min-height: 100%;
    z-index: 2;
    margin-left: 0;

    @include screen-max('sm') {
      width:100%;
    }
  }
}

.body-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  transition: 0.1s left ease;
  display: none;
}

.page-title {
  @include header-xl;

  padding: $padding-base 0;
  max-width: $max-content-width-sm;
  margin: auto;

  @include screen-max('md') {
    padding: $padding-compact;
  }
}

.page-subtitle {
  @include header-lg;
  text-align: center;

  padding: $padding-half 0;
  max-width: $max-content-width-sm;
  margin: auto;

  @include screen-max('md') {
    padding: $padding-compact;
  }
}

.loading-container {
  text-align: center;
}
