section.listing-published {
  background: $white;

  div.content {
    max-width: $max-content-width-md;
    margin: auto;
    padding: #{$base-unit * 2} $base-unit;
    text-align: center;

    @include screen('sm') {
      padding: #{$base-unit * 2} 0;
    }

    p {
      text-align: center;
      font-size: $font-size-md;
      font-weight: $font-weight-light;
    }

    h2 {
      font-size: $font-size-xxl;
    }
  }
}
