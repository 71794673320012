.sf-form-tag,
.sf-form-hideable-tag{
  color: $sf-gray-dark;
  width: 100%;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  vertical-align: top;
  line-height: $base-unit;
  margin-top: ($base-unit * 1.5);

  @include screen('sm') {
    width: 50%;
  }

  @include screen('xl') {
    width: 33%;
  }

  &:hover {
    color: shade($sf-gray-dark, 25%);
  }

  &.active {
    color: $sf-green;

    .tag-check {
      @include status-base($sf-green);
      border-color: $sf-green;
    }
  }

  .tag-check {
    @include square($base-unit);

    border: 1px solid $sf-gray-base;
    border-radius: $border-radius-sm;
    display: inline-block;
    vertical-align: top;
    position: relative;

    .fa {
      @include absolute-middle;

      font-size: $font-size-xs;
      color: $white;
    }
  }

  .tag-label {
    @include header-sm;

    width: calc(100% - 16px);
    display: inline-block;
    padding-left: 8px;
  }

  .tag-sub-label {
    @include subheader;

    color: $sf-gray-dark;
  }
}

.sf-form-row-inline {
  .sf-form-tag {
    margin-top: 0;
    @include screen-max('md') {
      &:nth-of-type(n+3) {
        margin-top: ($base-unit * 1.5);
      }
    }
    @include screen('sm') {
      &:nth-of-type(n+5) {
        margin-top: ($base-unit * 1.5);
      }
    }
    @include screen-max('sm') {
      &:nth-of-type(n+2) {
        margin-top: ($base-unit * 1.5);
      }
    }
  }
}

.sf-form-hideable-tag-wrapper {
  .transclude-content {
    margin-top: $base-unit;
    font-size: $font-size-xs;

    a {
      text-decoration: underline;
    }
  }
}
